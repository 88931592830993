const Model = require('./models/free-text')
const FormView = require('./views/form')

const init = (serviceLocator, done) => {
  serviceLocator.redemptionTypeFactory.register('freeText', {
    name: 'Free Text',
    FormView,
    Model,
    supportsUniqueCodes: true,
    supportsRedemptionReset: true,
    hasInstructions: true,
    supportsPreRedemptionQuestions: true,
    supportsStockLimits: true
  })
  done()
}

module.exports = () => ({ freeTextRedemptionType: [ 'offerAdmin', init ] })
