const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../../admin/source/js/lib/validate-delegate')()
const validateLength = require('validity-length')
const schema = schemata({
  name: 'Email',
  properties: Object.assign({}, baseSchema.getProperties(), {
    offers: {
      type: Array,
      validators: { all: [validateLength(1)] },
      defaultValue: () => []
    },
    segment: {
      type: String,
      defaultValue: () => false
    },
    conditionsSupplementText: {
      type: String
    }
  })
})

module.exports = BaseModel.extend({
  idAttribute: 'id',

  schemata: schema,

  type: 'splitOffer',

  defaults() {
    return schema.makeDefault({ type: this.type || 'splitOffer' })
  },

  validate: validateDelegate
})
