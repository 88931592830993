const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const ImageAreaModel = require('../../../asset/models/image-area')
const createImageUrlBuilder = require('cf-image-url-builder')
const config = window.config

module.exports = BaseModel.extend({
  schemata,
  defaults () {
    return schemata.makeDefault({ type: this.type || 'membersHero' })
  },
  initialize () {
    this.heroImage = new ImageAreaModel(this.get('heroImage'))
  },
  getPreviewImageUrl () {
    const images = this.heroImage.widgets.map(widget => widget.toJSON())
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Hero')
    if (!image) return null
    return image.crop('Landscape').constrain(300).url()
  },
  validate: validateDelegate
})
