const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, redemptionExperienceIndex) {
buf.push("<div class=\"js-download-voucher-notification notification notification--notice\">Users will be able to <strong>download a voucher</strong> to redeem this offer.</div><div" + (jade.attr("id", 'field--redemptionVoucherImage' + redemptionExperienceIndex, true, false)) + (jade.attr("data-field", 'redemptionVoucherImage' + redemptionExperienceIndex, true, false)) + " class=\"form-row js-redemption-voucher-image\"><label><span class=\"form-label-text\">Voucher Image<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field\"><button type=\"button\" class=\"btn js-btn-choose-asset\">Choose an image</button></div></label><div class=\"form-field asset-preview js-asset-preview\"></div><div class=\"js-error\"></div></div><div id=\"field--redemptionVoucherOptions\"" + (jade.attr("data-field", 'redemptionVoucherOptions' + redemptionExperienceIndex, true, false)) + " class=\"form-row form-row-boolean form-row js-redemption-voucher-options\"><span class=\"form-label-text\">Voucher Content</span><div class=\"form-field\"><ul><li><label><input type=\"checkbox\" name=\"displayCustomerDetails\"" + (jade.attr("checked", data.displayCustomerDetails, true, false)) + " class=\"control control--boolean\"/><span>Display Customer Details on Voucher</span></label></li></ul></div><div class=\"js-error\"></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"redemptionExperienceIndex" in locals_for_with?locals_for_with.redemptionExperienceIndex:typeof redemptionExperienceIndex!=="undefined"?redemptionExperienceIndex:undefined));;return buf.join("");
}
const AssetCollection = require('../../../asset/collections/asset')
const AssetPickerView = require('../../../asset/views/picker')
const modal = require('modal')
const createDarkroomUrlBuilder = require('darkroom-url-builder')

class FormView extends View {
  constructor (serviceLocator, model, redemptionExperienceIndex) {
    super(serviceLocator)
    this.serviceLocator = serviceLocator
    this.redemptionExperienceIndex = redemptionExperienceIndex
    this.model = model
    this.$el.on('change', this.applyChangeToModel.bind(this))
    this.darkroomUrl = this.serviceLocator.config.darkroom.url
    this.darkroomSalt = this.serviceLocator.config.darkroom.salt
    this.$el.on('click', '.js-btn-choose-asset', this.selectAsset.bind(this))
    this.listenTo(this.model, 'change:redemptionVoucherImage', this.updateAssetPreview.bind(this))
  }

  applyChangeToModel (e) {
    if (this.model.schema.getProperties.hasOwnProperty(e.target.name)) {
      if (e.target.type === 'checkbox') {
        this.model.set(e.target.name, e.target.checked)
      } else {
        this.model.set(e.target.name, e.target.value)
      }
    }
  }

  render () {
    this.$el.append(template({
      data: this.model.toJSON(),
      redemptionExperienceIndex: this.redemptionExperienceIndex
    }))
    this.updateAssetPreview()
    return this
  }

  selectAsset () {
    const assets = new AssetCollection()
    const assetPicker = new AssetPickerView({
      collection: assets,
      type: 'image',
      serviceLocator: this.serviceLocator
    })

    assets.getByType('image')
    modal({
      title: 'Voucher Image',
      className: 'modal-asset-view wide',
      content: assetPicker.$el,
      buttons: [
        {
          text: 'Cancel',
          event: 'Cancel',
          keyCodes: [ 27 ],
          className: 'btn'
        },
        {
          text: 'Choose selected image',
          event: 'add',
          className: 'btn btn--action',
          keyCodes: [ 13 ]
        }
      ]
    })
      .on('add', this.updateAsset.bind(this, assetPicker.selectedCollection))
      .on('close', assetPicker.remove.bind(assetPicker))
  }

  updateAsset (collection) {
    if (collection.length) {
      return this.model.set({ redemptionVoucherImage: collection.models[0].get('binaryUri') })
    }
    this.model.set({ redemptionVoucherImage: null })
  }

  updateAssetPreview () {
    this.$el.find('.js-asset-preview').empty()
    const uri = this.model.get('redemptionVoucherImage')
    if (!uri) return

    const urlBuilder = createDarkroomUrlBuilder(this.darkroomUrl, this.darkroomSalt)
    const img = new Image()

    img.src = urlBuilder().resource(uri).width(400).filename('preview.jpg').url()
    this.$el.find('.js-asset-preview').append(img)
  }
}

module.exports = FormView
