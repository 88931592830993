const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (className, data) {
buf.push("<div" + (jade.attr("classname", className, true, false)) + " class=\"panel\"><form><div id=\"titlePosition\" data-field=\"titlePosition\" class=\"form-row form-row-boolean\"><span class=\"form-label-text\">Title Position </span><div class=\"form-field\"><select name=\"alignment\" class=\"control control--choice form-field\"><option value=\"Left\"" + (jade.attr("selected", data.alignment === 'Left', true, false)) + ">Left</option><option value=\"Center\"" + (jade.attr("selected", data.alignment === 'Center', true, false)) + ">Center</option></select></div><div class=\"form-row-description form-copy\"><p>Select whether the title will be left aligned or centered </p></div></div><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div></form></div>");}.call(this,"className" in locals_for_with?locals_for_with.className:typeof className!=="undefined"?className:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const BaseWidgetView = require('../../../../widget/views/form/base')
const debug = require('../../../../../../admin/source/js/lib/debug')('offer layout social sharing widget view')

module.exports = BaseWidgetView.extend({ template: template, debug: debug })
