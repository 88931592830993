const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListView {
  constructor (serviceLocator, collection, paginationModel, tagTypes) {
    super(serviceLocator, collection, paginationModel)
    this.tagTypes = tagTypes
  }

  get FilterView () {
    return FilterView
  }

  get ListItemView () {
    return ListItemView
  }

  get name () {
    return { singular: 'Tag', plural: 'Tags', resource: 'tag' }
  }
}

module.exports = ListView
