const compileJade = require('browjadify-compile')
const path = require('path')
const templates = {
  fixed: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search<div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Fashion Week&quot; ] or [ Fashion -Week ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Sort By</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"desc\">Newest First</option><option value=\"asc\">Oldest First</option></select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
},
  condensed: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<form action=\"#\" method=\"get\" class=\"filter-condensed\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text vhidden\">Keywords</span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><button class=\"btn btn--action js-filter\">Filter</button></div><input type=\"hidden\" name=\"sort\" value=\"desc\"/></form>");;return buf.join("");
}
}
const BaseView = require('cf-base-view')

module.exports = BaseView.extend({
  className: 'list-filters',

  events: {
    'submit form': 'handleSubmit',
    'click input[type=reset]': 'handleClear'
  },

  initialize () {
    this.render()
  },

  handleSubmit (e) {
    e.preventDefault()
    var params = { filter: {}, sort: [] }

    params.sort[0] = 'created'
    params.sort[1] = this.$('form [name=sort]').val()

    var keywords = this.$('form [name=keywords]').val().split(/\W+/)
    keywords = keywords.filter(keyword => !!keyword)
    if (keywords.length) {
      params.keywords = keywords
    }

    this.trigger('filter', params)
  },

  handleClear () {
    this.trigger('filter', null)
  },

  render () {
    this.$el.empty().append(templates[this.options.layout](this.options))
    this.$('.js-tooltip-trigger').tooltip({ html: true })
  }
})
