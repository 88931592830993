const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, title) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\">");
if ( allowed('asset', 'delete'))
{
buf.push("<button type=\"button\" class=\"btn js-delete\">Delete Selected</button>");
}
if ( allowed('asset', 'create'))
{
buf.push("<div class=\"btn-group\"><button type=\"button\" class=\"btn js-tag\">Tag Selected</button></div><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn dropdown-toggle\">Selection (<span class=\"js-selection-count\">0 items</span>) <span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a class=\"js-selection-add\">Add visible to selection</a></li><li><a class=\"js-selection-clear\">Clear selection</a></li></ul></div>");
}
buf.push("</div><span class=\"label label--large label--notice\">Drop files anywhere on this page to upload</span></div>");
if ( allowed('asset', 'create'))
{
buf.push("<div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn btn--action js-upload\">Choose files</button></div><input type=\"file\" value=\"Choose File\" multiple=\"multiple\" class=\"control control--file js-file-input asset-file-input\"/></div>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><div class=\"grid\"><div class=\"grid__item three-quarters\"><div class=\"js-in-progress\"></div><div class=\"js-grid-view\"><div class=\"panel\"><div class=\"grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div><div class=\"grid__item one-quarter js-list-filter\"></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const InProgressView = require('./in-progress')
const createFileUploader = require('../lib/file-uploader')
const AssetCollection = require('../collections/asset')
const debug = require('../../../../admin/source/js/lib/debug')('article list view')
const GenericListView = require('../../../../admin/source/js/lib/generic/list-view')
const ListFilterView = require('./list-filter')
const ListItemView = require('./list-item')
const uploadUiDelegate = require('../lib/upload-ui-delegate')
const tagTypes = require('./tag-config.json')
const createListTagDelegate = require('../lib/list-tag-delegate')
const bindAll = require('lodash.bindall')
const AccountSelectView = require('./account-select')
const modal = require('modal')

module.exports = GenericListView.extend({
  events: {
    'click .js-upload': 'showFileInput',
    'click .js-more': 'loadMore',
    'click .js-tag': 'handleTag',
    'click .js-delete': 'handleDelete',
    'click .js-selection-add': 'addToSelection',
    'click .js-selection-clear': 'clearSelection'
  },

  template: template,
  itemContainer: '.js-grid-view .js-items',

  createListItemView (model) {
    var view = new ListItemView(
      { model: model,
        perRow: 5,
        serviceLocator: this.options.serviceLocator
      })

    if (this.selectedCollection.get(model.id)) {
      view.select()
    }

    return view
  },

  initialize () {
    // Ensure all instance methods of this view are
    // executed in the context 'this' for convenience
    bindAll(this)

    // Create an empty collection to hold in-progress uploads
    this.inProgressCollection = new AssetCollection()
    // Listen to the in progress collection for finished uploads
    this.listenTo(this.inProgressCollection, 'uploadEnd', this.onUploadEnd)

    this.listenTo(this.collection, 'filter', this.maintainOrder)

    this.listenTo(this.collection, 'delete', model => {
      var self = this
      model.destroy({ success () {
        self.collection.applyFilter()
      }
      })
    }, this)

    this.on('remove', this.onRemove)

    GenericListView.prototype.initialize.call(this)
  },

  initializeUploader () {
    this.fileUploader = createFileUploader(this.$el)
    this.fileUploader.on('add', this.addUpload.bind(this))
    uploadUiDelegate(this)
  },

  /*
   * Add a new model created by the uploader
   * to the in progress collection.
   */
  addUpload (model) {
    debug('Got model for a new upload', model)
    this.inProgressCollection.add(model)
  },

  loadMore () {
    this.collection.loadMore()
  },

  /*
   * Show the file input dialog.
   */
  showFileInput () {
    this.$('.js-file-input').click()
  },

  /*
   * Tidy up events and external plugins for when
   * the view is to be disposed of.
   */
  onRemove () {
    debug('removing fileupload behaviour')

    // Only created if upload is allowed
    if (this.options.serviceLocator.allowed('asset', 'create')) {
      // Remove the fileupload behaviour
      this.fileUploader.trigger('destroy')
    }
  },

  clearSelection () {
    this.trigger('clearSelection')
  },

  addToSelection () {
    this.trigger('addToSelection')
  },

  onSelectionChange () {
    var count = this.selectedCollection.length
    this.$('.js-selection-count').text(count + (count === 1 ? ' item' : ' items'))
  },

  handleTag () {
    debug('Bulk tag')
    const listTag = createListTagDelegate(this.options.serviceLocator, tagTypes)
    listTag(this.selectedCollection, model => model.save(), () => this.trigger('clearSelection'))
  },

  /*
   * Handle a completed upload.
   */
  onUploadEnd (model) {
    const onSuccess = () => {
      debug('Adding model to asset collection', model)
      this.collection.applyFilter()
    }
    const account = this.options.serviceLocator.session.account
    if (!account) {
      const accountSelectView = new AccountSelectView(this.options.serviceLocator)
      const m = modal({
        title: 'Select an account',
        content: accountSelectView.$el,
        clickOutsideToClose: false,
        buttons: []
      })
      accountSelectView.on('submit', account => {
        model.set('account', account)
        model.save(null, { success: onSuccess })
        m.close()
      })
    } else {
      model.set('account', account)
      model.save(null, { success: onSuccess })
    }
  },

  render () {
    this.$el.empty().append(template(
      { title: this.options.title,
        allowed: this.options.serviceLocator.allowed }))

    const inProgressView = new InProgressView({ collection: this.inProgressCollection })
    const listFilterView = new ListFilterView({ serviceLocator: this.options.serviceLocator })

    this.attachView(inProgressView)
    this.attachView(listFilterView)

    listFilterView.on('filter', params => {
      this.collection.applyFilter(params)
    })

    this.$('.js-in-progress').append(inProgressView.$el)
    this.$('.js-list-filter').append(listFilterView.$el)

    if (this.options.serviceLocator.allowed('asset', 'create')) {
      this.initializeUploader()
    }

    return this
  }
})
