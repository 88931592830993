const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()

const schema = schemata({
  name: 'Subscriber Benefits',
  properties: Object.assign({}, baseSchema.getProperties(), {
    openSubscribeLinkInNewTab: {
      type: Boolean
    }
  })
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'subscriberBenefits' })
  },
  validate: validateDelegate
})
