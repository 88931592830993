const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const isUrl = require('validity-url')
const createContextValidator = require('validity-cf-image-context-selection')
const ImageAreaModel = require('../../../../asset/models/image-area')
const config = window.config
const createImageUrlBuilder = require('cf-image-url-builder')

const schema = schemata({
  name: 'Content Stack Item',
  properties: {
    title: {
      type: String,
      validators: { all: [ required ] }
    },
    copy: {
      type: String,
      validators: { all: [ required ] }
    },
    linkUrl: {
      type: String,
      validators: { all: [ isUrl ] }
    },
    linkText: {
      type: Boolean,
      validators: { all: [ required ] }
    },
    image: {
      type: Object,
      defaultValue: () => ({}),
      validators: {
        all: [ createContextValidator([ 'Content Stack' ]) ]
      }
    }
  }
})

module.exports = BaseModel.extend({
  schema,

  defaults () {
    return schema.makeDefault()
  },

  initialize () {
    this.image = new ImageAreaModel(this.get('image'))
  },

  getPreviewImageUrl () {
    const images = this.image.widgets.map(widget => widget.toJSON())
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Content Stack')
    if (!image) return null
    return image.crop('Landscape').constrain(300).url()
  },

  validate (cb) {
    this.schema.validate(this.attributes, (ignoreErr, errors) =>
      cb(Object.keys(errors).length > 0 ? errors : undefined)
    )
  }
})
