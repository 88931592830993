import moment from 'moment'

const flattenArray = require('../../array-flattener')

const subtitleInfoMap = (category) => {
  switch (category) {
    case 'Competition':
      return 'Entries'
    case 'Event':
      return 'Entries'
    default:
      return 'Redemptions'
  }
}

const createHeaders = (sortedUniqueCategories, startDate, endDate) => {
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD')
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD')
  const title = flattenArray(
    sortedUniqueCategories.map((category) => [
      `Top 5 ${category}s for ${formattedStartDate} - ${formattedEndDate}`,
      ''
    ])
  )
  const subtitle = flattenArray(
    sortedUniqueCategories.map((category) => [
      'Headlines',
      subtitleInfoMap(category)
    ])
  )
  return [ title, subtitle ]
}

module.exports = createHeaders
