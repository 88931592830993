const join = require('path').join
const View = require('ventnor')
const InstanceSectionSelect = require('../../../section/views/instance-section-select')
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"field--section\" data-field=\"section\" class=\"form-row js-section-select-form-row\"><span class=\"form-label-text\">Section</span><div class=\"js-instance-sections form-field\"></div><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Choose the section that this navigation item will link to</p></div></div>");;return buf.join("");
}

class NavigationSectionField extends View {
  constructor (serviceLocator, item, instance) {
    super(...arguments)
    this.item = item
    this.instance = instance
  }

  render () {
    this.$el.append(template())

    this.serviceLocator.sectionService.find('', { instance: this.instance._id }, [], {}, (error, res) => {
      if (error) return this.serviceLocator.logger.error('Cannot find sections', error)
      const sectionIds = []
      res.results.forEach(section => {
        sectionIds.push(section._id)
      })

      const instanceSectionSelect = new InstanceSectionSelect(this.serviceLocator, this.item.section, sectionIds)
      this.$el.find('.js-instance-sections').append(instanceSectionSelect.render().$el)
      instanceSectionSelect.on('change', section => this.emit('change', section))
      this.attachView(instanceSectionSelect)
    })

    return this
  }
}

module.exports = NavigationSectionField
