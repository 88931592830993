// Handle the save for a backbone form
// * Map the form data based on the models schema
// * If there has been change save the data
// * If the sync returns errors show on form

const mapFormToObject = require('cf-map-form-to-object')
const formErrorsDelegate = require('./form-errors-delegate')()
const isEqual = require('lodash.isequal')

module.exports = function (debug) {
  return function formSaveDelegate (cb) {
    const model = this.model
    const view = this
    const form = this.$el.find('form')
    let formData
    const cbMode = typeof cb === 'function'

    // Tell rich text editor to update the value/text
    // of the underlying form element
    Object.keys(window.CKEDITOR.instances).forEach(function (key) {
      window.CKEDITOR.instances[ key ].updateElement()
    })

    // Rich text editor needs to update the
    // form element before the data is mapped
    formData = mapFormToObject(form, model.schemata)

    view.showErrors = view.showErrors || formErrorsDelegate.showErrors
    view.clearErrors = view.clearErrors || formErrorsDelegate.clearErrors

    model.set(formData)

    function onSaveSuccess () {
      if (view.unsavedChanges) view.unsavedChanges = false
      debug('saving model success', model.toJSON())
      if (cbMode) return cb(null, true)
      view.trigger('save')
    }

    function onSaveError (model, jqxhr) {
      debug('saving model failed', model.toJSON())
      // Firefox doesn't report the content-type so assume json
      const contentType = jqxhr.getResponseHeader('Content-Type') || 'application/json'
      let error

      if (contentType.indexOf('application/json') !== -1) {
        error = JSON.parse(jqxhr.responseText)

        // Incase wierd errors leak up to the frontend
        if (error.err) {
          error.errors = { error: error.err }
        }
        view.showErrors(error.errors)
      }
      if (cbMode) return cb(null, false)
    }

    debug('comparing initial model', this.initialModel, this.model.toJSON())

    if (model.isNew() || !isEqual(this.initialModel, this.model.toJSON())) {
      debug('saving model ', model.toJSON())
      model.save(null, { success: onSaveSuccess, error: onSaveError })
    } else {
      debug('not saving, nothing changed')
      view.trigger('save')
      if (cbMode) return cb(null, true)
    }
  }
}
