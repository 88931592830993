const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const urlValidity = require('validity-url-optional-tlds')()
const createUniqueValidator = require('validity-unique-property')
const parseUrl = require('url').parse
const scheduledRedirectSchema = require('./scheduled-redirect-schema')

const urlOrPathValidator = (key, keyDisplayName, object, cb) => {
  if (object[key].substr(0, 4) === 'http') {
    return urlValidity(key, keyDisplayName, object, cb)
  }
  return urlPathValidator(key, keyDisplayName, object, cb)
}

const urlPathValidator = (key, keyDisplayName, object, cb) => {
  if (object[key].substr(0, 1) !== '/') {
    return cb(null, keyDisplayName + ' should be a valid URL path')
  }
  return cb(null, undefined)
}

module.exports = (serviceLocator, save) => {
  const findLoop = (redirect, cb) => {
    const conditions = [
      { redirectUrl: redirect.path },
      { redirectUrl: serviceLocator.config.url + redirect.path },
      { path: redirect.redirectUrl }
    ]
    const urlParts = parseUrl(redirect.redirectUrl)

    if (urlParts.hostname === serviceLocator.config.hostname) {
      conditions.push({ path: urlParts.pathname })
    }

    save.count({ $or: conditions }, cb)
  }

  const noLoopValidator = (key, keyDisplayName, obj, cb) => {
    findLoop(obj, (err, count) => {
      var message = null

      if (err) return cb(err)

      if (count !== 0) {
        message =
          obj.path +
          ' to ' +
          obj.redirectUrl +
          ' may cause an infinite redirection loop'
      }
      cb(null, message)
    })
  }

  const notEqualRedirectValidator = (key, keyDisplayName, obj, cb) => {
    const pathUrl = serviceLocator.config.url + obj.path
    let message = null

    if (obj.redirectUrl === pathUrl) {
      message = 'The path can not equal the redirect URL'

      return cb(null, message)
    }

    cb()
  }

  return schemata({
    name: 'Redirect',
    properties: {
      _id: {
        type: String
      },
      path: {
        type: String,
        validators: {
          all: [
            required,
            urlPathValidator,
            notEqualRedirectValidator,
            createUniqueValidator(save.findOne)
          ]
        }
      },
      redirectUrl: {
        type: String,
        name: 'Redirect URL',
        validators: {
          all: [required, urlOrPathValidator, noLoopValidator]
        }
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      account: {
        type: String,
        validators: { all: [required] }
      },
      scheduledRedirects: {
        type: schemata.Array(scheduledRedirectSchema())
      }
    }
  })
}
