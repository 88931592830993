const determineFinancialYear = require('../../../../../worker/tasks/year-on-year-redemption-report/lib/financial-year-determiner')
const createRowTemplate = require('../../row-template-creator')

const determineUniqueYears = (data) => [ ...new Set(data
  .map(a => determineFinancialYear(a.month, a.year))) ]
  .sort((a, b) => a - b) || []

const createRedemptionsData = (data) => {
  const redemptionsData = []
  const uniqueYears = determineUniqueYears(data)

  uniqueYears.forEach(financialYear => {
    const rowDescription = `FY${financialYear} offer redemptions`
    const rowTemplate = createRowTemplate('monthNumbersNoBuffer')

    const filteredData = data.filter(datapoint => determineFinancialYear(datapoint.month, datapoint.year) === financialYear)

    filteredData.forEach(dataPoint => {
      const foundRowSlot = rowTemplate.find(rowValue => rowValue.month === parseInt(dataPoint.month))

      if (foundRowSlot) foundRowSlot.value = dataPoint.value
    })

    rowTemplate.unshift({ value: rowDescription })

    redemptionsData.push(rowTemplate)
  })

  return redemptionsData
}

module.exports = createRedemptionsData
