const schemata = require('@clocklimited/schemata')

module.exports = () =>
  schemata({
    name: 'List Item',
    properties: {
      itemId: {
        type: String,
        tag: [ 'manual' ]
      },
      isCustom: {
        type: Boolean,
        defaultvalue: false
      },
      overrides: {
        type: Object,
        defaultvalue: () => ({})
      },
      properties: {
        type: Object,
        defaultvalue: () => ({})
      }
    }
  })
