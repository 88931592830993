const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListView {
  get FilterView () {
    return FilterView
  }

  get ListItemView () {
    return ListItemView
  }

  get name () {
    return { singular: 'Role', plural: 'Roles', resource: 'role' }
  }
}

module.exports = ListView
