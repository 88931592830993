const ListView = require('../views/list')
const Collection = require('chale')
const OfferModel = require('../models/offer')
const Model = require('merstone')
const async = require('async')
const pageSize = 50
const moment = require('moment')
const crypto = require('crypto')
const createAuthedRequest = require('../../../../admin/source/js/lib/authed-request')
const downloadFile = require('../../../../admin/source/js/lib/download-file')

module.exports = serviceLocator => {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, { totalItems: 0, showing: 0 })
  let currentParams = { keywords: '', filter: {}, sort: [ 'dateCreated', 'desc' ] }
  let currentPage = 1
  const authedRequest = createAuthedRequest(window.config.apiUrl)

  const getOffers = (keywords, filter, sort, pagination) => {
    serviceLocator.offerService.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err) return serviceLocator.logger.error('Could not load offers', err)
      collection.reset(res.results.map(offer => new OfferModel(serviceLocator, offer)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendOffers = (keywords, filter, sort, pagination) => {
    serviceLocator.offerService.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(offer => {
        collection.add(new OfferModel(serviceLocator, offer))
      })
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.offerService.on('create', () => {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getOffers(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
  })

  // Whenever an offer is updated, reset the model with its new attributes
  serviceLocator.offerService.on('update', (id, attrs) => {
    var model = collection.get(id)
    if (model) model.reset(attrs)
  })

  serviceLocator.router.route('offers(/)', 'listOffers', () => {
    // Pre-populate the list's collection with a set of offers
    var pagination = { page: currentPage, pageSize: pageSize }
    getOffers(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)

    var list = new ListView(serviceLocator, collection, paginationModel).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      serviceLocator.router.navigate('offers/form', { trigger: true })
    })

    list.on('edit', id => {
      serviceLocator.router.navigate('offers/' + id + '/form', { trigger: true })
    })

    const getPreviewUrlComponents = (offer, cb) => {
      async.waterfall(
        [ cb => {
          const query = { _id: { $in: offer.get('sections') } }
          serviceLocator.sectionService.find('', query, [], {}, (err, res) => {
            if (err) return cb(err)
            return cb(null, { sections: res.results })
          })
        },
        (obj, cb) => {
          if (obj.sections.length > 0) {
            const query = { _id: { $in: obj.sections.map(s => s.instance) } }
            serviceLocator.instanceService.find('', query, [], {}, (err, res) => {
              if (err) return cb(err)
              if (res.results.length > 1) {
                obj.instances = res.results
              } else {
                obj.instance = res.results[0]
              }
              return cb(null, obj)
            })
          } else {
            serviceLocator.instanceService.find('', {}, [], {}, (err, res) => {
              if (err) return cb(err)
              obj.instance = res.results[0]
              return cb(null, obj)
            })
          }
        },
        (obj, cb) => {
          if (obj.instance) {
            serviceLocator.accountService.read(obj.instance.account, (err, account) => {
              if (err) return cb(err)
              obj.account = account
              return cb(null, obj)
            })
          } else {
            const query = { _id: { $in: obj.instances.map(i => i.account) } }
            serviceLocator.accountService.find('', query, [], { }, (err, res) => {
              if (err) return cb(err)
              if (res.results.length > 1) {
                obj.accounts = res.results
              } else {
                obj.account = res.results[0]
              }
              return cb(null, obj)
            })
          }
        }
        ], cb)
    }

    list.on('preview', offer => {
      getPreviewUrlComponents(offer, (err, obj) => {
        if (err) {
          return serviceLocator.logger.error('Could not preview offer', err)
        }
        const sections = obj.sections.map(section => {
          const instance = obj.instance || obj.instances.find(instance => instance._id === section.instance)
          const account = obj.account || obj.accounts.find(account => account._id === section.account)
          const url = section.fullUrlPath + '/' + offer.get('slug')
          return {
            name: `${instance.name} - ${section.name}`,
            url: serviceLocator.instanceService.createUrl(instance, account) +
                url + '?previewId=' + offer.get('previewId')
          }
        })

        list.emit('previewDialog', sections, offer.get('openingDate') || new Date())
      })
    })

    list.on('datePreview', (offer) => {
      const w = window.open('')
      const now = moment().format(serviceLocator.config.formats.isoZ)

      async.parallel(
        { url: getPreviewUrlComponents.bind(null, offer)
        }, (err, obj) => {
          if (err) {
            w.close()
            return serviceLocator.logger.error('Could not preview offer', err)
          }
          let url = serviceLocator.instanceService.createUrl(obj.url.instance, obj.url.account)
          const hash = crypto.createHash('sha1').update(now).digest('hex')

          url += '/date-preview' + offer.get('__fullUrlPath') + '?date=' +
            now + '&dateHash=' + hash
          w.location = url
        })
    })

    list.on('duplicate', model => {
      serviceLocator.router.navigate('offers/' + model.id + '/duplicate', { trigger: true })
    })

    list.on('exportPuff', offerIds => {
      const url = '/offers/puff-export'
      authedRequest('POST', url, { offerIds }, { binary: true }, (err, res, body) => {
        if (err) alert(err.message)
        if (res.statusCode >= 300) return alert('Could not export puff')

        const filename = 'puff-export.pdf'
        const pdfBlob = new Blob([ body ])

        downloadFile(filename, URL.createObjectURL(pdfBlob))
      })
    })

    list.on('delete', ids => {
      const deleteOne = (id, cb) => {
        serviceLocator.offerService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
        })
      }

      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
      })
    })

    list.on('filter', params => {
      currentParams = params
      var pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getOffers(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendOffers(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
    })

    serviceLocator.router.render(list, 'Offers')
  })
}
