const BaseListView = require('../../base/views/list')

class ListView extends BaseListView {
  addListItem (model) {
    const listItem = BaseListView.prototype.addListItem.apply(this, arguments)
    this.listenTo(listItem, 'showRevisions', this.emit.bind(this, 'showRevisions', model))
    return listItem
  }
}

module.exports = ListView
