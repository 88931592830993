module.exports = initWidget

function initWidget () {
  return { offerLayoutTermsWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init (serviceLocator, done) {
  serviceLocator.widgetFactories.get('offer').register('offerLayoutTerms',
    { model: require('./models/model'),
      name: 'Offer Terms',
      itemView: require('./views/item'),
      description: 'Placeholder for the offer terms and conditions'
    })

  done()
}
