const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\"><label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label></div><h2><a href=\"#base\" class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}

class ListItemView extends View {
  constructor (serviceLocator, model, index, totalItems, name) {
    super(...arguments)
    this.model = model
    this.index = index
    this.totalItems = totalItems
    this.resource = name ? name.resource : null
    this.$el.addClass('grid__item one-whole')
    this.listenTo(this.model, 'select', this.select.bind(this))
    this.listenTo(this.model, 'deSelect', this.deSelect.bind(this))

    this.listenTo(this.model, 'reset', this.render.bind(this))
    this.$el.on('click', '.js-edit', this.handleEdit.bind(this))
    this.$el.on('click', '.js-select', this.toggleSelect.bind(this))
  }

  get template () {
    return template
  }

  handleEdit (e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if ((e.which === 2) || e.metaKey) return false
    e.preventDefault()
    this.emit('edit')
  }

  toggleSelect (e) {
    var isChecked = $(e.target).is(':checked')
    this.model.emit(isChecked ? 'select' : 'deSelect', this.model)
  }

  select () {
    this.$el.find('.js-select')[0].checked = true
  }

  deSelect () {
    this.$el.find('.js-select')[0].checked = false
  }

  render () {
    this.$el.empty().append(this.template(
      { data: this.model.toJSON(),
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
        index: this.index,
        totalItems: this.totalItems
      }))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
