const ReorderView = require('../views/reorder')
const SectionCollection = require('../collections/lite-section')
const debug = require('../../../../admin/source/js/lib/debug')('router')

module.exports = serviceLocator => {
  const router = serviceLocator.router
  const collection = new SectionCollection()

  const bindSaveAndCancel = (view) => {
    view.on('save cancel', () => {
      router.navigate('sections', { trigger: true })
    })
    return view
  }

  router.route('sections/reorder/:instance', 'reorderSections', instance => {
    if (!serviceLocator.allow('section', 'reorder')) return false

    var reorderView = new ReorderView({ collection: collection, serviceLocator: serviceLocator, instance: instance })
    debug('section list reorder view route triggered')

    router.render(bindSaveAndCancel(reorderView), 'Reorder Sections')
    collection.applyFilter({ filter: { instance: instance } })
  })
}
