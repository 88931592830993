const join = require('path')
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, name) {
jade_mixins["visibilityMap"] = jade_interp = function(visibility, userVisibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( visibility)
{
if (userVisibility === 'all' || userVisibility === undefined) 
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to all&lt;/strong&gt;&lt;br&gt;This widget is visible to logged in and logged out users.\" class=\"label label--large label--success js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else if (userVisibility === 'loggedInOnly')
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to logged in users&lt;/strong&gt;&lt;br&gt;This widget will not appear for logged out users.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else if (userVisibility === 'loggedOutOnly')
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to logged out users&lt;/strong&gt;&lt;br&gt;This widget will not appear for logged in users.\" class=\"label label--large label--warning js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
};
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
jade_mixins["visibilityMap"](model.get('visible'), model.get('userVisibility'));
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\"><ul class=\"content-list-lists js-content-list-lists\"></ul><hr/><dl>");
if ( model.get('title'))
{
buf.push("<dt>Title:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('title')) ? "" : jade_interp)) + "</dd>");
}
if ( model.get('subtitle'))
{
buf.push("<dt>Subtitle:</dt><dd>" + (null == (jade_interp = model.get('subtitle')) ? "" : jade_interp) + "</dd>");
}
if ( model.get('section'))
{
buf.push("<dt>Linked Section:</dt><dd class=\"js-section-name\"></dd>");
}
if ( model.get('callToActionButtonText'))
{
buf.push("<dt>Section Link Text:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('callToActionButtonText')) ? "" : jade_interp)) + "</dd>");
}
if ( model.get('segment'))
{
buf.push("<dt>Segment:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('segment')) ? "" : jade_interp)) + "</dd>");
}
if ( model.get('limit'))
{
buf.push("<dt>Limit:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('limit')) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Offer Duplication:</dt>");
if ( model.get('dedupe'))
{
buf.push("<dd>Offers<em>will</em> be deduped</dd>");
}
else
{
buf.push("<dd>Offers<em>will not</em> be deduped</dd>");
}
buf.push("<dt>Width:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('width')) ? "" : jade_interp)) + "</dd>");
if ( model.get('emphasisedWordLocation'))
{
buf.push("<dt>Emphasised Word Location:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('emphasisedWordLocation')) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Background Colour:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('backgroundColour')) ? "" : jade_interp)) + "</dd></dl></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const ListEditItemView = require('../../../widget/views/item/edit-base')

module.exports = ListEditItemView.extend({
  template,

  render () {
    ListEditItemView.prototype.render.apply(this)

    this.renderSection()
  },

  renderSection () {
    if (this.model.get('linkedSection')) {
      this.options.serviceLocator.sectionService.read(this.model.get('linkedSection'), (error, section) => {
        if (error) return console.error(error)
        this.$el.find('.js-section-name').append(section.name)
      })
    }
  }
})
