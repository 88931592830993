const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (instances, undefined) {
buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search <div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;End of Season&quot; ] or [ End -Season ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div>");
if ( instances.length > 1)
{
buf.push("<div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Instance</span><select name=\"instance\" class=\"control control--choice form-field\"><option value=\"\">All Instances</option>");
// iterate instances
;(function(){
  var $$obj = instances;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var instance = $$obj[$index];

buf.push("<option" + (jade.attr("value", instance._id, true, false)) + ">" + (jade.escape(null == (jade_interp = instance.name) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var instance = $$obj[$index];

buf.push("<option" + (jade.attr("value", instance._id, true, false)) + ">" + (jade.escape(null == (jade_interp = instance.name) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label></div>");
}
buf.push("<div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");}.call(this,"instances" in locals_for_with?locals_for_with.instances:typeof instances!=="undefined"?instances:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class ListFilterView extends View {
  constructor (serviceLocator, listView) {
    super(...arguments)
    this.instances = listView.instances
    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
    this.$el.on('change', '[name=instance]', this.handleSubmit.bind(this))

    // Compatibility with backbone admin
    this.trigger = super.emit
  }

  updateDisplay (params) {
    this.$el.find('form [name=keywords]').val(params.keywords)

    if (params.filter.instance) {
      this.$el.find('[name=instance] option[value=' + params.filter.instance + ']').attr('selected', true)
    }
  }

  handleSubmit (e) {
    if (e) e.preventDefault()

    const params = { filter: {}, sort: [ 'order', 'asc' ] }
    const keywords = this.$el.find('form [name=keywords]').val()
    const instance = this.$el.find('form [name=instance]').val()

    if (instance) {
      params.filter.instance = instance
    }

    if (keywords.length) params.keywords = keywords

    this.emit('filter', params)
  }

  handleClear (e) {
    e.preventDefault()
    this.$el.find('form [name=keywords]').val('')
    this.$el.find('form [name=instance]').val('')
    this.handleSubmit()
  }

  render () {
    this.$el.empty().append(template({ instances: this.instances }))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListFilterView
