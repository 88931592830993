const retrieveIncrementalData = (row, index, transformedRow) => {
  const currentMonth = row[index]

  if (!currentMonth) return transformedRow

  const currentMonthValue = currentMonth.value
  const previousMonth = row[index - 1]
  const previousMonthValue = (previousMonth && previousMonth.value)

  let currentValue = (currentMonthValue - previousMonthValue) || 0

  if (currentMonthValue === 0) {
    currentValue = 0
  }

  transformedRow[index] = { value: currentValue }

  const updatedIndex = index + 1

  return retrieveIncrementalData(row, updatedIndex, transformedRow)
}

module.exports = retrieveIncrementalData
