const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Biography',
    itemView: require('./views/item'),
    description: 'Biography widget'
  }

  serviceLocator.widgetFactories.get('offerBody').register('biography', widget)

  done()
}

module.exports = () => ({ biogrpahyWidget: [ 'widget', 'sectionAdmin', init ] })
