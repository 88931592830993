import Model from './models/model'
import ItemView from '../../widget/views/item/base'

const init = (serviceLocator, done) => {
  const widget = {
    model: Model,
    itemView: ItemView,
    name: 'Random Offers',
    description: 'Shows a random selection of offers'
  }

  serviceLocator.widgetFactories.get('offer').register('randomOffers', widget)
  serviceLocator.widgetFactories.get('relatedOffer').register('randomOffers', widget)

  done()
}

module.exports = () => ({ randomOffersWidget: [ 'widget', 'sectionAdmin', init ] })
