const View = require('ventnor')

class AccountSelectView extends View {
  constructor (serviceLocator, value) {
    super(serviceLocator, value)
    this.$el = $('<select/>')
    this.$el.attr('name', 'account')
    this.$el.addClass('control control--choice')
    this.value = value
    this.$el.on('change', () => this.emit('change', this.$el.val()))
    this.notSetLabel = '-- Select an account --'
  }

  populate () {
    this.serviceLocator.accountService.find('', {}, [ 'name' ], {}, (err, res) => {
      if (err) return this.serviceLocator.logger.error(err.message, err)
      res.results.forEach(account => {
        this.$el.append($('<option/>'
          , { text: account.name,
            value: account._id,
            selected: this.value === account._id
          }))
      })
    })
    return this
  }

  off () {
  }

  render () {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
    return this
  }
}

module.exports = AccountSelectView
