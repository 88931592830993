const isBeyondTodaysDate = (foundItem) => {
  const { month, year } = foundItem || {}
  const dataPointDate = new Date(`${year}/${month}`)
  if (dataPointDate > new Date()) return true
}

const createAccumulatorData = (row) => {
  const defaultRow = [ 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6 ]
  const accumulatorDescription = 'Accumulative Unique Members Engaged'
  const amendedRow = defaultRow && defaultRow.map(item => {
    const foundItem = row && row.find(datapoint => {
      return parseInt(datapoint.month) === item
    })

    if (foundItem) {
      if (isBeyondTodaysDate(foundItem)) {
        return ({ value: 0 })
      }
      return ({ value: foundItem.value })
    }
    return ({ value: 0 })
  })
  amendedRow.unshift({ value: `${accumulatorDescription}` })
  return amendedRow
}

module.exports = createAccumulatorData
