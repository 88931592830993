const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, name) {
jade_mixins["visibilityMap"] = jade_interp = function(visibility, userVisibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( visibility)
{
if (userVisibility === 'all' || userVisibility === undefined) 
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to all&lt;/strong&gt;&lt;br&gt;This widget is visible to logged in and logged out users.\" class=\"label label--large label--success js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else if (userVisibility === 'loggedInOnly')
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to logged in users&lt;/strong&gt;&lt;br&gt;This widget will not appear for logged out users.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else if (userVisibility === 'loggedOutOnly')
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to logged out users&lt;/strong&gt;&lt;br&gt;This widget will not appear for logged in users.\" class=\"label label--large label--warning js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
};
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
jade_mixins["visibilityMap"](model.get('visible'), model.get('userVisibility'));
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\"><iframe></iframe></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const EditBaseItemView = require('../../../widget/views/item/edit-base')

module.exports = EditBaseItemView.extend({
  template,
  render () {
    EditBaseItemView.prototype.render.apply(this)

    setTimeout(() => {
      const $frame = this.$('iframe')
      const doc = $frame[0].contentWindow.document
      const $body = $('body', doc)
      const $head = $('head', doc)
      $head.append('<link type="text/css" rel="stylesheet" ' +
        'href="/assets/js/lib/vendor/ckeditor/contents.css">')
      $head.append('<link type="text/css" rel="stylesheet" ' +
        'href="/assets/css/index.css">')
      $body.addClass('iframe-content').html(this.model.get('subtitle'))
    }, 1)
  }
})
