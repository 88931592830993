module.exports = RichTextEditorInstanceManager

const extend = require('lodash.assign')
const ckeConfig = require('./ck-editor-config')
const findIndex = require('lodash.findindex')

function RichTextEditorInstanceManager () {
  this.ckEditorInstances = []
}

function destroy (instance) {
  instance.updateElement()
  $(instance.element).change()
  try {
    instance.destroy()
  } catch (e) {
    if (typeof console !== 'undefined' && console.error) console.error('Error destroying CKEditor instance', e)
  }
}

RichTextEditorInstanceManager.prototype.destroy = function () {
  this.ckEditorInstances.forEach(destroy)
  this.ckEditorInstances = []
}

RichTextEditorInstanceManager.prototype.destroySingle = function (name) {
  const instance = window.CKEDITOR.instances[name]
  const i = findIndex(this.ckEditorInstances, { name: instance.name })

  destroy(instance)
  this.ckEditorInstances.splice(i, 1)
}

RichTextEditorInstanceManager.prototype.getInstance = function (name) {
  const instance = window.CKEDITOR.instances[name]
  return instance
}

RichTextEditorInstanceManager.prototype.create = function (el, config, id) {
  id = id || el.name
  if (window.CKEDITOR.instances[id]) return
  const instance = window.CKEDITOR.replace(el, extend({}, ckeConfig(), config))
  instance.on('instanceReady', function () {
    // Output self-closing tags the HTML4 way, like <br>.
    this.dataProcessor.writer.selfClosingEnd = '>'

    // Use line breaks for block elements, tables, and lists.
    var dtd = window.CKEDITOR.dtd
    for (var e in window.CKEDITOR.tools.extend({}, dtd.$nonBodyContent, dtd.$block)) {
      this.dataProcessor.writer.setRules(e, {
        indent: true,
        breakBeforeOpen: true,
        breakAfterOpen: true,
        breakBeforeClose: true,
        breakAfterClose: true
      })
    }
  })
  instance.on('change', function () {
    instance.updateElement()
    $(el).change()
  })
  this.ckEditorInstances.push(instance)
}
