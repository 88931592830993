const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const AdministratorService = require('./services/administrator')
const createRevisionController = require('../revision/controllers/controller')

const initAdmin = () => ({ administratorAdmin: init })

const init = (serviceLocator, done) => {
  serviceLocator.administratorService = new AdministratorService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  createRevisionController(serviceLocator,
    serviceLocator.administratorService,
    'administrator',
    'administrators/:id/revisions',
    'administrators',
    'emailAddress'
  )

  done()
}

module.exports = initAdmin
