const xAxis = 'Month'

const yAxis = 'Quantity'

const defaultTitle = 'Accumulative Reward Redemptions'

const extractTitle = (series) => {
  if (!series || !series.length) return defaultTitle

  const years =
    series &&
    series.map((item) => item && item.name && item.name.split(' ')[1])

  const firstYear = years[0]
  if (series.length === 1) return defaultTitle + ' ' + firstYear

  const lastYear = years[years.length - 1]
  return defaultTitle + ' ' + firstYear + ' - ' + lastYear
}

const formatChartData = (series) => {
  return { series, title: extractTitle(series), xAxis, yAxis }
}

module.exports = formatChartData
