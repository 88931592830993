const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const isInteger = require('validity-integer')
const isUrl = require('validity-url')
const validateIfPropertySet = require('validity-validate-if-property-set')
const validateIfSet = require('validity-validate-if-set')

module.exports = () =>
  schemata({
    name: 'Offer Redemption Type Purchase',
    properties: {
      purchaseForm: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      cost: {
        type: Number,
        validators: {
          all: [ required, isInteger ]
        }
      },
      postageAndPackaging: {
        type: Number,
        validators: {
          all: [ required, isInteger ]
        }
      },
      allowRepeatPurchases: {
        type: Boolean,
        defaultValue: false
      },
      thankYouTitle: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      thankYouMessage: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      receiptThankYouMessage: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      itemName: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      receiptLinkCtaUrl: {
        type: String,
        validators: {
          all: [ validateIfSet(isUrl) ]
        }
      },
      receiptLinkCtaText: {
        type: String,
        validators: {
          all: [ validateIfPropertySet('receiptLinkCtaUrl', required) ]
        }
      },
      redemptionVoucherImage: {
        type: String,
        validators: {
          all: []
        }
      },
      displayCustomerDetails: {
        type: Boolean,
        defaultValue: true
      }
    }
  })
