const Model = require('merstone')
const createSchema = require('../../../service/list/schema')

class ListModel extends Model {
  constructor (serviceLocator, attributes) {
    super(...arguments)
    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    super.reset(attrs)
  }

  setOrder (ids) {
    const oldOrder = this.get('items') || []
    let newOrder = []
    if (!oldOrder) throw new Error('This list has no items')
    ids.forEach(id => {
      newOrder = newOrder.concat(oldOrder.filter(item => item.itemId === id))
    })
    if (newOrder.length !== oldOrder.length) throw new Error('Not all items were sorted')
    this.set('items', newOrder)
  }
}

module.exports = ListModel
