const compileJade = require('browjadify-compile')
const join = require('path').join
const templates = {
  fixed: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search<div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Fashion Week&quot; ] or [ Fashion -Week ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"-dateCreated\">Newest First</option><option value=\"dateCreated\">Oldest First</option><option value=\"score\">Relevance</option><option value=\"name\">Asset Name</option></select></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Account</span><select name=\"account\" class=\"control control--choice form-field\"><option value=\"\">All Accounts</option></select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
},
  condensed: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<form action=\"#\" method=\"get\"><div class=\"panel panel-styled\"><div class=\"panel-content\"><div class=\"grid\"><div class=\"grid__item two-fifths\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text vhidden\">Keywords</span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div></div><div class=\"grid__item one-fifth\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text vhidden\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"-dateCreated\">Newest First</option><option value=\"dateCreated\">Oldest First</option><option value=\"score\">Relevance</option><option value=\"name\">Asset Name</option></select></label></div></div><div class=\"grid__item one-fifth\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text vhidden\">Account</span><select name=\"account\" class=\"control control--choice form-field\"><option value=\"\">All Accounts</option></select></label></div></div><div class=\"grid__item one-fifth\"><div class=\"form-row form-row-actions\"><button type=\"button\" class=\"btn btn--action js-filter\">Filter</button><button type=\"button\" class=\"btn js-clear\">Clear</button></div></div></div></div></div></form>");;return buf.join("");
}
}
const BaseView = require('cf-base-view')
const defaults = { layout: 'fixed' }
const extend = require('lodash.assign')
const populateAccounts = require('../../base/lib/account-populator')

module.exports = BaseView.extend({
  className: 'list-filters',

  events: {
    'submit form': 'handleSubmit',
    'click input[type=reset]': 'handleClear',
    'click .js-filter': 'handleSubmit',
    'click .js-clear': 'handleClear',
    'keyup form': 'handleKeyup'
  },

  initialize () {
    this.options = extend({}, defaults, this.options)
    this.render()
  },

  handleKeyup (e) {
    if (e.keyCode === 13) e.stopPropagation()
  },

  handleSubmit (e) {
    e.preventDefault()

    const params = { filter: {}, sort: [] }
    const map = {
      dateCreated: [ 'dateCreated', 'asc' ],
      '-dateCreated': [ 'dateCreated', 'desc' ],
      'name': [ 'name', 'asc' ],
      '-score': [ 'score', 'desc' ]
    }
    const keywords = this.$('form [name=keywords]').val()
    const account = this.$('form [name=account]').val()

    params.sort = map[this.$('form [name=sort]').val()]

    if (account) params.filter.account = account
    if (keywords.length) {
      params.keywords = keywords
    }

    this.trigger('filter', params)
  },

  handleClear () {
    this.$('form [name=keywords]').val('')
    this.trigger('filter', {})
  },

  render () {
    this.$el.empty().append(templates[this.options.layout]())
    populateAccounts.bind(this)(this.options.serviceLocator)
    this.$('.js-tooltip-trigger').tooltip({ html: true })
  }

})
