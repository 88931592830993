const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (className, data, undefined) {
buf.push("<div" + (jade.attr("classname", className, true, false)) + " class=\"panel\"><form><div class=\"js-errors-summary\"></div>");
var segments = ['Early Tenure', 'Engaged', 'Not Engaged', 'Medium Engaged', 'High Engaged']
buf.push("<div id=\"field--segment\" data-field=\"segment\" class=\"form-row\"><label><span class=\"form-label-text\">Segment</span><select name=\"segment\" class=\"control control--choice form-field\"><option value=\"\"" + (jade.attr("selected", !data.segment, true, false)) + ">None</option>");
// iterate segments
;(function(){
  var $$obj = segments;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var segment = $$obj[$index];

buf.push("<option" + (jade.attr("value", segment, true, false)) + (jade.attr("selected", data.segment === segment, true, false)) + ">" + (jade.escape(null == (jade_interp = segment) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var segment = $$obj[$index];

buf.push("<option" + (jade.attr("value", segment, true, false)) + (jade.attr("selected", data.segment === segment, true, false)) + ">" + (jade.escape(null == (jade_interp = segment) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label></div><div id=\"field--conditionsSupplementText\" data-field=\"conditionsSupplementText\" class=\"form-row\"><label><span class=\"form-label-text\">Conditions Supplement Text</span><input type=\"text\" name=\"conditionsSupplementText\"" + (jade.attr("value", data.conditionsSupplementText, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Text that will appear above Call To Action button.</p></div></div><div class=\"js-content-source-form\">     </div><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div></form></div>");}.call(this,"className" in locals_for_with?locals_for_with.className:typeof className!=="undefined"?className:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const NewsletterFormView = require('../../../../widget/views/form/newsletter-form')
const EditableItemFormView = require('./offer-editor/form')
const EditableItemModel = require('../models/offer-editor/model')

module.exports = NewsletterFormView.extend({
  template,
  EditableItemFormView,
  EditableItemModel
})
