const ListView = require('../views/list')
const Collection = require('chale')
const AccountModel = require('../models/account')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, { totalItems: 0, showing: 0 })
  let currentPage = 1
  let currentParams = { keywords: '', filter: {}, sort: [ 'dateCreated', 'desc' ] }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an account is updated, reset the model with its new attributes
  serviceLocator.accountService.on('update', (id, attrs) => {
    const model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.accountService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getAccounts(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
  })

  serviceLocator.router.route('accounts(/)', 'listAccounts', () => {
    if (!serviceLocator.allow('account', 'discover')) return false

    getAccounts(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)

    const list = new ListView(serviceLocator, collection, paginationModel).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('account', 'create')) return false
      serviceLocator.router.navigate('accounts/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('account', 'update')) return false
      serviceLocator.router.navigate('accounts/' + id + '/form', { trigger: true })
    })

    list.on('delete', ids => {
      if (!serviceLocator.allow('account', 'delete')) return false

      const deleteOne = (id, cb) => {
        serviceLocator.accountService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
        })
      }

      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
      })
    })

    list.on('filter', params => {
      currentParams = params
      const pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getAccounts(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      const pagination = { page: currentPage, pageSize: pageSize }
      appendAccounts(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
    })

    list.on('showRevisions', model => {
      serviceLocator.router.navigate('accounts/' + model.id + '/revisions', { trigger: true })
    })

    serviceLocator.router.render(list, 'Accounts')
  })

  const getAccounts = (keywords, filter, sort, pagination) => {
    serviceLocator.accountService.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err) return serviceLocator.logger.error('Could not load accounts', err)
      collection.reset(res.results.map(account => new AccountModel(serviceLocator, account)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendAccounts = (keywords, filter, sort, pagination) => {
    serviceLocator.accountService.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(account => collection.add(new AccountModel(serviceLocator, account)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }
}

module.exports = createController
