const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

const schema = schemata({
  name: 'Quote Item',
  properties: {
    quote: {
      type: String,
      validators: { all: [ required ] }
    },
    author: {
      type: String,
      validators: { all: [ required ] }
    }
  }
})

module.exports = BaseModel.extend({
  schema,
  defaults () {
    return schema.makeDefault()
  },
  validate (cb) {
    this.schema.validate(this.attributes, (ignoreErr, errors) =>
      cb(Object.keys(errors).length > 0 ? errors : undefined)
    )
  }
})
