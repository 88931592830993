const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const validateIfPropertyIn = require('validity-validate-if-property-in')

module.exports = function () {
  return schemata({
    name: 'Navigation Item',
    properties: {
      title: {
        type: String,
        validators: [ required ]
      },
      type: {
        type: String,
        defaultValue: 'section',
        validators: [ required ]
      },
      section: {
        type: String,
        validators: [ validateIfPropertyIn('type', [ 'section' ], required) ]
      },
      url: {
        type: String,
        validators: [ validateIfPropertyIn('type', [ 'internalLink', 'externalLink' ], required) ]
      }
    }
  })
}
