const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const imageConfig = require('./image-config.json')
const createUniqueValidator = require('validity-unique-property')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const validateIfPropertySet = require('validity-validate-if-property-set')
const length = require('validity-length')

// TODO this should be released as an npm module
const customValidityMessage = (fn, message) => (key, name, object, cb) => {
  fn(key, name, object, (error, valid) => {
    cb(error, valid ? message : undefined)
  })
}

const hasWidgets = obj => obj && obj.widgets && obj.widgets.length > 0

const validateIf = (validateFn, comparator) => (key, keyDisplayName, object, cb) => {
  if (comparator(object[key])) {
    return validateFn(key, keyDisplayName, object, cb)
  }

  return cb(null, undefined)
}

module.exports = (find) => {
  const requiredContexts = imageConfig.contexts.map(context => context.name)
  const requiredCrops = imageConfig.crops.map(crop => crop.name)
  const fullUrlUniqueValidator = customValidityMessage(
    createUniqueValidator(find, { keys: [ 'instance' ] }),
    'Slug within this instance is already in use'
  )
  return schemata({
    name: 'Data Capture',
    properties: {
      _id: {
        type: String
      },

      headline: {
        type: String,
        validators: {
          all: [ required ]
        }
      },

      slug: {
        type: String,
        validators: {
          all: [ required, fullUrlUniqueValidator ]
        }
      },

      account: {
        type: String,
        validators: {
          all: [ required ]
        }
      },

      instance: {
        type: String,
        validators: {
          all: [ validateIfPropertySet('account', required) ]
        }
      },

      body: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          all: []
        }
      },

      form: {
        type: String,
        validators: {
          all: [ required ]
        }
      },

      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [
            validateIf(createContextValidator(requiredContexts), hasWidgets),
            validateIf(createCropValidator(requiredCrops), hasWidgets)
          ],
          published: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops)
          ],
          archived: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops)
          ]
        }
      },

      termsAndConditions: {
        type: String,
        validators: {
          all: [ required ]
        }
      },

      fullTermsAndConditions: {
        type: String
      },

      emailUniqueCode: {
        type: Boolean
      },

      thankYouMessage: {
        type: String,
        validators: {
          all: [ required ]
        }
      },

      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },

      preventMultipleEntries: {
        type: Boolean
      },

      uniqueFields: {
        type: Array,
        defaultValue: () => [],
        validators: {
          all: [ customValidityMessage(
            validateIfPropertySet('preventMultipleEntries', length(1)),
            'At least one field needs to be selected'
          ) ]
        }
      },

      partners: {
        type: Array
      }
    }
  })
}
