const Model = require('../models/offer')
const createOfferFormViewController = require('./form-view-controller')
const async = require('async')

module.exports = serviceLocator => {
  const initializeOfferFormView = createOfferFormViewController(serviceLocator)

  const setupNavigation = view => {
    view.on('close', () => {
      serviceLocator.router.navigate('offers', { trigger: true })
    })

    view.on('saveComplete', isNew => {
      if (isNew) serviceLocator.router.navigate('offers/' + view.model.id + '/form', { trigger: true })
    })

    view.on('back', () => {
      serviceLocator.router.navigate('offers', { trigger: true })
    })
  }
  // Create
  serviceLocator.router.route('offers/form', 'createOffer', () => {
    var model = new Model(serviceLocator)
    model.makeDefault((err, offerModel, account) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)
      setupNavigation(initializeOfferFormView(model, account, true))
    })
  })

  // Duplicate
  serviceLocator.router.route('offers/:id/duplicate', 'duplicateOffer', id => {
    async.parallel(
      { offer: serviceLocator.offerService.read.bind(serviceLocator.offerService, id)
      }, (err, data) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)
        const tempOffer = new Model(serviceLocator)
        let offerData = data.offer
        // Clear ID so it's new
        delete offerData._id
        // Clear slug as duplicate is not allowed
        delete offerData.slug
        // Clear previewId so a new one is generated
        delete offerData.previewId
        delete offerData.dateCreated
        delete offerData.lastUploadedUniqueCodesFilename

        const duplicatedData = tempOffer.schema.makeDefault(offerData)
        const offer = new Model(serviceLocator, duplicatedData)
        setupNavigation(initializeOfferFormView(offer, null, true))
      })
  })

  // Edit
  serviceLocator.router.route('offers/:id/form', 'editOffer', id => {
    async.parallel(
      { offer: serviceLocator.offerService.read.bind(serviceLocator.offerService, id)
      }, (err, data) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)
        var model = new Model(serviceLocator, data.offer)
        setupNavigation(initializeOfferFormView(model, null, false))
      })
  })
}
