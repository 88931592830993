const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const isUrl = require('validity-url')
const tagSchema = require('../tag/schema')
const imageConfig = require('./image-config.json')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const validateIfSet = require('validity-validate-if-set')

module.exports = () => {
  const requiredContexts = imageConfig.contexts.map(context => context.name)
  const requiredCrops = imageConfig.crops.map(crop => crop.name)

  return schemata({
    name: 'Instance',
    properties: {
      _id: {
        type: String
      },
      name: {
        type: String,
        validators: { all: [ required ] }
      },
      account: {
        type: String,
        validators: { all: [ required ] }
      },
      subdomain: {
        type: String,
        validators: { all: [ required ] }
      },
      enabled: {
        type: Boolean,
        defaultValue: true,
        validators: { all: [] }
      },
      tags: {
        type: schemata.Array(tagSchema()),
        defaultValue: () => []
      },
      strapline: {
        type: String,
        validators: { all: [] }
      },
      images: {
        type: Object,
        validators: [
          createContextValidator(requiredContexts),
          createCropValidator(requiredCrops)
        ]
      },
      facebookId: {
        type: String,
        validators: { all: [] }
      },
      twitterId: {
        type: String,
        validators: { all: [] }
      },
      linkedInId: {
        type: String,
        validators: { all: [] }
      },
      youtubeId: {
        type: String,
        validators: { all: [] }
      },
      instagramId: {
        type: String,
        validators: { all: [] }
      },
      googleTagManagerId: {
        type: String,
        validators: { all: [] }
      },
      footerHtml: {
        type: String,
        validators: { all: [] }
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },
      navigation: {
        type: Array
      },
      idaasSiteName: {
        type: String,
        validators: { all: [ required ] }
      },
      idaasSiteId: {
        type: String,
        validators: { all: [ required ] }
      },
      idaasMobileSiteId: {
        type: String,
        validators: { all: [ required ] }
      },
      auth0Site: {
        type: String,
        validators: { all: [ required ] }
      },
      paperName: {
        type: String,
        validators: { all: [ required ] }
      },
      paperUrl: {
        type: String,
        validators: { all: [ required, isUrl ] }
      },
      subscribeUrl: {
        type: String,
        validators: { all: [ required, isUrl ] }
      },
      bpointProductCode: {
        type: String,
        validators: { all: [ required ] }
      },
      duplicatedInstance: {
        type: String
      },
      tealiumNetPn: {
        type: String,
        validators: { all: [ required ] }
      },
      tealiumNetSec: {
        type: String,
        validators: { all: [ required ] }
      },
      tealiumNetContentType: {
        type: String,
        validators: { all: [ required ] }
      },
      tealiumInstance: {
        type: String,
        validators: { all: [ required ] }
      },
      androidAppLink: {
        type: String,
        validators: [ validateIfSet(isUrl) ]
      },
      iosAppLink: {
        type: String,
        validators: [ validateIfSet(isUrl) ]
      },
      unsubscribeLink: {
        type: String,
        validators: [ validateIfSet(isUrl) ]
      },
      paperAddress: {
        type: String,
        validators: [ required ]
      },
      order: {
        type: Number
      },
      timezone: {
        type: String,
        validators: [ required ]
      },
      facebookPixelId: {
        type: String
      }
    }
  })
}
