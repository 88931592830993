const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (account, allowed, data, format, imageUrl, instance) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + imageUrl + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('dataCapture', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\">");
if ( data.instance)
{
buf.push("<a type=\"button\"" + (jade.attr("href", data.url, true, false)) + " target=\"_blank\" class=\"btn btn--small js-preview\">View Page</a>");
}
else
{
buf.push("<div data-container=\"body\" data-toggle=\"tooltip\" title=\"Can’t be previewed without an instance assigned\" class=\"tooltip__disabled-wrapper js-tooltip-trigger\"><button type=\"button\" class=\"btn btn--small is-disabled\">View Page</button></div>");
}
buf.push("<a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-duplicate\">Duplicate</a></li></ul></div></div>");
if ( allowed('dataCapture', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/data-capture/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Instance:</dt><dd>" + (jade.escape(null == (jade_interp = instance.name) ? "" : jade_interp)) + "</dd><dt>Account:</dt><dd>" + (jade.escape(null == (jade_interp = account.name) ? "" : jade_interp)) + "</dd><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"account" in locals_for_with?locals_for_with.account:typeof account!=="undefined"?account:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"imageUrl" in locals_for_with?locals_for_with.imageUrl:typeof imageUrl!=="undefined"?imageUrl:undefined,"instance" in locals_for_with?locals_for_with.instance:typeof instance!=="undefined"?instance:undefined));;return buf.join("");
}
const createImageUrlBuilder = require('cf-image-url-builder')
const config = window.config

class ListItemView extends BaseListItemView {
  constructor (serviceLocator, model, index, totalItems, name, instances, accounts) {
    super(...arguments)

    this.instances = instances
    this.accounts = accounts
    this.$el.on('click', '.js-duplicate', this.emit.bind(this, 'duplicate'))
  }
  get template () {
    return template
  }

  render () {
    const data = this.model.toJSON()
    const images = this.model.get('images').widgets
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, this.model.get('images').widgets)
    let imageUrl = '/assets/img/content/no-photo-medium.png'
    if (images && images.length &&
      urlBuilder.getImage('Hero') && urlBuilder.getImage('Hero').crop('Square')) {
      imageUrl = urlBuilder.getImage('Hero').crop('Square').constrain(150).url()
    }

    const instance = this.instances.find(item => {
      if (this.model.get('instance') === null) return true
      if (this.model.get('instance') === item._id) return true
      return false
    })
    const account = this.accounts.find(item => {
      if (!instance) return true
      if (instance.account === item._id) return true
      return false
    })

    data.url = `${this.serviceLocator.instanceService.createUrl(instance, account)}/reader-comp/${data.slug}`

    this.$el.empty().append(this.template(
      { data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
        index: this.index,
        totalItems: this.totalItems,
        imageUrl,
        instance,
        account
      }))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
