const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div data-field=\"linkUrl\" class=\"form-row\"><label><span class=\"form-label-text\">Link URL<abbr title=\"This field is required\">*</abbr></span><input type=\"url\" name=\"linkUrl\"" + (jade.attr("value", data.linkUrl, true, false)) + " class=\"control--text form-field\"/><div class=\"js-error\"></div></label></div><div data-field=\"linkText\" class=\"form-row\"><label><span class=\"form-label-text\">Link Text<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"linkText\"" + (jade.attr("value", data.linkText, true, false)) + " class=\"control--text form-field\"/><div class=\"js-error\"></div></label></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor (...args) {
    super(...args)
    this.template = template
  }

  render () {
    this.$el.append(this.template({ data: this.model.toJSON() }))
    return this
  }
}

module.exports = ItemRepeaterFormView
