const View = require('ventnor')
const extend = require('lodash.assign')
const Pagination = require('../../base/views/pagination')
const Collection = require('chale')
const Model = require('merstone')
const OfferModel = require('../models/offer')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"js-filters\"></div><div class=\"list-container\"><div class=\"list-grid list-grid--scroll js-items\"></div></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div>");;return buf.join("");
}
const ListItemView = require('./browser-list-item')
const ListFilterView = require('./list-filter')

class BrowserView extends View {
  constructor (serviceLocator, defaultOfferFilter, uniqueId) {
    super(serviceLocator)

    extend(this, Pagination.prototype)

    this.uniqueId = uniqueId
    Pagination.call(this, new Model(serviceLocator, { totalItems: 0, showing: 0 }))

    this.collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
    this.currentPage = 1
    this.pageSize = 25
    this.defaultOfferFilter = defaultOfferFilter || {}
    this.currentParams = { keywords: '', filter: this.defaultOfferFilter, sort: [ 'dateCreated', 'desc' ] }
    this.listenTo(this.collection, 'add', this.addListItem.bind(this))
    this.listenTo(this.collection, 'remove', this.removeListItem.bind(this))
    this.listenTo(this.collection, 'reset', this.resetListItems.bind(this))

    this.$el.on('click', '.js-more', this.loadMore.bind(this))

    this.createFilterView()

    this.getOffers(
      this.currentParams.keywords,
      this.currentParams.filter,
      this.currentParams.sort,
      { page: this.currentPage, pageSize: this.pageSize }
    )
  }

  createFilterView () {
    this.filters = new ListFilterView(this.serviceLocator, this, 'condensed')
    this.filters.on('filter', this.filter.bind(this))
    this.attachView(this.filters)
  }

  addListItem (model) {
    const listItem = new ListItemView(this.serviceLocator, model, this.uniqueId)
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }

  resetListItems (models, previousModels) {
    previousModels.forEach(this.removeListItem.bind(this))
    models.forEach(this.addListItem.bind(this))
  }

  removeListItem (model) {
    const view = this.getViewByModelId(model.id)
    if (!view) return
    view.remove()
  }

  render () {
    this.$el.append(template())
    this.$el.find('.js-filters').append(this.filters.render().$el)
    this.updatePagination()
    return this
  }

  getOffers (keywords, filter, sort, pagination) {
    this.serviceLocator.offerService.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      this.collection.reset(res.results.map(offer =>
        new OfferModel(this.serviceLocator, offer)
      ))
      this.updatePaginationModel(res.totalItems, this.collection.models.length)
    })
  }

  updatePaginationModel (total, collectionTotal) {
    this.paginationModel.set('totalItems', total)
    this.paginationModel.set('showing', collectionTotal)
  }

  appendOffers (keywords, filter, sort, pagination) {
    this.serviceLocator.offerService.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(offer => {
        this.collection.add(new OfferModel(this.serviceLocator, offer))
      })
      this.updatePaginationModel(res.totalItems, this.collection.models.length)
    })
  }

  filter (params) {
    const filter = Object.assign({}, this.defaultOfferFilter, params.filter)
    this.currentParams = params
    const pagination = { page: this.currentPage, pageSize: this.pageSize }
    this.currentPage = 1
    this.getOffers(params.keywords, filter, params.sort, pagination)
  }

  loadMore () {
    this.currentPage += 1
    const pagination = { page: this.currentPage, pageSize: this.pageSize }
    this.appendOffers(this.currentParams.keywords, this.currentParams.filter, this.currentParams.sort, pagination)
  }
}

module.exports = BrowserView
