const JSC = require('jscharting')

const generateChart = ({ series, title, xAxis, yAxis, i }) => {
  const name = 'paretoDiv' + i
  JSC.chart(name, {
    debug: false,
    baseUrl: './js/jsc/',
    title_label_text: title,
    legend: {
      template:
        '%icon %name',
      position: 'bottom'
    },
    yAxis: { label_text: yAxis },
    xAxis_label_text: xAxis,
    series
  })
}

module.exports = generateChart
