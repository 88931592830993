module.exports={
  "crops": [
    { "name": "Square", "aspectRatio": "1:1" }
  ],
  "contexts": [
    { "name": "Available", "allowMultipleSelection": false },
    { "name": "Redeemed", "allowMultipleSelection": false }
  ]
}

