const async = require('async')
const EventEmitter = require('events').EventEmitter

module.exports = (serviceLocator, offer) => {
  const eventEmitter = new EventEmitter()
  const w = window.open('')

  process.nextTick(() => {
    eventEmitter.emit('window:open', w)

    if (!offer.get('__fullUrlPath')) {
      w.close()
      return eventEmitter.emit('error:noSection')
    }

    async.waterfall(
      [ cb => {
        serviceLocator.sectionService.read(offer.get('sections')[0], (err, section) => {
          if (err) return cb(err)
          return cb(null, { section: section })
        })
      },
      (obj, cb) => {
        if (obj.section.instance) {
          serviceLocator.instanceService.read(obj.section.instance, (err, instance) => {
            if (err) return cb(err)
            obj.instance = instance
            return cb(null, obj)
          })
        } else {
          serviceLocator.instanceService.find('', {}, [], { pageSize: 1, page: 1 }, (err, res) => {
            if (err) return cb(err)
            obj.instance = res.results[0]
            return cb(null, obj)
          })
        }
      },
      (obj, cb) => {
        serviceLocator.instanceService.getUrl(obj.instance, cb)
      }
      ], (err, url) => {
        if (err) {
          w.close()
          eventEmitter.emit('error')
          return serviceLocator.logger.error('Could not preview offer', err)
        }

        url += offer.get('__fullUrlPath') + '?previewId=' + offer.get('previewId')
        w.location = url
        eventEmitter.emit('window:set', w, url)
      })
  })

  return eventEmitter
}
