const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, sessionHasSpecificAccount, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Something to identify the contents of the list</p></div></div><div id=\"field--type\" data-field=\"type\" class=\"form-row\"><label><span class=\"form-label-text\">Content Source<abbr title=\"This field is required\">*</abbr></span><select name=\"type\" class=\"control control--choice form-field js-type-select\"><option value=\"\">-- Please select --</option><option value=\"auto\"" + (jade.attr("selected", data.type === 'auto', true, false)) + ">Automatic</option><option value=\"manual\"" + (jade.attr("selected", data.type === 'manual', true, false)) + ">Manual</option></select></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Whether the list should be populated automatically, or picked manually</p></div></div>");
if ( !sessionHasSpecificAccount)
{
buf.push("<div id=\"field--account\" data-field=\"account\" class=\"form-row\"><label><span class=\"form-label-text\">Account<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-account-select\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"></div></div>");
}
buf.push("</div></div><div class=\"js-content-source-form\"></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"sessionHasSpecificAccount" in locals_for_with?locals_for_with.sessionHasSpecificAccount:typeof sessionHasSpecificAccount!=="undefined"?sessionHasSpecificAccount:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const modal = require('modal')
const ManualListView = require('./manual-form')
const AutoListView = require('./auto-form')
const AccountSelect = require('../../account/views/account-select')

class FormView extends BaseFormView {
  constructor (serviceLocator, model, isNew, options) {
    super(...arguments)
    this.options = options || {}
    this.options.serviceLocator = serviceLocator
    this.$el.on('change', '.js-type-select', this.handleTypeChange.bind(this))
    this.currentContentSource = this.model.get('type') || this.options.forceType
  }

  handleTypeChange () {
    // If the source was previously not set, don't warn about changing it
    if (!this.currentContentSource) {
      this.currentContentSource = this.$el.find('select[name=type]').val()
      this.renderSourceForm()
      return
    }

    modal(
      { title: 'Source change',
        content: this.currentContentSource === 'auto'
          ? 'Are you sure you want to change the content source to manual? ' +
            'You will lose all the rules that have been set.'
          : 'Are you sure you want to change the content source to auto? ' +
            'You will lose any articles that have been selected.',
        buttons:
        [ { text: 'Continue with change', event: 'confirm', className: 'btn btn--warning' },
          { text: 'Don\'t change', event: 'cancel', className: 'btn' }
        ]
      })

      .on('confirm', () => {
        this.currentContentSource = this.$el.find('select[name=type]').val()
        this.renderSourceForm()
      })

      .on('cancel', () => {
        this.$el.find('select[name=type]').val(this.currentContentSource)
      })
  }

  render () {
    // Render the template
    this.$el.append(template({
      title: this.isNew ? 'New List' : 'Edit List',
      data: this.model.toJSON(),
      sessionHasSpecificAccount: this.options.serviceLocator.session.account
    }))

    this.renderSourceForm()
    this.renderAccountSelect()

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }

  renderAccountSelect () {
    const accountView = new AccountSelect(this.options.serviceLocator, this.model.get('account'))
    accountView.populate()
    this.$el.find('.js-account-select').append(accountView.render().$el)
    accountView.on('change', account => this.model.set('account', account))
  }

  renderSourceForm () {
    if (this.contentSourceView) this.contentSourceView.remove()
    if (!this.currentContentSource) return

    switch (this.currentContentSource) {
      case 'manual':
        this.contentSourceView = new ManualListView(this.serviceLocator, this.model, this.options)
        break
      case 'auto':
        this.contentSourceView = new AutoListView(this.serviceLocator, this.model)
        break
      default:
        this.contentSourceView = new ManualListView(this.serviceLocator, this.model, this.options)
        break
    }

    this.$el.find('.js-content-source-form').empty().append(this.contentSourceView.render().$el)
    this.attachView(this.contentSourceView)
  }
}

module.exports = FormView
