const compileJade = require('browjadify-compile')
const join = require('path').join
const templates = {
  'drop-area': function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"widget-drop-area js-widgets\"></div>");;return buf.join("");
},
  'beancan-area': function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"widget-beancan-area\"><div class=\"js-widgets\"></div></div>");;return buf.join("");
}
}
const debug = require('../../../../admin/source/js/lib/debug')('widget area view')
const extend = require('lodash.assign')
const defaults = { receiveDrops: true }

module.exports = window.Backbone.View.extend({
  events: {},

  initialize () {
    debug('init')
    this.options = extend({}, defaults, this.options)
    this.template = this.options.receiveDrops ? templates['drop-area'] : templates['beancan-area']
    this.render()
    this.addListenersAndAppend()
  },
  addListenersAndAppend () {
    this.model.on('add', (widgetArea, widget, options) => {
      debug('model add', widget)
      this.appendWidget(widget, true, options)
    }, this)

    this.model.get('widgets').forEach(widget => {
      this.appendWidget(widget)
    })
  },
  handleDrop (event, ui) {
    let type = $(ui.item).data('type')
    debug('handle widget drop', type)

    const factory = this.model.abstractWidgetFactory(type)
    const Model = factory.model
    const model = new Model({ type: type })

    ui.item.attr('id', 'placeholder-' + model.cid)
    this.model.add(model)
  },
  appendWidget (model, init, options) {
    debug('append widget', this, model)

    options = options || {}

    const factory = this.model.abstractWidgetFactory(model.get('type'))
    let WidgetItemView = factory.itemView.extend({ factory })
    const widgetItemOptions = extend({}, this.options.widgetItemOptions, {
      model: model,
      widgetArea: this,
      serviceLocator: this.options.serviceLocator
    })
    let widgetItemView

    // This allows you to override an edit template without having to create a new widget
    if (this.options.widgetItemOptions && this.options.widgetItemOptions.editView) {
      WidgetItemView = WidgetItemView.extend(
        { editView: this.options.widgetItemOptions.editView }
      )
    }

    widgetItemView = new WidgetItemView(widgetItemOptions)

    // Make sure each widget knows which widget area it belongs to
    widgetItemView.widgetArea = this
    widgetItemView.options.extraProperties = this.options.extraProperties || {}

    model.on('remove', () => {
      debug('model remove')
      widgetItemView.remove()
    })

    this.appendWidgetView(widgetItemView, options.at)

    if (init) {
      widgetItemView.trigger('init', null, init)
    }
  },

  setupSortable () {
    this.$('.js-widgets')
      .sortable({
        handle: '.js-sort-handle',
        placeholder: 'js-widget widget-grid__item ui-sortable-placeholder-custom',
        forcePlaceholderSize: 'true',
        cursor: 'move',
        cursorAt: { left: 30, top: 30 },
        tolerance: 'pointer',
        connectWith: this.options.receiveDrops ? '.js-widgets' : '',
        stop: () => {
          this.model.trigger('reload')
        }
      })
      .off('sortupdate')
      .on('sortupdate', (event, ui) => {
        if (!ui.item.hasClass('js-widget')) {
          this.handleDrop(event, ui)
        }
        var order = []
        this.$('.js-widget').map((index, el) => {
          order.push($(el).attr('data-cid'))
        })
        this.model.setOrder(order)
      })
  },
  appendWidgetView (widgetItemView, at) {
    const $widgetArea = this.$('.js-widgets')
    const $widgets = $widgetArea.children()
    const $placeholder = $widgetArea.find('#placeholder-' + widgetItemView.model.cid)

    if ($placeholder.length > 0) {
      $placeholder.replaceWith(widgetItemView.$el)
    } else {
      if (typeof at === 'number') {
        if ($widgets.length && $widgets.length > at) {
          $widgets.eq(at).before(widgetItemView.$el)
        } else {
          $widgetArea.append(widgetItemView.$el)
        }
      } else {
        $widgetArea.append(widgetItemView.$el)
      }
    }

    this.setupSortable()
  },
  render () {
    this.$el.empty().append(this.template())
    this.setupSortable()
    return this
  }
})
