const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, undefined) {
// iterate data
;(function(){
  var $$obj = data;
  if ('number' == typeof $$obj.length) {

  if ($$obj.length) {
    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<li><label><input type=\"checkbox\"" + (jade.attr("value", item.tag, true, false)) + (jade.attr("checked", item.checked, true, false)) + (jade.attr("data-tagType", item.type, true, false)) + " class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = item.tag) ? "" : jade_interp)) + "</span></label></li>");
    }

  } else {
buf.push("<label>No tags of this type</label>");
  }
  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<li><label><input type=\"checkbox\"" + (jade.attr("value", item.tag, true, false)) + (jade.attr("checked", item.checked, true, false)) + (jade.attr("data-tagType", item.type, true, false)) + " class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = item.tag) ? "" : jade_interp)) + "</span></label></li>");
    }

    if ($$l === 0) {
buf.push("<label>No tags of this type</label>");
    }
  }
}).call(this);
}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const Collection = require('chale')
const Model = require('merstone')
const find = require('lodash.find')

class TagCheckbox extends View {
  constructor (serviceLocator, initialTags, tagType) {
    super(...arguments)
    if (tagType === undefined) throw new Error('Tag type must be supplied')
    var tags = []
    if (initialTags) {
      tags = initialTags
        .filter(tag => tag.type === tagType)
        .map(tag => new Model(serviceLocator, tag))
    }
    this.tagType = tagType
    this.$el = $('<ul/>')
    this.$el.on('change', 'input[type=checkbox]', this.handleChange.bind(this))
    this.tags = new Collection(serviceLocator, tags)
  }

  loadTags () {
    const filter = { type: this.tagType }
    const order = [ 'tag', 'asc' ]
    const pagination = { page: 1, pageSize: 150 }
    this.serviceLocator.tagService.find('', filter, order, pagination, (err, res) => {
      if (err) return this.serviceLocator.logger.error('Error loading tags', err)
      this.renderTags(res.results)
    })
    return this
  }

  renderTags (tags) {
    var selectedTags = this.tags.models.map(model => model.get('tag'))
    tags = tags.map(tag => {
      if (selectedTags.indexOf(tag.tag) !== -1) tag.checked = true
      return tag
    })
    this.$el.empty().append(template({ data: tags }))
  }

  render () {
    return this
  }

  handleChange (e) {
    const $checkbox = $(e.target)
    let toDelete

    if ($checkbox.is(':checked')) {
      this.tags.add(new Model(this.serviceLocator, { tag: $checkbox.val(), type: this.tagType }))
    } else {
      toDelete = find(this.tags.models, tag => {
        return tag.get('type') === this.tagType && tag.get('tag') === $checkbox.val()
      })
      if (toDelete) this.tags.remove(toDelete.cid)
    }

    this.emit('change')
  }
}

module.exports = TagCheckbox
