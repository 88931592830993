const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data) {
jade_mixins["stateMap"] = jade_interp = function(schedule, visibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// iterate [schedule, visibility]
;(function(){
  var $$obj = [schedule, visibility];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  }
}).call(this);

};
buf.push("<div class=\"list-item list-item-condensed\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
jade_mixins["stateMap"](data.schedule, data.visibility);
if ( allowed('faq', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"form-field js-select\"/></label>");
}
buf.push("<div class=\"btn-group\"></div>");
if ( allowed('faq', 'update'))
{
buf.push("<button class=\"btn btn-small js-edit\">Edit</button>");
}
buf.push("</div>");
if ( allowed('faq', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/faqs/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape((jade_interp = data.title) == null ? '' : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.title) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = data.dateCreated) ? "" : jade_interp)) + "</dd><dt>Live:</dt><dd>" + (jade.escape((jade_interp = data.liveDate || 'always') == null ? '' : jade_interp)) + "</dd><dt>Expires:</dt><dd>" + (jade.escape((jade_interp = data.expiryDate || 'never') == null ? '' : jade_interp)) + "</dd><dt>Account:</dt><dd>" + (jade.escape((jade_interp = data.account) == null ? '' : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const stateMap = require('../../offer/state-map')
const moment = require('moment')
const config = window.config
const dateTimeFormat = config.locale.longDateFormat.L

class ListItemView extends BaseListItemView {
  constructor (serviceLocator, model, accounts) {
    super(...arguments)
    this.accounts = accounts
    this.$el.on('click', '.js-select', this.toggleSelect.bind(this))
  }

  get template () {
    return template
  }

  toggleSelect (e) {
    var isChecked = $(e.target).is(':checked')
    this.model.emit(isChecked ? 'select' : 'deSelect', this.model)
  }

  render () {
    const data = this.model.toJSON()
    data.account = this.accounts.filter(account =>
      account._id === this.model.get('account')
    ).pop().name

    data.dateCreated = moment(data.dateCreated).calendar()
    if (data.liveDate) data.liveDate = moment(data.liveDate).format(dateTimeFormat)
    if (data.expiryDate) data.expiryDate = moment(data.expiryDate).format(dateTimeFormat)

    const getVisibilityState = stateMap.visibility.get(this.model.getVisibilityState())
    const getScheduleState = stateMap.schedule.get(this.model.getScheduleState())

    data.schedule = getScheduleState(this.model.get('liveDate'), this.model.get('expiryDate'))
    data.visibility = getVisibilityState()

    this.$el.empty().append(this.template({
      data,
      allowed: this.serviceLocator.allowed
    }))

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })

    return this
  }
}

module.exports = ListItemView
