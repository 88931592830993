const Model = require('./models/link')
const FormView = require('./views/form')

const init = (serviceLocator, done) => {
  serviceLocator.redemptionTypeFactory.register('link', {
    name: 'Link',
    FormView,
    Model,
    allowedAsSecondary: true,
    supportsUniqueCodes: true,
    supportsRedemptionReset: true,
    hasInstructions: true,
    hasSecondaryCtaText: true,
    supportsPreRedemptionQuestions: true
  })
  done()
}

module.exports = () => ({ linkRedemptionType: [ 'offerAdmin', init ] })
