const init = (serviceLocator, done) => {
  const widget = {
    name: 'Info Panel',
    description: 'Displays key information in a panel',
    model: require('./models/model'),
    editView: require('./views/form'),
    itemView: require('./views/item')
  }

  serviceLocator.widgetFactories.get('section').register('infoPanel', widget)
  done()
}

module.exports = () => ({ infoPanelWidget: [ 'widget', 'sectionAdmin', init ] })
