const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const extend = require('lodash.assign')
const validateLength = require('validity-length')
const required = require('validity-required')

module.exports = () => {
  const widgetProperties = {
    title: {
      type: String,
      validators: { all: [ required ] }
    },
    width: {
      type: String,
      validators: { all: [ required ] }
    },
    section: {
      type: Array,
      validators: { all: [ validateLength(1) ] }
    },
    emphasisedWordLocation: {
      type: String
    },
    limit: {
      type: Number
    },
    regularFontWeight: {
      type: Boolean
    }
  }

  const widgetSchemata = schemata({
    name: 'Sortable Offers',
    properties: extend({}, baseSchema.getProperties(), widgetProperties)
  })

  return widgetSchemata
}
