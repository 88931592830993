const OfferEditorModel = require('../../../../../widget/models/offer-editor')
const createSchema = require('./editable-item-schema')

class EditableItemModel extends OfferEditorModel {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)
    this.schema = createSchema()
  }
}

module.exports = EditableItemModel
