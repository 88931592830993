const createWidgetFactory = require('../../../widget/lib/base-factory')
const itemView = require('../views/layout-item')
const Model = window.Backbone.Model

const createLayoutFactory = () => {
  const factory = createWidgetFactory()

  factory.register('one',
    { model: Model,
      name: 'One',
      itemView: itemView
    }
  )

  factory.register('two',
    { model: Model,
      name: 'Two',
      itemView: itemView
    }
  )

  factory.register('oneAndTwo',
    { model: Model,
      name: 'One and Two',
      itemView: itemView
    }
  )

  return factory
}

module.exports = createLayoutFactory
