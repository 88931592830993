const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (accounts, undefined) {
buf.push("<form class=\"js-account-select\"><div class=\"js-errors-summary\"></div><p>Select the account to assign this asset to<div class=\"form-row\"><select name=\"account\" class=\"control control--choice form-field\"><option value=\"\">-- Select an account --</option>");
// iterate accounts
;(function(){
  var $$obj = accounts;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var account = $$obj[$index];

buf.push("<option" + (jade.attr("value", account._id, true, false)) + ">" + (jade.escape(null == (jade_interp = account.name) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var account = $$obj[$index];

buf.push("<option" + (jade.attr("value", account._id, true, false)) + ">" + (jade.escape(null == (jade_interp = account.name) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></div></p></form><div class=\"modal-controls\"><button class=\"btn btn--action js-btn-select\">Select</button></div>");}.call(this,"accounts" in locals_for_with?locals_for_with.accounts:typeof accounts!=="undefined"?accounts:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const BaseView = require('cf-base-view')
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()

module.exports = BaseView.extend({
  initialize (serviceLocator) {
    this.serviceLocator = serviceLocator
    this.$el.on('click', '.js-btn-select', this.handleSubmit.bind(this))
    this.render()
  },

  showErrors: formErrorsDelegate.showErrors,
  clearErrors: formErrorsDelegate.clearErrors,

  handleSubmit () {
    var accountId = this.$('.js-account-select').find('option:selected').val()
    if (accountId) {
      this.trigger('submit', accountId)
      return true
    } else {
      this.showErrors({ error: 'You must enter an account' })
      return false
    }
  },

  render () {
    this.serviceLocator.accountService.find('', {}, [ 'name' ], {}, (error, res) => {
      if (error) return this.serviceLocator.logger.error(error.message, error)
      var accounts = res.results
      this.$el.append($(template({ accounts: accounts })))
    })
  }
})
