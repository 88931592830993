const View = require('ventnor')

class OfferSelect extends View {
  constructor (serviceLocator, selectedOffers) {
    super(...arguments)
    this.$el = $('<select multiple/>')
    this.$el.addClass('control control--choice')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose one or more offers')
    this.selectedOffers = selectedOffers || []
  }

  initializeSelectize () {
    if (!this.selectedOffers.length) {
      return this.el.selectize.on('change', this.updateSelection.bind(this))
    }
    var filter = { _id: { $in: this.selectedOffers } }
    this.serviceLocator.offerService.find('', filter, [], {}, (err, res) => {
      if (err) return this.serviceLocator.logger.error('Cannot find existing instances', err)
      res.results.forEach(offer => {
        // The item needs to be added to the list
        // of selectize options in order to be selected
        this.el.selectize.addOption(
          { value: offer._id,
            text: offer.shortHeadline
          })
        // Select the added option
        this.el.selectize.addItem(offer._id)
      })
      this.el.selectize.on('change', this.updateSelection.bind(this))
    })
  }

  off () {
  }

  updateSelection () {
    this.selectedOffers = this.el.selectize.getValue()
    this.emit('change', this.selectedOffers)
  }

  load (query, cb) {
    this.serviceLocator.offerService.find(query, {}, [], {}, (err, data) => {
      if (err) return this.serviceLocator.logger.error('Cannot find offers', err)
      cb(data.results.map(offer => {
        return { value: offer._id, text: offer.shortHeadline }
      }))
    })
  }

  render () {
    setTimeout(() => {
      this.$el.selectize(
        { delimiter: ',',
          persist: false,
          create: true,
          createOnBlur: true,
          onInitialize: this.initializeSelectize.bind(this),
          load: this.load.bind(this),
          preload: true
        })
    }, 0)
    return this
  }
}

module.exports = OfferSelect
