const init = (serviceLocator, done) => {
  const salutationWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Salutation',
    itemView: require('./views/item'),
    description: 'Salutation widget'
  }

  serviceLocator.widgetFactories.get('section').register('salutation', salutationWidget)

  done()
}

module.exports = () => ({ salutationWidget: [ 'widget', 'sectionAdmin', init ] })
