const schemata = require('@clocklimited/schemata')
const validateIfShowingMobileWallet = (validators) =>
  validateIfPropertyEquals(
    'showMobileWallet',
    true,
    composeValidator(validators)
  )
const composeValidator = require('@clocklimited/validity-compose')
const mobileWalletSupplementSchema = require('./mobile-wallet-supplement-schema')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const required = require('validity-required')
const validateLength = require('validity-length')

const mobileWalletSchema = () => {
  return schemata({
    name: 'Mobile Wallet Schema',
    properties: {
      showMobileWallet: {
        type: Boolean,
        defaultValue: false
      },
      mobileWalletTitle: {
        type: String,
        validators: {
          draft: [
            validateIfPropertyEquals(
              'showMobileWallet',
              true,
              composeValidator([required, validateLength(1, 25)])
            )
          ],
          published: [
            validateIfPropertyEquals(
              'showMobileWallet',
              true,
              composeValidator([required, validateLength(1, 25)])
            )
          ],
          archived: [
            validateIfPropertyEquals(
              'showMobileWallet',
              true,
              composeValidator([required, validateLength(1, 25)])
            )
          ]
        }
      },
      mobileWalletDescription: {
        type: String,
        validators: {
          draft: [
            validateIfShowingMobileWallet([required, validateLength(1, 55)])
          ],
          published: [
            validateIfShowingMobileWallet([required, validateLength(1, 55)])
          ],
          archived: [
            validateIfShowingMobileWallet([required, validateLength(1, 55)])
          ]
        }
      },
      mobileWalletRelevantDate: {
        type: Date
      },
      mobileWalletExpiryDate: {
        type: Date
      },
      mobileWalletSecondaryFields: {
        type: schemata.Array(mobileWalletSupplementSchema()),
        defaultValue: () => [],
        validators: {
          draft: [
            validateIfShowingMobileWallet([required, validateLength(1, 4)])
          ],
          published: [
            validateIfShowingMobileWallet([required, validateLength(1, 4)])
          ],
          archived: [
            validateIfShowingMobileWallet([required, validateLength(1, 4)])
          ]
        }
      },
      mobileWalletSupplement: {
        type: schemata.Array(mobileWalletSupplementSchema()),
        defaultValue: () => []
      },
      showBarCodeInMobileWallet: {
        type: Boolean,
        defaultValue: false
      },
      mobileWalletBarCodeType: {
        type: String
      },
      mobileWalletGenericBarCode: {
        type: String
      }
    }
  })
}

module.exports = mobileWalletSchema
