const createUniqueMembersRow = require('./data-creator')
const createUniqueAccumulatorMembersData = require('./unique-accumulator-creator')
const createIncrementalData = require('./incremental-creator')
const getSortedUnique = require('../../sorted-unique-retriever')
const createRowTemplate = require('../../row-template-creator')

const transformToReport = (data) => {
  const reportData = {}
  const descriptions = getSortedUnique(data, 'description')

  const lineBreak = createRowTemplate('lineBreak')
  let report = []

  const accumulativeUniqueData = data && data.filter(datapoint => datapoint.description.includes('Accumulative'))

  const financialYears = descriptions && descriptions.filter(description => !description.includes('Accumulative'))

  financialYears.forEach(description => {
    const monthHeaders = createRowTemplate('monthNamesWithBuffer')
    const financialYearData = data.filter(dataPoint => dataPoint.description === description)
    const uniqueMembersRow = createUniqueMembersRow(financialYearData)

    const year = description.replace(/\D+/g, '')
    const uniqueAccumulatorRawData = accumulativeUniqueData.filter(datapoint => datapoint.description.includes(year))
    const uniqueAccumulatorData = createUniqueAccumulatorMembersData(uniqueAccumulatorRawData, year)

    const incrementalData = createIncrementalData(uniqueAccumulatorData)

    report.push([ monthHeaders, uniqueMembersRow, uniqueAccumulatorData, incrementalData, lineBreak ])
  })

  const headers = financialYears

  reportData.report = report
  reportData.headers = headers

  return reportData
}

module.exports = transformToReport
