const View = require('./section-select')

class SectionSelect extends View {
  constructor (serviceLocator, selectedSections) {
    super(...arguments)
    this.selectedSections = selectedSections || []
    if (!Array.isArray(this.selectedSections)) {
      this.selectedSections = [ this.selectedSections ]
    }
    this.initFilter = { _id: { $in: this.selectedSections }, instance: { $ne: null } }
    this.loadFilter = { root: false, instance: { $ne: null } }
  }
}

module.exports = SectionSelect
