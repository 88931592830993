const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-quote-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Quote Details</h2></div><div class=\"panel-content\"><div data-field=\"author\" class=\"form-row\"><label><span class=\"form-label-text\">Author<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"author\"" + (jade.attr("value", data.author, true, false)) + " class=\"control--text form-field\"/><div class=\"js-error\"></div></label></div><div data-field=\"copy\" class=\"form-row\"><label><span class=\"form-label-text\">Quote Text<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"quote\"" + (jade.attr("value", data.quote, true, false)) + " class=\"control--text form-field\"/><div class=\"js-error\"></div></label></div></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor (...args) {
    super(...args)
    this.template = template
  }
}
module.exports = ItemRepeaterFormView
