const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('account', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-show-revisions\">Show Revisions</a></li></ul></div></div>");
if ( allowed('account', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/accounts/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  constructor (...args) {
    super(...args)
    this.$el.on('click', '.js-show-revisions', this.emit.bind(this, 'showRevisions'))
  }

  render () {
    const data = this.model.toJSON()

    this.$el.empty().append(template({
      data,
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format
    }))

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  get template () {
    return template
  }
}

module.exports = ListItemView
