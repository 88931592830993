const createController = require('./controllers/controller')
const createWidgetFactory = require('../widget/lib/base-factory')
const EmailService = require('./services/email')

const init = (serviceLocator, done) => {
  serviceLocator.emailService = new EmailService(serviceLocator)

  const widgetFactory = createWidgetFactory()
  serviceLocator.widgetFactories.register('email', widgetFactory)
  createController(serviceLocator)
  done()
}

module.exports = () => ({ emailAdmin: [ 'widget', init ] })
