const Emitter = require('events')
const extend = require('lodash.assign')
let uberMemoize = require('uber-memoize')
let createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')

class CrudService extends Emitter {
  constructor (serviceLocator, options) {
    super()
    this.authedRequest = createAuthedRequester(serviceLocator.config.apiUrl).bind(this)
    this.options = extend({ ttl: 1000 * 60 * 5 }, options)
    this.serviceLocator = serviceLocator
    const cacheId = this.name
    const memoize = uberMemoize(cacheId + '-find', this.serviceLocator.cache)
    this.cachedFind = memoize(this.find.bind(this), this.options.ttl)
    this._setupCachePurging()
  }

  create (obj, cb) {
    this.authedRequest('POST', this.urlRoot, obj, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return this.handleError(res.statusCode, body, cb)
      this.emit('create', body)
      cb(null, body)
    })
  }

  read (id, cb) {
    this.authedRequest('GET', this.urlRoot + '/' + id, null, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return this.handleError(res.statusCode, body, cb)
      cb(null, body)
    })
  }

  find (keywords, filter, sort, pagination, cb) {
    var query =
      { keywords: keywords,
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        pagination: JSON.stringify(pagination)
      }
    this.authedRequest('GET', this.urlRoot, query, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return this.handleError(res.statusCode, body, cb)
      cb(null, body)
    })
  }

  update (id, obj, cb) {
    this.authedRequest('PUT', this.urlRoot + '/' + id, obj, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return this.handleError(res.statusCode, body, cb)
      this.emit('update', id, body)
      cb(null, body)
    })
  }

  partialUpdate (id, data, cb) {
    this.authedRequest('PATCH', this.urlRoot + '/' + id, data, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return this.handleError(res.statusCode, body, cb)
      this.emit('partialUpdate', id, body)
      cb(null, body)
    })
  }

  delete (id, cb) {
    this.authedRequest('DELETE', this.urlRoot + '/' + id, null, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return this.handleError(res.statusCode, body, cb)
      this.emit('delete', id)
      cb(null)
    })
  }

  _setupCachePurging () {
    [ 'create', 'update', 'partialUpdate', 'delete' ].forEach(e => {
      this.on(e, () => {
        this.cachedFind.clear()
      })
    })
  }

  getError (body) {
    if (body.errors) {
      var error = new Error('Validation Error')
      error.errors = body.errors
      return error
    }
    return new Error(body.error || body.status || body || 'Unknown Error')
  }

  handleError (statusCode, body, cb) {
    if (statusCode === 401) return this.serviceLocator.router.trigger('noAccess', body)
    return cb(this.getError(body))
  }
}

module.exports = CrudService
