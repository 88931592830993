const moment = require('moment')
const momentTimezone = require('moment-timezone')
const determineMonthOnMonthDates = require('./month-on-month-date-determiner')

const retrievePeriodicDates = (startDate, endDate, returnFormattedDate) => {
  const startDateFilter = moment(startDate)
  const endDateFilter = moment(endDate)

  const {
    startDate: startDateMoM,
    endDate: endDateMoM
  } = determineMonthOnMonthDates(startDate, endDate, true)

  const startDateYoY = moment(startDate).subtract(1, 'years')
  const endDateYoY = moment(endDate).subtract(1, 'years')

  const dates = {
    startDateFilter,
    endDateFilter,
    startDateMoM,
    endDateMoM,
    startDateYoY,
    endDateYoY
  }

  if (!returnFormattedDate) {
    Object.entries(dates).forEach((value) => {
      dates[value[0]] = value[1].toDate()
    })
  }

  if (returnFormattedDate) {
    Object.entries(dates).forEach((value) => {
      // Timezone added here so front end presents correctly
      dates[value[0]] = momentTimezone(value[1])
        .tz('Australia/Sydney')
        .format('YYYY-MM-DD')
    })

    const {
      startDate: formattedStartDateMoM,
      endDate: formattedEndDateMoM
    } = determineMonthOnMonthDates(startDate, endDate)

    dates.startDateMoM = momentTimezone(formattedStartDateMoM).tz('Australia/Sydney').format('YYYY-MM-DD')
    dates.endDateMoM = momentTimezone(formattedEndDateMoM).tz('Australia/Sydney').format('YYYY-MM-DD')
  }

  return dates
}

module.exports = retrievePeriodicDates
