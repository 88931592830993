const View = require('ventnor')

class PartnerSelect extends View {
  constructor (serviceLocator, selectedPartners) {
    super(serviceLocator)
    this.$el = $('<select multiple/>')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose one or more partners')
    this.selectedPartners = selectedPartners || []
  }

  initializeSelectize () {
    this.serviceLocator.partnerService.find('', { _id: { $in: this.selectedPartners } }, [], {}, (err, res) => {
      if (err) return alert('Cannot find existing partners')
      res.results.forEach(partner => {
        // The item needs to be added to the list
        // of selectize options in order to be selected
        this.el.selectize.addOption({ value: partner._id, text: partner.name })
        // Select the added option
        this.el.selectize.addItem(partner._id)
      })
      this.el.selectize.on('change', this.updateSelection.bind(this))
    })
  }

  updateSelection () {
    this.selectedPartners = this.el.selectize.getValue()
    this.emit('change', this.selectedPartners)
  }

  load (query, cb) {
    this.serviceLocator.partnerService.find(query, {}, [], {}, (err, data) => {
      if (err) return this.serviceLocator.logger.error(err)
      cb(data.results.map(partner => ({
        value: partner._id,
        text: partner.name
      })))
    })
  }

  render () {
    setTimeout(() => {
      this.$el.selectize(
        { delimiter: ',',
          create: false,
          onInitialize: this.initializeSelectize.bind(this),
          load: this.load.bind(this),
          preload: true
        })
    }, 0)
    return this
  }
}

module.exports = PartnerSelect
