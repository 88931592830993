module.exports = function (serviceLocator) {
  serviceLocator.accountService.find({}, {}, {}, {}, (err, data) => {
    if (err) serviceLocator.logger.error('Cannot load accounts')
    data.results.map(account =>
      this.$el.find('form [name=account]').append(
        $('<option>', { value: account._id, text: account.name })
      )
    )
  })
}
