const BaseFormView = require('../../offer/views/form/pre-redemption')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"field--form\" data-field=\"form\" class=\"form-row\"><label><span class=\"form-label-text\">Form<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-form\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Choose a form containing the questions you wish the user to answer.</p></div></div><div class=\"form-row form-row-actions\"><button type=\"button\" class=\"btn js-download-responses\">Download Responses</button><button type=\"button\" class=\"btn js-edit-form\">Edit Form</button><a class=\"btn btn--notice js-create-form\"><i class=\"icon icon--create\"></i> Create Form</a></div>");;return buf.join("");
}

class DataCaptureFormView extends BaseFormView {
  get settings () {
    return {
      title: 'Data Capture Question Form',
      fieldName: 'form',
      formBuilderType: 'standard'
    }
  }

  get template () {
    return template
  }
}

module.exports = DataCaptureFormView
