const BasicToolbarView = require('../../../toolbar/views/form-toolbar')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data) {
buf.push("<div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button><div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\">Jump To\n <span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a href=\"#section-basic-details\">Basic Details</a></li><li><a href=\"#section-body\">Body</a></li><li><a href=\"#section-scheduling\">Scheduling</a></li></ul></div></div></div><div class=\"control-group\">");
if ( data.state === 'Published' && !allowed('faq', 'publish'))
{
buf.push("<span class=\"label label--large\">You don't have permission to alter Published content.</span>");
}
else
{
buf.push("<div class=\"btn-group\"><button type=\"button\" class=\"btn js-btn-save\">Save</button><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a href=\"#\" class=\"js-btn-save-and-close\">Save and Close</a></li></ul></div>");
if ( allowed('faq', 'publish'))
{
if ( data.state === 'Published')
{
buf.push("<button type=\"button\" class=\"btn btn--warning js-btn-draft\">Revert to Draft</button>");
}
else
{
buf.push("<button type=\"button\" class=\"btn btn--action js-btn-publish\">Publish</button>");
}
}
}
buf.push("</div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const modal = require('modal')

class ToolbarView extends BasicToolbarView {
  constructor (...args) {
    super(...args)
    this.$el.on('click', '.js-btn-save-and-preview', this.emit.bind(this, 'saveAndPreview'))
    this.$el.on('click', '.js-btn-publish', this.emit.bind(this, 'publish'))
    this.$el.on('click', '.js-btn-draft', this.confirmDraft.bind(this))
  }

  get template () {
    return template
  }

  confirmDraft () {
    modal(
      { title: 'Revert to Draft',
        content: 'This FAQ will be removed from public view. Are you sure?',
        buttons:
        [ { text: 'Remain Published', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
          { text: 'Revert to Draft', event: 'confirm', className: 'btn btn--warning' }
        ]
      }).on('confirm', this.emit.bind(this, 'draft'))
  }
}

module.exports = ToolbarView
