const BaseModel = require('cf-base-model')
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const schemata = require('./schema')()

module.exports = BaseModel.extend({
  schemata,

  type: 'video',

  defaults() {
    return schemata.makeDefault({ type: this.type || 'video' })
  },

  validate: validateDelegate,
})
