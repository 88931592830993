const groupColumns = (data, property = 'title') => {
  return data &&
    data.reduce((acc, cur) => {
      if (acc[cur[property]]) {
        acc[cur[property]].push(cur)
        return acc
      }
      acc[cur[property]] = [ cur ]
      return acc
    }, {})
}

module.exports = groupColumns
