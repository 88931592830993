const createController = require('./controllers/controller')
const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const createWidgetFactory = require('../widget/lib/base-factory')
const SectionService = require('./services/section')

const init = (serviceLocator, done) => {
  serviceLocator.sectionService = new SectionService(serviceLocator)
  serviceLocator.widgetFactories.register('section', createWidgetFactory())
  serviceLocator.widgetFactories.register('article', createWidgetFactory())
  serviceLocator.widgetFactories.register('offer', createWidgetFactory())
  createController(serviceLocator)
  createListController(serviceLocator)
  createFormController(serviceLocator)
  done()
}

module.exports = () => ({ sectionAdmin: [ 'widget', init ] })
