const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const modal = require('modal')
const signUri = require('cf-signature/uri')
const createFormSelector = require('../../../form-builder/views/form-select')
const FormBuilderForm = require('../../../form-builder/views/form')
const FormBuilderModel = require('../../../form-builder/models/form-builder')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"section-pre-redemption\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Pre Redemption Questions</h2><p>Only available for certain redemption types.</p></div><div class=\"panel-content\"><div style=\"display:none\" class=\"notification js-supports-pre-redemption--no\">Your chosen redemption type doesn't support Pre Redemption Questions.</div><div class=\"js-supports-pre-redemption--yes\"><div id=\"field--preRedemptionForm\" data-field=\"preRedemptionForm\" class=\"form-row\"><label><span class=\"form-label-text\">Form</span><div class=\"form-field js-form\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Choose a form containing the questions you wish the user to answer before they can redeem this offer.</p></div></div><div class=\"form-row form-row-actions\"><button type=\"button\" class=\"btn js-download-responses\">Download Responses</button><button type=\"button\" class=\"btn js-edit-form\">Edit Form</button><a class=\"btn btn--notice js-create-form\"><i class=\"icon icon--create\"></i> Create Form</a></div></div></div></div>");;return buf.join("");
}

class PreRedemptionQuestionsView extends View {
  get settings () {
    return {
      title: 'Pre-Redemption Question Form',
      fieldName: 'preRedemptionForm',
      formBuilderType: 'preRedemption'
    }
  }

  get template () {
    return template
  }

  constructor (serviceLocator, model) {
    super(serviceLocator)
    this.model = model

    this.FormSelect = createFormSelector(
      this.settings.title,
      this.settings.fieldName,
      'title', serviceLocator.formBuilderService,
      this.settings.formBuilderType
    )
    this.$el.on('click', '.js-edit-form', this.loadEditForm.bind(this))
    this.$el.on('click', '.js-create-form', this.loadCreateForm.bind(this))
    this.$el.on('click', '.js-download-responses', this.downloadCsv.bind(this))
  }

  loadEditForm () {
    const service = this.serviceLocator.formBuilderService
    service.read(this.model.get(this.settings.fieldName), (err, formBuilder) => {
      if (err) throw err
      this.showForm(service, formBuilder, false)
    })
  }

  loadCreateForm () {
    const service = this.serviceLocator.formBuilderService
    this.showForm(service, {
      account: this.model.get('account'),
      type: this.settings.formBuilderType
    }, true)
  }

  downloadCsv () {
    const apiKey = window.localStorage.apiKey
    const id = window.localStorage.apiId
    const uri = '/form-builders/' + this.model.get(this.settings.fieldName) + '/responses'
    window.open(window.config.apiUrl + signUri(uri, apiKey, id), '_blank', 'noopener,noreferrer')
  }

  showForm (service, attributes, isNew) {
    const formBuilderModel = new FormBuilderModel(this.serviceLocator, attributes)
    const form = new FormBuilderForm(
      this.serviceLocator,
      formBuilderModel,
      isNew,
      true
    )

    const formModal = modal({
      title: 'Editing Form',
      className: 'modal wide',
      content: form.render().$el,
      buttons: []
    })

    const removeForm = () => {
      formModal.close()
      form.remove()
    }

    const complete = (err, res) => {
      if (err) {
        return form.showErrors(err.errors)
      }
      this.model.set(this.settings.fieldName, res._id)
      this.renderPreRedemptionSelector()
      removeForm()
    }

    form.on('close', removeForm)
    form.on('save', () => {
      if (isNew) {
        service.create(form.model.toJSON(), complete)
      } else {
        service.update(attributes._id, form.model.toJSON(), complete)
      }
    })
  }

  reset () {
    this.model.set(this.settings.fieldName, null)
    this.renderPreRedemptionSelector()
  }

  render () {
    this.$el.append(this.template())
    this.renderPreRedemptionSelector()
    return this
  }

  renderPreRedemptionSelector () {
    const formSelect = new this.FormSelect(this.serviceLocator, this.model.get(this.settings.fieldName))
    this.$el.find('.js-form').empty().append(formSelect.render().$el)
    formSelect.on('change', form => this.model.set(this.settings.fieldName, form))
  }
}

module.exports = PreRedemptionQuestionsView
