const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const validateLength = require('validity-length')

const validateIfArrayHasLength = (property, validator) => {
  return function (key, msg, object, callback) {
    if (object[property] && object[property].length) {
      return validator(key, msg, object, callback)
    }
    return callback(null, undefined)
  }
}

module.exports = () =>
  schemata({
    name: 'Offer Redemption Type Competition',
    properties: {
      redemptionCompetition: {
        type: String,
        validators: { all: [ required ] }
      },
      redemptionWinners: {
        type: Array,
        validators: { all: [ validateIfArrayHasLength('redemptionRunnerUps', validateLength(1)) ] }
      },
      redemptionRunnerUps: {
        type: Array
      },
      thankYouMessage: {
        type: String,
        validators: { all: [ required ] }
      }
    }
  })
