const debug = require('../../../../admin/source/js/lib/debug')('asset list item')
const filesize = require('bytes')
const FormView = require('./form')
const getImageFactory = require('../lib/image-factory')()
const modal = require('modal')
const bindAll = require('lodash.bindall')

module.exports = window.Backbone.View.extend({
  events: {
    'click .js-edit': 'handleEdit',
    'click .js-delete-item': 'handleDelete',
    'click': 'toggleSelect'
  },

  className: 'grid__item',

  initialize () {
    bindAll(this)
    this.isSelected = false
    this.listenTo(this.model, 'select', this.select)
    this.listenTo(this.model, 'deSelect', this.deSelect)
    this.render()
  },

  toggleSelect (e) {
    // Don't select the element if the click started on the dropdown control
    if ($(e.target).attr('data-toggle') === 'dropdown' ||
          $(e.target).closest('.dropdown-toggle, .dropdown-menu').length) {
      return
    }
    var isChecked = this.$('.js-select').is(':checked')
    if ($(e.target).hasClass('js-select')) {
      this.model.trigger(isChecked ? 'select' : 'deSelect', this.model)
    } else {
      this.model.trigger(isChecked ? 'deSelect' : 'select', this.model)
    }
  },

  select () {
    this.$('.js-select')[0].checked = true
  },

  deSelect () {
    this.$('.js-select')[0].checked = false
  },

  handleEdit (e) {
    e.stopPropagation()
    debug('edit ' + this.model.id)

    var formView = new FormView({ model: this.model, serviceLocator: this.options.serviceLocator })
    modal(
      { title: 'Edit asset details',
        className: 'wide',
        content: formView.$el,
        buttons:
          [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
            { text: 'Save', event: 'save', className: 'btn btn--action' }
          ]
      })
      .on('save', formView.handleSave.bind(formView))
      .on('cancel', this.handleCancel.bind(this))
  },

  handleCancel () {
    debug('Edit cancelled')
  },

  handleSave () {
    debug('Saving', this.model)
    this.model.save()
  },

  handleDelete () {
    debug('Deleting', this.model)

    const deleteSection = () => {
      // this.model.trigger('delete', this.model)
      // Fixes delete within modals such as "Add Images" for articles
      // Issue is the delete event is never bound except under asset admin
      this.model.destroy()
    }

    modal(
      { content: 'Are you sure you want to delete this asset?',
        buttons:
          [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
            { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
          ]
      }).on('confirm', deleteSection)
  },

  getGridClass () {
    var widths =
        [ 'whole', 'half', 'third', 'quarter', 'fifth',
          'sixth', 'seventh', 'eighth', 'ninth', 'tenth'
        ]
    if (typeof this.options.perRow === 'number') {
      return 'one-' + widths[this.options.perRow - 1]
    } else {
      return 'one-fifth'
    }
  },

  render () {
    const type = this.model.get('type') || 'image'
    const factory = getImageFactory(type)
    const viewData = this.model.toJSON()

    viewData.size = filesize(viewData.size)
    viewData.imageUrl = this.model.buildPreviewUrl(150, 150)
    viewData.original = this.model.buildOriginalUrl()

    this.$el.empty().append(factory.itemTemplate(
      { data: viewData,
        allowed: this.options.serviceLocator.allowed
      }))
    this.$el.addClass(this.getGridClass())
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
})
