const debug = require('../../../../admin/source/js/lib/debug')('index router')
const OfferView = require('../views/offer-view')

module.exports = (serviceLocator) => {
  const router = serviceLocator.router

  router.route('analytics/offer', 'AnalyticsView', section => {
    debug('analytics ' + section + ' route triggered')

    const userInstanceIds = serviceLocator.session.instances

    serviceLocator.instanceService.find('', {}, [], {}, (err, instanceFind) => {
      if (err) return router.trigger('notFound', err.message)

      let instances = instanceFind.results

      if (userInstanceIds.length) instances = instanceFind.results.filter(instance => userInstanceIds.includes(instance._id))

      const view = new OfferView({
        serviceLocator: serviceLocator,
        instances: instances
      })

      router.render(view)
    })
  })
}
