const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (config, data, sessionHasSpecificAccount, title) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button></div></div><div class=\"control-group\"><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div class=\"panel panel-styled\"><div class=\"panel-content\"><div id=\"field--path\" data-field=\"path\" class=\"form-row\"><label><span class=\"form-label-text\">Path<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"path\"" + (jade.attr("value", data.path, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Your chosen URL path. This will be accessed via " + (jade.escape((jade_interp = config.url) == null ? '' : jade_interp)) + "&lt;Path&gt;.</p></div></div><div id=\"field--redirectUrl\" data-field=\"redirectUrl\" class=\"form-row\"><label><span class=\"form-label-text\">Redirect URL<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"redirectUrl\"" + (jade.attr("value", data.redirectUrl, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The destination for your URL. This can be either relative (/my-url) or absolute (http://www.google.com).</p></div></div>");
if ( !sessionHasSpecificAccount)
{
buf.push("<div id=\"field--account\" data-field=\"account\" class=\"form-row\"><label><span class=\"form-label-text\">Account<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-account-select\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"></div></div>");
}
buf.push("</div></div><div id=\"section-scheduled-redirects\" class=\"panel panel-styled\"><div class=\"panel-header\"><div class=\"grid\"><div class=\"grid__item four-fifths\"><h2>Scheduled Redirects</h2></div><div class=\"grid__item one-fifth\"><div class=\"control-group\"><button class=\"btn btn-primary js-add-scheduled-redirect\">Add Scheduled Redirect</button></div></div></div></div><div class=\"panel-conntent\"><div class=\"centering\"><div class=\"js-scheduled-redirect\"></div></div></div></div></form></div></div>");}.call(this,"config" in locals_for_with?locals_for_with.config:typeof config!=="undefined"?config:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"sessionHasSpecificAccount" in locals_for_with?locals_for_with.sessionHasSpecificAccount:typeof sessionHasSpecificAccount!=="undefined"?sessionHasSpecificAccount:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const debug = require('../../../../admin/source/js/lib/debug')(
  'redirect form view'
)
const formSaveDelegate =
  require('../../../../admin/source/js/lib/form-save-delegate')(debug)
const formCancelDelegate = require('cf-form-cancel-delegate')(debug)
const formTitleDelegate = require('../../../../admin/source/js/lib/form-title-delegate')
const BaseView = require('cf-base-view')
const ScheduledRedirectFormView = require('./scheduled-redirect-form')
const ScheduledRedirectItemView = require('./scheduled-redirect-item')
const ScheduledRedirectModel = require('../models/scheduled-redirect')
const modal = require('modal')
const url = require('url')
const InstanceSelect = require('../../instance/views/instance-select')
const config = window.config
const AccountSelect = require('../../account/views/account-select')

module.exports = BaseView.extend({
  events: {
    'click .js-save': 'handleSave',
    'click .js-cancel': 'handleCancel',
    'click .js-add-scheduled-redirect': 'handleAddScheduledRedirect',
    submit: (e) => e.preventDefault()
  },

  initialize() {
    this.initialModel = this.model.toJSON()
    this.formTitle = formTitleDelegate(this.options.title)
    this.scheduledRedirectViews = []
    this.instanceSelect = new InstanceSelect(
      this.options.serviceLocator,
      this.model.get('instance')
    )
    this.instanceSelect.$el.attr('name', 'instance')

    this.render()

    this.listenTo(this, 'afterAppend', this.focusForm)

    this.on('remove', this.destroy)
  },

  focusForm() {
    this.$(':input[name=path]').focus()
  },

  handleCancel: formCancelDelegate,

  handleSave() {
    // Handle absolute & relative
    const redirectUrl = this.$(':input[name=redirectUrl]').val()
    const parsedUrl = url.parse(redirectUrl)

    // Relative URL
    if (!parsedUrl.protocol) {
      this.populateRelativeUrl(this.$(':input[name=redirectUrl]'))
    }
    this.model.set(
      'scheduledRedirects',
      this.scheduledRedirectViews.map(function (scheduledRedirectView) {
        return scheduledRedirectView.model.toJSON()
      })
    )
    formSaveDelegate.call(this)
  },

  renderAccountSelect() {
    const accountView = new AccountSelect(
      this.options.serviceLocator,
      this.model.get('account')
    )
    accountView.populate()
    this.$el.find('.js-account-select').append(accountView.render().$el)
    accountView.on('change', (account) => this.model.set('account', account))
  },

  handleAddScheduledRedirect: function (e) {
    e.preventDefault()
    this.addScheduledRedirect()
  },

  addScheduledRedirectForm: function (isNew, model) {
    var scheduledRedirectView = new ScheduledRedirectFormView({
      model: model
    })

    var scheduledRedirectModal = modal({
      title: 'Scheduled Redirect',
      className: 'modal-form-view wide',
      content: scheduledRedirectView.$el,
      buttons: []
    })

    scheduledRedirectView.on('closeModal', function () {
      scheduledRedirectModal.close()
    })

    scheduledRedirectModal.on(
      'close',
      function () {
        scheduledRedirectView.remove()
      }.bind(this)
    )

    scheduledRedirectModal.on(
      'closeModal',
      function () {
        scheduledRedirectModal.close()
      }.bind(this)
    )

    scheduledRedirectView.on(
      'saveScheduledRedirect',
      function () {
        if (isNew) {
          this.addScheduledRedirectItem(model)
        }
        scheduledRedirectModal.close()
      }.bind(this)
    )

    return scheduledRedirectModal
  },

  addScheduledRedirect: function (isNew, scheduledRedirectModel) {
    if (!scheduledRedirectModel) {
      this.addScheduledRedirectForm(true, new ScheduledRedirectModel())
    } else {
      this.addScheduledRedirectItem(
        new ScheduledRedirectModel(scheduledRedirectModel)
      )
    }
  },

  addScheduledRedirectItem: function (model) {
    var itemView = new ScheduledRedirectItemView({ model: model })

    this.scheduledRedirectViews.push(itemView)

    itemView.on(
      'deleteScheduledRedirect',
      this.deleteScheduledRedirect.bind(this)
    )

    model.on(
      'change',
      function () {
        itemView.render()
      }.bind(this)
    )

    itemView.on(
      'editScheduledRedirect',
      function (model) {
        this.addScheduledRedirectForm(false, model)
      }.bind(this)
    )
    this.$el.find('.js-scheduled-redirect').append(itemView.render().$el)
  },

  deleteScheduledRedirect: function (cid) {
    var viewIndex = this.scheduledRedirectViews.findIndex(function (
      scheduledRedirectView
    ) {
      return scheduledRedirectView.model.cid === cid
    })

    this.scheduledRedirectViews[viewIndex].remove()
    this.scheduledRedirectViews.splice(viewIndex, 1)
  },

  populateRelativeUrl($input) {
    var value = $input.val()

    // Add leading / if missing
    if (value.charAt(0) !== '/') value = '/' + value

    $input.val(value)
  },

  render() {
    this.$el.empty().append(
      template({
        data: this.model.toJSON(),
        title: this.formTitle(this.model.toJSON()),
        config: config,
        sessionHasSpecificAccount: this.options.serviceLocator.session.account
      })
    )

    this.$el.find('.js-instances').append(this.instanceSelect.render().$el)
    this.attachView(this.instanceSelect)
    this.renderAccountSelect()
    this.model
      .get('scheduledRedirects')
      .forEach(this.addScheduledRedirect.bind(this, false))

    return this
  }
})
