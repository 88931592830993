const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (instances, tableData, undefined) {
jade_mixins["timePeriod"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label><span class=\"form-label-text\">Time Period</span><select class=\"control control--choice form-field js-reports-time-period\"><option value=\"7\" selected=\"selected\">Last 7 Days</option><option value=\"30\">Last 30 Days</option><option value=\"60\">Last 60 Days</option><option value=\"90\">Last 90 Days</option><option value=\"180\">Last 6 Months</option><option value=\"365\">Last Year</option></select></label>");
};
jade_mixins["instanceSelect"] = jade_interp = function(intances){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label><span class=\"form-label-text\">Instance</span><select class=\"control control--choice form-field js-reports-instance\"><option value=\"\">All</option>");
// iterate instances
;(function(){
  var $$obj = instances;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var instance = $$obj[$index];

buf.push("<option" + (jade.attr("value", instance._id, true, false)) + ">" + (jade.escape(null == (jade_interp = instance.name) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var instance = $$obj[$index];

buf.push("<option" + (jade.attr("value", instance._id, true, false)) + ">" + (jade.escape(null == (jade_interp = instance.name) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label>");
};
buf.push("<div class=\"page-content\"><div class=\"centering\"><header class=\"page-header\"><h1>Analytics</h1></header><div class=\"grid\"><div class=\"grid__item three-quarters\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Offers</h2></div><div class=\"panel-content\"><canvas class=\"js-chart\"></canvas></div></div></div><div class=\"grid__item one-quarter\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form><div class=\"form-row form-row-full-width\">");
jade_mixins["instanceSelect"](instances);
buf.push("</div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Offer</span><select placeholder=\"None\" class=\"control control--choice form-field js-reports-offerId\"></select></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Type</span><select class=\"control control--choice form-field js-reports-type\"><option value=\"both\" selected=\"selected\">Both</option><option value=\"redeem\">Redeem</option><option value=\"view\">View</option></select></label></div><div class=\"form-row form-row-full-width\">");
jade_mixins["timePeriod"]();
buf.push("</div></form></div></div></div><div class=\"js-table\"><div class=\"grid__item one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Top Offers</h2></div><div class=\"panel-content\"><table><tr><th></th><th>Logged-In Views</th><th>Redemptions</th></tr>");
// iterate tableData
;(function(){
  var $$obj = tableData;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var offer = $$obj[$index];

buf.push("<tr><td><a" + (jade.attr("href", '/offers/' + offer.offer._id + '/form', true, false)) + ">" + (jade.escape(null == (jade_interp = offer.offer.shortHeadline) ? "" : jade_interp)) + "</a></td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numViews.toLocaleString()) ? "" : jade_interp)) + "</td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numRedeems.toLocaleString()) ? "" : jade_interp)) + "</td></tr>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var offer = $$obj[$index];

buf.push("<tr><td><a" + (jade.attr("href", '/offers/' + offer.offer._id + '/form', true, false)) + ">" + (jade.escape(null == (jade_interp = offer.offer.shortHeadline) ? "" : jade_interp)) + "</a></td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numViews.toLocaleString()) ? "" : jade_interp)) + "</td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numRedeems.toLocaleString()) ? "" : jade_interp)) + "</td></tr>");
    }

  }
}).call(this);

buf.push("</table></div></div></div></div></div></div></div>");}.call(this,"instances" in locals_for_with?locals_for_with.instances:typeof instances!=="undefined"?instances:undefined,"tableData" in locals_for_with?locals_for_with.tableData:typeof tableData!=="undefined"?tableData:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const tableTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (tableData, undefined) {
buf.push("<div class=\"grid__item one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Top Offers</h2></div><div class=\"panel-content\"><table><tr><th></th><th>Logged-In Views</th><th>Redemptions</th></tr>");
// iterate tableData
;(function(){
  var $$obj = tableData;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var offer = $$obj[$index];

buf.push("<tr><td><a" + (jade.attr("href", '/offers/' + offer.offer._id + '/form', true, false)) + ">" + (jade.escape(null == (jade_interp = offer.offer.shortHeadline) ? "" : jade_interp)) + "</a></td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numViews.toLocaleString()) ? "" : jade_interp)) + "</td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numRedeems.toLocaleString()) ? "" : jade_interp)) + "</td></tr>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var offer = $$obj[$index];

buf.push("<tr><td><a" + (jade.attr("href", '/offers/' + offer.offer._id + '/form', true, false)) + ">" + (jade.escape(null == (jade_interp = offer.offer.shortHeadline) ? "" : jade_interp)) + "</a></td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numViews.toLocaleString()) ? "" : jade_interp)) + "</td><td class=\"number\">" + (jade.escape(null == (jade_interp = offer.numRedeems.toLocaleString()) ? "" : jade_interp)) + "</td></tr>");
    }

  }
}).call(this);

buf.push("</table></div></div></div>");}.call(this,"tableData" in locals_for_with?locals_for_with.tableData:typeof tableData!=="undefined"?tableData:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const Chart = require('chart.js')

module.exports = window.Backbone.View.extend({
  events: {
    'change .js-reports-offerId': 'handleFilterChange',
    'change .js-reports-time-period': 'handleFilterChange',
    'change .js-reports-type': 'handleFilterChange',
    'change .js-reports-instance': 'handleFilterChange'
  },

  template: template,

  initialize () {
    this.render()
    this.initialiseChart()
    this.instanceId = this.$el.find('.js-reports-instance').val()
    this.offerId = this.$el.find('.js-reports-offerId').val()
    this.type = this.$el.find('.js-reports-type').val()
    this.timePeriod = this.$('.js-reports-time-period').val()
    this.tableData = []
    this.loadStats({ offerId: this.offerId, timePeriod: this.timePeriod })
  },

  handleFilterChange () {
    this.instanceId = this.$el.find('.js-reports-instance').val()
    this.offerId = this.$el.find('.js-reports-offerId').val()
    this.type = this.$el.find('.js-reports-type').val()
    this.timePeriod = this.$('.js-reports-time-period').val()

    const options = { type: this.type, timePeriod: this.timePeriod }

    if (this.instanceId) options.instanceId = this.instanceId
    if (this.offerId) options.offerId = this.offerId
    this.loadStats(options)
  },

  loadStats (options) {
    options = Object.assign({}, options)

    const account = this.options.serviceLocator.session.account
    if (account) options.accountId = account

    Backbone.ajax({
      url: window.config.apiUrl + '/analytics',
      data: options,
      contentType: 'application/json',
      type: 'GET',
      dataType: 'json',
      success: data => {
        this.tableData = data.table
        this.updateTable()

        this.chart.data.datasets[0].data = data.chart.data
        this.chart.data.labels = data.chart.labels
        this.chart.update()
      }
    })
  },

  updateTable () {
    this.$el.find('.js-table').empty().append(tableTemplate({ tableData: this.tableData || [] }))
  },

  initialiseChart () {
    const ctx = this.$('.js-chart')
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [ ],
        datasets: [ {
          label: '',
          data: [ ]
        } ]
      },
      options: { legend: { display: false } }
    })
  },

  renderOfferSelect () {
    this.$el.find('select.js-reports-offerId').selectize({
      load: (query, callback) => {
        Backbone.ajax({
          url: this.options.serviceLocator.config.apiUrl + '/offers',
          type: 'GET',
          data: { keywords: query, sort: '["dateCreated","desc"]' },
          success: response => callback(response.results.map(offer => ({ value: offer._id, text: offer.headline })))
        })
      }
    })
  },

  render () {
    this.$el.empty().append(this.template({
      config: window.config,
      allowed: this.options.serviceLocator.allowed,
      instances: this.options.instances,
      tableData: this.tableData || []
    }))
    this.renderOfferSelect()
    return this
  }
})
