var compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (account, accounts, allowed, canSwitch, name, undefined) {
buf.push("<div class=\"page-split page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\">");
if ( allowed('faq', 'delete'))
{
buf.push("<div class=\"toolbar__left\"><button class=\"btn js-delete\">Delete Selected</button></div>");
}
if ( canSwitch)
{
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\">Select Account\n <span class=\"caret\"></span></a><ul class=\"dropdown-menu\">");
// iterate accounts
;(function(){
  var $$obj = accounts;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var acc = $$obj[$index];

buf.push("<li" + (jade.attr("data-account", acc._id, true, false)) + " class=\"account-item\"><a>" + (jade.escape(null == (jade_interp = acc.name) ? "" : jade_interp)) + "</a></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var acc = $$obj[$index];

buf.push("<li" + (jade.attr("data-account", acc._id, true, false)) + " class=\"account-item\"><a>" + (jade.escape(null == (jade_interp = acc.name) ? "" : jade_interp)) + "</a></li>");
    }

  }
}).call(this);

buf.push("</ul></div>");
}
buf.push("</div></div>");
if ( allowed('faq', 'delete'))
{
buf.push("<button class=\"btn js-edit-order\">Edit Order</button>");
}
if ( allowed('faq', 'create'))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New " + (jade.escape((jade_interp = name.singular) == null ? '' : jade_interp)) + "</button>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header>");
if ( !account)
{
buf.push("<div id=\"faq-notification\" class=\"notification notification--notice\">Please select an account from the dropdown at the top</div>");
}
buf.push("<div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"js-controls\"></div><div class=\"list-container\"><div class=\"dd\"><ol class=\"js-items dd-list\"></ol></div></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div>");}.call(this,"account" in locals_for_with?locals_for_with.account:typeof account!=="undefined"?account:undefined,"accounts" in locals_for_with?locals_for_with.accounts:typeof accounts!=="undefined"?accounts:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"canSwitch" in locals_for_with?locals_for_with.canSwitch:typeof canSwitch!=="undefined"?canSwitch:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const ListItemView = require('./list-item')
const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')

class ListView extends BaseListView {
  constructor (serviceLocator, collection, paginationModel, accounts, account) {
    super(...arguments)

    this.$el.on('click', '.js-toolbar .js-edit-order', this.emit.bind(this, 'reorder'))
    this.$el.on('click', '.account-item', this.selectAccount.bind(this))
    this.accounts = accounts
    this.account = account
  }

  selectAccount (e) {
    this.filters.clearFilter()

    this.$el.find('#faq-notification').hide()
    const item = e.currentTarget
    this.emit('accountSelected', item.dataset.account)
  }

  get FilterView () {
    return FilterView
  }

  get ListItemView () {
    return ListItemView
  }

  get name () {
    return { singular: 'FAQ', plural: 'FAQs', resource: 'faq' }
  }

  get template () {
    return template
  }

  addListItem (model) {
    if (this.$el.find('[data-id=' + model.id + ']').length !== 0) {
      return
    }

    const listItem = new ListItemView(this.serviceLocator, model, this.accounts)
    let $parent = this.$el.find('.js-items')
    const parentId = model.get('parent')

    if (parentId) {
      const parent = this.$el.find('.js-items [data-id=' + parentId + ']')
      if (parent.length > 0) {
        $parent = parent
      }
      if ($parent.find('ol').length > 0) {
        $parent = $parent.find('ol')
      } else {
        var a = $('<ol class="dd-list">')
        $parent.append(a)
        $parent = a
      }
    }

    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)

    $parent.append($('<li class="dd-item" data-id=' + model.id + '>').append(listItem.render().$el))
  }

  updateListItem (model) {
    // Ignore root items
    if (model.get('root')) {
      return true
    }

    const $current = this.$el.find('[data-id=' + model.id + '] > div')
    const newItem = new ListItemView(this.serviceLocator, model, this.accounts)

    if ($current.length !== 0) {
      $current.replaceWith(newItem.render().$el)
    }
  }

  removeListItem (model) {
    // Ignore root items
    if (model.get('root')) {
      return true
    }

    this.$el.find('[data-id=' + model.id + ']').remove()
  }

  render () {
    this.createFilterView()
    // Render the template
    this.$el.append(this.template({
      name: this.name,
      allowed: this.serviceLocator.allowed,
      canSwitch: !this.serviceLocator.session.account,
      account: this.account,
      accounts: this.accounts
    }))
    this.collection.models.forEach(this.addListItem.bind(this))
    if (this.filters) this.$el.find('.js-filters').append(this.filters.render().$el)
    this.updatePagination()
    return this
  }
}

module.exports = ListView
