const Model = require('merstone')

module.exports = (serviceLocator, collection, paginationModel) => {
  const getRevisions = (entityId, type) => {
    serviceLocator.revisionService.listRevisionsForEntity(entityId, type, (err, res) => {
      if (err) return serviceLocator.logger.error('Could not load revisions', err)
      collection.reset(res.map(revision =>
        new Model(serviceLocator, revision)
      ))
      paginationModel.set('totalItems', collection.models.length)
      paginationModel.set('showing', collection.models.length)
    })
  }

  return getRevisions
}
