const baseSchema = require('../../../../../service/widget/model/base-widget')()
const schema = require('./schema')()
const schemata = require('@clocklimited/schemata')
const validateDelegate = require('../../../../../../admin/source/js/lib/validate-delegate')()

module.exports = window.Backbone.Model.extend({
  schemata: schemata({
    name: schema.getName(),
    properties: Object.assign(
      {},
      baseSchema.getProperties(),
      schema.getProperties()
    )
  }),
  defaults () {
    return baseSchema.makeDefault({ type: 'articleLayoutHeader' })
  },
  validate: validateDelegate
})
