const FormView = require('../views/form')
const notify = require('../../notification/foreground')
const previewOffer = require('../lib/offer-preview')

module.exports = serviceLocator => (offer, account, isNew) => {
  const form = new FormView(serviceLocator, offer, account, isNew).render()
  serviceLocator.router.render(form, isNew ? 'New Offer' : 'Edit Offer')

  const save = (icon, state, cb) => {
    const previousState = form.model.get('state') || 'Draft'
    const service = serviceLocator.offerService
    let saveFn = null

    if (state) form.model.set('state', state)

    if (offer.id) {
      saveFn = service.update.bind(service, offer.id, form.model.toJSON())
    } else {
      saveFn = service.create.bind(service, form.model.toJSON())
    }

    afterSave(saveFn, err => {
      if (err) return form.model.set('state', previousState)
      notify(state || 'Saved', icon)
      if (typeof cb === 'function') cb(null, isNew)
    })
  }

  const afterSave = (saveFn, cb) => {
    saveFn((err, data) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.model.reset(data)
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, form.model)
    })
  }

  const preview = () => {
    previewOffer(serviceLocator, offer)
      .on('window:open', (w) => {
        w.document.write('Saving, please wait...')
      })
      .on('error:noSection', form.showMissingSectionError.bind(form))
  }

  form.on('publish', save.bind(null, 'published', 'Published', form.close.bind(form)))
  form.on('draft', save.bind(null, 'draft', 'Draft', form.close.bind(form)))
  form.on('saveAndClose', save.bind(null, 'save', '', form.close.bind(form)))

  form.on('save', () => {
    save('save', null, (err, isNew) => {
      if (err) return serviceLocator.logger.error(err)
      form.emit('saveComplete', isNew)
      isNew = false
    })
  })

  form.on('saveAndPreview', () => {
    save('save', null, preview)
  })

  return form
}
