// WARNING!
// This file is generated by pliers. Do not manually
// edit its contents. Your changes WILL be overwritten!

module.exports = initComponents

const componentLoader = require('component-loader')
let components = [ require('../../../components/admin/widgets/video/init.js')
, require('../../../components/admin/widgets/title/init.js')
, require('../../../components/admin/widgets/text/init.js')
, require('../../../components/admin/widgets/subscriber-benefits/init.js')
, require('../../../components/admin/widgets/sortable-offers/init.js')
, require('../../../components/admin/widgets/search-results/init.js')
, require('../../../components/admin/widgets/salutation/init.js')
, require('../../../components/admin/widgets/related-widgets/init.js')
, require('../../../components/admin/widgets/related-offers/init.js')
, require('../../../components/admin/widgets/random-offers/init.js')
, require('../../../components/admin/widgets/quote/init.js')
, require('../../../components/admin/widgets/personalised-related-offers/init.js')
, require('../../../components/admin/widgets/offer-stack/init.js')
, require('../../../components/admin/widgets/offer-redemption-history/init.js')
, require('../../../components/admin/widgets/offer-layout/terms/init.js')
, require('../../../components/admin/widgets/offer-layout/social/init.js')
, require('../../../components/admin/widgets/offer-layout/related-widgets/init.js')
, require('../../../components/admin/widgets/offer-layout/redemption-area/init.js')
, require('../../../components/admin/widgets/offer-layout/partners/init.js')
, require('../../../components/admin/widgets/offer-layout/key-facts/init.js')
, require('../../../components/admin/widgets/offer-layout/images/init.js')
, require('../../../components/admin/widgets/offer-layout/header/init.js')
, require('../../../components/admin/widgets/offer-layout/body/init.js')
, require('../../../components/admin/widgets/offer-layout/banner/init.js')
, require('../../../components/admin/widgets/offer-grid/init.js')
, require('../../../components/admin/widgets/not-found/init.js')
, require('../../../components/admin/widgets/navigation/init.js')
, require('../../../components/admin/widgets/most-popular/init.js')
, require('../../../components/admin/widgets/members-hero/init.js')
, require('../../../components/admin/widgets/inline-image/init.js')
, require('../../../components/admin/widgets/info-panel/init.js')
, require('../../../components/admin/widgets/html/init.js')
, require('../../../components/admin/widgets/hero-image-with-text/init.js')
, require('../../../components/admin/widgets/hero/init.js')
, require('../../../components/admin/widgets/google-map/init.js')
, require('../../../components/admin/widgets/gallery/init.js')
, require('../../../components/admin/widgets/featured-comp-winners/init.js')
, require('../../../components/admin/widgets/email/testimonial/init.js')
, require('../../../components/admin/widgets/email/split-offer/init.js')
, require('../../../components/admin/widgets/email/promo/init.js')
, require('../../../components/admin/widgets/email/offer-text/init.js')
, require('../../../components/admin/widgets/email/offer-image/init.js')
, require('../../../components/admin/widgets/email/offer-hero/init.js')
, require('../../../components/admin/widgets/email/offer-grid/init.js')
, require('../../../components/admin/widgets/email/featured-offer-grid/init.js')
, require('../../../components/admin/widgets/email/bio-list/init.js')
, require('../../../components/admin/widgets/divider/init.js')
, require('../../../components/admin/widgets/content-stack/init.js')
, require('../../../components/admin/widgets/competition-winners/init.js')
, require('../../../components/admin/widgets/biography/init.js')
, require('../../../components/admin/widgets/audio/init.js')
, require('../../../components/admin/widgets/article-layout/related-content/init.js')
, require('../../../components/admin/widgets/article-layout/images/init.js')
, require('../../../components/admin/widgets/article-layout/header/init.js')
, require('../../../components/admin/widgets/article-layout/comments/init.js')
, require('../../../components/admin/widgets/article-layout/body/init.js')
, require('../../../components/admin/widgets/all-faqs/init.js')
, require('../../../components/admin/widget/init.js')
, require('../../../components/admin/unique-code/init.js')
, require('../../../components/admin/tag/init.js')
, require('../../../components/admin/section/init.js')
, require('../../../components/admin/role/init.js')
, require('../../../components/admin/revision/init.js')
, require('../../../components/admin/reporting/init.js')
, require('../../../components/admin/redirect/init.js')
, require('../../../components/admin/redemption-report/init.js')
, require('../../../components/admin/question/init.js')
, require('../../../components/admin/partner/init.js')
, require('../../../components/admin/offer-redemption-types/voucher/init.js')
, require('../../../components/admin/offer-redemption-types/purchase/init.js')
, require('../../../components/admin/offer-redemption-types/link/init.js')
, require('../../../components/admin/offer-redemption-types/free-text/init.js')
, require('../../../components/admin/offer-redemption-types/download/init.js')
, require('../../../components/admin/offer-redemption-types/competition/init.js')
, require('../../../components/admin/offer/init.js')
, require('../../../components/admin/menu-bar/init.js')
, require('../../../components/admin/list/init.js')
, require('../../../components/admin/layout/init.js')
, require('../../../components/admin/instance/init.js')
, require('../../../components/admin/index/init.js')
, require('../../../components/admin/form-builder/init.js')
, require('../../../components/admin/faq/init.js')
, require('../../../components/admin/email/init.js')
, require('../../../components/admin/data-capture/init.js')
, require('../../../components/admin/code-word/init.js')
, require('../../../components/admin/asset/init.js')
, require('../../../components/admin/api-error/init.js')
, require('../../../components/admin/analytics/init.js')
, require('../../../components/admin/administrator/init.js')
, require('../../../components/admin/account/init.js') ]

function initComponents (serviceLocator) {
  componentLoader(
    components,
    (loadComponentFn) => {
      return function () {
        const callback = Array.prototype.slice.call(arguments, -1)[0]
        loadComponentFn(serviceLocator, callback)
      }
    },
    function (error) {
      if (error) throw error
      serviceLocator.componentLoader.emit('complete')
    }
  )
}
