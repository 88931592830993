const createHeaders = require('./header-creator')
const createRedemptionsData = require('./redemptions-data-creator')
const createAccumulatorData = require('./redemptions-accumulator-creator')

const transformToReport = (data) => {
  const reportData = {}
  const redemptionsData = createRedemptionsData(data)
  const lineBreak = Array(13).fill({ value: '-' })
  const accumulatorData = createAccumulatorData(redemptionsData)

  const report = [ ...redemptionsData, lineBreak, ...accumulatorData ]

  reportData.report = report
  reportData.headers = createHeaders()

  return reportData
}

module.exports = transformToReport
