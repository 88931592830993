const createController = require('./controllers/controller')
const UserActivityService = require('./services/user-activity')

const init = (serviceLocator, done) => {
  serviceLocator.userActivityService = new UserActivityService(serviceLocator)
  createController(serviceLocator)
  done()
}

module.exports = () => ({ redemptionDataAdmin: init })
