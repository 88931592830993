const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const dateBeforePropertyValidator = require('validity-date-before-property')(
  'endDate',
  'End Date'
)

module.exports = () =>
  schemata({
    name: 'Redemption Report Date Range',
    properties: {
      report: {
        type: String,
        validators: { all: [required] },
      },
      account: {
        type: String,
        validators: { all: [required] },
      },
      startDate: {
        type: Date,
        validators: {
          all: [required, dateBeforePropertyValidator],
        },
      },
      endDate: {
        type: Date,
        validators: {
          all: [required],
        },
      },
    },
  })
