const BaseModel = require('cf-base-model')
const validateDelegate = require('../../../../../../admin/source/js/lib/validate-delegate')()
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const validateIfSet = require('validity-validate-if-set')
const required = require('validity-required')
const validateIfPropertySet = require('validity-validate-if-property-set')
const isUrl = require('validity-url')

const schema = schemata({
  name: 'Offer Text',
  properties: Object.assign(
    {},
    baseSchema.getProperties(),
    {
      copyText: {
        type: String
      },
      callToActionText: {
        type: String,
        validators: [ validateIfPropertySet('callToActionLink', required) ]
      },
      callToActionLink: {
        type: String,
        validators: [ validateIfPropertySet('callToActionText', required), validateIfSet(isUrl) ]
      },
      segment: {
        type: String,
        defaultValue: () => false
      }
    }
  )
})

module.exports = BaseModel.extend({
  schemata: schema,
  type: 'offerText',
  defaults () {
    return schema.makeDefault({ type: this.type || 'offerText' })
  },
  validate: validateDelegate
})
