const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const dateBeforeEndDateValidator = require('validity-date-before-property')('endDate')

module.exports = function (find) {
  const validateDateOverlaps = (startProperty, endProperty) => (key, msg, object, callback) => {
    const start = object[startProperty]
    const end = object[endProperty]
    const query = {
      parentId: object.parentId,
      [startProperty]: { '$lte': end },
      [endProperty]: { '$gte': start }
    }
    find(query, (err, res) => {
      if (err) return callback(err)
      if (res && object._id === res._id) {
        return callback()
      }
      callback(null, res
        ? `Start and End Dates can not overlap with start and end dates of other code words: ${res.codeWord} overlaps`
        : undefined
      )
    })
  }

  return schemata({
    name: 'Code Word',
    properties: {
      _id: {
        type: String
      },
      parentId: {
        type: String,
        validators: { all: [ required ] }
      },
      codeWord: {
        type: String,
        validators: { all: [ required ] }
      },
      type: {
        type: String
      },
      startDate: {
        type: Date,
        validators: { all: [ dateBeforeEndDateValidator, validateDateOverlaps('startDate', 'endDate') ] }
      },
      endDate: {
        type: Date,
        validators: { all: [ validateDateOverlaps('startDate', 'endDate') ] }
      }
    }
  })
}
