const moment = require('moment')
const schedule = require('regg')()
const visibility = require('regg')()

schedule
  .register('notScheduled', () => null)

  .register('expired', (liveDate, expiryDate) => ({
    labelClass: 'label-scheduled label--inverse',
    iconClass: 'icon--clock',
    title: 'Expired',
    description: 'Expired ' + moment(expiryDate).fromNow()
  }))

  .register('scheduledFuture', liveDate => ({
    labelClass: 'label-scheduled label--notice',
    iconClass: 'icon--clock',
    title: 'Scheduled – Future',
    description: 'Goes live ' + moment(liveDate).fromNow()
  }))

  .register('scheduledInDateWillExpire', (liveDate, expiryDate) => ({
    labelClass: 'label-scheduled label--success',
    iconClass: 'icon--clock',
    title: 'Scheduled – In date',
    description: 'Expires ' + moment(expiryDate).fromNow()
  }))

  .register('scheduledInDateWillNotExpire', liveDate => ({
    labelClass: 'label-scheduled label--success',
    iconClass: 'icon--clock',
    title: 'Scheduled – In date',
    description: 'Will not expire<br>Live ' + moment(liveDate).fromNow()
  }))

  .register('scheduledInDateExpiringSoon', (liveDate, expiryDate) => ({
    labelClass: 'label-scheduled label--warning',
    iconClass: 'icon--clock',
    title: 'Scheduled – Ending Soon',
    description: 'Expires ' + moment(expiryDate).fromNow()
  }))

  .register('scheduledLiveSoonNotPublished', liveDate => ({
    labelClass: 'label-scheduled label--error',
    iconClass: 'icon--clock',
    title: 'Scheduled – Unpublished Content',
    description: 'Goes live ' + moment(liveDate).fromNow() + ' but item is not yet published.'
  }))

  .register('scheduledLiveNowNotPublished', liveDate => ({
    labelClass: 'label-scheduled label--error',
    iconClass: 'icon--clock-important',
    title: 'Scheduled – Unpublished Content',
    description: !liveDate
      ? 'Went live at time of writing, but item is not yet published.'
      : 'Went live ' + moment(liveDate).fromNow() + ', but item is not yet published.'
  }))

visibility

  .register('draft', () => ({
    labelClass: 'label-status',
    iconClass: 'icon--draft',
    title: 'Draft',
    description: 'This content is not published.<br>It is not visible on the site'
  }))

  .register('published', () => ({
    labelClass: 'label-status label--standard',
    iconClass: 'icon--published',
    title: 'Published',
    description: 'This content is visible on the site.'
  }))

  .register('publishedHidden', () => ({
    labelClass: 'label-status label--inverse',
    iconClass: 'icon--published',
    title: 'Published – Hidden',
    description: 'Published but not visible on the site due to schedule settings.'
  }))

  .register('archived', () => ({
    labelClass: 'label-status label--inverse',
    iconClass: 'icon--archived',
    title: 'Archived',
    description: 'This content is visible on the site. Will not appear in unfiltered CMS searches'
  }))

module.exports = { visibility, schedule }
