const FormView = require('./form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, hideHeaderAndControls, sessionHasSpecificAccount) {
buf.push("<form>");
if ( !hideHeaderAndControls)
{
buf.push("<div class=\"js-errors-summary\"></div><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/><div class=\"js-error\"></div></label><div class=\"form-row-description form-copy\"><p>Something to identify the contents of the list</p></div></div><div class=\"form-row\"><label><span class=\"form-label-text\">Content Source<abbr title=\"This field is required\">*</abbr></span><select name=\"type\" class=\"control control--choice form-field js-type-select\"><option value=\"\">-- Please select --</option><option value=\"auto\"" + (jade.attr("selected", data.type === 'auto', true, false)) + ">Automatic</option><option value=\"manual\"" + (jade.attr("selected", data.type === 'manual', true, false)) + ">Manual</option></select></label><div class=\"form-row-description form-copy\"><p>Whether the list should be populated automatically, or picked manually</p></div></div>");
if ( !sessionHasSpecificAccount)
{
buf.push("<div id=\"field--account\" data-field=\"account\" class=\"form-row\"><label><span class=\"form-label-text\">Account<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-account-select\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"></div></div>");
}
buf.push("</div></div>");
}
buf.push("<div class=\"js-content-source-form\"></div></form>");
if ( !hideHeaderAndControls)
{
buf.push("<div class=\"modal-controls\"><button class=\"btn js-cancel\">Cancel</button><button class=\"btn btn--action js-save\">Save &amp; Add New List</button></div>");
}}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"hideHeaderAndControls" in locals_for_with?locals_for_with.hideHeaderAndControls:typeof hideHeaderAndControls!=="undefined"?hideHeaderAndControls:undefined,"sessionHasSpecificAccount" in locals_for_with?locals_for_with.sessionHasSpecificAccount:typeof sessionHasSpecificAccount!=="undefined"?sessionHasSpecificAccount:undefined));;return buf.join("");
}
const AccountSelect = require('../../account/views/account-select')

class ModalFormView extends FormView {
  constructor (serviceLocator, model, isNew, options) {
    super(...arguments)
    this.model = model
    this.clearUnsavedChanges()
    this.isNew = !!isNew
    this.options = options || {}
    this.options.serviceLocator = serviceLocator
    this.$el.on('change', this.applyChangeToModel.bind(this))
    this.$el.on('change', '.js-type-select', this.handleTypeChange.bind(this))
    this.$el.on('click', '.js-cancel', this.emit.bind(this, 'cancel'))
    this.$el.on('click', '.js-save', this.emit.bind(this, 'save'))
    this.currentContentSource = this.model.get('type') || this.options.forceType
  }

  renderAccountSelect () {
    const accountView = new AccountSelect(this.options.serviceLocator, this.model.get('account'))
    accountView.populate()
    this.$el.find('.js-account-select').append(accountView.render().$el)
    accountView.on('change', account => this.model.set('account', account))
  }

  render () {
    // Render the template
    this.$el.append(template({
      title: this.isNew ? 'New List' : 'Edit List',
      data: this.model.toJSON(),
      hideHeaderAndControls: this.options.hideHeaderAndControls,
      sessionHasSpecificAccount: this.options.serviceLocator.session.account
    }))

    this.renderSourceForm()
    this.renderAccountSelect()

    return this
  }
}

module.exports = ModalFormView
