const View = require('../../section/views/instance-section-select')

class SectionSelect extends View {
  constructor (serviceLocator, selectedSections) {
    super(serviceLocator)
    this.selectedSections = []

    this.currentIndex = selectedSections.findIndex(section => section === '{CURRENT}')
    this.loadFilter = {
      root: false,
      _id: { $nin: selectedSections.filter(section => section !== '{CURRENT}') },
      instance: { $ne: null }
    }
  }

  initializeSelectize () {
    if (this.currentIndex === -1) {
      this.el.selectize.addOption({
        _id: '{CURRENT}',
        name: '{CURRENT}'
      })
      this.el.selectize.clear(true)
    }

    this.el.selectize.on('change', this.updateSelection.bind(this))
  }
}

module.exports = SectionSelect
