const View = require('ventnor')

class ListSelectView extends View {
  constructor (serviceLocator, selectedLists) {
    super(serviceLocator, selectedLists)
    this.$el = $('<select multiple/>')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose one or more lists')
    this.selectedLists = selectedLists || []
  }

  initializeSelectize () {
    this.serviceLocator.listService.find('', { _id: { $in: this.selectedLists } }, [], {}, (err, res) => {
      if (err) return alert('Cannot find existing lists')
      const index = res.results.reduce((index, list) => {
        index[list._id] = list
        return index
      }, {})
      // This ensure they stay in the same order
      this.selectedLists.forEach(id => {
        // The item needs to be added to the list
        // of selectize options in order to be selected
        this.el.selectize.addOption({ value: id, text: index[id] ? index[id].name : id })
        // Select the added option
        this.el.selectize.addItem(id)
      })
      this.el.selectize.on('change', this.updateSelection.bind(this))
    })
  }

  updateSelection () {
    this.selectedLists = this.el.selectize.getValue()
  }

  load (query, cb) {
    this.serviceLocator.listService.cachedFind(query, {}, [], {}, (err, data) => {
      if (err) return cb(err)
      cb(data.results.map(list => {
        return { value: list._id, text: list.name }
      }))
    })
  }

  render () {
    setTimeout(() => {
      this.$el.selectize(
        { delimiter: ',',
          persist: false,
          create: false,
          onInitialize: this.initializeSelectize.bind(this),
          load: this.load.bind(this),
          preload: true
        })
    }, 0)
    return this
  }
}

module.exports = ListSelectView
