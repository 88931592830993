const CrudService = require('../../../../admin/source/js/lib/crud-service')

class FormBuilderService extends CrudService {
  get name () {
    return 'formBuilderService'
  }
  get urlRoot () {
    return '/form-builders'
  }
}

module.exports = FormBuilderService
