import ItemView from '../../widget/views/item/base'
import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    model: Model,
    itemView: ItemView,
    name: 'Competition Winners',
    description: 'Displays a list of competitions with their winners'
  }

  serviceLocator.widgetFactories.get('section').register('competitionWinners', widget)

  done()
}

module.exports = () => ({ competitionWinners: [ 'sectionAdmin', init ] })
