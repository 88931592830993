const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const RoleService = require('./services/role')

const init = (serviceLocator, done) => {
  serviceLocator.roleService = new RoleService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  done()
}

module.exports = () => ({ roleAdmin: init })
