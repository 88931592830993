const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

const mobileWalletSupplementSchema = () =>
  schemata({
    name: 'Mobile Wallet Supplement',
    properties: {
      title: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: [required]
        },
        defaultValue: ''
      },
      text: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: [required]
        },
        defaultValue: ''
      }
    }
  })

module.exports = mobileWalletSupplementSchema
