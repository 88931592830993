const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const responseSchema = schemata({
  name: 'Response',
  properties: {
    response: {
      type: String,
      validators: [ required ]
    }
  }
})

module.exports = () => { return responseSchema }
