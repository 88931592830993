const widths = [ {
  name: 'Full',
  value: 'full'
}, {
  name: 'Half',
  value: 'half'
}, {
  name: 'Quarter',
  value: 'quarter'
} ]

module.exports.all = () => widths
