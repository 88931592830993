const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format) {
buf.push("<div class=\"panel\"><form><div class=\"js-errors-summary\"></div><div data-field=\"codeWord\" class=\"form-row\"><label><span class=\"form-label-text\">Code Word</span><input type=\"text\" name=\"codeWord\"" + (jade.attr("value", data.codeWord, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"form-row-description form-copy\"><p>The code word</p></div></div><div id=\"field--startDate\" data-field=\"startDate\" class=\"form-row\"><label><span class=\"form-label-text\">Start Date</span><span class=\"datepicker\"><input type=\"text\" name=\"startDate\"" + (jade.attr("value", data.startDate ? format(data.startDate, 'adminDate') : '', true, false)) + " readonly=\"readonly\" class=\"control control--text form-field js-timepicker\"/></span></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Sets when this code starts being valid</p></div></div><div id=\"field--endDate\" data-field=\"endDate\" class=\"form-row\"><label><span class=\"form-label-text\">End Date</span><span class=\"datepicker\"><input type=\"text\" name=\"endDate\"" + (jade.attr("value", data.endDate ? format(data.endDate, 'adminDate') : '', true, false)) + " readonly=\"readonly\" class=\"control control--text form-field js-timepicker\"/></span></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Sets when this code stops being valid</p></div></div><div class=\"modal-controls\">");
if ( data._id)
{
buf.push("<button type=\"button\" class=\"btn btn--error js-delete\">Delete</button>");
}
buf.push("<button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--notice js-save\">Save</button></div></form></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const modal = require('modal')
const TimePicker = require('anytime')
const moment = require('moment-timezone')

class CodeWordForm extends View {
  constructor (serviceLocator, model) {
    super(serviceLocator)
    this.$el.on('click', '.js-cancel', this.handleCancel.bind(this))
    this.$el.on('click', '.js-save', this.handleSave.bind(this))
    this.$el.on('click', '.js-delete', this.handleDelete.bind(this))
    this.$el.on('change', this.applyChangeToModel.bind(this))
    this.model = model

    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors

    this.timePickers = []
    this.on('remove', () => {
      this.timePickers.forEach(picker => picker.destroy())
    })
  }

  applyChangeToModel (e) {
    if (this.model.schema.getProperties().hasOwnProperty(e.target.name)) {
      if (e.target.type === 'checkbox') {
        this.model.set(e.target.name, e.target.checked)
      } else {
        this.model.set(e.target.name, e.target.value)
      }
    }
  }

  // Compatibility with the form errors delegate
  $ (selector) {
    return this.$el.find(selector)
  }

  handleCancel (e) {
    e.preventDefault()
    this.emit('remove')
  }

  handleSave (e) {
    e.preventDefault()
    this.clearErrors()

    const onSave = err => {
      if (err) {
        if (err.errors) return this.showErrors(err.errors)
        return this.showErrors({ error: 'Code could not be created' })
      }
      return this.remove()
    }

    if (!this.model.id) {
      this.serviceLocator.codeWordService.create(this.model.toJSON(), onSave.bind(this))
    } else {
      this.serviceLocator.codeWordService.update(this.model.id, this.model.toJSON(), onSave.bind(this))
    }
  }

  render () {
    this.$el.empty().append(template({
      format: this.serviceLocator.format,
      data: this.model.toJSON()
    }))

    this.$el.find('.js-timepicker').each((i, el) => {
      const picker = new TimePicker({
        input: el,
        initialValue: $(el).val(),
        format: this.serviceLocator.config.formats.adminDate,
        timezone: this.serviceLocator.config.timezone
      })
      picker.on('change', value => {
        if (value) value = moment(value).toDate()
        this.model.set(el.name, value)
      })
      picker.render()
      this.timePickers.push(picker)
    })

    return this
  }

  handleDelete (e) {
    e.preventDefault()
    modal(
      { title: 'Delete code',
        content: 'Are you sure you want to delete this code word?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn' },
          { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
        ]
      }
    ).on('confirm', () => {
      this.serviceLocator.codeWordService.delete(this.model.id, err => {
        if (err) {
          if (err.errors) return this.showErrors(err.errors)
          return this.showErrors({ error: 'Code could not be deleted' })
        }
        return this.remove()
      })
    })
  }
}

module.exports = CodeWordForm
