//
// ANCHOR SMOOTH SCROLLING
//

$(document).on('click', 'a[href^="#section-"], a[href^="#field--"]', function (e) {
  e.preventDefault()

  const target = this.hash
  const $target = $(target)
  const offset = 68

  // Don't try to scroll to something that can't be found
  if (!$target.length) return

  $('html, body').stop().animate({
    'scrollTop': $target.offset().top - offset
  }, 500, 'swing', function () {
    window.location.hash = target
  })
})
