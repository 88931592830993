window.jade = require('jade/runtime')
window.Backbone = require('backbone')
window.Backbone.$ = $

const serviceLocator = (window.serviceLocator = {})
const debug = require('./lib/debug')('index')
const createAcl = require('./lib/acl')
const getRoles = require('./lib/roles')
const security = require('./lib/security')
const Emitter = require('events').EventEmitter
const async = require('async')
const extend = require('lodash.assign')
const formatter = require('cf-formatter')
const UberCache = require('uber-cache')
const moment = require('moment')
const setupLoadingBar = require('./lib/global-loading')
const Router = require('./router')

// Get the 'global' libs

require('./lib/vendor/selectize')
require('./lib/vendor/bootstrap-transition')
require('./lib/vendor/bootstrap-dropdown')
require('./lib/vendor/bootstrap-tooltip')
require('./lib/smooth-scrolling')
require('./lib/user-firstname')
require('./lib/fixed-sidebar')
require('./lib/vendor/jquery-ui-1.10.1.custom')
require('./lib/vendor/jquery.ui.touch-punch.js')
require('./lib/vendor/jquery.stickem.js')
require('./lib/ckeditor-link-target-blank.js')
require('./lib/vendor/jquery.nestable')

window.CKEDITOR.stylesSet.add('custom_styles', [
  { name: 'Address', element: 'address' },
  { name: 'Deleted Text', element: 'del' },
  { name: 'Inserted Text', element: 'ins' },
  { name: 'Cited Work', element: 'cite' },
  { name: 'Inline Quotation', element: 'q' },
])

const loadNotFound = require('./components/not-found/routes')
const loadNoAccess = require('./components/no-access/routes')

function loadConfig(cb) {
  window.Backbone.ajax({
    url: '/config.json',
    dataType: 'json',
    type: 'GET',
    success: cb,
  })
}

// Load the ACL
function loadAcl(callback) {
  debug('Loading ACL...')
  async.waterfall(
    [
      function (callback) {
        window.Backbone.ajax({
          url: window.config.apiUrl + '/acl',
          dataType: 'json',
          type: 'GET',
          success: function (data) {
            debug('New ACL Loaded')
            serviceLocator.acl = createAcl(data)
            serviceLocator.hub.emit('acl:load')
            callback(null)
          },
        })
      },
      function (callback) {
        window.Backbone.ajax({
          url: window.config.apiUrl + '/adminDetails',
          dataType: 'json',
          type: 'POST',
          data: JSON.stringify({ id: window.localStorage.getItem('apiId') }),
          contentType: 'application/json',
          success: function (data) {
            debug('Retrieved admin details')
            serviceLocator.session = extend(serviceLocator.session, data)
            callback(null)
          },
        })
      },
    ],
    function () {
      if (typeof callback === 'function') callback()
    }
  )
}
serviceLocator.loadAcl = loadAcl

// Returns true if the ACL allows them access
serviceLocator.allowed = function (resource, action) {
  var isAllowed = serviceLocator.acl.allowed(
    serviceLocator.session.roles,
    resource,
    action
  )
  debug(
    'Checking for permission in ACL. Resource: ',
    resource,
    'Action:',
    action,
    'Allowed:',
    isAllowed,
    serviceLocator.session.roles
  )

  return isAllowed
}

// Display message and go back if now allowed
serviceLocator.allow = function (resource, action) {
  if (!serviceLocator.allowed(resource, action)) {
    alert("You don't have the rights to perform that action.")
    // serviceLocator.router.navigate('no-access', { trigger: true })
    window.history.back()
    return false
  }
  return true
}

serviceLocator.session = { roles: getRoles() }

loadConfig(function (config) {
  if (!config) throw new Error('Unable to load config')

  window.config = config
  window.debug = window.config.logLevel === 'debug'

  serviceLocator.hub = new Emitter()
  serviceLocator.config = window.config
  serviceLocator.logger = require('./logger')
  serviceLocator.format = formatter(window.config)
  serviceLocator.cache = new UberCache()

  moment.locale('clock', window.config.locale)
  moment.locale('clock')

  // Setting locale for moment.js to English (England) and setting
  // a custom calender format (uses 24 clock instead of default AM/PM)
  moment.locale('en-gb', {
    calendar: {
      lastDay: '[Yesterday at] HH:mm',
      sameDay: '[Today at] HH:mm',
      nextDay: '[Tomorrow at] HH:mm',
      lastWeek: '[last] dddd [at] HH:mm',
      nextWeek: 'dddd [at] HH:mm',
      sameElse: 'L',
    },
  })

  setupLoadingBar('.toolbar', 300, debug)

  debug('Creating router')

  serviceLocator.router = new Router(window.config)

  // Core components
  loadNotFound(serviceLocator)
  loadNoAccess(serviceLocator)

  // Will emit `complete` once all components are loaded
  serviceLocator.componentLoader = new Emitter()

  // Application specific components
  require('./components')(serviceLocator)

  window.Backbone.history.on('route', function () {
    $('div.modal-overlay').remove()
  })

  if (security.isAuthed()) {
    loadAcl(function () {
      window.Backbone.history.start({ pushState: true, root: '/' })
    })
  } else {
    // If not authed then take to login
    security.logout()
  }
})
