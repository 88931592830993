const retrievePeriodicDates = require('../../periodic-date-retriever')

const createAggregationQuery = (options, keyField, targetCollection, targetCollectionProperty) => {
  const { account, startDate, endDate, accountName } = options || {}
  const { startDateFilter, endDateFilter, startDateMoM, endDateMoM, startDateYoY, endDateYoY } = retrievePeriodicDates(startDate, endDate)

  const oldDataFilter = new Date('2000-01-01')

  return [
    {
      $match: {
        accountId: account,
        type: 'redeem',
        createdDate: {
          $gte: oldDataFilter
        },
        $or: [
          {
            createdDate: {
              $gte: startDateFilter,
              $lte: endDateFilter
            }
          },
          {
            createdDate: {
              $gte: startDateMoM,
              $lte: endDateMoM
            }
          },
          {
            createdDate: {
              $gte: startDateYoY,
              $lte: endDateYoY
            }
          }
        ]
      }
    },
    { $addFields: { [`${targetCollection}ObjectId`]: { $toObjectId: `$${targetCollection}Id` } } },
    {
      $lookup: {
        from: `${targetCollection}`,
        localField: `${targetCollection}ObjectId`,
        foreignField: '_id',
        as: `${targetCollection}Data`
      }
    },
    {
      $unwind: {
        path: `$${targetCollection}Data`
      }
    },
    { $addFields: { [`${keyField}`]: `$${targetCollection}Data.${targetCollectionProperty}` } },
    {
      $group: {
        _id: {
          $cond: {
            if: {
              $and: [
                { $gte: [ '$createdDate', startDateFilter ] },
                { $lte: [ '$createdDate', endDateFilter ] }
              ]
            },
            then: { userId: '$userId', [`${keyField}`]: `$${keyField}`, title: 'Campaign Period' },
            else: {
              $cond: {
                if: {
                  $and: [
                    { $gte: [ '$createdDate', startDateMoM ] },
                    { $lte: [ '$createdDate', endDateMoM ] }
                  ]
                },
                then: { userId: '$userId', [`${keyField}`]: `$${keyField}`, title: 'MoM' },
                else: {
                  $cond: {
                    if: {
                      $and: [
                        { $gte: [ '$createdDate', startDateYoY ] },
                        { $lte: [ '$createdDate', endDateYoY ] }
                      ]
                    },
                    then: { userId: '$userId', [`${keyField}`]: `$${keyField}`, title: 'YoY' },
                    else: 'Something has gone wrong'
                  }
                }
              }
            }
          }
        },
        count: { '$sum': 1 }
      }
    },
    {
      $group: {
        _id: {
          instance: '$_id.instance',
          title: '$_id.title'
        },
        totalCount: { $sum: '$count' },
        distinctCount: { $sum: 1 }
      }
    },
    { $addFields: {
      [`${keyField}`]: `$_id.${keyField}`,
      title: '$_id.title',
      value: '$distinctCount',
      account: accountName
    } },
    { $sort: { [`${keyField}`]: 1, value: 1 } }
  ]
}

module.exports = createAggregationQuery
