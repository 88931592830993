const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (accounts, allowed, canSwitch, formTitle, name, serviceLocator, undefined) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\">");
if ( (allowed(name.resource, 'delete')))
{
buf.push("<button type=\"button\" class=\"btn js-delete\">Delete Selected</button>");
}
if ( serviceLocator.allowed(name.resource, 'reorder') && canSwitch)
{
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\">Reorder Instances\n <span class=\"caret\"></span></a><ul class=\"dropdown-menu\">");
// iterate accounts
;(function(){
  var $$obj = accounts;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var acc = $$obj[$index];

buf.push("<li" + (jade.attr("data-account", acc._id, true, false)) + " class=\"js-reorder-instances account-item\"><a>" + (jade.escape(null == (jade_interp = acc.name) ? "" : jade_interp)) + "</a></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var acc = $$obj[$index];

buf.push("<li" + (jade.attr("data-account", acc._id, true, false)) + " class=\"js-reorder-instances account-item\"><a>" + (jade.escape(null == (jade_interp = acc.name) ? "" : jade_interp)) + "</a></li>");
    }

  }
}).call(this);

buf.push("</ul></div>");
}
if ( serviceLocator.allowed(name.resource, 'reorder') && !canSwitch)
{
buf.push("<div class=\"btn-group\"><btn type=\"button\" class=\"btn js-reorder-instances\">Reorder Instances</btn></div>");
}
buf.push("</div><div class=\"control-group\">");
if ( serviceLocator.allowed(name.resource, 'create'))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New Instance</button>");
}
buf.push("</div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = formTitle) ? "" : jade_interp)) + "</h1><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div data-layout=\"detail\" class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></header></div></div>");}.call(this,"accounts" in locals_for_with?locals_for_with.accounts:typeof accounts!=="undefined"?accounts:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"canSwitch" in locals_for_with?locals_for_with.canSwitch:typeof canSwitch!=="undefined"?canSwitch:undefined,"formTitle" in locals_for_with?locals_for_with.formTitle:typeof formTitle!=="undefined"?formTitle:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"serviceLocator" in locals_for_with?locals_for_with.serviceLocator:typeof serviceLocator!=="undefined"?serviceLocator:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class ListView extends BaseListView {
  constructor (serviceLocator, collection, paginationModel, accounts) {
    super(...arguments)
    this.accounts = accounts
    this.$el.on('click', '.js-reorder-instances', this.onReorderClicked.bind(this))
  }

  get name () {
    return { singular: 'Instance', plural: 'Instances', resource: 'instance' }
  }

  get FilterView () {
    return FilterView
  }

  get template () {
    return template
  }

  get templateModel () {
    return Object.assign({}, super.templateModel, {
      canSwitch: !this.serviceLocator.session.account,
      accounts: this.accounts
    })
  }

  get ListItemView () {
    return ListItemView
  }

  onReorderClicked (e) {
    let account = this.serviceLocator.session.account

    if (e.currentTarget.dataset.account) {
      account = e.currentTarget.dataset.account
    }
    this.emit('reorder', account)
  }

  addListItem (model) {
    var listItem = new this.ListItemView(this.serviceLocator, model, this.accounts)
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.listenTo(listItem, 'showRevisions', this.emit.bind(this, 'showRevisions', model))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }
}

module.exports = ListView
