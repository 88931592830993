const debug = require('../../../../admin/source/js/lib/debug')('image helper')

module.exports = {
  parseRatioToFloat: aspectString => {
    if (aspectString === '*') {
      return -1
    }

    if (aspectString.indexOf(':') < 0) {
      throw new Error('Aspect ratio must match the following format "1:1"')
    }

    var parts = aspectString.split(':')

    return Math.abs(parts[0]) / Math.abs(parts[1])
  },

  calculateBestFit: (imageWidth, imageHeight, aspectRatio) => {
    let cropWidth = 0
    let cropHeight = 0
    let result

    // If any crop ratio is valid
    if (aspectRatio === -1) {
      cropWidth = imageWidth
      cropHeight = imageHeight
      // tall crop
    } else if (aspectRatio < 1) {
      cropWidth = Math.min(imageHeight * aspectRatio, imageWidth)
      cropHeight = cropWidth / aspectRatio
      // wide or square crop
    } else {
      cropHeight = Math.min(imageWidth / aspectRatio, imageHeight)
      cropWidth = cropHeight * aspectRatio
    }

    result = [ cropWidth, cropHeight ]

    debug('calculating best fit for image', imageWidth, imageHeight, aspectRatio, result)

    return result
  }
}
