const CrudService = require('../../../../admin/source/js/lib/crud-service')

class TagService extends CrudService {
  get name () {
    return 'TagService'
  }

  get urlRoot () {
    return '/tags'
  }
}

module.exports = TagService
