const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const validateDelegate = require('../../../../../../admin/source/js/lib/validate-delegate')()
const schema = schemata({
  name: 'Testimonial',
  properties: Object.assign(
    {},
    baseSchema.getProperties(),
    {
      headline: {
        type: String
      },
      copy: {
        type: String
      },
      name: {
        type: String
      },
      state: {
        type: String
      },
      offerName: {
        type: String
      },
      style: {
        type: String
      },
      segment: {
        type: String,
        defaultValue: () => false
      }
    }
  )
})

module.exports = BaseModel.extend({
  idAttribute: 'id',

  schemata: schema,

  type: 'testimonial',

  defaults () {
    return schema.makeDefault({ type: this.type || 'testimonial' })
  },

  validate: validateDelegate
})
