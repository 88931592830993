const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Inline Image',
    itemView: require('./views/item'),
    description: 'Display images in line'
  }

  serviceLocator.widgetFactories.get('offerBody').register('inlineImage', widget)
  serviceLocator.widgetFactories.get('section').register('inlineImage', widget)
  serviceLocator.widgetFactories.get('offer').register('inlineImage', widget)

  done()
}

module.exports = () => ({ inlineImageWidget: [ 'widget', 'sectionAdmin', init ] })
