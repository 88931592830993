const ListView = require('../views/list')
const FormView = require('../views/form')
const PartnerCollection = require('../collections/partner')
const PartnerModel = require('../models/partner')
const debug = require('../../../../admin/source/js/lib/debug')('router')

module.exports = serviceLocator => {
  const router = serviceLocator.router
  const collection = new PartnerCollection()
  const displayName = { singular: 'Partner', plural: 'Partners' }

  router.route('partners(/)', 'listPartners', () => {
    const listView = new ListView({
      collection,
      displayName,
      serviceLocator
    })
    debug('partner list view route triggered')

    listView.on('new',
      router.navigate.bind(router, 'partners/form', { trigger: true }))

    listView.on('edit', model => {
      router.navigate('partners/' + model.id + '/form', { trigger: true })
    })

    listView.on('layout', model => {
      router.navigate('partners/' + model.id + '/layout', { trigger: true })
    })

    router.render(listView, displayName.plural)
    collection.load()
  })

  const bindSaveAndCancel = view => {
    view.on('save cancel', () => {
      router.navigate('partners', { trigger: true })
    })
    return view
  }

  router.route('partners/form(/)', 'newPartner', () => {
    debug('partner create view route triggered')
    const model = new PartnerModel({}, { collection: collection })
    const view = new FormView({
      model,
      title: displayName.singular,
      serviceLocator
    })

    router.render(bindSaveAndCancel(view), 'New ' + displayName.singular)
  })

  router.route('partners/:id/form(/)', 'editPartner', id => {
    debug('partner edit view route triggered')

    collection.retrieve(id, (err, model) => {
      if (err) {
        router.trigger('notFound', err.message)
        return
      }
      debug('Loading form view', model)
      const view = new FormView({
        model,
        title: displayName.singular,
        serviceLocator
      })

      router.render(bindSaveAndCancel(view), 'Edit ' + displayName.singular)
    })
  })
}
