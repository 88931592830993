const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()

module.exports = () => {
  const widgetProperties = {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    emphasisedWordLocation: {
      type: String
    },
    backgroundColour: {
      type: String
    }
  }

  const widgetSchemata = schemata({
    name: 'Most Popular',
    properties: Object.assign({}, baseSchema.getProperties(), widgetProperties)
  })

  return widgetSchemata
}
