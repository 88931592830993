const BaseModel = require('cf-base-model')
const schemata = require('../../../service/redirect/scheduled-redirect-schema')(
  {},
  {}
)

module.exports = BaseModel.extend({
  idAttribute: '_id',
  schemata: schemata,
  defaults: function () {
    return schemata.makeDefault()
  }
})
