const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"notification notification--notice\">Code words are not case sensitive, and only one can be active at a time</div><table class=\"table--striped\"><thead><tr><th>Code Word</th><th>Current</th><th>Start Date</th><th>End Date</th><th> </th></tr></thead><tbody class=\"js-code-word-table\"><tr><td colspan=\"5\">No Code Words</td></tr></tbody><tfoot><tr><td colspan=\"4\"></td><td><button type=\"button\" class=\"btn btn--small btn--notice js-add-code\">Add</button></td></tr></tfoot></table><div id=\"field--fileUpload\" data-field=\"fileUpload\" class=\"form-row\"><label><span class=\"form-label-text\">Upload Code Words</span><div class=\"form-field\"><input type=\"file\" name=\"fileUpload\" class=\"control control--file js-file-upload\"/></div></label><div class=\"form-row-description form-copy\"><p>If you have a CSV file containing your code words, upload them here.\n The CSV file does<strong> not </strong> require a header.</p></div><div style=\"display: none;\" class=\"js-loading form-error\"><strong>Upload in progress. This may take a while if there are a lot of codes! (DO NOT REFRESH THIS PAGE!)</strong></div><div class=\"js-multiple-summary\"></div><div class=\"js-errors-multiple-summary\"></div></div>");;return buf.join("");
}
const errorTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (errors, title) {
buf.push("<div class=\"form-error form-copy\"><strong>");
if ( title)
{
buf.push(" " + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "");
}
else
{
buf.push("There was a problem importing your code(s)");
}
buf.push("</strong><p>Please check below and try again.</p><ul class=\"form-error\"><li>" + (jade.escape((jade_interp = errors.code) == null ? '' : jade_interp)) + "</li><li>" + (jade.escape((jade_interp = errors.detail) == null ? '' : jade_interp)) + "</li></ul></div>");}.call(this,"errors" in locals_for_with?locals_for_with.errors:typeof errors!=="undefined"?errors:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const codeItemTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (current, data, format) {
buf.push("<tr><td>" + (jade.escape(null == (jade_interp = data.codeWord) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = current ? '✓' : '') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = data.startDate ? format(data.startDate, 'adminDate') : '') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = data.endDate ? format(data.endDate, 'adminDate') : '') ? "" : jade_interp)) + "</td><td><div class=\"btn-group\"><a href=\"#\"" + (jade.attr("data-id", data._id, true, false)) + " class=\"btn btn--small js-edit-code\">Manage</a></div></td></tr>");}.call(this,"current" in locals_for_with?locals_for_with.current:typeof current!=="undefined"?current:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const modal = require('modal')
const FormView = require('./code-word-form')
const CodeModel = require('../models/code-word')

class UniqueCodesView extends View {
  constructor (serviceLocator, model, type) {
    super(serviceLocator)
    this.model = model
    this.type = type
    this.hideManualCodeControls = false
    this.codeWordService = this.serviceLocator.codeWordService
    this.getCollectionStats(this.showCollectionStats.bind(this))
    this.$el.on('click', '.js-add-code', this.handleAddCode.bind(this))
    this.$el.on('change', '.js-file-upload', this.handleFileUpload.bind(this))
    this.$el.on('click', '.js-edit-code', this.handleEditCode.bind(this))
  }

  render () {
    this.$el.append(template({
      data: this.model.toJSON(),
      hideManualCodeControls: this.hideManualCodeControls
    }))

    return this
  }

  handleAddCode (e) {
    e.preventDefault()
    const model = new CodeModel(this.serviceLocator, {
      parentId: this.model.id,
      type: this.type
    })
    const view = new FormView(this.serviceLocator, model)
    this.attachView(view)
    this.showModal(view, 'Add Code Word')
  }

  handleEditCode (e) {
    e.preventDefault()
    const codeToEdit = e.currentTarget.dataset.id

    this.clearCodeErrors()

    if (codeToEdit) {
      this.codeWordService.read(codeToEdit, (err, code) => {
        if (err || !code) return this.showEditErrors({ 'Error': 'Could not find the specified code' })
        const editView = new FormView(this.serviceLocator, new CodeModel(this.serviceLocator, code))
        this.showModal(editView, 'Edit Code Word')
      })
    }
  }

  showModal (view, title) {
    const m = modal({
      title,
      content: view.render().$el,
      buttons: [],
      className: 'wide',
      clickOutsideToClose: false
    })

    m.on('cancel', () => view.remove())

    view.on('remove', () => {
      this.clearCodeErrors()
      this.getCollectionStats(this.showCollectionStats.bind(this))
      m.close()
    })
  }

  handleFileUpload (event) {
    this.clearCodeErrors()
    if (!event.target.files || event.target.files.length === 0) return false

    this.$el.find('.js-loading').show()

    const file = event.target.files.item(0)
    $(event.target).replaceWith($(event.target).val('').clone(true))

    // file.type is "" in IE11 so crudely checking file extension
    if (file.type !== 'text/csv' && !file.name.match(/.csv$/)) {
      this.$el.find('.js-loading').hide()
      return modal({
        title: 'Error',
        content: 'Code upload must be a CSV file',
        buttons: [ { text: 'Dismiss', className: 'btn' } ]
      })
    }

    const reader = new FileReader()
    reader.onload = e => {
      this.codeWordService.upload(this.model.id, e.target.result, this.type, (err, body) => {
        this.$el.find('.js-loading').hide()

        if (err) return this.showCodeErrors({ errors: { code: 'Could not upload codes' } })
        if (body.codeErrors && body.codeErrors.length > 0) {
          return this.showCodeErrors(this.processCodeErrors(body.codeErrors))
        }
        return this.showCollectionStats(err, body)
      })
    }

    reader.readAsText(file)
  }

  processCodeErrors (errors) {
    const processedErrors = {}
    let duplicates = 0
    let dates = []

    errors.forEach(error => {
      // Duplicate MongoError
      if (error.code === 11000) {
        duplicates++
      }

      if (!error.errors) return

      if ('startDate' in error.errors || 'endDate' in error.errors) {
        dates.push(error.codeWord)
      }
    })

    if (duplicates) {
      processedErrors.code = duplicates + ' of the uploaded codes already exist' + (duplicates === 1 ? 's' : '')
    }

    if (dates.length) {
      processedErrors.code = 'Error inserting ' + dates.length + ' code' + (dates.length > 1 ? 's' : '') + ' with dates that overlap'
      processedErrors.detail = dates.join(', ')
    }

    return processedErrors
  }

  showCodeErrors (errors) {
    this.clearCodeErrors()
    const $errors = errorTemplate({ errors })
    this.$el.find('.js-errors-multiple-summary').append($errors)
  }

  showEditErrors (errors) {
    this.clearCodeErrors()
    const $errors = errorTemplate({ title: 'Code edit error', errors })
    this.$el.find('.js-edit-error').append($errors)
  }

  showCollectionStats (err, stats) {
    if (err) return this.serviceLocator.logger.error('Cannot get codes stats')
    this.current = stats.current

    const sort = [ 'endDate', 'asc' ]
    const page = { pageSize: 10000 }
    this.codeWordService.find('', { parentId: this.model.get('_id') }, sort, page, this.displayCodes.bind(this))
  }

  displayCodes (err, res) {
    if (err) return this.serviceLocator.logger.error('Cannot get codes', err)
    if (res.results === 0) return

    this.$el.find('.js-code-word-table').empty()
    res.results.forEach(data => {
      this.$el.find('.js-code-word-table').append(codeItemTemplate({
        format: this.serviceLocator.format,
        data,
        current: this.current === data.codeWord
      }))
    })
  }

  getCollectionStats (cb) {
    this.codeWordService.current(this.model.id, cb)
  }

  clearCodeErrors () {
    this.$el.find('.js-generate-summary').empty()
    this.$el.find('.js-multiple-summary').empty()
    this.$el.find('.js-errors-multiple-summary').empty()
    this.$el.find('.js-edit-error').empty()
  }
}

module.exports = UniqueCodesView
