const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (section) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-btn-back\">Back to Sections</button></div></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>Manage layouts:");
if ( section.__instance)
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", section.__instance.name, true, false)) + " class=\"logo-icon js-tooltip-trigger\"><img" + (jade.attr("src", section.instanceLogoUrl, true, false)) + (jade.attr("altText", section.__instance.name, true, false)) + (jade.attr("title", section.__instance.name, true, false)) + "/></span>  " + (jade.escape((jade_interp = section.name) == null ? '' : jade_interp)) + "");
}
else
{
buf.push("<span>  " + (jade.escape((jade_interp = section.name) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</h1></header><div class=\"grid\"><div class=\"grid__item desktop-one-half\"><h2>Built-in Layouts\n <span data-toggle=\"tooltip\" data-original-title=\"These layouts are built in to the system. They can be modified but they cannot be removed.\" class=\"label js-tooltip-trigger\"><i class=\"icon icon--info\"></i></span></h2><ul class=\"js-built-in-layouts\"></ul></div><div class=\"grid__item desktop-one-half\"><h2>Additional Layouts\n <span data-toggle=\"tooltip\" data-original-title=\"These are custom layouts that can be added and removed. Articles within this section can use these custom layouts by selecting them on the article form.\" class=\"label js-tooltip-trigger\"><i class=\"icon icon--info\"></i></span></h2><ul class=\"js-additional-layouts\"></ul><button type=\"button\" class=\"btn btn--action js-btn-new-layout\">Add a layout</button></div></div></div></div>");}.call(this,"section" in locals_for_with?locals_for_with.section:typeof section!=="undefined"?section:undefined));;return buf.join("");
}
const ListItemView = require('./list-item')
const LayoutDescriptorModel = require('../models/layout-descriptor')
const LayoutEditorView = require('./layout-editor')
const createId = require('hat')
const createImageUrlBuilder = require('cf-image-url-builder')

class LayoutManagerView extends View {
  constructor (serviceLocator, section) {
    super(serviceLocator, section)
    this.section = section
    this.$el.on('click', '.js-btn-new-layout', this.createNewLayout.bind(this))
    this.$el.on('click', '.js-btn-back', this.emit.bind(this, 'back'))

    const drUrl = this.serviceLocator.config.darkroom.url
    const drSalt = this.serviceLocator.config.darkroom.salt

    if (this.section.get('__instance') && this.section.get('__instance').images) {
      const imageUrlBuilder = createImageUrlBuilder(drUrl, drSalt, this.section.get('__instance').images.widgets)
      const image = imageUrlBuilder && imageUrlBuilder.getImage('Logo - Light Bg')
      const url = image && image.crop() && image.crop().url()

      this.section.set('instanceLogoUrl', url)
    }

    this._descriptors = {}
    // Export Backbone view interface for compatibility with rest of admin
    this.trigger = this.emit
  }

  render () {
    this.$el.append(template({ section: this.section.toJSON() }))
    this.renderLayouts()
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true, placement: 'right' })
    return this
  }

  renderLayouts () {
    const layouts = this.section.get('layouts') || {}
    const layoutDescriptors = Object.keys(layouts).map(key => ({
      name: layouts[key].name,
      description: layouts[key].description,
      type: layouts[key].type,
      layout: layouts[key].layout,
      parent: this.section.toJSON(),
      isBuiltIn: layouts[key].isBuiltIn,
      key: layouts[key].key,
      instance: this.section.get('__instance')
    }))

    layoutDescriptors.filter(layoutDescriptor => layoutDescriptor.isBuiltIn).forEach(layoutDescriptor =>
      this.addItem(new LayoutDescriptorModel(this.serviceLocator, layoutDescriptor, false), this.$el.find('.js-built-in-layouts')))
    layoutDescriptors.filter(layoutDescriptor => !layoutDescriptor.isBuiltIn).forEach(layoutDescriptor =>
      this.addItem(new LayoutDescriptorModel(this.serviceLocator, layoutDescriptor, true), this.$el.find('.js-additional-layouts')))
  }

  addItem (layoutDescriptorModel, $container) {
    const view = this.attachView(new ListItemView(this.serviceLocator, layoutDescriptorModel))
    const key = layoutDescriptorModel.get('key')

    view
      // Listen to edit events so the edit view can be shown
      .on('edit', this.editLayout.bind(this))
      // Render and get the element
      .render().$el
      // Append to the provided container
      .appendTo($container)

    this._descriptors[key] = layoutDescriptorModel

    return view
  }

  editLayout (layoutDescriptor) {
    const layoutSlug = layoutDescriptor.get('isBuiltIn') ? layoutDescriptor.get('type') : layoutDescriptor.get('key')
    const view = this.attachView(new LayoutEditorView(this.serviceLocator, layoutDescriptor.toJSON()))
    this.serviceLocator.router.navigate('layouts/' + this.section.id + '/manager/' + layoutSlug)
    this.$el.hide()
    this.$el.after(view.render().$el.appendTo(this.$el))
    view.emit('afterAppend')
    view.on('remove', function () {
      this.serviceLocator.router.navigate('layouts/' + this.section.id + '/manager')
      this.$el.show()
    }.bind(this))
    return view
  }

  createNewLayout () {
    // Create a new layout descriptor
    const layoutDescriptor = {
      key: createId(),
      isBuiltIn: false,
      parent: this.section.toJSON(),
      type: 'offer',
      name: 'New Layout',
      description: ''
    }
    const model = new LayoutDescriptorModel(this.serviceLocator, layoutDescriptor, true)

    // Fire up the edit view for the new layout
    const editView = this.editLayout(model)
    // And add it to the list
    const listView = this.addItem(model, this.$el.find('.js-additional-layouts'))

    // If the edit view is closed without saving, the list item should be removed
    editView.on('remove', removeListItem)
    // remove the listener when the layout is saved
    editView.on('save', function () {
      editView.removeListener('remove', removeListItem)
    })

    function removeListItem () {
      listView.remove()
    }
  }
}

module.exports = LayoutManagerView
