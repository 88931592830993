module.exports = initWidget

function initWidget () {
  return { offerLayoutSocialSharingWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init (serviceLocator, done) {
  serviceLocator.widgetFactories.get('offer').register('offerLayoutSocialSharing', {
    model: require('./models/model'),
    name: 'Offer Social Sharing',
    itemView: require('./views/item'),
    editView: require('./views/form'),
    description: 'Placeholder for offer social sharing'
  })

  done()
}
