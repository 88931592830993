import ItemView from '../../widget/views/item/edit-base'
import EditView from '../../widget/views/form/base'
import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    model: Model,
    itemView: ItemView,
    editView: EditView,
    name: 'Not Found',
    description: 'Main content of the not found route'
  }

  serviceLocator.widgetFactories.get('section').register('notFound', widget)

  done()
}

module.exports = () => ({ notFound: [ 'sectionAdmin', init ] })
