const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, twoFaEnabled, undefined) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('administrator', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
if ( allowed('administrator', 'update'))
{
buf.push("<button type=\"button\" class=\"btn btn--small js-password-reset\">Reset Password</button>");
}
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\">");
if ( twoFaEnabled)
{
buf.push("<li><a class=\"js-2fa-reset\">Reset 2FA</a></li>");
}
buf.push("<li><a class=\"js-show-revisions\">Show Revisions</a></li></ul></div></div>");
if ( allowed('administrator', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/administrators/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape((jade_interp = data.firstName) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = data.lastName) == null ? '' : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape((jade_interp = data.firstName) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = data.lastName) == null ? '' : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Email:</dt><dd>" + (jade.escape(null == (jade_interp = data.emailAddress) ? "" : jade_interp)) + "</dd><dt>Instances:</dt>");
if ( data.__instances.length == 0)
{
buf.push("<dd>All</dd>");
}
else
{
buf.push("<dd>");
// iterate data.__instances
;(function(){
  var $$obj = data.__instances;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var instance = $$obj[$index];

buf.push("<span class=\"logo-icon\"> <img" + (jade.attr("src", instance.logoUrl, true, false)) + (jade.attr("alt", instance.name, true, false)) + (jade.attr("title", instance.name, true, false)) + "/></span>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var instance = $$obj[$index];

buf.push("<span class=\"logo-icon\"> <img" + (jade.attr("src", instance.logoUrl, true, false)) + (jade.attr("alt", instance.name, true, false)) + (jade.attr("title", instance.name, true, false)) + "/></span>");
    }

  }
}).call(this);

buf.push("</dd>");
}
buf.push("</dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"twoFaEnabled" in locals_for_with?locals_for_with.twoFaEnabled:typeof twoFaEnabled!=="undefined"?twoFaEnabled:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const modal = require('modal')
const createImageUrlBuilder = require('cf-image-url-builder')

class ListItemView extends BaseListItemView {
  constructor (...args) {
    super(...args)
    this.$el.on('click', '.js-show-revisions', this.emit.bind(this, 'showRevisions'))
    this.$el.on('click', '.js-password-reset', this.confirmReset.bind(this))
    this.$el.on('click', '.js-2fa-reset', this.confirm2faReset.bind(this))
  }

  get template () {
    return template
  }

  confirm2faReset () {
    modal(
      { title: 'Reset 2 Factor Verification',
        content: 'This will reset the 2 factor verification for this user. ' +
          'Next time they log in the will be prompted to set up 2fa. Are you sure?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn' },
          { text: 'Reset', event: 'confirm', className: 'btn btn--success' }
        ]
      })
      .on('confirm', this.emit.bind(this, 'reset2fa'))
  }

  confirmReset () {
    modal(
      { title: 'Reset Password',
        content: 'This will send a password reset email to this administrator. Are you sure?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn' },
          { text: 'Send', event: 'confirm', className: 'btn btn--success' }
        ]
      })
      .on('confirm', this.sendResetEmail.bind(this))
  }

  sendResetEmail () {
    const email = this.model.get('emailAddress')

    $.ajax({
      type: 'POST',
      url: window.config.apiUrl + '/administrator/password-reset-request',
      data: JSON.stringify({ emailAddress: email, returnUrl: window.config.adminUrl + '/password-reset' }),
      error: error => {
        const parseError = JSON.parse(error.responseText)
        if (parseError.errors) {
          this.showErrors(parseError.errors)
        } else {
          this.showErrors(parseError)
        }
      },
      dataType: 'json',
      contentType: 'application/json'
    })
  }

  render () {
    const data = this.model.toJSON()
    const drUrl = this.serviceLocator.config.darkroom.url
    const drSalt = this.serviceLocator.config.darkroom.salt

    data.__instances.forEach(function (instance, key, instances) {
      if (instance && instance.images) {
        const imageUrlBuilder = createImageUrlBuilder(drUrl, drSalt, instance.images.widgets)
        const image = imageUrlBuilder && imageUrlBuilder.getImage('Logo - Light Bg')
        const url = image && image.crop() && image.crop().url()

        instances[key].logoUrl = url
      }
    })

    this.$el.empty().append(this.template(
      { data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
        index: this.index,
        totalItems: this.totalItems,
        twoFaEnabled: this.serviceLocator.config.twoFa.enabled
      }))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
