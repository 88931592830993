const CrudService = require('../../../../admin/source/js/lib/crud-service')

class RedemptionReportingService extends CrudService {
  constructor (serviceLocator) {
    super(serviceLocator)
    this.serviceLocator = serviceLocator
  }

  get name () {
    return 'RedemptionReportingService'
  }

  get urlRoot () {
    return '/redemption-datapoint'
  }

  report (filterOptions, cb) {
    this.authedRequest('GET', '/redemption-reporting/report', filterOptions, (err, res, body) => {
      if (err) return cb(err)
      cb(null, body)
    })
  }

  findUniqueYears (filterOptions, cb) {
    this.authedRequest('GET', '/redemption-reporting/unique-years', filterOptions, (err, res, body) => {
      if (err) return cb(err)
      cb(null, body)
    })
  }
}

module.exports = RedemptionReportingService
