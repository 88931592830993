const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

module.exports = () =>
  schemata({
    name: 'Redemption Report',
    properties: {
      report: {
        type: String,
        validators: { all: [required] },
      },
      account: {
        type: String,
        validators: { all: [required] },
      },
    },
  })
