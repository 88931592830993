const Model = require('merstone')
const createSchema = require('../../../service/offer/schema')
const WidgetAreaModel = require('../../widget/models/widget-area')
const ImageAreaModel = require('../../asset/models/image-area')

class OfferModel extends Model {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)
    this.schema = createSchema()
    attributes = this.schema.cast(attributes || {})

    this.set(attributes)
    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })

    this.relatedWidgets = new WidgetAreaModel(this.get('relatedWidgets'), { abstractWidgetFactory: serviceLocator.widgetFactories.get('relatedOffer') })

    this.body = new WidgetAreaModel(this.get('body'),
      { abstractWidgetFactory: serviceLocator.widgetFactories.get('offerBody')
      })

    this.images = new ImageAreaModel(this.get('images'))

    this.images.on('add remove change', () => {
      this.set('images', this.images.toJSON())
    })

    this.body.on('add remove change', () => {
      this.set('body', this.body.toJSON())
    })

    this.relatedWidgets.on('add remove change', () => {
      this.set('relatedWidgets', this.relatedWidgets.toJSON())
    })
  }

  makeDefault (cb) {
    const serviceLocator = this.serviceLocator

    const done = (account, cb) => {
      this.set(this.schema.makeDefault())
      this.set('keyFacts', [])
      this.set('mobileWalletSupplement', [])
      cb(null, this, account)
    }

    if (!serviceLocator.session.account) {
      return done(null, cb)
    }

    serviceLocator.accountService.read(serviceLocator.session.account, (err, account) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)
      done(account, cb)
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }

  isInDate () {
    const now = new Date()
    // No schedule settings
    if (!this.get('liveDate') && !this.get('expiryDate')) return true
    // Live date in the past, no expiry date
    if (now - this.get('liveDate') > 0 && !this.get('expiryDate')) return true
    // Live date in the past, expiry date in the future
    if (now - this.get('liveDate') >= 0 && this.get('expiryDate') - now > 0) return true
    // No live date, expiry date in the future
    if (!this.get('liveDate') && this.get('expiryDate') - now > 0) return true
    // Otherwise not in date
    return false
  }

  getVisibilityState () {
    switch (this.get('state')) {
      case 'Draft':
        return 'draft'
      case 'Archived':
        return 'archived'
      case 'Published':
        if (this.isInDate()) {
          return 'published'
        } else {
          return 'publishedHidden'
        }
    }
  }

  getScheduleState () {
    const now = new Date()
    const threeDays = 3 * 24 * 60 * 60 * 1000

    // No schedule info
    if (!this.get('liveDate') && !this.get('expiryDate')) return 'notScheduled'

    // expiryDate in past
    if (this.get('expiryDate') && now - this.get('expiryDate') > 0) return 'expired'

    //
    // From here on in, expiryDate is either not set or in future:
    //

    // liveDate in future, expiryDate not set or in future
    if (this.get('liveDate') - now > 0 && (!this.get('expiryDate') || this.get('liveDate') - now > 0)) {
      if (this.get('state') !== 'Published') {
        // Draft, Archived states
        if (this.get('liveDate') - now < threeDays) return 'scheduledLiveSoonNotPublished'
        // Published
        return 'scheduledFuture'
      }

      return 'scheduledFuture'
    }

    // liveDate in past, expiryDate not set or in future
    if (now - this.get('liveDate') > 0) {
      if (this.get('state') !== 'Published') {
        // Not published
        return 'scheduledLiveNowNotPublished'
      } else if (!this.get('expiryDate')) {
        // Published, no expiryDate
        return 'scheduledInDateWillNotExpire'
      } else {
        // Published, expiryDate in future
        // Different state for expiring soon (less than 3 days)
        return this.get('expiryDate') - now > threeDays
          ? 'scheduledInDateWillExpire'
          : 'scheduledInDateExpiringSoon'
      }
    }
  }
}

module.exports = OfferModel
