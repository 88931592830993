const View = require('ventnor')
const availableStatuses = require('../../offer/lib/status-config.json')
const statuses = availableStatuses.map(s => { return { name: s } })

class StatusSelect extends View {
  constructor (serviceLocator, initialStatuses) {
    super(...arguments)
    this.$el = $('<select multiple/>')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose some statuses')
    this.statuses = initialStatuses || []
    this.on('remove', () => this.el.selectize.destroy())
  }

  onAdd (value) {
    this.statuses.push(value)
    this.emit('change')
  }

  onRemove (value) {
    const index = this.statuses.indexOf(value)
    this.statuses.splice(index, 1)
    this.emit('change')
  }

  addSelectizeHandlers () {
    this.el.selectize.on('item_add', this.onAdd.bind(this))
    this.el.selectize.on('item_remove', this.onRemove.bind(this))
  }

  initializeSelectize () {
    this.statuses.forEach(option => {
      this.el.selectize.addOption({ name: option })
      // Select the added option
      this.el.selectize.addItem(option)
    })
    this.addSelectizeHandlers()
  }

  render () {
    setTimeout(() => {
      this.$el.selectize(
        { delimiter: ',',
          persist: false,
          create: false,
          valueField: 'name',
          labelField: 'name',
          options: statuses,
          onInitialize: this.initializeSelectize.bind(this)
        })
    }, 100)
    return this
  }
}

module.exports = StatusSelect
