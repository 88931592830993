const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

module.exports = () =>
  schemata({
    name: 'Role',
    properties: {
      _id: {
        type: String
      },
      name: {
        type: String,
        validators: { all: [ required ] }
      },
      grants: {
        type: Object,
        validators: { all: [ required ] }
      },
      created: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })
