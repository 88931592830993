const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const config = window.config
const createImageUrlBuilder = require('cf-image-url-builder')

module.exports = BaseModel.extend({
  schemata,
  type: 'inlineImage',
  defaults () {
    return schemata.makeDefault({ type: 'inlineImage' })
  },
  validate: validateDelegate,
  getPreviewImageUrl () {
    const firstImage = this.get('images')[0]
    if (!firstImage) return null
    const images = firstImage.images.widgets
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Inline')
    if (!image) return null
    return image.crop('Free').constrain(300).url()
  }
})
