const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const WidgetAreaModel = require('../../../widget/models/widget-area')
const createLayoutFactory = require('../lib/layout-factory')
const getImageFactory = require('../../../asset/lib/image-factory')()

module.exports = window.Backbone.Model.extend({
  schemata: schemata,
  validate: validateDelegate,
  defaults: () => schemata.makeDefault({ type: 'articleLayoutGallery' }),
  initialize: function () {
    this.layout = new WidgetAreaModel(this.get('layout'),
      { abstractWidgetFactory: createLayoutFactory()
      })
    this.layout.on('add remove change', () => this.set('layout', this.layout.toJSON()))

    this.images = new WidgetAreaModel(this.get('images'),
      { abstractWidgetFactory: getImageFactory,
        serviceLocator: window.serviceLocator
      })

    this.images.on('add remove change', () => this.set('images', this.images.toJSON()))
  }
})
