const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('article').register('articleLayoutHeader', {
    model: require('./models/model'),
    name: 'Article Header',
    itemView: require('./views/item'),
    editView: require('./views/form'),
    description: 'Placeholder for the article header'
  })

  done()
}

module.exports = () => ({ articleLayoutArticleHeaderWidget: [ 'widget', 'sectionAdmin', init ] })
