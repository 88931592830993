const uniqueMemmbersByMastheadYearly = require('./lib/reports/unique-members-by-masthead-yearly')
const uniqueMemmbersByMasthead = require('./lib/reports/unique-members-by-masthead')
const topFiveOffersByCategory = require('./lib/reports/top-five-offers-by-category')
const redemptionCountByMasthead = require('./lib/reports/redemption-count-by-masthead')
const redemptionCountByCategory = require('./lib/reports/redemption-count-by-category')
const yearOnYearRedemptionReport = require('./lib/reports/year-on-year-redemption-report/')
const yearlyMembersEngaged = require('./lib/reports/yearly-members-engaged-report/')

const reports = [
  { name: 'Redemption Count By Masthead', type: 'standard', ...redemptionCountByMasthead() },
  { name: 'Redemption Count By Category', type: 'standard', showCategoriesField: true, ...redemptionCountByCategory() },
  { name: 'Unique Members Engaged By Masthead (Monthly)', type: 'standard', ...uniqueMemmbersByMasthead() },
  { name: 'Unique Members Engaged By Masthead (Yearly)', type: 'standard', ...uniqueMemmbersByMastheadYearly() },
  { name: 'Top Five Offers By Category', type: 'standard', ...topFiveOffersByCategory(), view: 'Top 5 Offers By Category' },
  { name: 'Year On Year Redemption', type: 'worker', hasLineGraph: true, ...yearOnYearRedemptionReport() },
  { name: 'Yearly Members Engaged', type: 'worker', view: 'multiple', ...yearlyMembersEngaged(), displayTitle: 'Yearly Unique Members Engaged' }
]

const getReports = (name) => {
  if (!name) return reports
  return reports.find(report => report.name === name)
}

module.exports = getReports
