const createUniqueId = require('hat')
const createBaseWidgetSchema = require('../../widget/model/base-widget')
const baseWidget = createBaseWidgetSchema().makeDefault()

const extendBaseWidget = widget => Object.assign({}, baseWidget, widget)

module.exports = () => {
  const layout = {
    '0':
    { id: '0',
      title: '3 Column',
      attributes: [],
      cols:
      [ { id: '3:0',
        order: 1,
        width: 3,
        attributes: [],
        widgetArea:
          { widgets:
            [ extendBaseWidget({ type: 'offerLayoutHeader',
              visible: true,
              essential: true,
              showOfferProperties: [ 'category', 'attributes' ],
              id: createUniqueId()
            }),
            extendBaseWidget({ type: 'offerLayoutImages',
              visible: true,
              essential: true,
              id: createUniqueId()
            })
            ]
          }
      }
      ]
    },
    '1':
    { id: '1',
      title: '2 Column Left',
      attributes: [],
      cols:
      [ {
        id: '1:0',
        order: 1,
        width: 2,
        widgetArea:
          { widgets: [
            extendBaseWidget({ type: 'offerLayoutBody',
              visible: true,
              essential: true,
              id: createUniqueId()
            }),
            extendBaseWidget({ type: 'offerLayoutRedemptionArea',
              visible: true,
              essential: true,
              id: createUniqueId()
            }),
            extendBaseWidget({ type: 'offerLayoutPartners',
              visible: true,
              essential: true,
              id: createUniqueId()
            }),
            extendBaseWidget({ type: 'offerLayoutTerms',
              visible: true,
              essential: true,
              id: createUniqueId()
            }),
            extendBaseWidget({ type: 'offerLayoutSocialSharing',
              visible: true,
              essential: true,
              id: createUniqueId()
            })
          ] }
      },
      { id: '1:1',
        order: 2,
        width: 1,
        widgetArea:
          { widgets:
            [ extendBaseWidget({ type: 'offerLayoutKeyFacts',
              visible: true,
              essential: true,
              id: createUniqueId()
            })
            ]
          }
      } ]
    }
  }

  return {
    name: 'Offer Layout',
    description: 'This is the layout that Offers in this section will have',
    isBuiltIn: true,
    type: 'offer',
    key: 'offer',
    layout: layout
  }
}
