const join = require('path').join
const View = require('ventnor')
const modal = require('modal')
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (itemNames) {
buf.push("<div class=\"grid\"><div class=\"grid__item\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h3>" + (jade.escape(null == (jade_interp = itemNames.plural) ? "" : jade_interp)) + "</h3></div><div class=\"panel-content\"><div class=\"form-row\"><label><input type=\"Submit\"" + (jade.attr("value", 'New ' + (itemNames.singular) + '', true, false)) + " class=\"btn js-new-item\"/></label></div><div class=\"js-item-list drop-area\"></div></div></div></div></div>");}.call(this,"itemNames" in locals_for_with?locals_for_with.itemNames:typeof itemNames!=="undefined"?itemNames:undefined));;return buf.join("");
}

class ItemRepeater extends View {
  constructor (serviceLocator, config, currentItems, options) {
    super(serviceLocator)
    options = options || {}
    this.FormView = config.formView
    this.ItemView = config.itemView
    this.ItemModel = config.itemModel
    this.itemNames = config.itemNames
    this.currentModels = currentItems.map((item, i) => {
      const model = new this.ItemModel(item, serviceLocator)
      model.id = i
      return model
    })
    this.nextId = this.currentModels.length
    this.embellishItems = options.embellishItems || ((items, cb) => cb(null, items))
    this.$el.on('click', '.js-new-item', this.handleNewItem.bind(this))
  }

  render () {
    this.$el.append(template({
      itemNames: this.itemNames
    }))
    this.renderItems()
    this.renderDropArea()

    return this
  }

  renderDropArea () {
    this.$el.find('.js-item-list').sortable(
      { handle: '.js-sort-handle',
        cursor: 'move',
        addClasses: false
      })
      .off('sortupdate')
      .on('sortupdate', (_event, _ui) => {
        this.currentModels = this.$el.find('.js-item')
          .map((_index, el) => $(el).data('id'))
          .toArray()
          .map(foundId => this.currentModels.find(({ id }) => id === foundId))
      })
  }

  renderItems () {
    this.$el.find('.js-item-list').empty()
    this.embellishItems(this.currentModels, (err, embellishedModels) => {
      if (err) console.error('could not embellish items')
      embellishedModels.forEach(model => {
        const view = new this.ItemView(this.serviceLocator, model)
        view.on('editItem', this.handleEditItem.bind(this))
        view.on('removeItem', this.handleRemoveItem.bind(this))
        this.$el.find('.js-item-list').append(view.render().$el)
        this.attachView(view)
      })
    })
  }

  handleNewItem (e) {
    e.preventDefault()
    const model = new this.ItemModel(null, this.serviceLocator)
    const view = new this.FormView(this.serviceLocator, model, true)

    const itemModal = modal({
      title: `Add ${this.itemNames.singular}`,
      className: 'wide',
      content: view.render().$el,
      buttons: []
    })

    view.on('save', () => {
      model.id = this.nextId
      this.nextId++
      this.currentModels.push(model)
      this.renderItems()
      itemModal.close()
    })

    view.on('cancel', () => itemModal.close())
  }

  handleEditItem (model) {
    const view = new this.FormView(this.serviceLocator, model)
    const itemModal = modal({
      title: `Edit ${this.itemNames.singular}`,
      className: 'wide',
      content: view.render().$el,
      buttons: []
    })

    view.on('save', () => {
      this.renderItems()
      itemModal.close()
    })

    view.on('cancel', () => itemModal.close())
  }

  handleRemoveItem (model) {
    this.currentModels = this.currentModels.filter(item => item.id !== model.id)
    this.renderItems()
  }

  getItems () {
    return this.currentModels.map(item => item.toJSON())
  }
}

module.exports = ItemRepeater
