const BaseModel = require('cf-base-model')
const schemata = require('../../../../service/widget/model/base-widget')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata,
  defaults () {
    return schemata.makeDefault({ type: this.type || 'competitionWinners' })
  },
  validate: validateDelegate
})
