const retrievePeriodicDates = require('../periodic-date-retriever')

const createAggregationQuery = (
  options,
  keyField,
  targetCollection,
  targetCollectionProperty
) => {
  const { account, startDate, endDate, accountName } = options || {}
  const {
    startDateFilter,
    endDateFilter,
    startDateMoM,
    endDateMoM,
    startDateYoY,
    endDateYoY
  } = retrievePeriodicDates(startDate, endDate)

  const oldDataFilter = new Date('2000-01-01')

  const { categoryFilter } = options || {}

  let filter

  const hasCategoryFilter = categoryFilter && categoryFilter.length

  if (hasCategoryFilter) {
    filter = Array.isArray(categoryFilter) ? categoryFilter : [ categoryFilter ]
  }

  return [
    {
      $match: {
        accountId: account,
        type: 'redeem',
        createdDate: {
          $gte: oldDataFilter
        },
        $or: [
          {
            createdDate: {
              $gte: startDateFilter,
              $lte: endDateFilter
            }
          },
          {
            createdDate: {
              $gte: startDateMoM,
              $lte: endDateMoM
            }
          },
          {
            createdDate: {
              $gte: startDateYoY,
              $lte: endDateYoY
            }
          }
        ]
      }
    },
    {
      $addFields: {
        [`${targetCollection}ObjectId`]: {
          $toObjectId: `$${targetCollection}Id`
        }
      }
    },
    {
      $lookup: {
        from: `${targetCollection}`,
        localField: `${targetCollection}ObjectId`,
        foreignField: '_id',
        as: `${targetCollection}Data`
      }
    },
    {
      $unwind: {
        path: `$${targetCollection}Data`
      }
    },
    {
      $addFields: {
        [`${keyField}`]: `$${targetCollection}Data.${targetCollectionProperty}`
      }
    },
    ...hasCategoryFilter ? [ {
      $match: {
        category: { $in: filter }
      }
    } ] : [],
    {
      $group: {
        _id: {
          $cond: {
            if: {
              $and: [
                { $gte: [ '$createdDate', startDateFilter ] },
                { $lte: [ '$createdDate', endDateFilter ] }
              ]
            },
            then: { [`${keyField}`]: `$${keyField}`, title: 'Campaign Period' },
            else: {
              $cond: {
                if: {
                  $and: [
                    { $gte: [ '$createdDate', startDateMoM ] },
                    { $lte: [ '$createdDate', endDateMoM ] }
                  ]
                },
                then: { [`${keyField}`]: `$${keyField}`, title: 'MoM' },
                else: {
                  $cond: {
                    if: {
                      $and: [
                        { $gte: [ '$createdDate', startDateYoY ] },
                        { $lte: [ '$createdDate', endDateYoY ] }
                      ]
                    },
                    then: { [`${keyField}`]: `$${keyField}`, title: 'YoY' },
                    else: 'Something has gone wrong'
                  }
                }
              }
            }
          }
        },
        count: { $sum: 1 }
      }
    },
    {
      $addFields: {
        [`${keyField}`]: `$_id.${keyField}`,
        title: '$_id.title',
        value: '$count',
        account: accountName
      }
    },
    { $sort: { [`${keyField}`]: 1, value: 1 } }
  ]
}

module.exports = createAggregationQuery
