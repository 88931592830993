const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

module.exports = () =>
  schemata({
    name: 'Offer Redemption Type Link',
    properties: {
      redemptionLink: {
        type: String,
        validators: { all: [ required ] }
      },
      redemptionNewWindow: {
        type: Boolean
      }
    }
  })
