const View = require('ventnor')

class InstanceSelect extends View {
  constructor (serviceLocator, selectedInstances, options = {}) {
    super(...arguments)
    this.selectOptions = options.selectOptions
    this.$el = $('<select multiple/>')
    this.$el.addClass('control control--choice')
    this.el = this.$el[0]
    this.$el.attr('placeholder', options.placeholder || 'Choose one or more instances')
    this.selectedInstances = selectedInstances || []
  }

  initializeSelectize () {
    if (!this.selectedInstances.length) {
      return this.el.selectize.on('change', this.updateSelection.bind(this))
    }
    var filter = { _id: { $in: this.selectedInstances } }
    this.serviceLocator.instanceService.find('', filter, [], {}, (err, res) => {
      if (err) return this.serviceLocator.logger.error('Cannot find existing instances', err)
      res.results.forEach(instance => {
        // The item needs to be added to the list
        // of selectize options in order to be selected
        this.el.selectize.addOption(
          { value: instance._id,
            text: instance.name
          })
        // Select the added option
        this.el.selectize.addItem(instance._id)
      })
      this.el.selectize.on('change', this.updateSelection.bind(this))
    })
  }

  off () {
  }

  updateSelection () {
    this.selectedInstances = this.el.selectize.getValue()
    this.emit('change', this.selectedInstances)
  }

  load (query, cb) {
    this.serviceLocator.instanceService.find(query, {}, [], {}, (err, data) => {
      if (err) return this.serviceLocator.logger.error('Cannot find instances', err)
      cb(data.results.map(instance => {
        return { value: instance._id, text: instance.name }
      }))
    })
  }

  render () {
    const options = Object.assign(
      {},
      { delimiter: ',',
        persist: false,
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true
      },
      this.selectOptions
    )

    setTimeout(() => this.$el.selectize(options))
    return this
  }
}

module.exports = InstanceSelect
