const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (className, data) {
buf.push("<div" + (jade.attr("classname", className, true, false)) + " class=\"panel\"><form><div id=\"showOfferProperties\" data-field=\"showOfferProperties\" class=\"form-row form-row-boolean\"><span class=\"form-label-text\">Show Offer Properties</span><div class=\"form-field\"><ul><li><label><input type=\"checkbox\" name=\"showOfferProperties\"" + (jade.attr("checked", data.showOfferProperties.indexOf('category') !== -1, true, false)) + " value=\"category\" class=\"control control--boolean\"/><span>Category</span></label></li><li><label><input type=\"checkbox\" name=\"showOfferProperties\"" + (jade.attr("checked", data.showOfferProperties.indexOf('sell') !== -1, true, false)) + " value=\"sell\" class=\"control control--boolean\"/><span>Sell</span></label></li></ul></div><div class=\"form-row-description form-copy\"><p>Select the offer properties that should be shown on this widget.</p></div></div><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div></form></div>");}.call(this,"className" in locals_for_with?locals_for_with.className:typeof className!=="undefined"?className:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const BaseWidgetView = require('../../../../widget/views/form/base')
const debug = require('../../../../../../admin/source/js/lib/debug')('offer layout header widget view')

module.exports = BaseWidgetView.extend({ template: template, debug: debug })
