const moment = require('moment')

const monthDigitArrayInFYOrder = [ 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6 ]

const digitFormat = month => ({ month, value: 0 })
const wordFormat = month => {
  const wordMonth = moment().month(month - 1).format('MMMM')
  return { month, value: wordMonth }
}

const templates = {
  lineBreak: () => Array(13).fill({ value: '' }),
  monthNamesWithBuffer: () => {
    const array = monthDigitArrayInFYOrder.map(wordFormat)
    array.unshift({ value: '' })
    return array
  },
  monthNumbersNoBuffer: () => monthDigitArrayInFYOrder.map(digitFormat)
}

const createRowTemplate = (type) => templates[type]()

module.exports = createRowTemplate
