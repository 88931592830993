module.exports={
  "crops": [
    { "name": "Square", "aspectRatio": "1:1" },
    { "name": "4:3", "aspectRatio": "4:3" },
    { "name": "13:7", "aspectRatio": "13:7" }
  ],
  "contexts": [
    { "name": "Thumbnail", "allowMultipleSelection": false }
  ]
}
