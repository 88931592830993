const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (icon, message) {
buf.push("<span><i" + (jade.cls(['icon','icon--' + icon], [null,true])) + "></i></span><p>" + (jade.escape(null == (jade_interp = message) ? "" : jade_interp)) + "</p>");}.call(this,"icon" in locals_for_with?locals_for_with.icon:typeof icon!=="undefined"?icon:undefined,"message" in locals_for_with?locals_for_with.message:typeof message!=="undefined"?message:undefined));;return buf.join("");
}

class ForegroundNotification extends View {
  constructor (serviceLocator, message, icon) {
    super(...arguments)
    this.message = message
    this.icon = icon
    this.$el.addClass('js-foreground-notification foreground-notification is-hidden')
  }

  render () {
    $('body').find('.js-foreground-notification').addClass('is-hidden')
    this.$el.append(template({ message: this.message, icon: this.icon }))
    this.on('afterAppend', this.$el.removeClass.bind(this.$el, 'is-hidden'))
    return this
  }

  remove () {
    this.$el.addClass('is-hidden')
    setTimeout(() => {
      View.prototype.remove.call(this)
    }, 300)
  }
}

module.exports = (message, icon) => {
  var n = new ForegroundNotification({}, message, icon)
  n.render().$el.appendTo('body')
  setTimeout(() => {
    n.emit('afterAppend')
  }, 10)
  setTimeout(() => {
    n.remove()
  }, 2000)
}
