const LayoutFormView = require('../views/manager')
const SectionModel = require('merstone')

module.exports = serviceLocator => {
  serviceLocator.router.route('layouts/:id/manager', 'manageLayouts', id => {
    if (!serviceLocator.allow('section', 'manageLayouts')) return false

    serviceLocator.sectionService.read(id, (err, sectionAttributes) => {
      if (err) throw err
      const sectionModel = new SectionModel(serviceLocator, sectionAttributes)
      const view = new LayoutFormView(serviceLocator, sectionModel)
      serviceLocator.router.render(view.render(), sectionModel.get('name') + ' Layout')
      view.on('back', () => {
        serviceLocator.router.navigate('/sections', { trigger: true })
      })
    })
  })

  serviceLocator.router.route('layouts/:id/manager/:key', 'manageLayouts', (id, key) => {
    if (!serviceLocator.allow('section', 'manageLayouts')) return false

    serviceLocator.sectionService.read(id, (err, sectionAttributes) => {
      if (err) throw err
      const sectionModel = new SectionModel(serviceLocator, sectionAttributes)
      const view = new LayoutFormView(serviceLocator, sectionModel)
      serviceLocator.router.render(view.render(), sectionModel.get('name') + ' Layout')
      view.on('back', () => {
        serviceLocator.router.navigate('/sections', { trigger: true })
      })
      view.editLayout(view._descriptors[key])
    })
  })
}
