const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const validateLength = require('validity-length')

const schema = schemata({
  name: 'Related Offers',
  properties: Object.assign(
    {},
    baseSchema.getProperties(),
    {
      offers: {
        type: Array,
        validators:
          { all: [ validateLength(1) ] },
        defaultValue: () => []
      }
    }
  )
})

module.exports = BaseModel.extend({
  idAttribute: 'id',

  schemata: schema,

  type: 'relatedOffers',

  defaults () {
    return schema.makeDefault({ type: this.type || 'relatedOffers' })
  },

  validate: validateDelegate
})
