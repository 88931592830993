const Model = require('merstone')
const createSchema = require('../../../service/account/schema')
const ImageAreaModel = require('../../asset/models/image-area')

class AccountModel extends Model {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})
    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })

    this.campaignTakeoverImages = new ImageAreaModel(this.get('campaignTakeoverImages'))
    this.campaignTakeoverImages.on('add remove change', () => {
      this.set('campaignTakeoverImages', this.campaignTakeoverImages.toJSON())
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }
}

module.exports = AccountModel
