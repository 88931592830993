const periodicCreateAggregationQuery = require('../../periodic-reports/aggregation-query-creator')
const periodicTransformToChart = require('../../periodic-reports/chart-transformer')
const periodicReportTransformer = require('../../periodic-reports/report-transformer')
const periodicCreateHeaders = require('../../periodic-reports/header-creator')
const ranking = require('../../periodic-reports/ranking')

const fields = require('./fields')

const redemptionCountByCategory = () => ({
  createAggregationQuery: (options) => periodicCreateAggregationQuery(options, 'category', 'offer', 'category'),
  transformToChart: (data) => periodicTransformToChart(data, 'category'),
  transformToReport: (data, startDate, endDate) => periodicReportTransformer(data, startDate, endDate, 'category', ranking, periodicCreateHeaders),
  fields
})

module.exports = redemptionCountByCategory
