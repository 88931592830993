const Model = require('merstone')
const createSchema = require('../../../service/data-capture/schema')
const WidgetAreaModel = require('../../widget/models/widget-area')
const ImageAreaModel = require('../../asset/models/image-area')

class DataCaptureModel extends Model {
  constructor (serviceLocator, attributes) {
    super(...arguments)
    this.schema = createSchema()
    this.attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })

    this.body = new WidgetAreaModel(this.get('body'),
      { abstractWidgetFactory: serviceLocator.widgetFactories.get('offerBody')
      })

    this.images = new ImageAreaModel(this.get('images'))

    this.images.on('add remove change', () => {
      this.set('images', this.images.toJSON())
    })

    this.body.on('add remove change', () => {
      this.set('body', this.body.toJSON())
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    super.reset(attrs)
  }
}

module.exports = DataCaptureModel
