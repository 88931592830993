const View = require('ventnor')
const modal = require('modal')
const formErrorsDelegate = require('../../../../../admin/source/js/lib/form-errors-delegate')()

class RedemptionTypeView extends View {
  constructor (serviceLocator, offerView, redemptionExperienceIndex) {
    super(serviceLocator)
    this.offerView = offerView
    this.offer = offerView.model
    this.currentType = {}
    this.previousType = ''
    this.redemptionExperienceIndex = redemptionExperienceIndex

    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
  }

  // Compatibility with the form errors delegate
  $ (selector) {
    return this.$el.find(selector)
  }

  getData () {
    if (!this.currentType.model) return {}
    return this.currentType.model.toJSON()
  }

  validate (cb) {
    if (!this.currentType.model || typeof this.currentType.model.validate === 'undefined') return cb(null, true)
    this.currentType.model.validate((err, validationErrors) => {
      if (err) return cb(err)
      const keys = Object.keys(validationErrors || {})
      if (keys.length) {
        keys.forEach((k) => {
          validationErrors[k + this.redemptionExperienceIndex] = validationErrors[k]
          delete validationErrors[k]
        })
        return cb(null, validationErrors)
      }
      return cb()
    })
  }

  updateType (type, attributes) {
    this.detachCurrentRedemptionType()

    let redemptionType

    // See if a redemption type can be found
    try { redemptionType = this.serviceLocator.redemptionTypeFactory.get(type) } catch (e) {}

    // Some offer types won't have a model/form because they don't have any type-specific properties
    if (!redemptionType || !redemptionType.Model || !redemptionType.FormView) {
      this.currentType = {}
      this.previousType = type
      return
    }

    // Create the type specific model
    this.currentType = {}
    this.currentType.model = new redemptionType.Model(this.serviceLocator, attributes || {})
    this.currentType.view = new redemptionType.FormView(this.serviceLocator, this.currentType.model, this.redemptionExperienceIndex, this.offer)

    this.currentType.model.on('change', () => {
      const redemptionExperiences = this.offer.get('redemptionExperiences')
      redemptionExperiences[this.redemptionExperienceIndex].redemptionTypeProperties = this.currentType.model.toJSON()
      this.offer.set('redemptionExperiences', redemptionExperiences)
    })

    this.$el.append(this.currentType.view.render().$el)
    this.attachView(this.currentType.view)

    if (redemptionType.hasInstructions) {
      this.offerView.$('#field--redemptionInstructions[data-index=' + this.redemptionExperienceIndex + ']').show()
    } else {
      this.offerView.$('#field--redemptionInstructions[data-index=' + this.redemptionExperienceIndex + ']').hide()
    }

    if (redemptionType.supportsUniqueCodes) {
      this.offerView.$('#field--redemptionUsesCode[data-index=' + this.redemptionExperienceIndex + ']').show()
    } else {
      this.offerView.$('#field--redemptionUsesCode[data-index=' + this.redemptionExperienceIndex + ']').hide()
    }

    if (redemptionType.hasSecondaryCtaText) {
      this.offerView.$('#field--redemptionSecondaryCta[data-index=' + this.redemptionExperienceIndex + ']').show()
    } else {
      this.offerView.$('#field--redemptionSecondaryCta[data-index=' + this.redemptionExperienceIndex + ']').hide()
    }

    this.currentType.view.emit('afterAppend')

    // Keep track of the previous type so that the user can cancel a change
    this.previousType = type
  }

  detachCurrentRedemptionType () {
    if (this.currentType.view) this.currentType.view.remove()
    if (this.currentType.model) this.currentType.model.removeAllListeners()
  }

  confirmChange (cb) {
    const currentRedemptionType = this.offer.get('redemptionExperiences')[this.redemptionExperienceIndex].redemptionType
    if (currentRedemptionType === this.previousType) return
    if (!this.previousType) {
      this.updateType(currentRedemptionType)
      return cb()
    }
    modal(
      { title: 'Are you sure?',
        content:
        'Changing the redemption type of an offer will discard any of the existing redemption-related properties.',
        buttons:
        [ { text: 'Change redemption type', event: 'confirm', className: 'btn btn--warning' },
          { text: 'Don\'t change', event: 'cancel', className: 'btn' }
        ]
      })
      .on('confirm', () => {
        this.updateType(currentRedemptionType)
        cb()
      })
      .on('cancel', () => {
        const redemptionExperiences = this.offer.get('redemptionExperiences')
        redemptionExperiences[this.redemptionExperienceIndex].redemptionType = this.previousType
        this.offer.set('redemptionExperiences', redemptionExperiences)
        cb()
      })
  }

  render () {
    return this
  }
}

module.exports = RedemptionTypeView
