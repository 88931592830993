const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const defaultTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowAdditionalQuestions, data, sessionHasSpecificAccount, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["question-form"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<form><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--title\" data-field=\"title\" class=\"form-row\"><label><span class=\"form-label-text\">Title<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"title\"" + (jade.attr("value", data.title, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--type\" data-field=\"type\" class=\"form-row\"><label><span class=\"form-label-text\">Form Type<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field\"><select type=\"text\" name=\"type\" class=\"control control--choice\"><option selected=\"selected\" disabled=\"disabled\">-- Select a form type --</option><option" + (jade.attr("selected", data.type === 'standard', true, false)) + " value=\"standard\">Standard Form</option><option" + (jade.attr("selected", data.type === 'competition', true, false)) + " value=\"competition\">Competition</option><option" + (jade.attr("selected", data.type === 'purchase', true, false)) + " value=\"purchase\">Purchase</option><option" + (jade.attr("selected", data.type === 'preRedemption', true, false)) + " value=\"preRedemption\">Pre-Redemption</option></select></div></label><div class=\"js-error\"></div></div>");
if ( !sessionHasSpecificAccount)
{
buf.push("<div id=\"field--account\" data-field=\"account\" class=\"form-row\"><label><span class=\"form-label-text\">Account<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-account-select\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"></div></div>");
}
buf.push("</div></div></form>");
};
jade_mixins["question-form"]();
buf.push("<div id=\"section-questions\" class=\"counter-question-reset js-questions\"></div>");
if ( allowAdditionalQuestions)
{
buf.push("<a class=\"btn js-question-add\">Add Question</a>");
}
buf.push("</form></div></div>");}.call(this,"allowAdditionalQuestions" in locals_for_with?locals_for_with.allowAdditionalQuestions:typeof allowAdditionalQuestions!=="undefined"?allowAdditionalQuestions:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"sessionHasSpecificAccount" in locals_for_with?locals_for_with.sessionHasSpecificAccount:typeof sessionHasSpecificAccount!=="undefined"?sessionHasSpecificAccount:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const modalTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowAdditionalQuestions, data, sessionHasSpecificAccount) {
buf.push("<form><div class=\"js-errors-summary\"></div>");
jade_mixins["question-form"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<form><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--title\" data-field=\"title\" class=\"form-row\"><label><span class=\"form-label-text\">Title<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"title\"" + (jade.attr("value", data.title, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--type\" data-field=\"type\" class=\"form-row\"><label><span class=\"form-label-text\">Form Type<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field\"><select type=\"text\" name=\"type\" class=\"control control--choice\"><option selected=\"selected\" disabled=\"disabled\">-- Select a form type --</option><option" + (jade.attr("selected", data.type === 'standard', true, false)) + " value=\"standard\">Standard Form</option><option" + (jade.attr("selected", data.type === 'competition', true, false)) + " value=\"competition\">Competition</option><option" + (jade.attr("selected", data.type === 'purchase', true, false)) + " value=\"purchase\">Purchase</option><option" + (jade.attr("selected", data.type === 'preRedemption', true, false)) + " value=\"preRedemption\">Pre-Redemption</option></select></div></label><div class=\"js-error\"></div></div>");
if ( !sessionHasSpecificAccount)
{
buf.push("<div id=\"field--account\" data-field=\"account\" class=\"form-row\"><label><span class=\"form-label-text\">Account<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-account-select\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"></div></div>");
}
buf.push("</div></div></form>");
};
jade_mixins["question-form"]();
buf.push("<div id=\"section-questions\" class=\"counter-question-reset js-questions\"></div>");
if ( allowAdditionalQuestions)
{
buf.push("<a class=\"btn js-question-add\">Add Question</a>");
}
buf.push("</form><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div>");}.call(this,"allowAdditionalQuestions" in locals_for_with?locals_for_with.allowAdditionalQuestions:typeof allowAdditionalQuestions!=="undefined"?allowAdditionalQuestions:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"sessionHasSpecificAccount" in locals_for_with?locals_for_with.sessionHasSpecificAccount:typeof sessionHasSpecificAccount!=="undefined"?sessionHasSpecificAccount:undefined));;return buf.join("");
}
const QuestionFormView = require('../../question/views/question')
const QuestionModel = require('../../question/models/question')
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const dateTimePickers = require('../../../../admin/source/js/lib/init-date-time-pickers')
const AccountSelect = require('../../account/views/account-select')

class FormBuilderFormView extends BaseFormView {
  constructor (serviceLocator, model, isEditing, isModal) {
    super(...arguments)

    this.template = isModal ? modalTemplate : defaultTemplate

    this.title = 'Form Builder'
    this.QuestionFormView = QuestionFormView
    this.allowAdditionalQuestions = true
    this.allowQuestionDescription = true
    this.allowReorder = true
    this.isModal = isModal

    this.questionViews = []

    this.$el.on('click', '.js-question-add', this.handleAddQuestion.bind(this))
    this.on('remove', () => {
      this.removeQuestion.bind(this)
    })

    this.model.questions.on('add', this.addQuestion.bind(this))
    this.model.questions.on('remove', this.removeQuestion.bind(this))
    this.dateTimePickers = []
    this.initDateTimePickers = dateTimePickers(window.config.locale.longDateFormat.LLLL, this.model)
    this.on('remove', () => {
      this.dateTimePickers.forEach(picker => picker.destroy())
    })

    this.on('afterAppend', () => { this.$el.find('input[type=text]:first').focus() })
  }

  handleAddQuestion () {
    const question = new QuestionModel(this.serviceLocator)
    question.index = this.model.questions.models.length
    this.model.questions.add(question)
  }

  showErrors (errors) {
    formErrorsDelegate.showErrors.call(this, errors)

    if (errors.questions && typeof errors.questions === 'object') {
      Object.keys(errors.questions).forEach(key => {
        this.questionViews[key].view.showErrors(errors.questions[key])
      })
    }
  }

  addQuestion (model) {
    const view = new this.QuestionFormView(
      this.serviceLocator,
      model,
      this.account,
      this.isNew,
      this.allowQuestionDescription)

    this.questionViews.push({ cid: model.cid, view: view })
    view.on('remove', id => {
      this.model.questions.remove(id)
    })

    this.$el.find('.js-questions').append(view.render().$el)
  }

  initReorderableQuestions () {
    this.$el.find('.js-questions').sortable(
      { handle: '.js-sort-handle',
        cursor: 'move',
        addClasses: false,
        connectWith: '.js-item-drag'
      })
      .off('sortupdate')
      .on('sortupdate', (event, ui) => {
        var order = []
        this.$el.find('.js-question').map((index, el) => {
          order.push($(el).data('item'))
        })

        this.model.questions.emit('reorder', order)
      })
      .off('sortstart')
      .on('sortstart', (event, ui) => {
        $(ui.item).data('item').emit('dragged')
      })
      .off('sortstop')
      .on('sortstop', (event, ui) => {
        $(ui.item).data('item').emit('dropped')
      })

    this.$el.find('.js-questions').addClass('drop-area')
  }

  removeQuestion (id) {
    const i = this.questionViews.findIndex(view => view.cid === id)
    this.questionViews.splice(i, 1)
  }

  render () {
    // Render the template
    this.$el.append(this.template({
      title: this.isNew ? 'New ' + this.title : 'Edit ' + this.title,
      data: this.model.toJSON(),
      account: this.account,
      format: this.serviceLocator.format,
      allowAdditionalQuestions: this.allowAdditionalQuestions,
      sessionHasSpecificAccount: this.serviceLocator.session.account
    }))

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.dateTimePickers = this.$el.find('.js-date-time-picker').toArray().map(this.initDateTimePickers)
    this.model.questions.models.forEach((question, i) => {
      question.index = i
      this.addQuestion(question)
    })

    if (this.isModal) {
      this.$el.on('click', '.js-save', this.submit.bind(this))
      this.$el.on('click', '.js-cancel', this.close.bind(this))

      this.$el.find('#field--account').hide()
      this.$el.find('#field--type').hide()
    }

    if (this.allowReorder) this.initReorderableQuestions()

    this.renderAccountSelect()
    return this
  }

  renderAccountSelect () {
    const accountView = new AccountSelect(this.serviceLocator, this.model.get('account'))
    accountView.populate()
    this.$el.find('.js-account-select').append(accountView.render().$el)
    accountView.on('change', account => this.model.set('account', account))
  }
}

module.exports = FormBuilderFormView
