const BaseModel = require('cf-base-model')
const ImageAreaModel = require('../../../../asset/models/image-area')
const config = window.config
const createImageUrlBuilder = require('cf-image-url-builder')
const validateDelegate = require('../../../../../../admin/source/js/lib/validate-delegate')()
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const validateIfSet = require('validity-validate-if-set')
const required = require('validity-required')
const validateIfPropertySet = require('validity-validate-if-property-set')
const isUrl = require('validity-url')

const schema = schemata({
  name: 'Email',
  properties: Object.assign(
    {},
    baseSchema.getProperties(),
    {
      title: {
        type: String
      },
      subtitle: {
        type: String
      },
      callToActionText: {
        type: String,
        validators: [ validateIfPropertySet('callToActionLink', required) ]
      },
      callToActionLink: {
        type: String,
        validators: [ validateIfPropertySet('callToActionText', required), validateIfSet(isUrl) ]
      },
      images: {
        type: Object,
        defaultValue: () => ({}),
        validators: { all: [] }
      },
      segment: {
        type: String,
        defaultValue: () => false
      }
    }
  )
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults () {
    return schema.makeDefault({ type: this.type || 'emailPromo' })
  },
  initialize () {
    this.images = new ImageAreaModel(this.get('images'))
  },
  getPreviewImageUrl () {
    const images = this.images.widgets.map(widget => widget.toJSON())
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Promo')
    if (!image) return null
    return image.crop('Free').constrain(300).url()
  },
  validate: validateDelegate
})
