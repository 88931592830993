const Model = require('./models/download')
const FormView = require('./views/form')

const init = (serviceLocator, done) => {
  serviceLocator.redemptionTypeFactory.register('download', {
    name: 'Download',
    FormView,
    Model,
    allowedAsSecondary: true,
    hasInstructions: true,
    hasSecondaryCtaText: true,
    supportsPreRedemptionQuestions: true
  })
  done()
}

module.exports = () => ({ downloadRedemptionType: [ 'offerAdmin', init ] })
