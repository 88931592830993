const required = require('validity-required')
const schemata = require('@clocklimited/schemata')
const tagSchema = require('../tag/schema')
const itemSchema = require('./item-schema')

module.exports = () =>
  schemata({
    name: 'List',
    properties: {
      _id: {
        type: String,
        tag: [ 'default', 'auto', 'manual' ]
      },

      name: {
        type: String,
        validators: { all: [ required ] },
        tag: [ 'default', 'auto', 'manual' ]
      },

      type: {
        type: String,
        options: [ 'auto', 'manual' ],
        validators: { all: [ required ] },
        tag: [ 'default', 'auto', 'manual' ]
      },

      limit: {
        type: Number,
        defaultValue: 50,
        validators: { all: [ required ] },
        tag: [ 'default', 'auto', 'manual' ]
      },

      dateCreated: {
        type: Date,
        defaultValue: () => new Date(),
        tag: [ 'default', 'auto', 'manual' ]
      },

      account: {
        type: String,
        validators: { all: [ required ] },
        tag: [ 'default', 'auto', 'manual' ]
      },

      // Auto-related properties

      tags: {
        type: schemata.Array(tagSchema()),
        tag: [ 'auto' ]
      },

      status: {
        type: Array,
        tag: [ 'auto' ]
      },

      sections: {
        type: Array,
        tag: [ 'auto' ]
      },

      order: {
        type: String,
        options: [ 'recent', 'most comments', 'popular' ],
        validators: { all: [ required ] },
        tag: [ 'auto' ]
      },

      extendable: {
        type: Boolean,
        tag: [ 'auto' ],
        defaultValue: false
      },

      // Manual related properties

      items: {
        type: schemata.Array(itemSchema()),
        validators: { all: [ required ] },
        tag: [ 'manual' ]
      }
    }
  })
