const View = require('ventnor')
const Pagination = require('./pagination')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, name) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\">");
if ( (allowed(name.resource, 'delete')))
{
buf.push("<div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-delete\">Delete Selected</button></div>");
}
if ( (allowed(name.resource, 'create')))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New " + (jade.escape((jade_interp = name.singular) == null ? '' : jade_interp)) + "</button>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const Collection = require('chale')
const modal = require('modal')
const ListItemView = require('./list-item')

class ListView extends View {
  constructor (serviceLocator, collection, paginationModel) {
    super(...arguments)
    Object.assign(this, Pagination.prototype)
    Pagination.call(this, paginationModel)

    this.collection = collection
    this.selectedCollection = new Collection(serviceLocator)

    this.listenTo(collection, 'add', this.addListItem.bind(this))
    this.listenTo(collection, 'remove', this.removeListItem.bind(this))
    this.listenTo(collection, 'reset', this.resetListItems.bind(this))
    this.listenTo(collection, 'model:select', this.select.bind(this))
    this.listenTo(collection, 'model:deSelect', this.deSelect.bind(this))
    this.listenTo(this.selectedCollection, 'add', this.onSelectionChange.bind(this))
    this.listenTo(this.selectedCollection, 'remove', this.onSelectionChange.bind(this))
    this.listenTo(this.selectedCollection, 'reset', this.onSelectionChange.bind(this))

    this.$el.on('click', '.js-toolbar .js-new', this.emit.bind(this, 'createNew'))
    this.$el.on('click', '.js-select-all', this.selectAll.bind(this))
    this.$el.on('click', '.js-select-none', this.selectNone.bind(this))
    this.$el.on('click', '.js-toolbar .js-delete', this.handleDelete.bind(this))
    this.$el.on('click', '.js-more', this.emit.bind(this, 'loadMore'))

    // Compatibility with backbone admin
    this.trigger = super.emit
  }

  get ListItemView () {
    return ListItemView
  }

  get template () {
    return template
  }

  get name () {
    return { singular: 'Item', plural: 'Items', resource: 'item' }
  }

  get templateModel () {
    return {
      name: this.name,
      allowed: this.serviceLocator.allowed
    }
  }

  displayFilterParams (params) {
    if (this.filters) this.filters.updateDisplay(params)
  }

  addListItem (model, index) {
    var listItem = new this.ListItemView(this.serviceLocator, model, index, this.collection.length, this.name)
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
    return listItem
  }

  resetListItems (models, previousModels) {
    previousModels.forEach(this.removeListItem.bind(this))
    models.forEach(this.addListItem.bind(this))
  }

  removeListItem (model) {
    var view = this.getViewByModel(model)
    if (!view) return
    view.remove()
  }

  createFilterView () {
    if (!this.filters && this.FilterView) {
      this.filters = new this.FilterView(this.serviceLocator, this)
      this.filters.on('filter', this.emit.bind(this, 'filter'))
      this.attachView(this.filters)
    }
  }

  onSelectionChange () {
    var count = this.selectedCollection.models.length
    this.$el.find('.js-selection-count').text(count + (count === 1 ? ' item' : ' items'))
  }

  select (model) {
    this.selectedCollection.add(model)
  }

  deSelect (model) {
    this.selectedCollection.remove(model.id)
  }

  selectAll () {
    this.collection.models.forEach(model => {
      model.emit('select', model)
    })
  }

  selectNone () {
    var models = this.selectedCollection.models.slice(0)
    models.forEach(model => {
      model.emit('deSelect', model)
    })
  }

  handleDelete () {
    var length = this.selectedCollection.models.length
    if (!length) return
    modal(
      { title: 'Delete',
        content: 'Are you sure you want to delete ' + length + ' ' + (length === 1 ? 'item' : ' items') + '?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
          { text: 'Delete', event: 'confirm', className: 'btn btn--action' }
        ]
      })
      .on('confirm', () => {
        this.emit('delete', this.selectedCollection.models.map(model => model.id))
      })
  }

  render () {
    this.createFilterView()
    // Render the template
    this.$el.append(this.template(this.templateModel))
    this.collection.models.forEach(this.addListItem.bind(this))
    if (this.filters) this.$el.find('.js-filters').append(this.filters.render().$el)
    this.updatePagination()
    return this
  }
}

module.exports = ListView
