const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateLength = require('validity-length')

module.exports = () => {
  const widgetProperties = {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    lists: {
      type: Array,
      validators:
        { all: [ validateLength(1) ] }
    },
    limit: {
      type: Number
    },
    dedupe: {
      type: Boolean,
      defaultValue: true
    }
  }
  const widgetSchemata = schemata({
    name: 'Offer Stack',
    properties: Object.assign({}, baseSchema.getProperties(), widgetProperties)
  })

  return widgetSchemata
}
