const dropUiDelegate = require('./drop-ui-delegate')
const modal = require('modal')

module.exports = view => {
  dropUiDelegate(view)

  const displayUploadWarning = (msg) => {
    modal(
      { title: 'Upload warning',
        content: msg,
        buttons: [ { text: 'Dismiss', className: 'btn' } ]
      })
  }

  const displayUploadError = (msg) => {
    modal(
      { title: 'Upload error',
        content: msg,
        buttons: [ { text: 'Dismiss', className: 'btn' } ]
      })
  }

  view.fileUploader.on('error', displayUploadError)
  view.fileUploader.on('warning', displayUploadWarning)
}
