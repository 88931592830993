const DataCaptureService = require('./services/data-capture')
const Model = require('./models/data-capture')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const FormView = require('./views/form')
const ListView = require('./views/list')

const init = (serviceLocator, cb) => {
  const getInstances = new Promise((resolve, reject) => {
    serviceLocator.instanceService.find('', {}, [ 'name' ], { pageSize: 1000 }, (err, res) => {
      if (err) return reject(err)
      return resolve(res.results)
    })
  })

  const getAccounts = new Promise((resolve, reject) => {
    serviceLocator.accountService.find('', {}, [ 'name' ], { pageSize: 1000 }, (err, res) => {
      if (err) return reject(err)
      return resolve(res.results)
    })
  })

  const getListExtraArgs = () => {
    return Promise.all([ getInstances, getAccounts ])
  }

  serviceLocator.dataCaptureService = new DataCaptureService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'data capture page',
    plural: 'data capture pages',
    path: 'data-capture',
    service: serviceLocator.dataCaptureService,
    Model,
    getListExtraArgs,
    canDupe: true,
    dupeFields: [ '_id', 'slug', 'dateCreated' ]
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb()
}

module.exports = () => ({ dataCaptureAdmin: [ 'codeWordAdmin', 'offerAdmin', 'widget', init ] })
