var config = window.config,
  DateTimePicker = require('anytime'),
  moment = require('moment'),
  extend = require('lodash.assign')

module.exports = function initDateTimePickers(i, el) {
  var $el = $(el),
    initialDate = new Date(),
    defaults = { format: config.locale.longDateFormat.LLLL },
    options = $el.data('options') || {}

  options = extend({}, defaults, options)

  $el.attr('readonly', true)

  if ($el.val()) {
    initialDate = moment($el.val()).toDate()
    $el.val(moment($el.val()).format(options.format))
  }

  var pickerOptions = extend({ initialValue: initialDate, input: el }, options),
    picker = new DateTimePicker(pickerOptions)

  picker.on(
    'change',
    function (value) {
      if (value) value = moment(value).toDate()
      this.model.set(el.name, value)
    }.bind(this)
  )

  picker.render()
  if (!Array.isArray(this.dateTimePickers)) this.dateTimePickers = []
  this.dateTimePickers.push(picker)
}
