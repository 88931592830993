const ListView = require('../views/list')
const FormView = require('../views/form')
const EmailCollection = require('../collections/email')
const EmailModel = require('../models/email')
const debug = require('../../../../admin/source/js/lib/debug')('email')
const async = require('async')
const notify = require('../../notification/foreground')

module.exports = serviceLocator => {
  const router = serviceLocator.router
  const collection = new EmailCollection()
  const displayName = { singular: 'Email', plural: 'Emails' }

  const getPreviewUrlComponents = (email, cb) => {
    async.parallel({
      instance: cb => {
        serviceLocator.instanceService.read(email.get('instance'), (error, instance) => {
          if (error) return cb(error)
          return cb(null, instance)
        })
      },
      account: cb => {
        serviceLocator.accountService.read(email.get('account'), (error, account) => {
          if (error) return cb(error)
          return cb(null, account)
        })
      }
    }, (error, object) => {
      if (error) return cb(error)
      return cb(null, object)
    })
  }

  router.route('emails(/)', 'listEmails', () => {
    if (!serviceLocator.allow('email', 'read')) {
      return false
    }

    const listView = new ListView({
      collection,
      serviceLocator,
      displayName
    })

    debug('email list view route triggered')

    listView.on('new',
      router.navigate.bind(router, 'emails/form', { trigger: true }))

    listView.on('edit', (model) => {
      router.navigate('emails/' + model.id + '/form', { trigger: true })
    })

    listView.on('publish', email => {
      getPreviewUrlComponents(email, (error, obj) => {
        if (error) {
          return serviceLocator.logger.error('Could not publish email', error)
        }
        const url = serviceLocator.instanceService.createUrl(obj.instance, obj.account) + '/email-preview/' + email.get('_id') + '/source'
        window.open(url)
      })
    })

    listView.on('preview', email => {
      getPreviewUrlComponents(email, (error, obj) => {
        if (error) {
          return serviceLocator.logger.error('Could not preview email', error)
        }
        const url = serviceLocator.instanceService.createUrl(obj.instance, obj.account) + '/email-preview/' + email.get('_id') + '/preview'
        window.open(url)
      })
    })

    listView.on('delete', email => {
      const id = email.get('_id')
      if (!serviceLocator.allow('email', 'delete')) return false
      serviceLocator.emailService.delete(id, err => {
        if (err) return alert(err.message)
        collection.remove(id)
      })
    })

    router.render(listView, displayName.plural)
    collection.load()
  })

  const bindEvents = view => {
    view.on('cancel', () => router.navigate('emails', { trigger: true }))

    view.on('save', () => notify('Saved', 'save'))

    view.on('saveAndClose', () => {
      notify('Saved', 'save')
      router.navigate('emails', { trigger: true })
    })

    view.on('saveAndPreview', email => {
      getPreviewUrlComponents(email, (error, obj) => {
        if (error) {
          return serviceLocator.logger.error('Could not preview email', error)
        }
        const url = serviceLocator.instanceService.createUrl(obj.instance, obj.account) + '/email-preview/' + email.get('_id') + '/preview'
        window.open(url)
      })
    })

    return view
  }

  router.route('emails/form(/)', 'newEmail', () => {
    if (!serviceLocator.allow('email', 'create')) {
      return false
    }
    debug('email create view route triggered')
    const model = new EmailModel({}, { collection: collection })
    const view = new FormView({
      model,
      title: displayName.singular,
      serviceLocator: serviceLocator
    })

    router.render(bindEvents(view), 'New ' + displayName.singular)
  })

  router.route('emails/:id/form(/)', 'editEmail', id => {
    debug('email edit view route triggered')

    collection.retrieve(id, (err, model) => {
      if (err) {
        router.trigger('notFound', err.message)
        return
      }
      debug('Loading form view', model)
      const view = new FormView({
        model: collection.get(id),
        title: displayName.singular,
        serviceLocator
      })

      router.render(bindEvents(view), 'Edit ' + displayName.singular)
    })
  })
}
