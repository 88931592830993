module.exports = createSchema

const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const extend = require('lodash.assign')

function createSchema () {
  const offerHeaderSchema =
    { showOfferProperties:
      { type: Array,
        defaultValue: function () {
          return [ 'category', 'attributes' ]
        }
      }
    }

  const widgetSchemata = schemata({
    name: 'Offer Layout Header',
    properties: extend({}, baseSchema.getProperties(), offerHeaderSchema)
  })

  return widgetSchemata
}
