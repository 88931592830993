const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, sessionHasSpecificAccount, title) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button></div></div>");
if ( allowed('partner', 'update'))
{
buf.push("<div class=\"control-group\"><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--url\" data-field=\"url\" class=\"form-row\"><label><span class=\"form-label-text\">Partner URL <abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"url\" placeholder=\"http://www.example.com\"" + (jade.attr("value", data.url, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div>");
if ( !sessionHasSpecificAccount)
{
buf.push("<div id=\"field--account\" data-field=\"account\" class=\"form-row\"><label><span class=\"form-label-text\">Account<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-account-select\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"></div></div>");
}
buf.push("</div></div><div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Images</h2></div><div class=\"panel-content\"><div id=\"field--images\" data-field=\"images\" class=\"form-row form-row-full-width\"><div class=\"js-image-area\"></div><div class=\"js-error\"></div></div><div class=\"form-row form-row-actions\"><button class=\"btn btn--success js-logo-add\">Add Images</button></div></div></div></form></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"sessionHasSpecificAccount" in locals_for_with?locals_for_with.sessionHasSpecificAccount:typeof sessionHasSpecificAccount!=="undefined"?sessionHasSpecificAccount:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const debug = require('../../../../admin/source/js/lib/debug')('partner form view')
const formSaveDelegate = require('../../../../admin/source/js/lib/form-save-delegate')(debug)
const formCancelDelegate = require('cf-form-cancel-delegate')(debug)
const AccountSelect = require('../../account/views/account-select')
const AssetCollection = require('../../asset/collections/asset')
const AssetPickerView = require('../../asset/views/picker')
const getImageFactory = require('../../asset/lib/image-factory')()
const WidgetAreaView = require('../../widget/views/widget-area')
const imageConfig = require('../../../service/partner/image-config.json')
const modal = require('modal')
const formTitleDelegate = require('../../../../admin/source/js/lib/form-title-delegate')

module.exports = Backbone.View.extend({
  events: {
    'click .js-save': 'handleSave',
    'click .js-cancel': 'handleCancel',
    'click .js-logo-add': 'handleAddLogo',
    'submit': e => e.preventDefault()
  },

  initialize () {
    this.initialModel = this.model.toJSON()
    this.formTitle = formTitleDelegate(this.options.title)
    this.render()
    this.on('afterAppend', this.focusForm, this)
  },

  handleCancel: formCancelDelegate,

  handleSave: formSaveDelegate,

  renderAccountSelect () {
    const accountView = new AccountSelect(this.options.serviceLocator, this.model.get('account'))
    accountView.populate()
    this.$el.find('.js-account-select').append(accountView.render().$el)
    accountView.on('change', account => this.model.set('account', account))
  },

  handleAddLogo (e) {
    e.preventDefault()
    debug('adding logo', arguments)

    const collection = new AssetCollection()
    const assetListView = new AssetPickerView({
      collection,
      serviceLocator: this.options.serviceLocator,
      type: 'image'
    })

    collection.load()

    modal({
      title: 'Partner Logo',
      className: 'modal-asset-view wide',
      content: assetListView.$el,
      buttons: [
        {
          text: 'Cancel',
          event: 'Cancel',
          keyCodes: [ 27 ],
          className: 'btn'
        },
        {
          text: 'Add Logo',
          event: 'add',
          className: 'btn btn--action',
          keyCodes: [ 13 ]
        }
      ]
    })
      .on('add', this.addLogo.bind(this, assetListView.selectedCollection))
      .on('close', assetListView.remove.bind(assetListView))
  },

  addLogo (images) {
    debug('models', images)
    var factory = getImageFactory('image')
    images.map(model => {
      const Model = factory.model
      const image = new Model(model.attributes)
      this.model.images.add(image)
      image.setDefaultCrops(imageConfig.crops)
      debug('Adding new image to partner', image)
      return image
    })
  },

  render () {
    this.$el.empty().append(template({
      data: this.model.toJSON(),
      title: this.formTitle(this.model.toJSON()),
      allowed: this.options.serviceLocator.allowed,
      sessionHasSpecificAccount: this.options.serviceLocator.session.account
    }))
    const imageView = new WidgetAreaView({
      model: this.model.images,
      receiveDrops: false,
      serviceLocator: this.options.serviceLocator,
      widgetItemOptions: imageConfig
    })
    this.$('.js-image-area').append(imageView.$el)

    this.renderAccountSelect()
    return this
  }
})
