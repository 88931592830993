const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')

module.exports = () => {
  const properties = {
    overlay: {
      type: Boolean,
      defaultValue: false
    }
  }
  const widgetSchemata = schemata({
    name: 'Navigation',
    properties: Object.assign({}, baseSchema.getProperties(), properties)
  })

  return widgetSchemata
}
