const retrievePeriodicDates = require('../periodic-date-retriever')

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const createHeaders = (startDate, endDate, type) => {
  const returnFormattedDate = true

  const { startDateFilter, endDateFilter, startDateMoM, endDateMoM, startDateYoY, endDateYoY } = retrievePeriodicDates(startDate, endDate, returnFormattedDate)
  const dateRangeMoMTitle = `${startDateMoM} - ${endDateMoM}`
  const dateRangeFilterTitle = `${startDateFilter} - ${endDateFilter}`
  const dateRangeYoYTitle = `${startDateYoY} - ${endDateYoY}`

  const primaryHeaders = [ '', 'Month on Month View', 'Campaign Period', 'Year on Year View', '', '' ]
  const secondaryHeaders = [ capitalize(type), dateRangeMoMTitle, dateRangeFilterTitle, dateRangeYoYTitle, '% MoM Change', '% YoY Change' ]

  return [ primaryHeaders, secondaryHeaders ]
}

module.exports = createHeaders
