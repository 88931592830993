const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const validateDelegate = require('../../../../../../admin/source/js/lib/validate-delegate')()
const validateLength = require('validity-length')
const schema = schemata({
  name: 'Offer Hero',
  properties: Object.assign(
    {},
    baseSchema.getProperties(),
    {
      offers: {
        type: Array,
        validators:
          { all: [ validateLength(1) ] },
        defaultValue: () => []
      },
      leftAligned: {
        type: Boolean,
        defaultValue: false
      },
      dualCta: {
        type: Boolean,
        defaultValue: false
      },
      smallHeading: {
        type: Boolean,
        defaultValue: false
      },
      partnerSupplementText: {
        type: String,
        defaultValue: () => 'Supported by'
      },
      conditionsSupplementText: {
        type: String
      },
      segment: {
        type: String,
        defaultValue: () => false
      },
      hideConditionsApplyText: {
        type: Boolean,
        defaultValue: () => false
      }
    }
  )
})

module.exports = BaseModel.extend({
  idAttribute: 'id',

  schemata: schema,

  type: 'offerHero',

  defaults () {
    return schema.makeDefault({ type: this.type || 'offerHero' })
  },

  validate: validateDelegate
})
