module.exports = PaginationView

function PaginationView (paginationModel) {
  this.paginationModel = paginationModel

  this.listenTo(paginationModel, 'change', this.updatePagination.bind(this))
}

PaginationView.prototype.updatePagination = function () {
  var pagination = this.paginationModel.toJSON()
  this.$el.find('.js-item-count').text(pagination.showing)
  this.$el.find('.js-total-item-count').text(pagination.totalItems)
  if (pagination.showing < pagination.totalItems) {
    this.$el.find('.js-more').show()
  } else {
    this.$el.find('.js-more').hide()
  }
}
