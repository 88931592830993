module.exports = initWidget

function initWidget () {
  return { googleMap: [ 'widget', 'sectionAdmin', init ] }
}

function init (serviceLocator, done) {
  // var widget =
  //   { editView: require('./form'),
  //     model: require('./models/model'),
  //     name: 'Google Map',
  //     itemView: require('./item'),
  //     description: '',
  //     options:
  //     { crops:
  //       [ { name: 'Image',
  //         aspectRatio: '1:1'
  //       }
  //       ],
  //     contexts:
  //       [ { name: 'Main',
  //         allowMultipleSelection: false
  //       }
  //       ]
  //     }
  //   }

  // serviceLocator.widgetFactories.get('offerBody').register('googleMap', widget)

  done()
}
