import ItemView from '../../widget/views/item/base'
import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    model: Model,
    itemView: ItemView,
    name: 'Offer Redemption History',
    description: 'Offer redemption history for a user'
  }

  serviceLocator.widgetFactories.get('section').register('offerRedemptionHistory', widget)

  done()
}

module.exports = () => ({ offerRedemptionHistory: [ 'sectionAdmin', init ] })
