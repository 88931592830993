const parseUrl = require('url').parse

const determineCompleteUrl = (serviceLocator, instance, account) => {
  const parsed = parseUrl(serviceLocator.config.url)
  let portPostfix = ''
  if (parsed.port) portPostfix = ':' + parsed.port
  const subdomainIsPath = instance.subdomain && instance.subdomain.indexOf('/') === 0
  const subdomainIsFullDomain = instance.subdomain && instance.subdomain.indexOf('.') > -1
  let fullDomain = instance.subdomain + (account.domain ? '.' + account.domain : '')
  if (subdomainIsPath) {
    fullDomain = account.domain
  } else if (subdomainIsFullDomain) {
    fullDomain = instance.subdomain
  }
  return 'http://' + fullDomain + portPostfix + (subdomainIsPath ? instance.subdomain : '')
}

module.exports = determineCompleteUrl
