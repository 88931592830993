const View = require('../views/view')

module.exports = serviceLocator => {
  const router = serviceLocator.router
  const displayName = { singular: 'Redemption Report', plural: 'Redemption Report' }

  router.route('redemption-report(/)', 'viewRedemptionReports', () => {
    if (!serviceLocator.allow('redemption-report', 'read')) {
      return false
    }
    const view = new View({ serviceLocator, displayName })
    router.render(view, displayName.plural)
  })
}
