const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterItemView = require('../../../../widget/views/item/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, id) {
buf.push("<div" + (jade.attr("data-id", id, true, false)) + " class=\"js-item list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", "background-image: url('" + data.previewImageUrl + "');", true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-edit-item\">Edit</a><a class=\"js-remove-item\">Remove</a></li></ul></div></div><h2><a class=\"js-edit-item\">" + (jade.escape(null == (jade_interp = data.title) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>Show Original when Clicked:</dt><dd>" + (jade.escape(null == (jade_interp = data.showOriginalWhenClicked ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd>");
if ( data.link)
{
buf.push("<dt>Link:</dt><dd>" + (jade.escape(null == (jade_interp = data.link) ? "" : jade_interp)) + "</dd><dt>Is external link:</dt><dd>" + (jade.escape(null == (jade_interp = data.externalLink ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd>");
}
if ( data.caption)
{
buf.push("<dt>Caption:</dt><dd>" + (jade.escape(null == (jade_interp = data.caption) ? "" : jade_interp)) + "</dd>");
}
buf.push("</dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined));;return buf.join("");
}

class ItemRepeaterItemView extends BaseItemRepeaterItemView {
  constructor (serviceLocator, model, ...args) {
    super(serviceLocator, model, ...args)
    this.template = template
  }

  render () {
    const data = this.model.toJSON()
    data.previewImageUrl = this.model.getPreviewImageUrl()
    this.$el.append(this.template({ data: data, id: this.model.id }))
    return this
  }
}

module.exports = ItemRepeaterItemView
