const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const isUrl = require('validity-url')
const imageConfig = require('./image-config.json')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')

module.exports = () => {
  const requiredContexts = imageConfig.contexts.map(context => context.name)
  const requiredCrops = imageConfig.crops.map(crop => crop.name)

  return schemata({
    name: 'Partner',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      name: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      url: {
        type: String,
        name: 'URL',
        validators: {
          all: [ required, isUrl ]
        }
      },
      images: {
        type: Object,
        validators: {
          all: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops)
          ]
        }
      },
      created: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })
}
