const CrudService = require('../../../../admin/source/js/lib/crud-service')

class RoleService extends CrudService {
  get name () {
    return 'RoleService'
  }

  get urlRoot () {
    return '/roles'
  }

  reset2fa (model, cb) {
    this.partialUpdate(model.get('_id'), { twoFaKey: null, twoFaChallengeDates: {} }, cb)
  }
}

module.exports = RoleService
