const BaseListItemView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, title) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><div class=\"btn-group\">");
if ( allowed('offer', 'delete'))
{
buf.push("<button type=\"button\" class=\"btn js-delete\">Delete Selected</button>");
}
buf.push("<button type=\"button\" class=\"btn js-export-puff\">Export Selected as Puff</button></div><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn dropdown-toggle\">Selection (<span class=\"js-selection-count\">0 items</span>) <span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a class=\"js-select-all\">Add visible to selection</a></li><li><a class=\"js-select-none\">Clear selection</a></li></ul></div></div></div><div class=\"control-group\"><div class=\"btn-group\">");
if ( allowed('offer', 'create'))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New Offer</button>");
}
buf.push("</div></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div data-layout=\"detail\" class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const modal = require('modal')
const PreviewSelectModal = require('./preview-select-modal')

class ListView extends BaseListItemView {
  constructor () {
    super(...arguments)

    this.$el.on('click', '.js-toolbar .js-export-puff', () => {
      this.emit('exportPuff', this.selectedCollection.models.map(model => model.id))
    })

    this.on('previewDialog', this.showPreviewList.bind(this))
  }
  get name () {
    return { singular: 'Offer', plural: 'Offers', resource: 'offer' }
  }

  get FilterView () {
    return FilterView
  }

  get ListItemView () {
    return ListItemView
  }

  get template () {
    return template
  }

  showPreviewList (sections, defaultDate) {
    const selectModal = new PreviewSelectModal(this.serviceLocator, sections, defaultDate)

    modal({
      title: 'Preview Offer',
      content: selectModal.render().$el,
      buttons: [ {
        text: 'Open',
        event: 'open',
        className: 'btn',
        keyCodes: [ 13 ]
      } ]
    }).on('open', () => {
      if (selectModal.selectedUrl) {
        window.open(selectModal.selectedUrl + '&date=' + selectModal.selectedDate.toISOString())
      }
    })
  }

  addListItem (model) {
    var listItem = new this.ListItemView(this.serviceLocator, model)
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.listenTo(listItem, 'preview', this.emit.bind(this, 'preview', model))
    this.listenTo(listItem, 'datePreview', this.emit.bind(this, 'datePreview', model))
    this.listenTo(listItem, 'duplicate', this.emit.bind(this, 'duplicate', model))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }
}

module.exports = ListView
