const transformToReport = require('./report-transformer')
const queryCreator = require('./query-creator')
const transformToChart = require('./chart-transformer')
const fields = require('../year-on-year-redemption-report/fields')

const yearOnYearRedemptionReport = () => ({
  queryCreator,
  transformToReport,
  transformToChart: (data) => transformToChart(data),
  fields
})

module.exports = yearOnYearRedemptionReport
