const ListView = require('../views/list')
const Collection = require('chale')
const AdministratorModel = require('../models/administrator')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, { totalItems: 0, showing: 0 })
  let currentPage = 1
  let currentParams = { keywords: '', filter: {}, sort: [ 'dateCreated', 'desc' ] }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an administrator is updated, reset the model with its new attributes
  serviceLocator.administratorService.on('update', (id, attrs) => {
    const model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.administratorService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getAdministrators(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
  })

  serviceLocator.router.route('administrators(/)', 'listAdministrators', () => {
    if (!serviceLocator.allow('administrator', 'discover')) return false

    getAdministrators(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)

    const list = new ListView(serviceLocator, collection, paginationModel).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('administrator', 'create')) return false
      serviceLocator.router.navigate('administrators/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('administrator', 'update')) return false
      serviceLocator.router.navigate('administrators/' + id + '/form', { trigger: true })
    })

    list.on('delete', ids => {
      const deleteOne = (id, cb) => {
        serviceLocator.administratorService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
        })
      }

      if (!serviceLocator.allow('administrator', 'delete')) return false
      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
      })
    })

    list.on('filter', params => {
      currentParams = params
      const pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getAdministrators(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      const pagination = { page: currentPage, pageSize: pageSize }
      appendAdministrators(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
    })

    list.on('showRevisions', model => {
      serviceLocator.router.navigate('administrators/' + model.id + '/revisions', { trigger: true })
    })

    list.on('reset2fa', model => {
      serviceLocator.administratorService.reset2fa(model, err => {
        if (err) return alert(err.message)
      })
    })

    serviceLocator.router.render(list, 'Administrators')
  })

  const getAdministrators = (keywords, filter, sort, pagination) => {
    serviceLocator.administratorService.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err) return serviceLocator.logger.error('Could not load administrators', err)
      collection.reset(res.results.map(administrator => new AdministratorModel(serviceLocator, administrator)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendAdministrators = (keywords, filter, sort, pagination) => {
    serviceLocator.administratorService.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(administrator => collection.add(new AdministratorModel(serviceLocator, administrator)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }
}

module.exports = createController
