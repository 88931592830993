const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, undefined) {
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-content\"><p>Total Results: " + (jade.escape((jade_interp = data.totalResults) == null ? '' : jade_interp)) + "</p>");
if ( data.totalResults)
{
buf.push("<table><thead><th>Redemption Date / Time</th><th>Customer Details</th><th>Offer Details</th><th>Unique Code</th><th>Instance</th></thead><tbody class=\"js-results-wrapper\">");
// iterate data.results
;(function(){
  var $$obj = data.results;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var result = $$obj[$index];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = result.redemptionDate) ? "" : jade_interp)) + "</td><td> <dt>Name:</dt><dd>" + (jade.escape(null == (jade_interp = result.customerName) ? "" : jade_interp)) + "</dd><dt>Email:</dt><dd>" + (jade.escape(null == (jade_interp = result.customerEmail) ? "" : jade_interp)) + "</dd></td><td> <dt>ID:</dt><dd>" + (jade.escape(null == (jade_interp = result.offerId) ? "" : jade_interp)) + "</dd><dt>Title:</dt><dd><a" + (jade.attr("href", '/offers/' + result.offerId + '/form', true, false)) + ">" + (jade.escape(null == (jade_interp = result.offerName) ? "" : jade_interp)) + "</a></dd></td><td>" + (jade.escape(null == (jade_interp = result.uniqueCode) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = result.instanceName) ? "" : jade_interp)) + "</td></tr>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var result = $$obj[$index];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = result.redemptionDate) ? "" : jade_interp)) + "</td><td> <dt>Name:</dt><dd>" + (jade.escape(null == (jade_interp = result.customerName) ? "" : jade_interp)) + "</dd><dt>Email:</dt><dd>" + (jade.escape(null == (jade_interp = result.customerEmail) ? "" : jade_interp)) + "</dd></td><td> <dt>ID:</dt><dd>" + (jade.escape(null == (jade_interp = result.offerId) ? "" : jade_interp)) + "</dd><dt>Title:</dt><dd><a" + (jade.attr("href", '/offers/' + result.offerId + '/form', true, false)) + ">" + (jade.escape(null == (jade_interp = result.offerName) ? "" : jade_interp)) + "</a></dd></td><td>" + (jade.escape(null == (jade_interp = result.uniqueCode) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = result.instanceName) ? "" : jade_interp)) + "</td></tr>");
    }

  }
}).call(this);

buf.push("</tbody></table><div class=\"pagination\"><p>Showing <b class=\"js-item-count\">" + (jade.escape(null == (jade_interp = data.results.length) ? "" : jade_interp)) + "</b> of <b class=\"js-total-item-count\">" + (jade.escape(null == (jade_interp = data.totalResults) ? "" : jade_interp)) + "</b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div>");
}
buf.push("</div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const rowTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (result) {
buf.push("<tr><td>" + (jade.escape(null == (jade_interp = result.redemptionDate) ? "" : jade_interp)) + "</td><td> <dt>Name:</dt><dd>" + (jade.escape(null == (jade_interp = result.customerName) ? "" : jade_interp)) + "</dd><dt>Email:</dt><dd>" + (jade.escape(null == (jade_interp = result.customerEmail) ? "" : jade_interp)) + "</dd></td><td> <dt>ID:</dt><dd>" + (jade.escape(null == (jade_interp = result.offerId) ? "" : jade_interp)) + "</dd><dt>Title:</dt><dd><a" + (jade.attr("href", '/offers/' + result.offerId + '/form', true, false)) + ">" + (jade.escape(null == (jade_interp = result.offerName) ? "" : jade_interp)) + "</a></dd></td><td>" + (jade.escape(null == (jade_interp = result.uniqueCode) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = result.instanceName) ? "" : jade_interp)) + "</td></tr>");}.call(this,"result" in locals_for_with?locals_for_with.result:typeof result!=="undefined"?result:undefined));;return buf.join("");
}

class ResultsView extends View {
  constructor(serviceLocator, filterOptions, data) {
    super(serviceLocator)
    this.data = data
    this.totalResults = this.data.totalResults
    this.filterOptions = filterOptions
    this.currentlyShowing = this.data.results.length
    this.page = 1

    this.$el.on('click', '.js-more', this.loadMore.bind(this))
  }

  loadMore(e) {
    e.preventDefault()
    this.serviceLocator.userActivityService.search(
      this.filterOptions,
      ++this.page,
      (error, response) => {
        if (error) return this.options.serviceLocator.logger.error(error)
        this.currentlyShowing += response.results.length
        this.$el.find('.js-item-count').text(this.currentlyShowing)
        if (this.currentlyShowing >= this.totalResults) {
          this.$el.find('.js-more').hide()
        } else {
          this.$el.find('.js-more').show()
        }
        response.results.forEach((result) => {
          const html = rowTemplate({ result: result })
          this.$el.find('.js-results-wrapper').append(html)
        })
      }
    )
  }

  render() {
    this.$el.empty().append(
      template({
        data: this.data,
        allowed: this.serviceLocator.allowed
      })
    )
    return this
  }
}

module.exports = ResultsView
