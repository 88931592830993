var schemata = require('@clocklimited/schemata'),
  urlValidity = require('validity-url-optional-tlds')(),
  required = require('validity-required')

module.exports = function () {
  return schemata({
    name: 'Scheduled Redirect',
    properties: {
      url: {
        type: String,
        validators: { all: [required, urlValidity] }
      },
      startDate: { type: Date, validators: { all: [required] } },
      endDate: { type: Date, validators: { all: [required] } }
    }
  })
}
