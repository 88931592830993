const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"panel\"><form><div class=\"js-errors-summary\"></div><div data-field=\"code\" class=\"form-row\"><label><span class=\"form-label-text\">Unique Voucher Code</span><input type=\"text\" name=\"code\"" + (jade.attr("value", data.code, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"form-row-description form-copy\"><p>The unique voucher code</p></div></div><div data-field=\"used\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Used</span><div class=\"form-field\"><input type=\"checkbox\" name=\"used\"" + (jade.attr("checked", data.used, true, false)) + " class=\"control control--boolean\"/><span>This code has been used</span></div></label></div><div class=\"js-used-fields\"><hr/><div data-field=\"userId\" class=\"form-row\"><label><span class=\"form-label-text\">Customer ID</span><input type=\"text\" name=\"userId\"" + (jade.attr("value", data.userId, true, false)) + " class=\"control control--text form-field\"/></label></div><div data-field=\"userFirstName\" class=\"form-row\"><label><span class=\"form-label-text\">Customer First Name</span><input type=\"text\" name=\"userFirstName\"" + (jade.attr("value", data.userFirstName, true, false)) + " class=\"control control--text form-field\"/></label></div><div data-field=\"userLastName\" class=\"form-row\"><label><span class=\"form-label-text\">Customer Last Name</span><input type=\"text\" name=\"userLastName\"" + (jade.attr("value", data.userLastName, true, false)) + " class=\"control control--text form-field\"/></label></div><div data-field=\"userEmail\" class=\"form-row\"><label><span class=\"form-label-text\">Customer Email</span><input type=\"text\" name=\"userEmail\"" + (jade.attr("value", data.userEmail, true, false)) + " class=\"control control--text form-field\"/></label></div></div><div class=\"modal-controls\">");
if ( data._id)
{
buf.push("<button type=\"button\" class=\"btn btn--error js-delete\">Delete</button>");
}
buf.push("<button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div></form></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const modal = require('modal')

class UniqueCodeForm extends View {
  constructor (serviceLocator, model) {
    super(serviceLocator)
    this.$el.on('click', '.js-cancel', this.handleCancel.bind(this))
    this.$el.on('click', '.js-save', this.handleSave.bind(this))
    this.$el.on('click', '.js-delete', this.handleDelete.bind(this))
    this.$el.on('change', '[name=used]', this.updateVisisbility.bind(this))
    this.$el.on('change', this.applyChangeToModel.bind(this))
    this.model = model

    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
  }

  applyChangeToModel (e) {
    if (this.model.schema.getProperties().hasOwnProperty(e.target.name)) {
      if (e.target.type === 'checkbox') {
        this.model.set(e.target.name, e.target.checked)
      } else {
        this.model.set(e.target.name, e.target.value)
      }
    }
  }

  // Compatibility with the form errors delegate
  $ (selector) {
    return this.$el.find(selector)
  }

  handleCancel (e) {
    e.preventDefault()
    this.emit('remove')
  }

  handleSave (e) {
    e.preventDefault()
    this.clearErrors()

    const onSave = err => {
      if (err) {
        if (err.errors) return this.showErrors(err.errors)
        return this.showErrors({ error: 'Code could not be created' })
      }
      return this.remove()
    }

    if (!this.model.id) {
      this.serviceLocator.uniqueCodeService.create(this.model.toJSON(), onSave.bind(this))
    } else {
      this.serviceLocator.uniqueCodeService.update(this.model.id, this.model.toJSON(), onSave.bind(this))
    }
  }

  render () {
    this.$el.empty().append(template({ data: this.model.toJSON() }))
    this.updateVisisbility()
    return this
  }

  updateVisisbility () {
    var $usedFields = this.$el.find('.js-used-fields')
    if (this.$el.find('[name=used]')[0].checked) {
      $usedFields.show()
    } else {
      $usedFields.hide()
      this.model.set('userId', null)
      this.model.set('userFirstName', null)
      this.model.set('userLastName', null)
      this.model.set('userEmail', null)
    }
  }

  handleDelete (e) {
    e.preventDefault()
    modal(
      { title: 'Delete code',
        content: 'Are you sure you want to delete this code?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn' },
          { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
        ]
      }
    ).on('confirm', () => {
      this.serviceLocator.uniqueCodeService.delete(this.model.id, err => {
        if (err) {
          if (err.errors) return this.showErrors(err.errors)
          return this.showErrors({ error: 'Code could not be deleted' })
        }
        return this.remove()
      })
    })
  }
}

module.exports = UniqueCodeForm
