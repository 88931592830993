module.exports = createAuthedRequester

let request = require('request')
const sign = require('cf-signature')
const qs = require('querystring')
const extend = require('lodash.assign')

function createAuthedRequester (apiUrl) {
  function makeAuthedRequest (method, path, data, options, cb) {
    /* eslint complexity: [ 2, 14 ] */

    // remove empty arrays as it breaks the querystring stringify function causing `&&` to appear in URL
    if (data && method === 'GET') {
      Object.keys(data).forEach(key => {
        const value = data[key]
        if (Array.isArray(value) && !value.length) {
          delete data[key]
        }
      })
    }

    const key = window.localStorage.getItem('apiKey')
    const id = window.localStorage.getItem('apiId')
    let sessionTimeout = window.localStorage.getItem('apiTimeout')

    if (typeof options === 'function') {
      cb = options
      options = { headers: {} }
    }

    // Set a 24 hour session timeout. After which point you have to login again
    if ((sessionTimeout !== null) && (sessionTimeout < Date.now())) return logout('timeout')

    if (!isAuthed()) return logout()

    sessionTimeout = Date.now() + (24 * 60 * 1000 * 60)
    window.localStorage.setItem('apiTimeout', sessionTimeout)

    /* eslint-disable one-var */
    var contentType = (options.headers && options.headers['Content-Type']) || getContentType(method),
      date = (new Date()).toUTCString(),
      query = hasBodyData(method) || !data ? '' : '?' + qs.stringify(data).replace(/'/g, '\''),
      signature = sign(key, method, contentType, date, path + query),
      req =
      { method: method,
        uri: apiUrl + path,
        headers:
            extend(
              { 'Authorization': 'Catfish ' + id + ':' + signature,
                'x-cf-date': date
              }, options.headers, { 'Content-Type': contentType }),
        json: !contentType || contentType === 'application/json',
        withCredentials: false
      }

    if (options.binary) {
      req.encoding = null
    }

    if (hasBodyData(method)) {
      req.body = data
    } else {
      req.uri += query
    }

    request(req, function (err, res, body) {
      if (err) return cb(err)
      cb(null, res, body)
    })
  }

  function logout (reason) {
    window.localStorage.removeItem('apiKey')
    window.localStorage.removeItem('apiTimeout')
    window.localStorage.removeItem('aclRoles')
    document.location = '/login?reason=' + (reason || '')
  }

  function isAuthed () {
    return (!!window.localStorage.getItem('apiKey') && !!window.localStorage.getItem('apiId'))
  }

  function hasBodyData (method) {
    return [ 'GET', 'DELETE' ].indexOf(method) === -1
  }

  function getContentType (method) {
    switch (method) {
      case 'GET': return ''
      case 'DELETE': return 'text/plain'
      default: return 'application/json'
    }
  }

  return makeAuthedRequest
}
