const required = require('validity-required')
const schemata = require('@clocklimited/schemata')
const tagSchema = require('../tag/schema')
const redemptionExperienceSchema = require('./redemption-experience-schema')
const imageConfig = require('./image-config.json')
const dateBeforeExpiryValidator = require('validity-date-before-property')(
  'expiryDate'
)
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const moment = require('moment')
const createUniqueValidator = require('validity-unique-property')
const unique = require('lodash.uniq')
const validateLength = require('validity-length')
const validateIfSet = require('validity-validate-if-set')
const stripTags = require('striptags')
const he = require('he')
const mobileWalletSchema = require('./mobile-wallet-schema')()

const validateStrippedHtml = (fn) => (key, name, object, cb) => {
  const objToValidate = { ...object }
  objToValidate[key] = he.decode(stripTags(object[key]))
  fn(key, name, objToValidate, cb)
}

const customValidityMessage = (fn, message) => (key, name, object, cb) => {
  fn(key, name, object, (error, valid) => {
    cb(error, valid ? message : undefined)
  })
}

const hasWidgets = (obj) => obj && obj.widgets && obj.widgets.length > 0

const validateIf =
  (validateFn, comparator) => (key, keyDisplayName, object, cb) => {
    if (comparator(object[key])) {
      return validateFn(key, keyDisplayName, object, cb)
    }

    return cb(null, undefined)
  }

module.exports = (find, sectionService) => {
  const requiredContexts = imageConfig.contexts.map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)
  const fullUrlUniqueValidator = customValidityMessage(
    createUniqueValidator(find, { keys: ['section'] }),
    'Slug within this section is already in use'
  )

  const uniqueInstanceValidator = (key, keyDisplayName, object, cb) => {
    // If no section service, assume valid
    if (!sectionService) return cb(null, undefined)

    sectionService.find({ _id: { $in: object[key] } }, (err, sections) => {
      if (err) return cb(err)

      var sectionCount = sections.length
      const uniqueInstanceCount = unique(
        sections.map((section) => section.instance)
      ).length

      if (sectionCount !== uniqueInstanceCount) {
        return cb(
          null,
          keyDisplayName +
            ' must not contain more than one section from any instance'
        )
      }

      return cb(null, undefined)
    })
  }
  return schemata({
    name: 'Offer',
    properties: {
      _id: { type: String },
      state: {
        type: String,
        options: ['Draft', 'Published', 'Archived', 'Trashed'],
        defaultValue: 'Draft',
        validators: { all: [] }
      },
      headline: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: []
        }
      },
      displayHeadline: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: []
        }
      },
      shortHeadline: {
        type: String,
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      displayShortHeadline: {
        type: String,
        validators: {
          draft: [validateIfSet(validateStrippedHtml(validateLength(1, 70)))],
          published: [validateStrippedHtml(validateLength(1, 70))],
          archived: []
        }
      },
      sell: {
        type: String
      },
      offerTitle: {
        type: String,
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      slug: {
        type: String,
        validators: {
          draft: [],
          published: [required, fullUrlUniqueValidator],
          archived: []
        }
      },
      sections: {
        type: Array,
        validators: {
          draft: [],
          published: [
            customValidityMessage(
              validateLength(1),
              'At least one section must be assigned'
            ),
            uniqueInstanceValidator
          ],
          archived: []
        }
      },
      sectionLayouts: {
        type: Object,
        defaultValue: () => ({})
      },
      category: {
        type: String,
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      attributes: {
        type: Array
      },
      offerType: {
        type: String,
        defaultValue: 'normal'
      },
      body: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      preRedemptionForm: {
        type: String
      },
      keyFactsTitle: {
        type: String
      },
      keyFacts: {
        type: Array
      },
      stockLimit: {
        type: Number,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      lowStockThreshold: {
        type: Number,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      criticallyLowStockThreshold: {
        type: Number,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      uniqueCodeStockLevelThreshold: {
        type: Number,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      uniqueCodeStockLevelNotificationEmails: {
        type: String
      },
      status: {
        type: String,
        defaultValue: 'Available',
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      redemptionExperiences: {
        type: schemata.Array(redemptionExperienceSchema()),
        validators: {
          draft: [],
          published: [],
          archived: []
        },
        defaultValue: () => []
      },
      umbrellaOffers: { type: Array },
      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [
            validateIf(createContextValidator(requiredContexts), hasWidgets),
            validateIf(createCropValidator(requiredCrops), hasWidgets)
          ],
          published: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops)
          ],
          archived: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops)
          ]
        }
      },
      partners: {
        type: Array
      },
      termsAndConditions: {
        type: String
      },
      fullTermsAndConditions: {
        type: String
      },
      displayDate: {
        type: Date,
        defaultValue: () => moment().startOf('minute').toDate(),
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      liveDate: {
        type: Date,
        validators: {
          draft: [dateBeforeExpiryValidator],
          published: [dateBeforeExpiryValidator],
          archived: []
        }
      },
      openingDate: {
        type: Date,
        validators: {
          draft: [dateBeforeExpiryValidator],
          published: [dateBeforeExpiryValidator],
          archived: []
        }
      },
      closingDate: {
        type: Date,
        validators: {
          draft: [dateBeforeExpiryValidator],
          published: [dateBeforeExpiryValidator],
          archived: []
        }
      },
      expiryDate: {
        type: Date,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date(),
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      tags: {
        type: schemata.Array(tagSchema())
      },
      subCategory: {
        type: String,
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      updatedDate: {
        type: Date,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      lastUniqueCodeStockLevelNotificationDate: {
        type: Date,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      metaTitle: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      metaDescription: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      shareTitle: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      shareDescription: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      // ID used by admin to preview
      previewId: {
        type: String,
        defaultValue: () =>
          Math.round(Math.random() * 100000000000).toString(36)
      },
      relatedWidgets: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      account: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: [required]
        }
      },
      preRedemptionQuestions: {
        type: Array
      },
      extraInfo: {
        type: String,
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      incrementedId: {
        type: Number
      },
      redemptionResetInterval: {
        type: String
      },
      redemptionResetStartDate: {
        type: Date,
        defaultValue: null
      },
      homepageHeroCtaLabel: {
        type: String
      },
      umbrellaClosedTitle: {
        type: String
      },
      umbrellaMaxRedemptions: {
        type: Number,
        defaultValue: 0
      },
      redemptionCount: {
        type: Number,
        defaultValue: 0
      },
      lastUploadedUniqueCodesFilename: {
        type: String
      },
      umbrellaChildrenOpenInNewPage: {
        type: Boolean
      },
      showCountdownOverlay: {
        type: Boolean
      },
      showSeasonalOfferHero: {
        type: Boolean,
        defaultValue: false
      },
      hideHomepageTakeoverCampaignOfferBanner: {
        type: Boolean,
        defaultValue: false
      },
      mobileWallet: {
        type: mobileWalletSchema,
        defaultValue: () => mobileWalletSchema.makeDefault()
      }
    }
  })
}
