const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const schema = schemata({
  name: 'Audio',
  properties: Object.assign({}, baseSchema.getProperties(), {
    audioId: {
      type: String,
      validators: [required],
    },
  }),
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'audioId' })
  },
  validate: validateDelegate,
})
