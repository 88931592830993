const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (account, allowed, data, dropdownActions, instance) {
buf.push("<div class=\"list-item list-item--condensed\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( data.visible)
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible&lt;/strong&gt;&lt;br&gt;This section is visible on the site.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This section will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
buf.push("<div class=\"btn-group\"><button type=\"button\" class=\"btn btn--small js-preview\">Preview</button>");
if ( allowed('section', 'update'))
{
buf.push("<a" + (jade.attr("href", '/sections/' + (data._id) + '/form', true, false)) + " class=\"btn btn--small js-edit\">Details</a>");
}
if ( dropdownActions.length > 0)
{
buf.push("<a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-duplicate-to-instance\">Duplicate</a></li>");
if ( dropdownActions.indexOf('delete') > -1)
{
buf.push("<li><a class=\"js-delete\">Delete</a></li>");
}
buf.push("</ul>");
}
buf.push("</div></div>");
if ( allowed('section', 'manageLayouts'))
{
buf.push("<h2><a" + (jade.attr("href", '/layouts/' + (data._id) + '/manager', true, false)) + " class=\"js-manage-layouts\">");
if ( data.__instance)
{
buf.push("<span class=\"instance-label\"><img" + (jade.attr("src", data.instanceLogoUrl, true, false)) + (jade.attr("alt", data.__instance.name, true, false)) + (jade.attr("title", data.__instance.name, true, false)) + "/></span> ");
}
buf.push((jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>");
if ( data.__instance)
{
buf.push("<span class=\"instance-label\">" + (jade.escape((jade_interp = data.__instance.name) == null ? '' : jade_interp)) + "<img" + (jade.attr("src", data.instanceLogoUrl, true, false)) + (jade.attr("alt", data.__instance.name, true, false)) + (jade.attr("title", data.__instance.name, true, false)) + "/></span> ");
}
buf.push((jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl>");
if ( instance && account)
{
buf.push("<dd><a" + (jade.attr("href", data.__url, true, false)) + " target=\"_blank\">" + (jade.escape((jade_interp = data.__path) == null ? '' : jade_interp)) + "/</a></dd>");
}
buf.push("</dl></div></div>");}.call(this,"account" in locals_for_with?locals_for_with.account:typeof account!=="undefined"?account:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"dropdownActions" in locals_for_with?locals_for_with.dropdownActions:typeof dropdownActions!=="undefined"?dropdownActions:undefined,"instance" in locals_for_with?locals_for_with.instance:typeof instance!=="undefined"?instance:undefined));;return buf.join("");
}
const find = require('lodash.find')
const modal = require('modal')
const DuplicateToInstanceView = require('./duplicate-to-instance')
const createImageUrlBuilder = require('cf-image-url-builder')

class ListItemView extends BaseListItemView {
  constructor (serviceLocator, model, instances, accounts) {
    super(serviceLocator, model)
    this.instances = instances
    this.accounts = accounts
    this.$el.on('click', '.js-manage-layouts', this.handleManageLayouts.bind(this))
    this.$el.on('click', '.js-preview', this.emit.bind(this, 'preview', this.model))
    this.$el.on('click', '.js-date-preview', this.emit.bind(this, 'datePreview', this.model))
    this.$el.on('click', '.js-delete', this.handleDelete.bind(this))
    this.$el.on('click', '.js-duplicate-to-instance', this.handleDuplicateToInstance.bind(this))
  }

  get template () {
    return template
  }

  handleManageLayouts (e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if ((e.which === 2) || e.metaKey) return
    e.preventDefault()
    this.emit('manageLayouts')
  }

  handleDelete () {
    modal(
      { title: 'Delete',
        content: 'Are you sure you want to delete this section?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
          { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
        ]
      })
      .on('confirm', this.emit.bind(this, 'delete'))
  }

  render () {
    const instance = find(this.instances, item => {
      if (this.model.get('instance') === null) return true
      if (this.model.get('instance') === item._id) return true
      return false
    })
    const account = find(this.accounts, item => {
      if (!instance) return true
      if (instance.account === item._id) return true
      return false
    })

    let dropdownActions = []
    const data = this.model.toJSON()
    const fullUrlpath = data.fullUrlPath === '/' ? '' : data.fullUrlPath

    if (this.serviceLocator.allowed('section', 'delete')) {
      dropdownActions.push('delete')
    }

    // Do not show delete or duplicate actions for root sections
    if (data.root) {
      dropdownActions = []
    }

    const drUrl = this.serviceLocator.config.darkroom.url
    const drSalt = this.serviceLocator.config.darkroom.salt

    if (data.__instance && data.__instance.images) {
      const imageUrlBuilder = createImageUrlBuilder(drUrl, drSalt, data.__instance.images.widgets)
      const image = imageUrlBuilder && imageUrlBuilder.getImage('Logo - Light Bg')
      const url = image && image.crop('Free') && image.crop('Free').url()
      data.instanceLogoUrl = url
    }

    dropdownActions.push('datePreview')
    instance.__url = this.serviceLocator.instanceService.createUrl(instance, account)
    data.__url = instance.__url + fullUrlpath
    data.__path = fullUrlpath

    this.$el.empty().append(template(
      { data: data,
        account: account,
        instance: instance,
        allowed: this.serviceLocator.allowed,
        config: this.serviceLocator.config,
        dropdownActions: dropdownActions
      }))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  handleDuplicateToInstance (e, data, errors) {
    if (e) e.stopPropagation()
    const duplicateToInstanceView = new DuplicateToInstanceView({ serviceLocator: this.serviceLocator, data, errors, section: this.model.get('_id') })
    const m = modal(
      { title: 'Duplicating: ' + this.model.get('name'),
        className: 'modal-duplicate-layout-view wide',
        content: duplicateToInstanceView.$el,
        buttons: []
      })
    duplicateToInstanceView.on('cancel', m.close)
    duplicateToInstanceView.on('duplicate', m.close)
    duplicateToInstanceView.on('error', this.handleDuplicateToInstance.bind(this, null))
  }
}

module.exports = ListItemView
