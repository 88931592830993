const retrieveAccumulatedRow = require('../../retrieve-accumulated-row')
const cloneSimpleDeep = require('../../simple-deep-cloner')

const createAccumulatorData = (redemptionsData) => {
  const accumulatorData = []

  const redemptionsDataClone = cloneSimpleDeep(redemptionsData)

  for (const row of redemptionsDataClone) {
    if (!row) return
    const accumulatorDescription = 'Accumulative ' + row.shift().value
    const accumulatorRow = retrieveAccumulatedRow(row, 0, 0)
    accumulatorRow.unshift({ value: `${accumulatorDescription}` })
    accumulatorData.push(accumulatorRow)
  }
  return accumulatorData
}

module.exports = createAccumulatorData
