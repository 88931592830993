const ListView = require('../views/list')
const FormView = require('../views/form')
const Collection = require('chale')
const FaqModel = require('../models/faq')
const debug = require('../../../../admin/source/js/lib/debug')('faq router')
const ReorderView = require('../views/reorder')
const Model = require('merstone')
const async = require('async')
const pageSize = 50
const notify = require('../../notification/foreground')

module.exports = (serviceLocator) => {
  const router = serviceLocator.router
  const faqsCollection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])

  const paginationModel = new Model(serviceLocator, { totalItems: 0, showing: 0 })
  let currentPage = 1
  const defaultParams = { keywords: '', filter: {}, sort: [ 'order', 'asc' ] }
  let currentParams = defaultParams
  const pagination = { page: currentPage, pageSize: pageSize }
  let account

  const getFaqs = (keywords, filter, sort, pagination) => {
    serviceLocator.faqService.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return serviceLocator.logger.error('Could not load faqs', err)
      faqsCollection.reset()
      res.results.forEach(faq => faqsCollection.add(new FaqModel(serviceLocator, faq)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', faqsCollection.models.length)
    })
  }

  serviceLocator.faqService.on('update', (id, attrs) => {
    const model = faqsCollection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.faqService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getFaqs(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
  })

  router.route('faqs(/)', 'listFaqs', () => {
    account = account || serviceLocator.session.account
    debug('faq list view route triggered')
    if (!serviceLocator.allow('faq', 'read')) {
      return false
    }

    if (account) {
      getFaqs(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
    }

    serviceLocator.accountService.cachedFind('', {}, [ 'name' ], { pageSize: 1000 }, (err, res) => {
      if (err) throw err

      const listView = new ListView(serviceLocator, faqsCollection, paginationModel, res.results, account).render()
      listView.displayFilterParams(currentParams)

      listView.on('createNew', router.navigate.bind(router, 'faqs/form', { trigger: true }))

      listView.on('edit', model => {
        router.navigate('faqs/' + model + '/form', { trigger: true })
      })

      listView.on('layout', model => {
        router.navigate('faqs/' + model + '/layout', { trigger: true })
      })

      listView.on('reorder', model => {
        router.navigate('faqs/reorder', { trigger: true })
      })

      listView.on('delete', ids => {
        if (!serviceLocator.allow('faq', 'delete')) return false
        const deleteOne = (id, cb) => {
          serviceLocator.faqService.delete(id, err => {
            if (err) return cb(err)
            faqsCollection.remove(id)
          })
        }

        async.each(ids, deleteOne, err => {
          if (err) return alert(err.message)
        })
      })

      listView.on('filter', params => {
        currentParams.keywords = params.keywords
        currentParams.filter = { account }

        var pagination = { page: currentPage, pageSize: pageSize }
        currentPage = 1
        getFaqs(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
      })

      listView.on('accountSelected', newAccount => {
        account = newAccount
        currentParams.filter = { account }
        var pagination = { page: currentPage, pageSize: pageSize }
        currentPage = 1
        getFaqs(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
      })

      serviceLocator.router.render(listView, 'FAQs')
    })
  })

  router.route('faqs/reorder(/)', 'reorderFaqs', () => {
    account = account || serviceLocator.session.account
    if (account) {
      getFaqs(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
    } else {
      router.navigate('faqs/', { trigger: true })
    }

    var reorderView = new ReorderView(serviceLocator, faqsCollection).render()
    router.render(reorderView, 'Reorder FAQs')

    debug('faq list reorder view route triggered')

    reorderView.on('cancel', back)
    reorderView.on('update', (model, cb) => {
      updateModel(model.get('_id'), model, (err, saved) => {
        if (err) return
        notify('Saved', 'save')
        cb()
      })
    })

    reorderView.on('save', back)

    reorderView.setupNestable()
  })

  router.route('faqs/form(/)', 'newFaq', () => {
    if (!serviceLocator.allow('faq', 'create')) {
      return false
    }
    debug('faq create view route triggered')
    const model = new FaqModel(serviceLocator, {})
    const form = new FormView(serviceLocator, model, true).render()
    serviceLocator.router.render(form, 'New FAQ')

    form.on('back', back)

    form.on('save', (state) => {
      save(form, state, true, (err, saved) => {
        if (err) return
        serviceLocator.router.navigate('faqs/' + saved._id + '/form')
      })
    })

    form.on('saveAndClose', (state) => {
      save(form, state, true, (err, saved) => {
        if (err) return
        back()
      })
    })
  })

  router.route('faqs/:id/form(/)', 'editFaq', (id) => {
    if (!serviceLocator.allow('faq', 'update')) {
      return false
    }
    debug('faq edit view route triggered')

    serviceLocator.faqService.read(id, (err, faq) => {
      if (err || !faq) {
        router.trigger('notFound', 'Could not find an FAQ with ID')
        return
      }
      const model = new FaqModel(serviceLocator, faq)
      debug('Loading form view', model)
      const form = new FormView(serviceLocator, model, false).render()

      router.render(form, 'Edit FAQ')

      form.on('back', back)

      form.on('save', (state) => {
        save(form, state, false, (err, saved) => {
          if (err) return
          serviceLocator.router.navigate('faqs/' + saved._id + '/form')
        })
      })

      form.on('saveAndClose', (state) => {
        save(form, state, true, (err, saved) => {
          if (err) return
          back()
        })
      })
    })
  })

  const updateModel = (id, model, cb) => {
    serviceLocator.faqService.update(id, model.toJSON(), (err, faq) => {
      if (err) {
        return cb(err)
      }

      cb(null, faq)
    })
  }

  const save = (form, state, isNew, cb) => {
    const previousState = form.model.get('state') || 'Draft'
    const service = serviceLocator.faqService
    let saveFn
    let id

    if (state) form.model.set('state', state)

    if (isNew && !form.model.get('_id')) {
      saveFn = service.create.bind(service, form.model.toJSON())
    } else {
      id = form.model.get('_id')
      saveFn = service.update.bind(service, id, form.model.toJSON())
    }

    afterSave(form, saveFn, (err, data) => {
      if (err) return form.model.set('state', previousState)
      notify(state || 'Saved', 'save')
      if (typeof cb === 'function') cb(null, data)
    })
  }

  const afterSave = (form, saveFn, cb) => {
    saveFn((err, data) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.model.reset(data)
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, data)
    })
  }

  const back = () => {
    serviceLocator.router.navigate('faqs', { trigger: true })
  }
}
