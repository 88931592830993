const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Content Stack',
    description: 'Content Stack Widget'
  }
  serviceLocator.widgetFactories.get('section').register('contentStack', widget)

  done()
}

module.exports = () => ({ contentStackWidget: [ 'sectionAdmin', init ] })
