const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (className, data, undefined) {
buf.push("<div" + (jade.attr("classname", className, true, false)) + " class=\"panel\"><form><div class=\"js-errors-summary\"></div><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--headline\" data-field=\"headline\" class=\"form-row form-row--ckeditor\"><label><span class=\"form-label-text\">Headline</span><textarea name=\"headline\" rows=\"2\" class=\"control control--text control--multiline js-text-editor-testimonial-italics-only form-field\">" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div><div id=\"field--copy\" data-field=\"copy\" class=\"form-row form-row--ckeditor\"><label><span class=\"form-label-text\">Copy</span><textarea name=\"copy\" rows=\"20\" class=\"control control--text control--multiline js-text-editor-testimonial form-field\">" + (jade.escape(null == (jade_interp = data.copy) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div><div id=\"field--style\" data-field=\"style\" class=\"form-row\"><label><span class=\"form-label-text\">Style</span><select name=\"style\" class=\"control control--choice form-field\"><option value=\"Rewards\"" + (jade.attr("selected", data.style==='Rewards', true, false)) + ">Rewards</option><option value=\"Solus\"" + (jade.attr("selected", data.style==='Solus', true, false)) + ">Solus</option></select><div class=\"js-error\"></div></label><div class=\"form-row-description form-copy\"><p>Background colour to be applied to the entire widget.</p></div></div>");
var segments = ['Early Tenure', 'Engaged', 'Not Engaged', 'Medium Engaged', 'High Engaged']
buf.push("<div id=\"field--segment\" data-field=\"segment\" class=\"form-row\"><label><span class=\"form-label-text\">Segment</span><select name=\"segment\" class=\"control control--choice form-field\"><option value=\"\"" + (jade.attr("selected", !data.segment, true, false)) + ">None</option>");
// iterate segments
;(function(){
  var $$obj = segments;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var segment = $$obj[$index];

buf.push("<option" + (jade.attr("value", segment, true, false)) + (jade.attr("selected", data.segment === segment, true, false)) + ">" + (jade.escape(null == (jade_interp = segment) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var segment = $$obj[$index];

buf.push("<option" + (jade.attr("value", segment, true, false)) + (jade.attr("selected", data.segment === segment, true, false)) + ">" + (jade.escape(null == (jade_interp = segment) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label></div></div></div><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Member Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row form-row--ckeditor\"><label><span class=\"form-label-text\">Name</span><textarea name=\"name\" rows=\"2\" class=\"control control--text control--multiline js-text-editor-testimonial-italics-only form-field\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div><div id=\"field--state\" data-field=\"state\" class=\"form-row form-row--ckeditor\"><label><span class=\"form-label-text\">State</span><textarea name=\"state\" rows=\"2\" class=\"control control--text control--multiline js-text-editor-testimonial-italics-only form-field\">" + (jade.escape(null == (jade_interp = data.state) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div><div id=\"field--offerName\" data-field=\"offerName\" class=\"form-row form-row--ckeditor\"><label><span class=\"form-label-text\">Offer Name</span><textarea name=\"offerName\" rows=\"2\" class=\"control control--text control--multiline js-text-editor-testimonial form-field\">" + (jade.escape(null == (jade_interp = data.offerName) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div></div></div><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div></form></div>");}.call(this,"className" in locals_for_with?locals_for_with.className:typeof className!=="undefined"?className:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const NewsletterFormView = require('../../../../widget/views/form/newsletter-form')

module.exports = NewsletterFormView.extend({
  template
})
