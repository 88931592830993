const View = require('ventnor')
const modal = require('modal')
const compileJade = require('browjadify-compile')
const { join } = require('path')
const rowTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div" + (jade.attr("data-index", data.id, true, false)) + " class=\"panel widget-row js-widget-row\"><div class=\"row-header\"><div class=\"row-header__left\"><div class=\"row-header__title js-layout-title\">" + (jade.escape(null == (jade_interp = data.title) ? "" : jade_interp)) + "</div></div><div class=\"row-header__center\"><div class=\"label label--large js-row-drag-handle\"><i title=\"Drag row\" class=\"icon icon--hamburger\"></i></div></div><div class=\"row-header__right\"><button class=\"text-btn label label--large js-remove\"><i title=\"Remove row\" class=\"icon icon--cross\"></i></button></div></div><div class=\"js-columns grid\"></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const colTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, getWidth) {
buf.push("<div" + (jade.attr("data-index", data.id, true, false)) + (jade.cls(['grid__item',(getWidth(data.width))], [null,true])) + "><div class=\"js-widget-area\"></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"getWidth" in locals_for_with?locals_for_with.getWidth:typeof getWidth!=="undefined"?getWidth:undefined));;return buf.join("");
}
const WidgetAreaView = require('../../widget/views/widget-area')

class WidgetRow extends View {
  constructor (serviceLocator, row, layout) {
    super(serviceLocator)
    this.row = row
    this.layout = layout
  }

  setElement (el) {
    this.$el = $(el)
    this.el = this.$el[0]
    this.$el.find('.js-remove').on('click', this.handleRemove.bind(this))
    this.$el.on('update:id', this.handleUpdateId.bind(this))
  }

  render () {
    this.setElement(rowTemplate({ data: this.row }))
    this.row.cols.forEach(this.renderColumn.bind(this))
    return this
  }

  renderColumn (column) {
    const getWidth = (width) => {
      if (width === 1.5) {
        return 'one-half'
      }
      const widths = [ 'one-third', 'two-thirds', 'one-whole' ]
      return widths[width - 1]
    }
    const $tmpl = $(colTemplate({ data: column, getWidth }))
    const $el = $tmpl.find('.js-widget-area')
    const view = new WidgetAreaView({ el: $el, model: column.widgetArea, serviceLocator: this.serviceLocator })

    this.attachView(view)

    this.$el.find('.js-columns').append($tmpl)
  }

  handleRemove () {
    modal({
      content: 'Do you want to delete this row and containing widgets?',
      buttons: [
        {
          text: 'Cancel',
          event: 'cancel',
          className: 'btn',
          keyCodes: [ 27 ]
        }, {
          text: 'Delete',
          event: 'confirm',
          className: 'btn btn--error'
        }
      ]
    }).on('confirm', () => {
      this.layout.removeRow(this.row.id)
      this.remove()
    })
  }

  handleUpdateId (e, newId) {
    this.row.id = newId
    this.el.setAttribute('data-index', newId)
  }
}

module.exports = WidgetRow
