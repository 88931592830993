import FormView from './views/form'
import ItemView from './views/item'
import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    itemView: ItemView,
    name: 'Bio List',
    description: 'Display a list of bios'
  }

  serviceLocator.widgetFactories.get('email').register('bioList', widget)

  done()
}

module.exports = () => ({ bioListWidget: [ 'widget', 'sectionAdmin', init ] })
