const moment = require('moment')

const TOPX = 5

const createAggregationQuery = (options, keyField, targetCollection, targetCollectionProperty) => {
  const { account, startDate, endDate } = options || {}
  const startDateFilter = moment(startDate).toDate()
  const endDateFilter = moment(endDate).toDate()

  return [
    {
      $match: {
        accountId: account,
        type: 'redeem',
        createdDate: {
          $gte: startDateFilter,
          $lte: endDateFilter
        }
      }
    },
    { '$addFields': { [`${targetCollection}ObjectId`]: { '$toObjectId': `$${targetCollection}Id` } } },
    {
      '$lookup': {
        'from': `${targetCollection}`,
        'localField': `${targetCollection}ObjectId`,
        'foreignField': '_id',
        'as': `${targetCollection}Data`
      }
    },
    {
      $unwind: {
        path: `$${targetCollection}Data`
      }
    },
    { '$addFields': {
      [`${keyField}`]: `$${targetCollection}Data.${targetCollectionProperty}`,
      shortHeadline: `$${targetCollection}Data.shortHeadline` } },
    {
      $group: {
        _id: { [`${keyField}`]: `$${keyField}`, offerId: '$offerId', shortHeadline: '$shortHeadline' },
        'count': { '$sum': 1 }
      }
    },
    { '$addFields': { [`${keyField}`]: `$_id.${keyField}`, 'offerId': '$_id.offerId', 'shortHeadline': '$_id.shortHeadline' } },
    {
      $sort: { [`${keyField}`]: 1, count: -1 }
    },
    { $group: { _id: [ `$${keyField}` ], docs: { $push: '$$ROOT' } } },
    { $project: { _id: 0, docs: { $slice: [ '$docs', TOPX ] } } },
    { $unwind: '$docs' },
    { '$addFields': { [`${keyField}`]: `$docs.${keyField}`, 'offerId': '$docs.offerId', 'shortHeadline': '$docs.shortHeadline', count: '$docs.count' } },
    { $project: { _id: 0, count: 1, category: 1, shortHeadline: 1, offerId: 1 } }
  ]
}

module.exports = createAggregationQuery
