const notify = require('../../notification/foreground')

const createController = ({ serviceLocator, service, path, singular, Model, FormView }) => {
  serviceLocator.router.route(`${path}/:id/duplicate`, `edit${singular}`, (id) => {
    if (!serviceLocator.allow(singular, 'create')) return false

    service.read(id, (err, entity) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)
      delete entity._id
      delete entity.responseCount
      delete entity.dateCreated
      const form = new FormView(serviceLocator, new Model(serviceLocator, entity), true).render()
      serviceLocator.router.render(form, `New ${singular}`)

      form.on('back', back)

      form.on('save', () =>
        saveNew(form, (err, saved) => {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate(`${path}/` + saved._id + '/form', { trigger: true })
        }))

      form.on('saveAndClose', () => saveNew(form, err => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate(path, { trigger: true })
      }))
    })
  })

  const saveNew = (form, cb) => {
    service.create(form.model.toJSON(), (err, entity) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      cb(null, entity)
    })
  }

  const back = () => serviceLocator.router.navigate(path, { trigger: true })
}

module.exports = createController
