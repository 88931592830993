const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const InstanceService = require('./services/instance')
const createRevisionController = require('../revision/controllers/controller')

const init = (serviceLocator, done) => {
  serviceLocator.instanceService = new InstanceService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  createRevisionController(serviceLocator
    , serviceLocator.instanceService
    , 'instance'
    , 'instances/:id/revisions'
    , 'instances'
    , 'name'
  )
  done()
}

module.exports = () => ({ instanceAdmin: init })
