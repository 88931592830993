const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const modalTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (sections, undefined) {
buf.push("<form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Section</span><div class=\"form-field\"><select type=\"text\" name=\"section\" class=\"control control--choice js-section-preview-select\">");
// iterate sections
;(function(){
  var $$obj = sections;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var section = $$obj[$index];

buf.push("<option" + (jade.attr("value", section.url, true, false)) + ">" + (jade.escape((jade_interp = section.name) == null ? '' : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var section = $$obj[$index];

buf.push("<option" + (jade.attr("value", section.url, true, false)) + ">" + (jade.escape((jade_interp = section.name) == null ? '' : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></div></label><div class=\"form-row-description form-copy\"><p>The section to preview this offer in</p></div></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Preview Date</span><span class=\"datepicker\"><input type=\"text\" readonly=\"readonly\" class=\"control control--text form-field js-timepicker\"/></span></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Set a custom date to preview the offer under. By default it will preview the site as if this offer is open.</p></div></div></form>");}.call(this,"sections" in locals_for_with?locals_for_with.sections:typeof sections!=="undefined"?sections:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const TimePicker = require('anytime')
const moment = require('moment-timezone')

class SectionSelect extends View {
  constructor (serviceLocator, sections, defaultDate) {
    super(...arguments)
    this.sections = sections

    this.on('remove', () =>
      this.picker.destroy()
    )

    this.selectedDate = defaultDate
  }

  get selectedUrl () {
    return this.$el.find('.js-section-preview-select').val()
  }

  render () {
    this.$el.append(modalTemplate({
      sections: this.sections,
      format: this.serviceLocator.format
    }))

    // Instantiate a datepicker for each date/time input
    const input = this.$el.find('.js-timepicker').get(0)
    this.picker = new TimePicker({
      input,
      initialValue: this.selectedDate,
      format: this.serviceLocator.config.formats.adminDate,
      timezone: this.serviceLocator.config.timezone
    })

    this.picker.on('change', value => {
      this.selectedDate = moment(value).toDate()
    })

    this.picker.render()

    return this
  }
}

module.exports = SectionSelect
