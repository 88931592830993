// Financial year ends in June (month 6)
const monthOfFinancialYearEnd = 6

// i.e August (08) 2020 would be
// financial year 2021.

const determineFinancialYear = (actualMonth, actualYear) => {
  const intYear = parseInt(actualYear)
  if (actualMonth > monthOfFinancialYearEnd) return intYear + 1
  return intYear
}

module.exports = determineFinancialYear
