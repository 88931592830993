const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join

const singleTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, footers, headers, undefined) {
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-content\">");
if ( headers.length)
{
buf.push("<table>");
// iterate headers 
;(function(){
  var $$obj = headers ;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var header = $$obj[$index];

buf.push("<thead>");
// iterate header
;(function(){
  var $$obj = header;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  }
}).call(this);

buf.push("</thead>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var header = $$obj[$index];

buf.push("<thead>");
// iterate header
;(function(){
  var $$obj = header;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  }
}).call(this);

buf.push("</thead>");
    }

  }
}).call(this);

buf.push("<tbody>");
// iterate data
;(function(){
  var $$obj = data;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  }
}).call(this);

    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  }
}).call(this);

    }

  }
}).call(this);

buf.push("</tbody>");
// iterate footers
;(function(){
  var $$obj = footers;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var footer = $$obj[$index];

buf.push("<thead>");
// iterate footer
;(function(){
  var $$obj = footer;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  }
}).call(this);

buf.push("</thead>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var footer = $$obj[$index];

buf.push("<thead>");
// iterate footer
;(function(){
  var $$obj = footer;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var title = $$obj[$index];

buf.push("<th>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
    }

  }
}).call(this);

buf.push("</thead>");
    }

  }
}).call(this);

buf.push("</table>");
}
buf.push("</div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"footers" in locals_for_with?locals_for_with.footers:typeof footers!=="undefined"?footers:undefined,"headers" in locals_for_with?locals_for_with.headers:typeof headers!=="undefined"?headers:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const multipleTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, headers, undefined) {
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-content\">");
// iterate headers
;(function(){
  var $$obj = headers;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var title = $$obj[i];

buf.push("<table> <thead><th colspan=\"13\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th></thead><tbody>");
// iterate data[i]
;(function(){
  var $$obj = data[i];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  }
}).call(this);

    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  }
}).call(this);

    }

  }
}).call(this);

buf.push("</tbody></table><div" + (jade.attr("id", "paretoDiv" + i, true, false)) + " class=\"js-pareto\"></div>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var title = $$obj[i];

buf.push("<table> <thead><th colspan=\"13\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th></thead><tbody>");
// iterate data[i]
;(function(){
  var $$obj = data[i];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  }
}).call(this);

    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<td>" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
    }

  }
}).call(this);

    }

  }
}).call(this);

buf.push("</tbody></table><div" + (jade.attr("id", "paretoDiv" + i, true, false)) + " class=\"js-pareto\"></div>");
    }

  }
}).call(this);

buf.push("</div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"headers" in locals_for_with?locals_for_with.headers:typeof headers!=="undefined"?headers:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const topFiveOffersByCategoryTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, headers, undefined) {
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-content\">");
if ( headers.length)
{
buf.push("<table class=\"table-border\">");
// iterate headers 
;(function(){
  var $$obj = headers ;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var header = $$obj[$index];

buf.push("<thead class=\"table-header--highlighted\">");
// iterate header
;(function(){
  var $$obj = header;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var title = $$obj[$index];

if ( title.length)
{
buf.push("<th class=\"table-header--highlighted table-border-left\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
else
{
buf.push("<th class=\"table-header--highlighted table-border-right\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var title = $$obj[$index];

if ( title.length)
{
buf.push("<th class=\"table-header--highlighted table-border-left\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
else
{
buf.push("<th class=\"table-header--highlighted table-border-right\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
    }

  }
}).call(this);

buf.push("</thead>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var header = $$obj[$index];

buf.push("<thead class=\"table-header--highlighted\">");
// iterate header
;(function(){
  var $$obj = header;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var title = $$obj[$index];

if ( title.length)
{
buf.push("<th class=\"table-header--highlighted table-border-left\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
else
{
buf.push("<th class=\"table-header--highlighted table-border-right\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var title = $$obj[$index];

if ( title.length)
{
buf.push("<th class=\"table-header--highlighted table-border-left\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
else
{
buf.push("<th class=\"table-header--highlighted table-border-right\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</th>");
}
    }

  }
}).call(this);

buf.push("</thead>");
    }

  }
}).call(this);

buf.push("<tbody>");
// iterate data
;(function(){
  var $$obj = data;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td class=\"table-border\"><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td class=\"table-border\">" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td class=\"table-border\"><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td class=\"table-border\">" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  }
}).call(this);

    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var row = $$obj[$index];

buf.push("<tr></tr>");
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td class=\"table-border\"><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td class=\"table-border\">" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

if ( (item.id))
{
buf.push("<td class=\"table-border\"><a" + (jade.attr("href", '/offers/' + item.id + '/form', true, false)) + ">" + (jade.escape((jade_interp = item.value) == null ? '' : jade_interp)) + "</a></td>");
}
else
{
buf.push("<td class=\"table-border\">" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</td>");
}
    }

  }
}).call(this);

    }

  }
}).call(this);

buf.push("</tbody></table>");
}
buf.push("</div></div><!--         each footer in footers--><!--          thead--><!--            each title in footer--><!--              if title--><!--                th.table-border= title-->");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"headers" in locals_for_with?locals_for_with.headers:typeof headers!=="undefined"?headers:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class ResultsView extends View {
  constructor (data, view) {
    super()
    const { report = [], headers = [], footers = [] } = data || {}
    this.data = report
    this.headers = headers
    this.footers = footers
    this.view = view
  }

  render () {
    let template

    switch (this.view) {
      case 'multiple':
        template = multipleTemplate
        break
      case 'Top 5 Offers By Category':
        template = topFiveOffersByCategoryTemplate
        break
      default:
        template = singleTemplate
    }

    this.$el.empty().append(template({
      data: this.data,
      headers: this.headers,
      footers: this.footers
    }))
    return this
  }
}

module.exports = ResultsView
