const BaseModel = require('cf-base-model')
const ImageAreaModel = require('../../asset/models/image-area')
const config = window.config
const schemata = require('../../../service/partner/schema')()

module.exports = BaseModel.extend({
  idAttribute: '_id',
  urlRoot: config.apiUrl + '/partners',
  schemata,
  defaults () {
    return schemata.makeDefault()
  },

  initialize () {
    this.images = new ImageAreaModel(this.get('images'))
    this.set('images', this.images)
    this.images.on('add remove', () => {
      this.set('images', this.images)
    })
  }
})
