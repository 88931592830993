const createAuthedRequest = require('../../../../admin/source/js/lib/authed-request')

class UserActivityService {
  constructor(serviceLocator) {
    this.authedRequest = createAuthedRequest(serviceLocator.config.apiUrl)
  }

  search(options, page, cb) {
    const data = { ...options, page }
    this.authedRequest(
      'GET',
      '/user-activity/search',
      data,
      (err, res, body) => {
        if (err) return cb(err)
        cb(null, body)
      }
    )
  }
}

module.exports = UserActivityService
