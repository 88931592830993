const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (uniqueClass) {
buf.push("<div class=\"grid\"><div class=\"grid__item three-fifths js-selected-items\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><span class=\"label label--large\">Drag in items to populate the list.</span></div><div class=\"panel-content\"><div" + (jade.cls(['js-selected-item-list','drop-area',"" + ( uniqueClass ) + ""], [null,null,true])) + "></div></div></div></div><div class=\"grid__item two-fifths\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Content Search</h2></div><div class=\"panel-content\"><div class=\"js-item-browser\"></div></div></div></div></div>");}.call(this,"uniqueClass" in locals_for_with?locals_for_with.uniqueClass:typeof uniqueClass!=="undefined"?uniqueClass:undefined));;return buf.join("");
}
const OfferBrowser = require('../../../offer/views/browser')
const Collection = require('chale')
const ListItemModel = require('../../models/item')
const EditableItem = require('../item/editable-item')
const modal = require('modal')
const async = require('async')
const createUrlComponentsGetter = require('../../../offer/lib/get-preview-url-components')
const RichTextInstanceManager = require('../../../../../admin/source/js/lib/rich-text-editor-instance-manager')
const generateId = require('hat')

const loadItem = (view, ItemModel, toDelete) => (item, cb) => {
  let ContentModel = new ItemModel(view.serviceLocator, item.properties)

  if (item.properties) return cb(null, new ListItemModel(view.serviceLocator, item, ContentModel))

  view.serviceLocator.offerService.read(item.itemId, (err, attrs) => {
    if (err) {
      toDelete.push(item.itemId)
      return cb(null)
    }

    view.getUrlComponents(view.instance, view.account, (error, obj) => {
      if (error) {
        toDelete.push(item.itemId)
        return cb(null)
      }
      const domainUrl = view.serviceLocator.instanceService.createUrl(obj.instance, obj.account)

      const AllEditableModel = ContentModel.schema.stripUnknownProperties(ContentModel.schema.makeDefault(attrs))
      ContentModel = new ItemModel(view.serviceLocator, setExtraProperties(AllEditableModel, attrs, domainUrl))

      cb(null, new ListItemModel(view.serviceLocator, item, ContentModel))
    })
  })
}

const setExtraProperties = (properties, allProperties, domainUrl) => {
  properties.label = allProperties.keyFactsTitle
  // Map the first redemption experience CTA label to the call to action text property
  const redemptionExperiences = allProperties.redemptionExperiences
  properties.callToActionText = redemptionExperiences && redemptionExperiences.length ? redemptionExperiences[0].redemptionButtonText : null
  return properties
}

class EditableOfferFormView extends View {
  constructor (serviceLocator, model, options, EditableItemModel, EditableItemFormView) {
    super(serviceLocator, model, options, EditableItemModel, EditableItemFormView)
    this.model = model
    this.getUrlComponents = createUrlComponentsGetter(serviceLocator)
    this.limitRequired = !options.hideLimit
    this.newItemRequired = !options.hideNewItem
    this.titleRequired = !options.hideTitle
    this.options = options
    this.instance = this.options.extraProperties && this.options.extraProperties.instance
    this.account = this.options.extraProperties && this.options.extraProperties.account
    this.EditableItemModel = EditableItemModel
    this.EditableItemFormView = EditableItemFormView

    this.richTextEditorManager = new RichTextInstanceManager()
    this.on('save', this.richTextEditorManager.destroy.bind(this.richTextEditorManager))
    this.on('cancel', this.richTextEditorManager.destroy.bind(this.richTextEditorManager))

    this.uniqueId = generateId()
    this.listClassName = `.js-selected-item-list${this.uniqueId && '-' + this.uniqueId}`

    this.manualItems = new Collection(serviceLocator, [], [ 'remove' ])
    this.loadManualItems()
  }

  applyChangeToModel (e) {
    if (this.model.schema.getProperties().hasOwnProperty(e.target.name)) {
      this.model.set(e.target.name, e.target.value)
    }
  }

  handleNewItem () {
    const itemModel = new this.EditableItemModel(this.serviceLocator)
    const view = new this.EditableItemFormView(this.serviceLocator, itemModel, this.options)
    const itemModal = modal({
      title: 'New Editable Item',
      className: 'wide',
      buttons: [],
      content: view.render().$el,
      clickOutsideToClose: false
    })

    view.on('error', () => itemModal.centre())
    view.on('save', () => {
      const items = this.model.get('items') || []
      const newItem = { itemId: itemModel.id, manual: true, properties: itemModel.toJSON() }
      items.push(newItem)
      this.model.set('items', items)
      const model = new ListItemModel(this.serviceLocator, newItem, itemModel)
      this.manualItems.add(model)
      this.addItem(model)
      itemModal.close()
    })
    view.on('cancel', () => itemModal.close())
  }

  render () {
    this.$el.empty().append(template({
      data: this.model.toJSON(),
      limitRequired: this.limitRequired,
      newItemRequired: this.newItemRequired,
      titleRequired: this.titleRequired,
      uniqueClass: this.listClassName.replace('.', '')
    }))

    this.renderManualItems()
    this.renderRichTextEditor()

    // Create droppable and add on drop event
    this.$el.find(this.listClassName).sortable(
      { handle: '.js-sort-handle',
        cursor: 'move',
        addClasses: false,
        connectWith: '.js-item-drag'
      })
      .off('sortupdate')
      .on('sortupdate', (event, ui) => {
        if (!ui.item.hasClass('js-selected-item')) this.handleDrop(event, ui)
        var order = []
        this.$el.find('.js-selected-item').map(function (index, el) {
          order.push($(el).data('item').get('itemId'))
        })
        this.model.setOrder(order)
      })

    this.$el.find('.js-new-item').on('click', e => {
      e.preventDefault()
      this.handleNewItem()
    })

    this.$el.on('change', this.applyChangeToModel.bind(this))
    this.listenTo(this.manualItems, 'model:remove', this.removeItem.bind(this))

    this.loadItemBrowser()

    return this
  }

  loadItemBrowser () {
    this.serviceLocator.sectionService.find('', { instance: this.instance }, [], { pageSize: 5000 }, (err, res) => {
      if (err) return this.serviceLocator.logger.error('Cannot find instance sections', err)

      const sectionIds = res.results.map(({ _id }) => _id)
      const offerFilter = Object.assign(this.options.defaultOfferFilter || {}, {
        sections: {
          $in: sectionIds
        }
      })
      this.itemBrowser = new OfferBrowser(this.serviceLocator, offerFilter, this.uniqueId)
      this.$el.find('.js-item-browser').append(this.itemBrowser.render().$el)
    })
  }

  editOfferItem (model) {
    const view = new this.EditableItemFormView(this.serviceLocator, model.contentModel, this.options)
    const editableItemModal = modal({
      title: 'Edit Offer Item',
      className: 'wide',
      content: view.render().$el,
      clickOutsideToClose: false,
      buttons: []
    })
    view.on('save', () => {
      const items = this.model.get('items')
      const item = items.find(item => item.itemId === model.get('itemId'))

      item.properties = model.contentModel.toJSON() // Update properties based on latest content input
      this.model.set('items', items)
      this.$el.find('.js-selected-item-list').html('')
      this.renderManualItems()
      editableItemModal.close()
    })
    view.on('cancel', () => editableItemModal.close())
  }

  handleDrop (event, ui) {
    /* eslint-disable one-var */
    const itemId = ui.item.attr('data-itemId')
    const exists = this.manualItems.models.some(model => model.get('itemId') === itemId)

    if (exists) {
      ui.item.remove()
      modal(
        { title: 'Duplicate item',
          content: 'This item is already in the list',
          buttons: [ { text: 'Dismiss', className: 'btn', keyCodes: [ 27 ] } ]
        })
      return
    }

    const OfferModel = this.itemBrowser.collection.get(itemId)
    const EditableModel = new this.EditableItemModel(this.serviceLocator, {})
    const AllEditableModel = EditableModel.schema.stripUnknownProperties(EditableModel.schema.makeDefault(OfferModel.attributes))

    this.getUrlComponents(this.instance, this.account, (error, obj) => {
      if (error) alert('Unable to get Offer URL Components')

      const domainUrl = this.serviceLocator.instanceService.createUrl(obj.instance, obj.account)

      const ContentModel = new this.EditableItemModel(this.serviceLocator, setExtraProperties(AllEditableModel, OfferModel.attributes, domainUrl))

      const items = this.model.get('items') || []
      const newItem = { itemId: itemId }

      items.push(newItem)
      this.model.set('items', items)

      const manualListItemModel = new ListItemModel(this.serviceLocator, newItem, ContentModel)

      this.addItem(manualListItemModel, ui.item)
      this.manualItems.add(manualListItemModel)
    })
  }

  loadManualItems () {
    const items = this.model.get('items')
    // Keep track of any missing items
    const toDelete = []
    if (!Array.isArray(items)) return

    async.map(items, loadItem(this, this.EditableItemModel, toDelete), (err, items) => {
      if (err) return alert(err.message)

      // Remove any items that couldn't be found
      const remainingItems = this.model.get('items').filter(item => toDelete.indexOf(item.itemId) === -1)
      this.model.set('items', remainingItems)
      this.manualItems.reset(items.filter(item => !!item))
    })
  }

  renderManualItems () {
    this.manualItems.models.forEach(this.addItem.bind(this))
    this.manualItems.on('reset', this.renderManualItems.bind(this))
  }

  addItem (model, placeholder) {
    if (typeof placeholder === 'number') placeholder = null
    const view = new EditableItem(this.serviceLocator, model)
    if (placeholder) {
      placeholder.replaceWith(view.render().$el)
    } else {
      this.$el.find('.js-selected-item-list').append(view.render().$el)
    }
    view.$el.find('.js-edit-offer').on('click', () => this.editOfferItem(model))
    this.attachView(view)
  }

  removeItem (model) {
    this.model.set('items', this.model.get('items').filter(item => item.itemId !== model.get('itemId')))
    this.manualItems.remove(model.cid)
  }

  renderRichTextEditor () {
    this.$el.find('.js-text-editor').each((index, value) => {
      this.richTextEditorManager.create(value, { height: 300 })
    })
  }
}

module.exports = EditableOfferFormView
