module.exports={
  "crops": [
    { "name": "Landscape", "aspectRatio": "16:9" },
    { "name": "Alternative Landscape", "aspectRatio": "16:9" },
    { "name": "4:3", "aspectRatio": "4:3" },
    { "name": "Square", "aspectRatio": "1:1" },
    { "name": "13:7", "aspectRatio": "13:7" },
    { "name": "IOS Mobile Wallet", "aspectRatio": "2.6:1" }
  ],
  "contexts": [
    { "name": "Hero", "allowMultipleSelection": false },
    { "name": "Offer Hero", "allowMultipleSelection": true },
    { "name": "Thumbnail", "allowMultipleSelection": false },
    { "name": "IOS Mobile Wallet", "allowMultipleSelection": false },
    { "name": "Android Mobile Wallet", "allowMultipleSelection": false }
  ]
}
