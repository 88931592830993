const inputTypes = [ {
  name: 'Short text',
  input: 'text',
  requiresResponse: false,
  placeholderAvailable: true
}, {
  name: 'Email Address',
  input: 'email',
  requiresResponse: false,
  placeholderAvailable: true
}, {
  name: 'Phone Number',
  input: 'phoneNumber',
  requiresResponse: false,
  placeholderAvailable: true
}, {
  name: 'Code Word',
  input: 'codeWord',
  requiresResponse: false,
  placeholderAvailable: true
}, {
  name: 'Paragraph',
  input: 'textarea',
  requiresResponse: false,
  hasWordLimit: true,
  placeholderAvailable: true
}, {
  name: 'Address',
  input: 'address',
  requiresResponse: false,
  placeholderAvailable: true
}, {
  name: 'Drop-down',
  input: 'select',
  requiresResponse: true,
  placeholderAvailable: false
}, {
  name: 'Multiple choice',
  input: 'radio',
  requiresResponse: true,
  placeholderAvailable: false
}, {
  name: 'Checkboxes',
  input: 'checkbox',
  requiresResponse: true,
  placeholderAvailable: false
}, {
  name: 'Inline Checkbox',
  input: 'inlineCheckbox',
  requiresResponse: false,
  placeholderAvailable: false
}, {
  name: 'Date',
  input: 'datePicker',
  requiresResponse: false,
  placeholderAvailable: true
} ]

module.exports.all = () => inputTypes
module.exports.requiresResponse = () => inputTypes.filter(input => input.requiresResponse)
