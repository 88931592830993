const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed js-fixed-sidebar\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search <div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Fashion Week&quot; ] or [ Fashion -Week ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}

class ListFilterView extends View {
  constructor (...args) {
    super(...args)
    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
    this.$el.on('change', '[name=instance]', this.handleSubmit.bind(this))
  }

  trigger (...args) {
    return this.emit(...args)
  }

  updateDisplay (params) {
    this.$el.find('form [name=keywords]').val(params.keywords)
  }

  handleSubmit (e) {
    if (e) e.preventDefault()

    const params = { filter: {}, sort: [] }
    const keywords = this.$el.find('form [name=keywords]').val()

    if (keywords.length) params.keywords = keywords

    this.emit('filter', params)
  }

  handleClear (e) {
    e.preventDefault()
    this.$el.find('form [name=keywords]').val('')
    this.handleSubmit()
  }

  render () {
    this.$el.empty().append(template({ instances: this.instances }))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListFilterView
