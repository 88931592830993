const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

const dateBeforeExpiryValidator = require('validity-date-before-property')('expiryDate')

module.exports = () => {
  function requiredIfDisplayDateIsTrue (key, msg, object, callback) {
    var valid = true

    // If the user does not intend to display the date, do not validate
    if (!object.showDisplayDate) {
      return callback(null, undefined)
    }

    if (object[key] == null) {
      valid = false
    }

    return callback(null, valid ? undefined : msg + ' must be set if you intend to display it')
  }

  return schemata({
    name: 'FAQ',
    properties: {
      _id: {
        type: String
      },

      state: {
        type: String,
        options: [ 'Draft', 'Published', 'Archived', 'Trashed' ],
        defaultValue: 'Draft',
        validators: {
          all: []
        }
      },

      liveDate: {
        type: Date,
        validators: {
          draft: [ dateBeforeExpiryValidator ],
          published: [ dateBeforeExpiryValidator ],
          archived: []
        }
      },

      expiryDate: {
        type: Date,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },

      dateCreated: {
        type: Date,
        validators: {
          draft: [],
          published: [],
          archived: []
        },
        defaultValue: () => new Date()
      },

      displayDate: {
        type: Date,
        defaultValue: () => new Date(),
        validators: {
          draft: [],
          published: [ requiredIfDisplayDateIsTrue ],
          archived: []
        }
      },

      showDisplayDate: {
        type: Boolean,
        validators: {
          draft: [],
          published: [],
          archived: []
        },
        defaultValue: false
      },

      title: {
        type: String,
        validators: {
          all: [ required ]
        }
      },

      body: {
        type: String,
        validators: {
          all: []
        }
      },

      order: {
        type: Number
      },

      parent: {
        type: String
      },

      account: {
        type: String,
        validators: {
          all: [ required ]
        }
      }
    }
  })
}
