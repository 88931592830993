const required = require('validity-required')
const schemata = require('@clocklimited/schemata')
const tagSchema = require('../tag/schema')
const isInteger = require('validity-integer')
const numberInRange = require('validity-number-in-range')

module.exports = () => schemata({
  name: 'Asset',
  properties: {
    _id: {
      type: String
    },
    account: {
      type: String,
      validators: {
        image: [ required ],
        binary: [ required ]
      }
    },
    name: {
      type: String
    },
    caption: {
      type: String
    },
    binaryUri: {
      type: String
    },
    credits: {
      type: String
    },
    uploader: {
      type: String
    },
    mime: {
      type: String
    },
    tags: {
      type: schemata.Array(tagSchema())
    },
    dateCreated: {
      type: Date,
      defaultValue: () => new Date()
    },
    size: {
      type: Number,
      thresholds: {
        warning: '8mb',
        error: '15mb'
      }
    },
    type: {
      type: String,
      validators: {
        image: [ required ],
        binary: [ required ]
      }
    },
    width: {
      type: Number,
      validators: {
        image: [
          required,
          isInteger,
          numberInRange(null, 10000)
        ]
      }
    },
    height: {
      type: Number,
      validators: {
        image: [
          required,
          isInteger,
          numberInRange(null, 10000)
        ]
      }
    }
  }
})
