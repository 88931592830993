const Emitter = require('events').EventEmitter

class LayoutService extends Emitter {
  constructor (serviceLocator) {
    super()
    this.serviceLocator = serviceLocator
  }

  update (layoutDescriptor, layout, cb) {
    const data = {}

    data.layouts = layoutDescriptor.parent.layouts || {}
    data.layouts[layoutDescriptor.key] = {
      key: layoutDescriptor.key,
      type: layoutDescriptor.type,
      name: layoutDescriptor.name,
      description: layoutDescriptor.description,
      layout: layout,
      isBuiltIn: layoutDescriptor.isBuiltIn
    }

    this.serviceLocator.sectionService.partialUpdate(layoutDescriptor.parent._id, data, (err, res) => {
      if (err) return cb(err)

      const updateEvent = {
        key: layoutDescriptor.key,
        isBuiltIn: layoutDescriptor.isBuiltIn,
        parentId: layoutDescriptor.parent._id
      }

      updateEvent.layout = res.layouts[layoutDescriptor.key].layout
      updateEvent.name = layoutDescriptor.name
      updateEvent.description = layoutDescriptor.description

      this.emit('update', updateEvent)
      cb(null, res)
    })
  }

  delete (layoutDescriptor, cb) {
    const data = {}

    if (layoutDescriptor.isBuiltIn) return cb(new Error('Built-in layouts cannot me deleted'))

    data.layouts = layoutDescriptor.parent.layouts || {}
    delete data.layouts[layoutDescriptor.key]

    this.serviceLocator.sectionService.partialUpdate(layoutDescriptor.parent._id, data, (err, res) => {
      if (err) return alert(err)

      const deleteEvent = {
        key: layoutDescriptor.key,
        parentId: layoutDescriptor.parent._id
      }

      this.emit('delete', deleteEvent)
      cb(null, res)
    })
  }
}

module.exports = LayoutService
