module.exports = () => {
  const layout = {
    '0': {
      id: '0',
      cols: [ { id: '0:0', width: 3, order: 1, widgetArea: {} } ]
    }
  }
  return {
    name: 'Section Layout',
    description: 'This is the layout that the section page will use',
    isBuiltIn: true,
    type: 'section',
    key: 'section',
    layout: layout
  }
}
