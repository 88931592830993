const FormView = require('./views/form')
const ListView = require('../question/views/list')
const Model = require('./models/form-builder')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const duplicationController = require('./controllers/controller')
const FormBuilderService = require('./services/form-builder')

const initAdmin = () => ({ formBuilderAdmin: [ init ] })

const init = (serviceLocator, cb) => {
  serviceLocator.formBuilderService = new FormBuilderService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'form',
    plural: 'forms',
    path: 'form-builders',
    service: serviceLocator.formBuilderService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  duplicationController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
