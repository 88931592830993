const BaseOfferEditorFormView = require('../../../../../widget/views/form/offer-editor-form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (item) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--termsAndConditions\" data-field=\"termsAndConditions\" class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Terms and Conditions</span><textarea name=\"termsAndConditions\" rows=\"20\" autofocus=\"autofocus\" class=\"control control--text control--multiline js-text-editor form-field\">" + (jade.escape(null == (jade_interp = item.termsAndConditions) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"item" in locals_for_with?locals_for_with.item:typeof item!=="undefined"?item:undefined));;return buf.join("");
}
const imageConfig = require('../../image-config.json')

class FormView extends BaseOfferEditorFormView {
  constructor (serviceLocator, model, options) {
    super(serviceLocator, model)
    this.template = template
    this.imageConfig = imageConfig
    this.account = options.extraProperties && options.extraProperties.account
  }
}

module.exports = FormView
