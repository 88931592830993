const createController = require('./controllers/controller')
const FaqService = require('./services/faq')

const init = (serviceLocator, done) => {
  serviceLocator.faqService = new FaqService(serviceLocator)
  createController(serviceLocator)

  done()
}

module.exports = () => ({ faqAdmin: init })
