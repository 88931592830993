const retrieveIncrementalRow = require('./incremental-data-retriever')
const cloneSimpleDeep = require('../../simple-deep-cloner')

const createIncrementalData = (row) => {
  const clonedRow = cloneSimpleDeep(row)
  clonedRow.shift()
  const accumulatorDescription = 'Monthly Incremental Members Engaged'

  const accumulatorRow = retrieveIncrementalRow(clonedRow, 0, [])
  accumulatorRow.unshift({ value: `${accumulatorDescription}` })
  return accumulatorRow
}

module.exports = createIncrementalData
