const View = require('ventnor')

class YearSelect extends View {
  constructor (serviceLocator) {
    super(...arguments)
    this.$el = $('<select multiple />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Select categories')
    this.years = []
    this.on('remove', () => this.el.selectize.destroy())
    this.serviceLocator = serviceLocator
  }

  loadYears () {
    return this
  }

  onChange () {
    const items = this.el.selectize.items
    this.emit('change', items)
  }

  onRemove (value) {
    this.el.selectize.addOption({ value: value, text: value })
    this.el.selectize.refreshOptions()
    this.onChange()
  }

  onAdd (value) {
    this.onChange()
  }

  addSelectizeHandlers () {
    this.el.selectize.on('item_add', this.onAdd.bind(this))
    this.el.selectize.on('item_remove', this.onRemove.bind(this))
  }

  addOptions (years) {
    this.el.selectize.clearOptions()
    years.forEach(year => {
      this.el.selectize.addOption({ value: year.value, text: year.text })
    })
  }

  initializeSelectize () {
    this.addSelectizeHandlers()
  }

  load (accountId) {
    this.serviceLocator.accountService.read(accountId, (err, res, body) => {
      if (err) return this.serviceLocator.logger.error('Error loading years', err)
      this.addOptions(res.categories.map(year => {
        return ({ value: year, text: year })
      }))
    })
  }

  render () {
    setTimeout(() => {
      this.$el.selectize(
        { delimiter: ',',
          persist: false,
          create: false,
          sortField: 'asc',
          onInitialize: this.initializeSelectize.bind(this)
        })
    }, 100)
    return this
  }
}

module.exports = YearSelect
