const extend = require('lodash.assign')
const clone = require('lodash.clonedeep')
const isEqual = require('lodash.isequal')
const debug = require('./debug')('base collection')
const sortDirectionTable = { 'asc': 1, 'desc': -1 }

function parse (response) {
  var schemata = this.model.prototype.schemata
  if (response.results) {
    this.totalItems = response.totalItems
    return response.results.map(function (item) {
      return schemata.cast(item)
    })
  }
  return response.map(function (item) {
    return schemata.cast(item)
  })
}

module.exports = window.Backbone.Collection.extend(
  { parse: parse,

  // Pagination
    pageSize: 50,
    currentPage: 1,
    totalItems: 0,

    // Sort properties
    sortProperty: 'dateCreated',
    sortDirection: 'desc',
    currentFilter: {},
    defaultFilter: {},
    currentParams: {},

    /*
     * Comparator function used by
     * Backbone.Collection.prototype.sort()
     * Returns:
     *    0 - If a and b are of 'equal' order
     *   -1 - If a should come before b
     *   +1 - If b sould come before a
     */
    comparator: function (a, b) {
      if (!this.sortProperty || !this.sortDirection) {
        throw new Error('sortDirection and sortProperty must be defined on the collection')
      }
      const aProp = a.get(this.sortProperty)
      const bProp = b.get(this.sortProperty)
      const d = sortDirectionTable[this.sortDirection]
      if (isEqual(aProp, bProp)) return 0
      return aProp > bProp ? d * 1 : d * -1
    },

    /*
     * Apply a filter in the form
     * { sort: [...], filter: {...} }
     * to the collection.
     */
    applyFilter: function (params, keep) {
      /* eslint complexity: [ 2, 9 ] */

      if (!params && keep) {
        params = extend({}, { filter: this.defaultFilter }, this.currentParams)
      } else if (!params) {
        params = { filter: this.defaultFilter }
      }

      // If not keeping the current set, reset pagination
      if (!keep) this.currentPage = 1

      // Get sort properties from query
      if (params.sort && params.sort.length) {
        this.sortProperty = params.sort[0]
        this.sortDirection = params.sort[1]
      } else {
        params.sort = [ this.sortProperty, this.sortDirection ]
      }

      // If no filters came in, set them to the defaults
      if (!params.filter || Object.keys(params.filter).length < 1) {
        params.filter = this.defaultFilter
      } else {
        this.currentFilter = params.filter
      }

      // Deal with pagination
      params.pagination =
      { pageSize: this.pageSize,
        page: this.currentPage
      }

      this.currentParams = clone(params)

      // Write the params to JSON
      Object.keys(params).forEach(function (key) {
        params[key] = JSON.stringify(params[key])
      })

      debug('Sending GET with params', params)
      this.fetch(
        { update: true,
          data: params,
          remove: !keep,
          success: function () {
            this.sort({ silent: true })
            this.trigger('filter')
          }.bind(this)
        })
    },

    loadMore: function () {
      this.currentPage++
      this.applyFilter(null, true)
    },

    /*
     * Reset the collection and load in the
     * contents, applying the default filter.
     */
    load: function () {
      this.reset([])
      this.applyFilter({ filter: this.defaultFilter })
    },

    /*
     * Retrieves a model by id, ensuring (if it
     * exists on the backend) that it is held
     * within the collection.
     * `cb` is expected to be: `function (err, model) {...}`
     */
    retrieve: function (id, cb) {
      var model
      model = this.get(id)
      if (model) return cb(null, model)

      function onSuccess () {
        // TODO: to ensure widget areas get rendered on refresh. Risk of Cyclic pain?
        model.initialize()
        return cb(null, model)
      }

      function onError () {
        this.remove(model)
        return cb(new Error('An item with id "' + id + '" could not be retrieved'))
      }

      this.add({ _id: id })
      model = this.get(id)
      model.fetch(
        { success: onSuccess,
          error: onError.bind(this)
        })
    }
  })
