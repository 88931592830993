const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search <div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Fashion Week&quot; ] or [ Fashion -Week ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Sort By</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"desc\">Newest First</option><option value=\"asc\">Oldest First</option></select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}

class ListFilterView extends View {
  constructor () {
    super(...arguments)
    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))

    // Compatibility with backbone admin
    this.trigger = super.emit
  }

  updateDisplay (params) {
    this.$el.find('form [name=keywords]').val(params.keywords)

    if (Array.isArray(params.sort)) {
      this.$el.find('[name=sort] option[value=' + params.sort[1] + ']').attr('selected', true)
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    const params = { filter: {}, sort: [] }

    params.sort[0] = 'dateCreated'
    params.sort[1] = this.$el.find('form [name=sort]').val()

    let keywords = this.$el.find('form [name=keywords]').val().split(/\W+/)
    keywords = keywords.filter(keyword => !!keyword)
    if (keywords.length) {
      params.keywords = keywords
    }

    this.trigger('filter', params)
  }

  handleClear (e) {
    e.preventDefault()
    this.$el.find('form [name=keywords]').val('')
    this.$el.find('form [name=sort]').val('-dateCreated')
    this.handleSubmit()
  }

  render () {
    this.$el.empty().append(template({}))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListFilterView
