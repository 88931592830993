const Model = require('merstone')
const Collection = require('chale')
const ResponseModel = require('./response')
const createSchema = require('../../../service/form-builder/question-schema')
const hat = require('hat')

class QuestionModel extends Model {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)

    Model.apply(this, arguments)
    this.responses = new Collection()

    this.schema = createSchema()
    this.attributes = this.schema.cast(this.schema.makeDefault(attributes || {}))

    this.id = hat()

    this.responses.on('add', () => {
      this.set('responses', this.responses.toJSON())
    })

    this.responses.on('model:change', () => {
      this.set('responses', this.responses.toJSON())
    })

    this.responses.on('remove', () => {
      this.set('responses', this.responses.toJSON())
    })

    if (!this.has('responses')) this.set('responses', [])

    this.get('responses').forEach(response => {
      this.responses.add(new ResponseModel({}, response))
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }

  has (key) {
    return !!this.get(key)
  }
}

module.exports = QuestionModel
