const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const validateIfPropertySet = require('validity-validate-if-property-set')

module.exports = () =>
  schemata({
    name: 'Email',
    properties: {
      _id: {
        type: String
      },
      name: {
        type: String,
        validators: [ required ]
      },
      subject: {
        type: String,
        validators: [ required ]
      },
      previewText: {
        type: String
      },
      body: {
        type: Object,
        defaultValue: () => ({
          '0': {
            id: '0',
            cols: [ { id: '3', width: 3, order: 1, widgetArea: {} } ]
          }
        })
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },
      dateUpdated: {
        type: Date,
        defaultValue: () => new Date()
      },
      state: {
        type: String,
        options: [ 'Draft', 'Published' ],
        defaultValue: 'Draft',
        validators: []
      },
      publishedVersion: {
        type: Number,
        defaultValue: 0
      },
      lastPublished: {
        type: Date
      },
      account: {
        type: String,
        validators: [ required ]
      },
      instance: {
        type: String,
        validators: [ validateIfPropertySet('account', required) ]
      },
      duplicatedFromEmail: {
        type: String
      }
    }
  })
