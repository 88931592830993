const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, name) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\"></div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li><li><a class=\"js-duplicate\">Duplicate</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\"><div class=\"grid\"><div class=\"grid__item\"><dl>");
if ( model.get('segment'))
{
buf.push("<dt>Segment:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('segment')) ? "" : jade_interp)) + "</dd>");
}
buf.push("</dl></div></div><div class=\"grid\"><div class=\"grid__item one-third\">");
var imgUrl = model.getPreviewImageUrl()
if ( imgUrl)
{
buf.push("<img" + (jade.attr("src", imgUrl, true, false)) + "/>");
}
buf.push("</div><div class=\"grid__item two-thirds\"><dl>");
if ( model.get('title'))
{
buf.push("<dt>Title:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('title')) ? "" : jade_interp)) + "</dd>");
}
if ( model.get('callToActionText') )
{
buf.push("<dt>Call to Action Text:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('callToActionText')) ? "" : jade_interp)) + "</dd>");
}
if ( model.get('callToActionLink'))
{
buf.push("<dt>Call to Action Link:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('callToActionLink')) ? "" : jade_interp)) + "</dd>");
}
buf.push("</dl></div></div></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const NewsletterItemView = require('../../../../widget/views/item/newsletter-item')

module.exports = NewsletterItemView.extend({
  template
})
