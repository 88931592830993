const FormView = require('../views/form')
const Model = require('../models/account')
const notify = require('../../notification/foreground')

const createController = serviceLocator => {
  // Edit
  serviceLocator.router.route('accounts/:id/form', 'editAccount', id => {
    if (!serviceLocator.allow('account', 'update')) return false

    serviceLocator.accountService.read(id, (err, account) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const form = new FormView(serviceLocator, new Model(serviceLocator, account), false).render()
      serviceLocator.router.render(form, 'Edit Account')

      form.on('back', back)

      form.on('save', () => {
        saveExisting(id, form, (err) => {
          if (err) return
          notify('Saved', 'save')
        })
      })

      form.on('saveAndClose', () => {
        saveExisting(id, form, (err) => {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate('accounts', { trigger: true })
        })
      })
    })
  })

  // Create
  serviceLocator.router.route('accounts/form', 'createAccount', () => {
    if (!serviceLocator.allow('account', 'create')) return false

    const model = new Model(serviceLocator)

    model.set(model.schema.makeDefault())

    const form = new FormView(serviceLocator, model, true).render()

    serviceLocator.router.render(form, 'New Account')

    form.on('back', back)

    form.on('save', () => {
      saveNew(form, (err, saved) => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('accounts/' + saved._id + '/form', { trigger: true })
      })
    })

    form.on('saveAndClose', () => {
      saveNew(form, (err) => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('accounts', { trigger: true })
      })
    })
  })

  const saveExisting = (id, form, cb) => {
    serviceLocator.accountService.update(id, form.model.toJSON(), (err, account) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, account)
    })
  }

  const saveNew = (form, cb) => {
    serviceLocator.accountService.create(form.model.toJSON(), (err, account) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      cb(null, account)
    })
  }

  const back = () => {
    serviceLocator.router.navigate('accounts', { trigger: true })
  }
}

module.exports = createController
