const display = {
  toolbarGroups:
    [
      { name: 'basicstyles', groups: [ 'basicstyles' ] },
      { name: 'cleanup', groups: [ 'cleanup' ] }
    ],
  removeButtons: 'Bold,Underline,Strike,Subscript,-,CopyFormatting',
  height: 60,
  forcePasteAsPlainText: true
}

const testimonial = {
  ...display,
  removeButtons: 'Underline,Strike,Subscript,-,CopyFormatting,RemoveFormat'
}

module.exports = { display, testimonial }
