const schemata = require('@clocklimited/schemata')

module.exports = function () {
  return schemata({
    name: 'Redemption Experience',
    properties: {
      redemptionButtonText:
      { type: String,
        defaultValue: 'Redeem'
      },

      redemptionInstructions:
      { type: String
      },

      redemptionUsesCode:
      { type: Boolean
      },

      redemptionSecondaryCta:
      { type: String
      },

      redemptionType:
      { type: String
      },

      redemptionTypeProperties:
      { type: Object,
        defaultValue: () => ({})
      }
    }
  })
}
