const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('article').register('articleLayoutImages',
    {
      model: require('./models/model'),
      name: 'Article Images',
      itemView: require('./views/item'),
      description: 'Placeholder for article images'
    })

  done()
}

module.exports = () => ({ articleLayoutImagesWidget: [ 'widget', 'sectionAdmin', init ] })
