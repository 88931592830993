import ItemView from '../../widget/views/item/edit-base'

import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    model: Model,
    itemView: ItemView,
    editView: require('./views/form'),
    name: 'Subscriber Benefits',
    description: 'Subscriber Benefits'
  }

  serviceLocator.widgetFactories
    .get('section')
    .register('subscriberBenefits', widget)

  done()
}

module.exports = () => ({ subscriberBenefits: [ 'sectionAdmin', init ] })
