const View = require('ventnor')

class CategorySelectView extends View {
  constructor (serviceLocator, value) {
    super(serviceLocator, value)
    this.$el = $('<select/>')
    this.$el.attr('name', 'category')
    this.$el.addClass('control control--choice')
    this.value = value
    this.$el.on('change', () => this.emit('change', this.$el.val()))
    this.notSetLabel = '-- Select a category --'
  }

  populate (accountId) {
    this.$el.empty()
    this.$el.append($('<option/>', { text: 'All',
      value: '',
      selected: this.value === ''
    }))
    this.serviceLocator.accountService.find('', { _id: accountId }, [ 'name' ], {}, (err, res) => {
      if (err) return this.serviceLocator.logger.error(err.message, err)
      const categories = res.results[0].categories
      categories.forEach(category => {
        this.$el.append($('<option/>', { text: category,
          value: category
        }))
      })
    })
    return this
  }

  off () {}

  render () {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
    return this
  }
}

module.exports = CategorySelectView
