const schemata = require('@clocklimited/schemata')
const assetSchemata = require('../../../service/asset/schema')()
const baseSchema = require('../../../service/widget/model/base-widget')()

module.exports = () =>
  schemata({
    name: 'Image',
    properties: {
      selectedContexts: {
        type: Array,
      },
      ...baseSchema.getProperties(),
      ...assetSchemata.getProperties(),
    },
  })
