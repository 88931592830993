const schemata = require('./schema')()
const validateDelegate = require('../../../../../../admin/source/js/lib/validate-delegate')()

module.exports = Backbone.Model.extend(
  { schemata: schemata,
    defaults: function () {
      return schemata.makeDefault({ type: 'offerLayoutHeader' })
    },
    validate: validateDelegate
  })
