const ListView = require('../views/list')
const FormView = require('../views/form')
const RedirectCollection = require('../collections/redirect')
const RedirectModel = require('../models/redirect')
const debug = require('../../../../admin/source/js/lib/debug')('redirect')

module.exports = serviceLocator => {
  const router = serviceLocator.router
  const collection = new RedirectCollection()
  const displayName = { singular: 'Redirect', plural: 'Redirects' }

  router.route('redirects(/)', 'listRedirects', () => {
    debug('redirect url list view route triggered')
    if (!serviceLocator.allow('redirect', 'read')) {
      return false
    }

    var listView = new ListView({
      collection,
      serviceLocator,
      displayName
    })

    listView.on('new'
      , router.navigate.bind(router, 'redirects/form', { trigger: true }))

    listView.on('edit', model => {
      router.navigate('redirects/' + model.id + '/form', { trigger: true })
    })

    router.render(listView, displayName.plural)
    collection.load()
  })

  const bindSaveAndCancel = (view) => {
    view.on('save cancel', () => {
      router.navigate('redirects', { trigger: true })
    })
    return view
  }

  router.route('redirects/form(/)', 'newRedirect', () => {
    if (!serviceLocator.allow('redirect', 'create')) {
      return false
    }
    debug('redirect create view route triggered')
    const model = new RedirectModel({}, { collection: collection })
    const view = new FormView({
      model,
      serviceLocator,
      title: displayName.singular
    })

    router.render(bindSaveAndCancel(view), 'New ' + displayName.singular)
  })

  router.route('redirects/:id/form(/)', 'editRedirect', id => {
    if (!serviceLocator.allow('redirect', 'update')) {
      return false
    }
    debug('redirect edit view route triggered')

    collection.retrieve(id, (err, model) => {
      if (err) {
        router.trigger('notFound', err.message)
        return
      }
      debug('Loading form view', model)
      var view = new FormView({
        model,
        serviceLocator,
        title: displayName.singular
      })

      router.render(bindSaveAndCancel(view), 'Edit ' + displayName.singular)
    })
  })
}
