const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (Object, allowed, data, undefined) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('role', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('role', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/roles/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Grants:</dt><dd>");
var i = Object.keys(data.grants).length
// iterate data.grants
;(function(){
  var $$obj = data.grants;
  if ('number' == typeof $$obj.length) {

    for (var grant = 0, $$l = $$obj.length; grant < $$l; grant++) {
      var actions = $$obj[grant];

buf.push("<span>" + (jade.escape((jade_interp = grant) == null ? '' : jade_interp)) + " [" + (jade.escape((jade_interp = actions) == null ? '' : jade_interp)) + "]</span>");
if ( i-- > 1)
{
buf.push(", ");
}
    }

  } else {
    var $$l = 0;
    for (var grant in $$obj) {
      $$l++;      var actions = $$obj[grant];

buf.push("<span>" + (jade.escape((jade_interp = grant) == null ? '' : jade_interp)) + " [" + (jade.escape((jade_interp = actions) == null ? '' : jade_interp)) + "]</span>");
if ( i-- > 1)
{
buf.push(", ");
}
    }

  }
}).call(this);

buf.push("</dd></dl></div></div>");}.call(this,"Object" in locals_for_with?locals_for_with.Object:typeof Object!=="undefined"?Object:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  get template () {
    return template
  }
}

module.exports = ListItemView
