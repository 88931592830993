const CrudService = require('../../../../admin/source/js/lib/crud-service')
const determineCompleteUrl = require('../lib/complete-url-determiner')

class InstanceService extends CrudService {
  get name () {
    return 'InstanceService'
  }

  get urlRoot () {
    return '/instances'
  }

  getUrl (instance, cb) {
    this.serviceLocator.accountService.read(instance.account, (err, account) => {
      if (err) return cb(err)
      cb(null, this.createUrl(instance, account))
    })
  }

  createUrl (instance, account) {
    return determineCompleteUrl(this.serviceLocator, instance, account)
  }
}

module.exports = InstanceService
