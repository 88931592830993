const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, displayName) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\">");
if ( allowed('redirect', 'delete'))
{
buf.push("<div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-delete\">Delete Selected</button></div>");
}
if ( allowed('redirect', 'create'))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New " + (jade.escape((jade_interp = displayName.singular) == null ? '' : jade_interp)) + "</button>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = displayName.plural) ? "" : jade_interp)) + "</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"displayName" in locals_for_with?locals_for_with.displayName:typeof displayName!=="undefined"?displayName:undefined));;return buf.join("");
}
const ListItemView = require('./list-item')
const ListFilterView = require('./list-filter')
const GenericListView = require('../../../../admin/source/js/lib/generic/list-view')
const extend = require('lodash.assign')
const config = window.config

module.exports = GenericListView.extend({
  events: extend(
    {},
    GenericListView.prototype.events,
    {
      'click .js-more': 'loadMore',
      'click .js-edit-order': 'handleEditOrder'
    }),

  template,

  initialize () {
    GenericListView.prototype.initialize.call(this)
    this.renderFilters()
    this.listenTo(this.collection, 'filter', this.maintainOrder)
    this.listenTo(this.collection, 'filter', () => {
      this.trigger('clearSelection')
    })
  },

  renderFilters () {
    this.filters = new ListFilterView()
    this.filters.on('filter', this.applyFilters, this)
    this.$('.js-filters').append(this.filters.$el)
  },

  applyFilters (params) {
    this.collection.applyFilter(params)
  },

  handleEditOrder () {
    this.trigger('reorder')
  },

  createListItemView (model) {
    return new ListItemView(
      { model: model,
        serviceLocator: this.options.serviceLocator
      })
  },

  loadMore () {
    this.collection.loadMore()
  },
  render () {
    this.$el.empty().append(this.template({
      config,
      displayName: this.options.displayName,
      allowed: this.options.serviceLocator.allowed
    }))
    return this
  }
})
