const BaseModel = require('cf-base-model')
const schemata = require('../../../../components/service/email/schema')()
const Layout = require('../../widget/models/layout')
const debug = require('../../../../admin/source/js/lib/debug')('email model')

const getJson = (item) => {
  if (typeof item.toJSON === 'function') {
    return item.toJSON()
  } else {
    return item
  }
}

module.exports = BaseModel.extend({
  idAttribute: '_id',

  schemata,

  defaults () {
    return schemata.makeDefault()
  },

  toJSON () {
    var json = BaseModel.prototype.toJSON.call(this)
    json.body = this.body.toJSON()
    return json
  },

  initialize () {
    this.body = new Layout(getJson(this.get('body')), {
      abstractWidgetFactory: window.serviceLocator.widgetFactories.get('email')
    })

    this.listenTo(this.body, 'addRow change remove removeRow', () => {
      debug('set body', this.body)
      this.set('body', this.body.toJSON())
    })
  }
})
