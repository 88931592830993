const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, name, undefined) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\"></div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li><li><a class=\"js-duplicate\">Duplicate</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\"><div class=\"grid\"><div class=\"grid__item\"><dl>");
if ( model.get('heading'))
{
buf.push("<dt>Heading:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('heading')) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Center Align Text?:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('leftAlignText') ? 'Yes' : 'No') ? "" : jade_interp)) + "</dd><dt>Background Colour:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('backgroundColour')) ? "" : jade_interp)) + "</dd>");
if ( model.get('segment'))
{
buf.push("<dt>Segment:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('segment')) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>&nbsp;</dt><dt>Items:</dt></dl></div>");
// iterate model.get('bios') 
;(function(){
  var $$obj = model.get('bios') ;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<div class=\"grid__item one-third\"><dl><dt>Heading:</dt><dl>" + (jade.escape(null == (jade_interp = item.heading) ? "" : jade_interp)) + "</dl></dl></div><div class=\"grid__item one-third\"><dl><dt>Tagline:</dt><dd>" + (jade.escape(null == (jade_interp = item.tagline) ? "" : jade_interp)) + "</dd></dl></div><div class=\"grid__item one-third\"><dl>       <dt>Image Position:</dt><dd>" + (jade.escape(null == (jade_interp = item.imagePosition) ? "" : jade_interp)) + "</dd></dl></div>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<div class=\"grid__item one-third\"><dl><dt>Heading:</dt><dl>" + (jade.escape(null == (jade_interp = item.heading) ? "" : jade_interp)) + "</dl></dl></div><div class=\"grid__item one-third\"><dl><dt>Tagline:</dt><dd>" + (jade.escape(null == (jade_interp = item.tagline) ? "" : jade_interp)) + "</dd></dl></div><div class=\"grid__item one-third\"><dl>       <dt>Image Position:</dt><dd>" + (jade.escape(null == (jade_interp = item.imagePosition) ? "" : jade_interp)) + "</dd></dl></div>");
    }

  }
}).call(this);

buf.push("</div></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const DuplicateBaseItemView = require('../../../../widget/views/item/duplicatable-base')

module.exports = DuplicateBaseItemView.extend({ template })
