const init = (serviceLocator, done) => {
  const titleWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Title',
    itemView: require('./views/item'),
    description: 'Title with subtitle'
  }

  serviceLocator.widgetFactories.get('section').register('title', titleWidget)

  done()
}

module.exports = () => ({ titleWidget: [ 'widget', 'sectionAdmin', init ] })
