const createSignedApiUrl = require('../../../../admin/source/js/lib/create-signed-api-url')

module.exports = serviceLocator => {
  $(document).on('click', '.js-print-offer-details', e => {
    var articleId = $(e.currentTarget).data('id')
    window.location = createSignedApiUrl('/offer/print/' + articleId,
      window.localStorage.apiKey,
      window.localStorage.apiId,
      serviceLocator.config.apiUrl
    )
  })
}
