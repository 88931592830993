const init = (serviceLocator, done) => {
  const textWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Hero Image with Text',
    itemView: require('./views/item'),
    description: 'Hero Image with Text'
  }

  serviceLocator.widgetFactories.get('section').register('heroWithText', textWidget)

  done()
}

module.exports = () => ({ heroWithTextWidget: [ 'widget', 'offerAdmin', init ] })
