module.exports=[
  "Available",
  "Closed",
  "Coming soon",
  "Ending soon",
  "Last chance",
  "Limited stock",
  "Limited time",
  "New",
  "Selling fast",
  "Sold out"
]
