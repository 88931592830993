const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (redemptionExperienceIndex) {
buf.push("<div class=\"notification notification--notice\">Users must <strong>download a file</strong> to redeem this offer.</div><div" + (jade.attr("id", 'field--redemptionDownload' + redemptionExperienceIndex, true, false)) + (jade.attr("data-field", 'redemptionDownload' + redemptionExperienceIndex, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">File to Download<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-asset-preview\"></div></label><div class=\"js-error\"></div></div><div class=\"form-row form-row-actions\"><a class=\"btn btn--notice js-button-set-file\"><i class=\"icon icon--create\"></i> Change File</a></div>");}.call(this,"redemptionExperienceIndex" in locals_for_with?locals_for_with.redemptionExperienceIndex:typeof redemptionExperienceIndex!=="undefined"?redemptionExperienceIndex:undefined));;return buf.join("");
}
const AssetCollection = require('../../../asset/collections/asset')
const AssetPickerView = require('../../../asset/views/picker')
const modal = require('modal')
const createDarkroomUrlBuilder = require('darkroom-url-builder')

class FormView extends View {
  constructor (serviceLocator, model, redemptionExperienceIndex) {
    super(serviceLocator)
    this.serviceLocator = serviceLocator
    this.model = model
    this.redemptionExperienceIndex = redemptionExperienceIndex
    this.$el.on('change', this.applyChangeToModel.bind(this))
    this.darkroomUrl = this.serviceLocator.config.darkroom.url
    this.darkroomSalt = this.serviceLocator.config.darkroom.salt

    this.$el.on('click', '.js-button-set-file', this.selectAsset.bind(this))
    this.listenTo(this.model, 'change:file', this.updateAssetPreview.bind(this))
  }

  applyChangeToModel (e) {
    if (this.model.schema.getProperties().hasOwnProperty(e.target.name)) {
      this.model.set(e.target.name, e.target.value)
    }
  }

  selectAsset () {
    const assets = new AssetCollection()
    const assetPicker = new AssetPickerView({
      collection: assets,
      type: 'binary',
      serviceLocator: this.serviceLocator
    })

    assets.getByType('binary')
    modal({
      title: 'Choose a file',
      className: 'modal-asset-view wide',
      content: assetPicker.$el,
      buttons: [
        {
          text: 'Cancel',
          event: 'Cancel',
          keyCodes: [ 27 ],
          className: 'btn'
        },
        {
          text: 'Select',
          event: 'add',
          className: 'btn btn--action',
          keyCodes: [ 13 ]
        }
      ]
    })
      .on('add', this.updateAsset.bind(this, assetPicker.selectedCollection))
      .on('close', assetPicker.remove.bind(assetPicker))
  }

  updateAsset (collection) {
    if (collection.length) {
      return this.model.set({ file: collection.models[0].toJSON() })
    }
    this.model.set({ file: null })
  }

  updateAssetPreview () {
    this.$el.find('.js-asset-preview').empty()
    const file = this.model.get('file')
    if (!file) return

    const urlBuilder = createDarkroomUrlBuilder(this.darkroomUrl, this.darkroomSalt)
    const link = urlBuilder().resource(file.binaryUri).filename(file.name).url()

    const name = `${file.name}`
    const filenameSplit = file.name.split('.')
    const extension = filenameSplit[filenameSplit.length - 1]
    const icon = `<i class='file-icon file-icon--${extension}'>${extension}</i>`

    this.$el.find('.js-asset-preview')
      .append(`<a class='button-file-type' target='_blank' href=${link}>${icon}${name}</a>`)
  }

  render () {
    this.$el.append(template({
      data: this.model.toJSON(),
      redemptionExperienceIndex: this.redemptionExperienceIndex
    }))
    this.updateAssetPreview()
    return this
  }
}

module.exports = FormView
