const Model = require('./models/voucher')
const FormView = require('./views/form')

const init = (serviceLocator, done) => {
  serviceLocator.redemptionTypeFactory.register('voucher', {
    name: 'Voucher',
    FormView,
    Model,
    allowedAsSecondary: true,
    supportsUniqueCodes: true,
    supportsRedemptionReset: true,
    hasInstructions: true,
    hasSecondaryCtaText: true,
    supportsPreRedemptionQuestions: true,
    supportsStockLimits: true
  })
  done()
}

module.exports = () => ({ voucherRedemptionType: [ 'offerAdmin', init ] })
