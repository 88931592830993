const BaseModel = require('../../members-hero/models/model')
const baseSchema = require('../../members-hero/models/schema')()
const schemata = require('@clocklimited/schemata')

const required = require('validity-required')

const schema = schemata({
  name: 'Hero Image With Text',
  properties: Object.assign({}, baseSchema.getProperties(), {
    title: {
      type: String,
      validators: [required],
    },
    subtitle: {
      type: String,
    },
  }),
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'heroImageWithText' })
  },
})
