const schemata = require('@clocklimited/schemata')

module.exports = () =>
  schemata({
    name: 'Article Layout Header',
    properties: {
      showArticleProperties: {
        type: Array,
        defaultValue: function () {
          return [ 'category', 'attributes' ]
        }
      }
    }
  })
