const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('redirect', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('redirect', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", 'redirects/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.path) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.path) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd><dt>Redirect To:</dt><dd>" + (jade.escape(null == (jade_interp = data.redirectUrl) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}

module.exports = Backbone.View.extend({
  events: {
    'click .js-select': 'toggleSelect',
    'click .js-edit': 'edit'
  },

  className: '',

  initialize () {
    this.listenTo(this.model, 'select', this.select)
    this.listenTo(this.model, 'deSelect', this.deSelect)
    this.render()
  },

  attributes () {
    return { id: 'redirect-id-' + this.model.get('_id') }
  },

  edit (e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if ((e.which === 2) || e.metaKey) return
    e.preventDefault()
    this.trigger('edit')
  },

  toggleSelect (e) {
    var isChecked = $(e.target).is(':checked')
    this.model.trigger(isChecked ? 'select' : 'deSelect', this.model)
  },

  select () {
    this.$('.js-select')[0].checked = true
  },

  deSelect () {
    this.$('.js-select')[0].checked = false
  },

  render () {
    var data = this.model.toJSON()

    this.$el.empty().append(template({
      data,
      allowed: this.options.serviceLocator.allowed,
      format: this.options.serviceLocator.format
    }))
    return this
  }
})
