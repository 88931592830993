// Financial year ends in June (month 6)
const monthOfFinancialYearEnd = 6

// i.e Period 2 with a June year end
// would be August (2 + 6 = 8)

const determineActualMonth = (period) => {
  if (period > monthOfFinancialYearEnd) return period - monthOfFinancialYearEnd
  if (period <= monthOfFinancialYearEnd) return period + monthOfFinancialYearEnd
}

module.exports = determineActualMonth
