const platforms = {
  youtube: {
    name: 'YouTube Video',
    link: id => `https://www.youtube.com/watch?v=${id}`,
    embedUrl: id => `https://www.youtube.com/embed/${id}`
  },
  vimeo: {
    name: 'Vimeo',
    link: id => `https://vimeo.com/${id}`,
    embedUrl: id => `https://player.vimeo.com/video/${id}`
  },
  brightcove: {
    name: 'Brightcove',
    embedUrl: id => `//resourcesssl.newscdn.com.au/cs/video/vjs/stable/build/index.html?id=5348771529001-${id}`,
    isPlaylist: false
  }
}

module.exports = platforms
