module.exports = window.Backbone.View.extend(
  { events:
  { 'click .js-select': 'toggleSelect',
    'click .js-edit': 'edit'
  },

  className: 'grid__item one-whole',
  initialize: function () {
    this.listenTo(this.model, 'select', this.select)
    this.listenTo(this.model, 'deSelect', this.deSelect)
    this.render()
  },

  edit: function () {
    this.trigger('edit')
  },

  toggleSelect: function (e) {
    var isChecked = $(e.target).is(':checked')
    this.model.trigger(isChecked ? 'select' : 'deSelect', this.model)
  },

  select: function () {
    this.$('.js-select')[ 0 ].checked = true
  },

  deSelect: function () {
    this.$('.js-select')[ 0 ].checked = false
  },

  render: function () {
    this.$el.empty().append(this.template(
      { data: this.model.toJSON(),
        allowed: this.options.serviceLocator.allowed
      }))
    this.$('.js-tooltip-trigger').tooltip({ 'html': true })
    return this
  }
  })
