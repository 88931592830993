import FormView from './views/form'
import Model from './models/model'
import ItemView from './views/item'

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Featured Competition Winners',
    itemView: ItemView,
    description: 'A manual selection of competition winners to display'
  }
  serviceLocator.widgetFactories.get('section').register('featuredCompWinners', widget)

  done()
}

module.exports = () => ({ featuredCompWinners: [ 'widget', 'offerAdmin', init ] })
