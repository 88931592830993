const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const createUniqueValidator = require('validity-unique-property')
const customValidityMessage = (fn, message) => (key, name, object, cb) => {
  fn(key, name, object, (error, valid) => {
    cb(error, valid ? message : undefined)
  })
}

module.exports = function (findOne) {
  const uniqueCodeValidator = customValidityMessage(createUniqueValidator(findOne, { keys: [ 'resourceId' ] }),
    'This code already exists, to edit a code please use the “Edit” form')
  return schemata({
    name: 'Unique Code',
    properties: {
      _id:
        { type: String
        },
      resourceId:
        { type: String,
          validators: { all: [ required ] }
        },
      code:
        { type: String,
          validators: { all: [ required, uniqueCodeValidator ] }
        },
      instanceId:
        { type: String
        },
      userId:
        { type: String
        },
      userFirstName:
        { type: String
        },
      userLastName:
        { type: String
        },
      userEmail:
        { type: String
        },
      used:
        { type: Boolean,
          defaultValue: false
        },
      redeemedDate:
        { type: Date
        },
      dateCreated:
        { type: Date,
          defaultValue: function () { return new Date() }
        }
    }
  })
}
