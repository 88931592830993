const Model = require('merstone')
const schema = require('../schema')()

class PurchaseModel extends Model {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)
    this.schema = schema
    attributes = this.schema.makeDefault(attributes)
    this.set(attributes)

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }
}

module.exports = PurchaseModel
