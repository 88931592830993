const createController = require('./controllers/controller')

const initAdmin = () => ({ questionAdmin: [ init ] })

const init = (serviceLocator, cb) => {
  createController(serviceLocator)
  cb()
}

module.exports = initAdmin
