const BaseModel = require('cf-base-model')
const schemata = require('../../../service/redirect/schema')({}, {})

module.exports = BaseModel.extend({
  idAttribute: '_id',
  schemata,
  defaults () {
    return schemata.makeDefault()
  }
})
