const moment = require('moment')
const financialMonthDeterminer = require('../../../../../worker/tasks/year-on-year-redemption-report/lib/actual-month-determiner')

const createDataPoints = (year, i) => {
  const row = year[i + 1]
  const name = row && row[0] && row[0].value
  const points =
    row &&
    row.splice(1).map((point, i) => {
      point.month = moment.months(financialMonthDeterminer(i + 1) - 1)
      return point
    })

  return { points, name }
}

module.exports = createDataPoints
