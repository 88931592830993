const init = (serviceLocator, done) => {
  var htmlWidget =
    { editView: require('./views/form'),
      model: require('./models/model'),
      name: 'HTML',
      itemView: require('./views/item'),
      description: 'Block of HTML'
    }

  serviceLocator.widgetFactories.get('offerBody').register('html', htmlWidget)
  serviceLocator.widgetFactories.get('section').register('html', htmlWidget)
  serviceLocator.widgetFactories.get('offer').register('html', htmlWidget)

  done()
}

module.exports = () => ({ htmlWidget: [ 'widget', 'sectionAdmin', init ] })
