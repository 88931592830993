const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const required = require('validity-required')
const validateLength = require('validity-length')
const validateInteger = require('validity-integer')
const validateIfSet = require('validity-validate-if-set')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')

module.exports = () => {
  const listProperties = {
    lists: {
      type: Array,
      validators:
        { all: [ validateLength(1) ] }
    },
    header: {
      type: String
    },
    headerLink: {
      type: String
    },
    emptyMessage: {
      type: String
    },
    dedupe: {
      type: Boolean,
      defaultValue: true
    },
    // The maximum number of items the view should show.
    // null means show as many and possible
    limit:
      { type: Number,
        validators:
          { all: [ validateIfSet(validateInteger) ] },
        defaultValue: 3
      },
    loadMore: {
      type: Boolean,
      defaultValue: false
    },
    loadMoreMethod: {
      type: String,
      defaultValue: '',
      validators: { all: [ validateIfPropertyEquals('loadMore', true, required) ] }
    },
    initialPageSize: {
      type: Number,
      defaultValue: 3,
      validators: { all: [ validateIfPropertyEquals('loadMore', true, validateInteger) ] }
    },
    loadMorePageSize: {
      type: Number,
      defaultValue: 6,
      validators: { all: [ validateIfPropertyEquals('loadMore', true, validateInteger) ] }
    },
    showArticleProperties: {
      type: Array,
      defaultValue: () => [ 'attributes', 'sell' ]
    }
  }
  const widgetSchema = schemata({
    name: 'List',
    properties: Object.assign({}, baseSchema.getProperties(), listProperties)
  })

  return widgetSchema
}
