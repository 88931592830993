const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const schema = schemata({
  name: 'Title',
  properties: Object.assign({}, baseSchema, {
    title: {
      type: String,
      validators: [required],
    },
    subtitle: {
      type: String,
    },
    emphasisedWordLocation: {
      type: String,
    },
    size: {
      type: String,
    },
    leftAligned: {
      type: Boolean,
    },
    spacing: {
      type: String,
    },
    regularFontWeight: {
      type: Boolean,
    },
  }),
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'title' })
  },
  validate: validateDelegate,
})
