const init = (serviceLocator, done) => {
  const textWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Text',
    itemView: require('./views/item'),
    description: 'Rich Text Block',
    colour: 'Select Colour of Text'
  }

  serviceLocator.widgetFactories.get('offerBody').register('text', textWidget)
  serviceLocator.widgetFactories.get('section').register('text', textWidget)
  serviceLocator.widgetFactories.get('offer').register('text', textWidget)

  done()
}

module.exports = () => ({ textWidget: [ 'widget', 'offerAdmin', init ] })
