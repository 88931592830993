const BaseListItemView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListItemView {
  constructor (serviceLocator, collection, paginationModel, instances, accounts) {
    super(...arguments)
    this.instances = instances
    this.accounts = accounts
  }

  get FilterView () {
    return FilterView
  }

  get ListItemView () {
    return ListItemView
  }

  get name () {
    return { singular: 'Data Capture Page', plural: 'Data Capture Pages', resource: 'dataCapture' }
  }

  addListItem (model, index) {
    var listItem = new this.ListItemView(
      this.serviceLocator, model, index, this.collection.length, this.name, this.instances, this.accounts
    )
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.listenTo(listItem, 'duplicate', this.emit.bind(this, 'duplicate', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
    return listItem
  }
}

module.exports = ListView
