const Model = require('merstone')
const createSchema = require('../../../service/instance/navigation-item-schema')

class NavigationItemModel extends Model {
  constructor (serviceLocator, attributes) {
    super(...arguments)
    this.schema = createSchema()
    attributes = Object.assign(this.schema.makeDefault(), attributes)
    attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    super.reset(attrs)
  }
}

module.exports = NavigationItemModel
