const View = require('ventnor')

class InstanceSelectView extends View {
  constructor (serviceLocator, value, accountId) {
    super(serviceLocator, value)
    this.$el = $('<select/>')
    this.$el.attr('name', 'instance')
    this.$el.addClass('control control--choice')
    this.value = value
    this.accountId = accountId
    this.$el.on('change', () => this.emit('change', this.$el.val()))
    this.notSetLabel = '-- Select an instance --'
  }

  populate () {
    const filter = {}
    if (this.accountId) {
      filter.account = this.accountId
    }

    this.serviceLocator.instanceService.find('', filter, [ 'name' ], {}, (err, res) => {
      if (err) return this.serviceLocator.logger.error(err.message, err)
      res.results.forEach(instance => {
        this.$el.append($('<option/>'
          , { text: instance.name,
            value: instance._id,
            selected: this.value === instance._id
          }))
      })
    })
    return this
  }

  off () {
  }

  render () {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
    return this
  }
}

module.exports = InstanceSelectView
