module.exports = downloadFile

/*
 * Download File: generates a hidden <a> element with the content,
 * and clicks it so the file downloads
 */
function downloadFile (filename, content) {
  const a = document.createElement('a')
  a.setAttribute('download', filename)
  a.setAttribute('href', content)
  a.setAttribute('type', 'hidden')
  document.body.appendChild(a)
  a.click()
  a.remove()
}
