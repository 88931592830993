const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()

const createSchema = () => {
  const gallery = {
    images: {
      type: Object,
      defaultValue: () => ({ widgets: [] })
    },
    limit: {
      type: String
    }
  }

  const widgetSchemata = schemata({
    name: 'Gallery',
    properties: Object.assign({}, baseSchema.getProperties(), gallery)
  })

  return widgetSchemata
}

module.exports = createSchema
