const init = (serviceLocator, done) => {
  const galleryWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Gallery',
    itemView: require('./views/item'),
    description: 'Display a gallery'
  }

  serviceLocator.widgetFactories.get('offerBody').register('gallery', galleryWidget)

  done()
}
module.exports = () => ({ galleryWidget: [ 'widget', 'sectionAdmin', init ] })
