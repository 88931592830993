const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

module.exports = () =>
  schemata({
    name: 'Offer Redemption Type Download',
    properties: {
      file: {
        type: String,
        validators: { all: [ required ] }
      }
    }
  })
