const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const baseSchema = require('../../../../service/widget/model/base-widget')()

module.exports = () =>
  schemata({
    name: 'Biography',
    properties: {
      name: {
        type: String,
        validators: [required],
      },
      jobTitle: {
        type: String,
        validators: [required],
      },
      organisation: {
        type: String,
        validators: [required],
      },
      bio: {
        type: String,
        validators: [required],
      },
      image: {
        type: String,
        validators: [required],
      },
      ...baseSchema.getProperties(),
    },
  })
