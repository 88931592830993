const EditBaseItemView = require('./duplicatable-base')
const createImageUrlBuilder = require('cf-image-url-builder')
const async = require('async')

module.exports = EditBaseItemView.extend({
  render () {
    EditBaseItemView.prototype.render.apply(this)
    this.renderLists()
    return this
  },

  renderLists () {
    async.eachSeries(this.model.get('offers'), this.renderList.bind(this))
  },

  embellishPreviewImage (offer) {
    const images = offer.images.widgets
    const drUrl = this.options.serviceLocator.config.darkroom.url
    const drSalt = this.options.serviceLocator.config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drSalt, images)

    if (images && images.length && urlBuilder.getImage('Thumbnail') && urlBuilder.getImage('Thumbnail').crop('Square')) {
      offer.previewImageUrlSmall = urlBuilder.getImage('Thumbnail').crop('Square').constrain(150).url()
    }
    return offer
  },

  renderList (offerItem, cb) {
    if (!offerItem.manual && !offerItem.properties) {
      this.options.serviceLocator.offerService.read(offerItem.itemId, (err, offer) => {
        if (err) return cb(err)

        const embellishedOffer = this.embellishPreviewImage(offer)

        const html = this.offerItemTemplate({
          data: embellishedOffer,
          format: this.options.serviceLocator.format
        })
        this.$el.find('.js-offers-list').append(html)
        cb()
      })
    } else {
      const embellishedOffer = this.embellishPreviewImage(offerItem.properties)
      const html = this.offerItemTemplate({
        data: embellishedOffer,
        format: this.options.serviceLocator.format
      })
      this.$el.find('.js-offers-list').append(html)
      cb()
    }
  }
})
