var compileJade = require('browjadify-compile'),
  template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"centering\"><div class=\"list-item\"><div class=\"list-item-header scheduled-redirects\"><h2>Redirect to: " + (jade.escape((jade_interp = data.url) == null ? '' : jade_interp)) + "</h2><div class=\"list-item-actions\"><div class=\"btn-group\"><button type=\"button\" class=\"btn btn-small js-delete-scheduled-redirect\">Delete</button></div><div class=\"btn-group\"><button type=\"button\" class=\"btn btn-primary btn-small js-edit-scheduled-redirect\">Edit</button></div></div></div><div class=\"list-item-content\"><dl><dt>Active from:</dt><dd>" + (jade.escape((jade_interp = data.startDate) == null ? '' : jade_interp)) + "</dd><dt>Active to:</dt><dd>" + (jade.escape((jade_interp = data.endDate) == null ? '' : jade_interp)) + "</dd></dl></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
},
  BaseView = require('cf-base-view'),
  moment = require('moment')

module.exports = BaseView.extend({
  events: {
    'click .js-edit-scheduled-redirect': 'handleEdit',
    'click .js-delete-scheduled-redirect': 'handleDelete'
  },
  template: template,
  render: function () {
    var modelData = this.model.toJSON()
    modelData.startDate = moment(modelData.startDate).format('LLLL')
    modelData.endDate = moment(modelData.endDate).format('LLLL')
    this.$el.html(this.template({ data: modelData }))

    return this
  },
  handleEdit: function () {
    this.trigger('editScheduledRedirect', this.model)
  },

  handleDelete: function () {
    this.trigger('deleteScheduledRedirect', this.model.cid)
  }
})
