const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (label, options, undefined) {
if ( label)
{
buf.push("<span class=\"toggle-control__label\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "</span>");
}
buf.push("<div class=\"btn-group\">");
// iterate options
;(function(){
  var $$obj = options;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var option = $$obj[$index];

buf.push("<button type=\"button\"" + (jade.attr("data-toggle", option.value, true, false)) + (jade.cls(['btn' + (option.selected ? ' active' : '')], [true])) + ">" + (jade.escape(null == (jade_interp = option.label) ? "" : jade_interp)) + "</button>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var option = $$obj[$index];

buf.push("<button type=\"button\"" + (jade.attr("data-toggle", option.value, true, false)) + (jade.cls(['btn' + (option.selected ? ' active' : '')], [true])) + ">" + (jade.escape(null == (jade_interp = option.label) ? "" : jade_interp)) + "</button>");
    }

  }
}).call(this);

buf.push("</div>");}.call(this,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"options" in locals_for_with?locals_for_with.options:typeof options!=="undefined"?options:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class ToggleControl extends View {
  constructor (serviceLocator, options, label) {
    super()
    this.options = options
    this.label = label
    this.$el.addClass('toggle-control')
    this.$el.on('click', '[data-toggle]', this.handleChange.bind(this))
  }

  render () {
    this.$el.append(template({ options: this.options, label: this.label }))
    return this
  }

  handleChange (e) {
    this.$el.find('[data-toggle]').removeClass('active')
    var $selected = $(e.currentTarget).addClass('active')
    this.emit('change', $selected.attr('data-toggle'))
  }
}

module.exports = ToggleControl
