const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Hero',
    itemView: require('../../widget/views/item/edit-base'),
    description: 'Display list content in main hero block'
  }

  serviceLocator.widgetFactories.get('section').register('hero', widget)

  done()
}

module.exports = () => ({ heroWidget: [ 'widget', 'sectionAdmin', init ] })
