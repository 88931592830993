const moment = require('moment')

const createRedemptionsData = require('./redemptions-data-creator')
const createAccumulatorData = require('./redemptions-accumulator-creator')

const transformToChart = (data) => {
  const redemptionsData = createRedemptionsData(data)
  const accumulatorData = createAccumulatorData(redemptionsData)

  const series = accumulatorData && accumulatorData.map(row => {
    const title = row[0].value
    const data = row.splice(1)

    const formattedData = data && data.map((datum, index) => {
      var monthName = moment.months(index + 6)
      return [ monthName, datum.value ]
    })

    return {
      name: title,
      points: formattedData
    }
  })

  return series
}

module.exports = transformToChart
