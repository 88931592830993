const BaseModel = require('cf-base-model')
const schemata = require('./schema')()
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata,

  type: 'offerStack',

  displayName: 'Offer Stack',

  defaults () {
    return schemata.makeDefault({ type: this.type || 'offerStack' })
  },

  validate: validateDelegate
})
