const GenericListItemView = require('../../../../admin/source/js/lib/generic/list-item')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('email', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\">");
if ( allowed('email', 'read'))
{
buf.push("<a class=\"btn btn--small js-view\">Preview</a>");
}
if ( allowed('email', 'publish'))
{
buf.push("<a class=\"btn btn--small js-publish\">Publish</a>");
if ( allowed('email', 'create'))
{
buf.push("<a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-duplicate-to-instance\">Duplicate</a></li><li><a class=\"js-delete\">Delete</a></li></ul>");
}
}
buf.push("</div></div>");
if ( allowed('email', 'update'))
{
buf.push("<h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Subject:</dt><dd>" + (jade.escape(null == (jade_interp = data.subject) ? "" : jade_interp)) + "</dd><dt>Current State:</dt><dd>" + (jade.escape(null == (jade_interp = data.state) ? "" : jade_interp)) + "</dd><dt>Updated:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateUpdated, 'calendar')) ? "" : jade_interp)) + "</dd>");
if ( (data.publishedVersion !== 0))
{
buf.push("<dt>Version:</dt><dd>" + (jade.escape(null == (jade_interp = data.publishedVersion) ? "" : jade_interp)) + "</dd><dt>Last Publish:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.lastPublished, 'calendar')) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const debug = require('../../../../admin/source/js/lib/debug')('email list item view')
const modal = require('modal')
const DuplicateToInstanceView = require('./duplicate-to-instance')

class ListItemView extends GenericListItemView {
  constructor (serviceLocator, model) {
    super(serviceLocator, model)
    this.$el.on('click', '.js-view', this.viewEmail.bind(this))
    this.$el.on('click', '.js-publish', this.publishEmail.bind(this))
    this.$el.on('click', '.js-delete', this.handleDelete.bind(this))
    this.$el.on('click', '.js-duplicate-to-instance', this.handleDuplicateToInstance.bind(this))
  }

  className () {
    return ''
  }

  template () {
    return template
  }

  viewEmail () {
    this.trigger('preview')
  }

  handleDelete () {
    modal(
      { title: 'Delete',
        content: 'Are you sure you want to delete this email?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
          { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
        ]
      })
      .on('confirm', this.deleteEmail.bind(this))
  }

  deleteEmail () {
    this.trigger('delete')
  }

  render () {
    const data = this.model.toJSON()

    this.$el.empty().append(template({
      data: data,
      format: this.options.serviceLocator.format,
      allowed: this.options.serviceLocator.allowed
    }))
    this.$('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  publishEmail () {
    const formData = {}

    formData.state = 'Published'
    formData.publishedVersion = this.model.get('publishedVersion') + 1
    formData.lastPublished = new Date()
    formData.body = JSON.parse(JSON.stringify(this.model.attributes.body))

    this.model.set(formData)
    this.model.save(null, {
      success: this.onSaveSuccess.bind(this),
      error: this.onSaveError.bind(this)
    })
    this.trigger('publish')
  }

  onSaveError (model, jqxhr) {
    debug('saving model failed', model.toJSON())
    const contentType = jqxhr.getResponseHeader('Content-Type') || 'application/json'

    if (contentType.indexOf('application/json') !== -1) {
      const error = JSON.parse(jqxhr.responseText)

      if (error.err) {
        error.errors = { error: error.err }
      }
      this.showErrors(error.errors)
    }
  }

  onSaveSuccess () {
    this.render()
    this.trigger('save')
  }

  handleDuplicateToInstance (e, data, errors) {
    if (e) e.stopPropagation()
    const duplicateToInstanceView = new DuplicateToInstanceView({ serviceLocator: this.options.serviceLocator, data, errors, email: this.model.get('_id') })
    const m = modal(
      { title: 'Duplicating: ' + this.model.get('name'),
        className: 'modal-duplicate-layout-view wide',
        content: duplicateToInstanceView.$el,
        buttons: []
      })
    duplicateToInstanceView.on('cancel', m.close)
    duplicateToInstanceView.on('duplicate', m.close)
    duplicateToInstanceView.on('error', this.handleDuplicateToInstance.bind(this, null))
  }
}

module.exports = ListItemView
