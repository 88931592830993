const calculateTotal = require('../../periodic-reports/total-calculator')
const groupColumns = require('../../periodic-reports/column-grouper')
const flattenArray = require('../../array-flattener')

const createTotalsFooter = (data, categories) => {
  const groupedData = groupColumns(data, 'category')

  const keys = Object.keys(groupedData)

  const totals = {}

  keys.forEach(key => {
    const lowerCaseKey = key.toLowerCase()
    const values = groupedData[key]
    const keyTotals = calculateTotal(values, 'count')
    totals[lowerCaseKey] = keyTotals
  })

  const { undefined: Combined } = totals || {}

  const totalStrings = flattenArray(categories.map(category => {
    const lowerCaseCategory = category.toLowerCase()
    return totals[lowerCaseCategory]
  }).map(s => [ s, '' ]))

  return [ [ 'Totals', ...totalStrings, Combined ] ]
}

module.exports = createTotalsFooter
