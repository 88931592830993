module.exports = initWidget

function initWidget () {
  return { offerLayoutKeyFactsWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init (serviceLocator, done) {
  serviceLocator.widgetFactories.get('offer').register('offerLayoutKeyFacts',
    { model: require('./models/model'),
      name: 'Offer Key Facts',
      itemView: require('./views/item'),
      description: 'Placeholder for offer key facts'
    })

  done()
}
