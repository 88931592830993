const required = require('validity-required')
const schemata = require('@clocklimited/schemata')
const imageConfig = require('../../image-config.json')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../../../../../lib/validators/crop-integrity-validator')
const hat = require('hat')
const validateIfPropertySet = require('validity-validate-if-property-set')
const isUrl = require('validity-url')
const validateIfSet = require('validity-validate-if-set')

module.exports = () => {
  const requiredContexts = imageConfig.contexts
    .filter((context) => context.name === 'Thumbnail')
    .map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)
  const schema = schemata({
    name: 'Email Featured Offer Grid Item',
    properties: {
      _id: {
        type: String,
        defaultValue: () => hat()
      },
      shortHeadline: {
        type: String,
        validators: [required]
      },
      displayShortHeadline: {
        type: String,
        validators: []
      },
      callToActionText: {
        type: String,
        validators: [validateIfPropertySet('callToActionLink', required)]
      },
      callToActionLink: {
        type: String,
        validators: [validateIfSet(isUrl)]
      },
      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: [
          required,
          createContextValidator(requiredContexts),
          createCropValidator(requiredCrops)
        ]
      },
      imageUsesCustomCta: {
        type: Boolean
      },
      conditionsSupplementText: {
        type: String
      }
    }
  })

  return schema
}
