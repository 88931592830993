const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()

module.exports = () =>
  schemata({
    name: 'Text',
    properties: {
      description: {
        type: String,
      },
      colour: {
        type: String,
        defaultValue: 'Black',
      },
      html: {
        type: String,
        defaultValue: '',
      },
      ...baseSchema.getProperties(),
    },
  })
