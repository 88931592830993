import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Video',
    description: 'Displays a video from a third party provider'
  }

  serviceLocator.widgetFactories.get('section').register('video', widget)
  serviceLocator.widgetFactories.get('offerBody').register('video', widget)

  done()
}

module.exports = () => ({ videoWidget: [ 'widget', 'offerAdmin', init ] })
