const createNestedChart = require('./nested-chart-creator')
const createDataPoints = require('./data-point-creator')

const createLineChart = () => ({
  type: 'line',
  defaultPoint_tooltip: '<b>%yValue</b> members'
})

const defaultGraphs = [
  {
    type: 'column',
    defaultPoint_tooltip: '<b>%yValue</b> members'
  },
  createLineChart(),
  createLineChart()
]

const formatParetoChart = (year, title, i) => {
  const series =
    defaultGraphs &&
    defaultGraphs.map((graph, i) => {
      const { points, name } = createDataPoints(year, i)
      const nestedChart = createNestedChart(points)
      graph.points = nestedChart
      graph.name = name
      return graph
    })
  const xAxis = 'Month'
  const yAxis = 'Quantity'

  return { series, title, xAxis, yAxis, i }
}

module.exports = formatParetoChart
