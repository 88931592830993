const OfferModel = require('../../offer/models/offer')
const ImageAreaModel = require('../../asset/models/image-area')

class EditableItemModel extends OfferModel {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.attributes = this.schema.cast(attributes || this.schema.makeDefault())
    this.id = this.attributes._id
    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })

    this.images = new ImageAreaModel(this.get('images'))

    this.images.on('add remove change', () =>
      this.set('images', this.images.toJSON()))
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    super.reset(attrs)
  }

  setOrder (ids) {
    const oldOrder = this.get('items') || []
    let newOrder = []
    if (!oldOrder) throw new Error('This list has no items')
    ids.forEach(id => {
      newOrder = newOrder.concat(oldOrder.filter(item => item.itemId === id))
    })
    if (newOrder.length !== oldOrder.length) throw new Error('Not all items were sorted')
    this.set('items', newOrder)
  }
}

module.exports = EditableItemModel
