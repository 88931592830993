const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowQuestionDescription, allowReorder, cid, data, inputTypes, prePopulateTypes, responseRequired, showDelete, showInputType, showRequired, undefined, widths) {
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-header\">");
if ( allowReorder)
{
buf.push("<div class=\"panel-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span><a class=\"label btn label--large js-collapse\">Collapse</a></div>");
}
buf.push("<h2 class=\"counter-question\">Question</h2><div class=\"panel-actions\"><input type=\"hidden\" name=\"type\"" + (jade.attr("value", data.type, true, false)) + "/>");
if ( showDelete)
{
buf.push("<a class=\"btn btn--small js-question-delete\">Delete Question</a>");
}
buf.push("</div></div><div class=\"panel-content\"><div id=\"field--questions-question\" data-field=\"question\" class=\"form-row form-row--ckeditor\"><label><span class=\"form-label-text\">Question<abbr title=\"This field is required\">*</abbr></span><textarea type=\"text\" name=\"question\" rows=\"2\"" + (jade.attr("id", cid, true, false)) + " class=\"control control--text control--multiline js-question-text-editor form-field\">" + (jade.escape(null == (jade_interp = data.question) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div>");
if ( allowQuestionDescription)
{
buf.push("<div id=\"field--questions-description\" data-field=\"description\" class=\"form-row\"><label><span class=\"form-label-text\">Description</span><textarea type=\"text\" name=\"description\" rows=\"2\" class=\"control control--text control--multiline form-field\">" + (jade.escape(null == (jade_interp = data.description) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Further information relating to the question, to be shown below the questions input.</p></div></div>");
}
if ( showInputType)
{
buf.push("<div id=\"field--questions-inputType\" data-field=\"inputType\" class=\"form-row\"><label><span class=\"form-label-text\">Input Type<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field\"><select name=\"inputType\" class=\"control control--choice form-field\">");
// iterate inputTypes
;(function(){
  var $$obj = inputTypes;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var input = $$obj[$index];

buf.push("<option" + (jade.attr("value", input.input, true, false)) + (jade.attr("selected", data.inputType === input.input, true, false)) + ">" + (jade.escape((jade_interp = input.name) == null ? '' : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var input = $$obj[$index];

buf.push("<option" + (jade.attr("value", input.input, true, false)) + (jade.attr("selected", data.inputType === input.input, true, false)) + ">" + (jade.escape((jade_interp = input.name) == null ? '' : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></div></label><div class=\"js-error\"></div></div>");
}
buf.push("<div id=\"field--questions-wordLimit\" data-field=\"wordLimit\" class=\"form-row\"><label><span class=\"form-label-text\">Word Limit</span><div class=\"form-field\"><input type=\"number\" name=\"wordLimit\"" + (jade.attr("value", data.wordLimit, true, false)) + " class=\"control control--text form-field\"/></div></label><div class=\"js-error\">   </div><div class=\"form-row-description form-copy\"><p>Optional limit to the amount of words a user can use to answer this question.</p></div></div><div id=\"field--questions-width\" data-field=\"width\" class=\"form-row\"><label><span class=\"form-label-text\">Width<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field\"><select name=\"width\" class=\"control control--choice form-field\">");
// iterate widths
;(function(){
  var $$obj = widths;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var width = $$obj[$index];

buf.push("<option" + (jade.attr("value", width.value, true, false)) + (jade.attr("selected", data.width === width.value, true, false)) + ">" + (jade.escape((jade_interp = width.name) == null ? '' : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var width = $$obj[$index];

buf.push("<option" + (jade.attr("value", width.value, true, false)) + (jade.attr("selected", data.width === width.value, true, false)) + ">" + (jade.escape((jade_interp = width.name) == null ? '' : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></div></label><div class=\"js-error\"></div></div><div id=\"field--questions-prePopulate\" data-field=\"prePopulate\" class=\"form-row\"><label><span class=\"form-label-text\">Pre-populate</span><div class=\"form-field\"><select name=\"prePopulate\" class=\"control control--choice form-field\">");
// iterate prePopulateTypes
;(function(){
  var $$obj = prePopulateTypes;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var prePopulateType = $$obj[$index];

buf.push("<option" + (jade.attr("value", prePopulateType.value, true, false)) + (jade.attr("selected", data.prePopulate === prePopulateType.value, true, false)) + ">" + (jade.escape((jade_interp = prePopulateType.name) == null ? '' : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var prePopulateType = $$obj[$index];

buf.push("<option" + (jade.attr("value", prePopulateType.value, true, false)) + (jade.attr("selected", data.prePopulate === prePopulateType.value, true, false)) + ">" + (jade.escape((jade_interp = prePopulateType.name) == null ? '' : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Choose what data should be prepopulated on this form field if the user has previously entered this information</p></div></div><div id=\"field--questions-placeholder\" data-field=\"placeholderAsQuestion\" class=\"form-row form-row-boolean js-placeholder\"><label><span class=\"form-label-text\">Question as input placeholder</span><div class=\"form-field\"><input type=\"checkbox\" name=\"placeholderAsQuestion\"" + (jade.attr("checked", data.placeholderAsQuestion, true, false)) + " class=\"control control--boolean\"/></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>If checked, the question label will be replaced by a placeholder inside the input field</p></div></div>");
if ( showRequired)
{
buf.push("<div id=\"field--questions-required\" data-field=\"required\" class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Required</span><div class=\"form-field\"><input type=\"checkbox\" name=\"required\"" + (jade.attr("checked", data.required, true, false)) + " class=\"control control--boolean\"/></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>If checked, this question must be answered.</p></div></div>");
}
buf.push("<hr/><h4>Responses</h4><div data-field=\"responses\"><div class=\"js-error\"></div></div><form class=\"js-response-input counter-answer-reset\">");
if ( responseRequired)
{
buf.push("<p class=\"js-no-responses\">Must have at least one response.</p>");
}
else
{
buf.push("<p class=\"js-no-responses\">Responses are not required for the current input.</p>");
}
buf.push("</form><div class=\"form-row form-row-actions\"><a" + (jade.attr("disabled", (responseRequired ? null : 'disabled'), true, false)) + " class=\"btn btn--action js-response-add\">Add Response</a></div></div></div>");}.call(this,"allowQuestionDescription" in locals_for_with?locals_for_with.allowQuestionDescription:typeof allowQuestionDescription!=="undefined"?allowQuestionDescription:undefined,"allowReorder" in locals_for_with?locals_for_with.allowReorder:typeof allowReorder!=="undefined"?allowReorder:undefined,"cid" in locals_for_with?locals_for_with.cid:typeof cid!=="undefined"?cid:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"inputTypes" in locals_for_with?locals_for_with.inputTypes:typeof inputTypes!=="undefined"?inputTypes:undefined,"prePopulateTypes" in locals_for_with?locals_for_with.prePopulateTypes:typeof prePopulateTypes!=="undefined"?prePopulateTypes:undefined,"responseRequired" in locals_for_with?locals_for_with.responseRequired:typeof responseRequired!=="undefined"?responseRequired:undefined,"showDelete" in locals_for_with?locals_for_with.showDelete:typeof showDelete!=="undefined"?showDelete:undefined,"showInputType" in locals_for_with?locals_for_with.showInputType:typeof showInputType!=="undefined"?showInputType:undefined,"showRequired" in locals_for_with?locals_for_with.showRequired:typeof showRequired!=="undefined"?showRequired:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined,"widths" in locals_for_with?locals_for_with.widths:typeof widths!=="undefined"?widths:undefined));;return buf.join("");
}
const ResponseModel = require('../models/response')
const ResponseView = require('./response')
const debug = require('../../../../admin/source/js/lib/debug')('question view')
const modal = require('modal')
const inputTypes = require('../../../service/form-builder/input-types').all()
const widths = require('../../../service/form-builder/widths').all()
const prePopulateTypes = require('../../../service/form-builder/prepopulate-types').all()
const formErrorTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (error, undefined) {
if ( typeof error === 'object')
{
buf.push("<div class=\"form-copy form-error\"><ul>");
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var err = $$obj[key];

buf.push("<li>" + (jade.escape(null == (jade_interp = err) ? "" : jade_interp)) + "</li>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var err = $$obj[key];

buf.push("<li>" + (jade.escape(null == (jade_interp = err) ? "" : jade_interp)) + "</li>");
    }

  }
}).call(this);

buf.push("</ul></div>");
}
else
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = error) ? "" : jade_interp)) + "</div>");
}}.call(this,"error" in locals_for_with?locals_for_with.error:typeof error!=="undefined"?error:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const RichTextEditorInstanceManager = require('../../../../admin/source/js/lib/rich-text-editor-instance-manager')

class QuestionFormView extends BaseFormView {
  constructor (...args) {
    super(...args)
    this.showInputType = true
    this.showRequired = true
    this.showDelete = true
    this.allowQuestionDescription = false
    this.allowReorder = true
    this.isCollapsed = false
    this.responseViews = []
    this.richTextEditorInstanceManager = new RichTextEditorInstanceManager()

    this.$el.addClass('js-question')
    this.$el.data('item', this.model)
    this.$el.on('click', '.js-response-add', this.handleResponseAdd.bind(this))
    this.$el.on('click', '.js-collapse', this.toggleCollapse.bind(this))
    this.$el.on('click', '.js-question-delete', this.handleDelete.bind(this))
    this.$el.on('change', 'select[name=inputType]', this.updateFormFields.bind(this))
    this.on('afterAppend', () => { this.$el.find('input[type=text]:first').focus() })

    this.model.on('dragged', () => {
      this.richTextEditorInstanceManager.destroy()
      this.$el.find('.form-row--ckeditor').hide()
    })

    this.model.on('dropped', () => {
      this.render()
      this.$el.find('.form-row--ckeditor').show()
      if (this.isCollapsed) {
        this.$el.find('.panel-content').hide()
        this.$el.find('.js-collapse').text('Expand')
      }
    })

    this.on('remove', () => {
      this.richTextEditorInstanceManager.destroy()
    })
  }

  toggleCollapse () {
    if (this.isCollapsed) {
      this.$el.find('.panel-content').show()
      this.$el.find('.js-collapse').text('Collapse')
    } else {
      this.$el.find('.panel-content').hide()
      this.$el.find('.js-collapse').text('Expand')
    }

    this.isCollapsed = !this.isCollapsed
  }

  handleDelete () {
    modal(
      { content: 'Are you sure you want to delete this question?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
          { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
        ]
      })
      .on('confirm', this.deleteQuestion.bind(this))
  }

  deleteQuestion () {
    this.trigger('remove', this.model.cid)
    this.remove()
  }

  showErrors (errors) {
    if (errors.question) {
      this.$('[data-field=question] .js-error')
        .append(formErrorTemplate({ error: errors.question }))
    }

    if (errors.responses && typeof errors.responses === 'object') {
      Object.keys(errors.responses).forEach(key => {
        this.responseViews[key].view.showErrors(errors.responses[key])
      })
    }

    if (errors.responses && typeof errors.responses === 'string') {
      this.$('[data-field=responses] .js-error')
        .append(formErrorTemplate({ error: errors.responses }))
    }
  }

  displayNoResponseText (show) {
    const text = this.$('.js-response-input').find('.js-no-responses')

    if (show) {
      text.show()
    } else {
      text.hide()
    }
  }

  // Responses are not required for text, textarea and date inputs
  updateFormFields () {
    const inputTypeValue = this.$('select[name=inputType]').val()
    const input = inputTypes.find(input => input.input === inputTypeValue)

    if (input.requiresResponse) {
      this.$('.js-response-add').attr('disabled', false)
    } else {
      this.$('.js-response-add').attr('disabled', true)
    }

    this.updateResponseText(input.requiresResponse)

    if (input.hasWordLimit) {
      this.$('#field--questions-wordLimit').show()
    } else {
      this.$('#field--questions-wordLimit').hide()
    }
  }

  updateResponseText (responseRequired) {
    this.displayNoResponseText(!responseRequired || this.model.responses.length === 0)
    const responseText = responseRequired ? 'Must have at least one response.' : 'Responses are not required for the current input.'
    this.$('.js-no-responses').text(responseText)
  }

  handleResponseAdd (response) {
    debug('add response input')
    if (this.type === 'free-text') this.setType('radio')
    const $responsesElement = this.$('.js-response-input')
    let model = response
    if (this.model.responses.length === 0) {
      this.displayNoResponseText(false)
    }

    if (response.target) {
      model = new ResponseModel()
      this.model.responses.add(model)
    }

    const responseView = new ResponseView(this.serviceLocator, model)

    responseView.on('remove', (id) => {
      this.model.responses.remove(id)
      const i = this.responseViews.findIndex(view => view.cid === id)
      this.responseViews.splice(i, 1)
      if (this.model.responses.length === 0) {
        this.displayNoResponseText(true)
      }
    })

    this.responseViews.push({ cid: model.cid, view: responseView })

    $responsesElement.append(responseView.render().$el)
  }

  render () {
    debug('render')
    const questionInput = inputTypes.find(input => input.input === this.model.get('inputType'))

    this.$el.empty().append(template({
      data: this.model.toJSON(),
      cid: this.model.cid,
      questionIndex: this.model.index,
      inputTypes,
      widths,
      prePopulateTypes,
      responseRequired: questionInput ? questionInput.requiresResponse : false,
      placeholderAvailable: questionInput ? questionInput.placeholderAvailable : false,
      hasWordLimit: questionInput ? questionInput.hasWordLimit : false,
      showInputType: this.showInputType,
      showRequired: this.showRequired,
      showDelete: this.showDelete,
      allowReorder: this.allowReorder
    }))

    this.model.responses.models.forEach(response => {
      this.handleResponseAdd(response)
    }, this)

    if (this.model.responses.models.length > 0) this.$('.js-no-responses').hide()

    this.$el.find('.js-question-text-editor').each((i, el) => {
      this.richTextEditorInstanceManager.create(el, { height: 200 }, el.id)
    })

    this.updateFormFields()
    return this
  }
}

module.exports = QuestionFormView
