const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const widgetSchema = {
  title: {
    type: String,
    validators: [required],
  },
  subtitle: {
    type: String,
    validators: [required],
  },
  phoneNumber: {
    type: String,
  },
  contactInfo: {
    type: String,
  },
  linksTitle: {
    type: String,
  },
  links: {
    type: Array,
    defaultValue: () => [],
  },
}

const schema = schemata({
  name: 'Info Panel',
  properties: Object.assign({}, baseSchema.getProperties(), widgetSchema),
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'infoPanel' })
  },
  validate: validateDelegate,
})
