const BaseWidgetView = require('./base')
const debug = require('../../../../../admin/source/js/lib/debug')('newsletter form')
const ListModel = require('../../../list/models/list')
const BaseEditableOfferForm = require('./editable-offer-form')
const config = require('../../../../../admin/lib/ckeditor-config')

module.exports = BaseWidgetView.extend({
  debug,
  events: {
    'click .js-save': 'handleFormSave',
    'click .js-cancel': 'handleCancel',
    'submit': (e) => { e.preventDefault() },
    'click .js-expander-toggle': 'handleExpanderToggle',
    'change .js-type-select': 'handleTypeChange',
    'change [name=heading]': 'generateHeadingBasedProperties'
  },

  initialize (options) {
    BaseWidgetView.prototype.initialize.apply(this)

    this.$('.js-text-editor').each((index, value) => this.richTextEditorManager.create(value, { height: 100, startupFocus: false }))
    this.$('.js-text-editor-display').each((index, value) => this.richTextEditorManager.create(value, config.display))
    this.$('.js-text-editor-testimonial').each((index, value) => this.richTextEditorManager.create(value, config.testimonial))
    this.$('.js-text-editor-testimonial-italics-only').each((index, value) =>
      this.richTextEditorManager.create(value, {
        ...config.testimonial,
        removeButtons: config.testimonial.removeButtons.concat(',Bold')
      })
    )
    this.model = options.model
  },

  handleFormSave () {
    this.handleSave()
  },

  addCustomFormData (formData) {
    formData.offers = this.offerPickerModel.get('items') || []
    formData.type = this.model.type
    return formData
  },

  generateHeadingBasedProperties () {
    const $heading = this.$el.find('[name=heading]').val()
    const $displayHeading = window.CKEDITOR.instances['displayHeading']
    if (!$displayHeading.getData()) $displayHeading.setData($heading)
  },

  render () {
    BaseWidgetView.prototype.render.apply(this)

    this.offerPickerModel = new ListModel()
    this.offerPickerModel.set('items', this.model.get('offers'))
    this.contentSourceView = new BaseEditableOfferForm(this.options.serviceLocator, this.offerPickerModel, this.options, this.EditableItemModel, this.EditableItemFormView)

    this.$el.find('.js-content-source-form').empty().append(this.contentSourceView.render().$el)
  }

})
