const View = require('ventnor')
const emailOptions = require('./email-options')()

class BaseSelectView extends View {
  constructor (serviceLocator, selected) {
    super(serviceLocator, selected)

    this.$el = $('<select multiple />')
    this.$el.attr('name', 'emailOptions')
    this.$el.attr('placeholder', `Choose one or more newsletter options`)
    this.$el.addClass('control control--choice control--multiline')

    this.el = this.$el[0]
    this.selected = selected || []
  }

  initializeSelectize () {
    const index = emailOptions.reduce((index, option) => {
      index[option.value] = option
      return index
    }, {})
    this.selected.forEach(value => {
      this.el.selectize.addOption({ value: value, text: index[value] ? index[value].text : value })
      this.el.selectize.addItem(value)
    })
    this.el.selectize.on('change', this.updateSelection.bind(this))
  }

  updateSelection () {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load (query, cb) {
    cb(emailOptions.map(option => ({ value: option.value, text: option.text })))
  }

  render () {
    const selectizeSettings = {
      onInitialize: this.initializeSelectize.bind(this),
      load: this.load.bind(this),
      delimiter: ',',
      create: false,
      preload: true
    }

    setTimeout(() => {
      this.$el.selectize(selectizeSettings)
    }, 0)
    return this
  }
}

module.exports = BaseSelectView
