module.exports = initWidget

function initWidget () {
  return { offerLayoutBodyWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init (serviceLocator, done) {
  serviceLocator.widgetFactories.get('offer').register('offerLayoutBody',
    { model: require('./models/model'),
      name: 'Offer Body',
      itemView: require('./views/item'),
      description: 'Placeholder for offer body widgets'
    })

  done()
}
