const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div data-field=\"response\" class=\"form-row\"><label class=\"form-label-text counter-answer\">Response</label><div class=\"form-field\"><div class=\"control-addon\"><input type=\"text\" name=\"response\"" + (jade.attr("value", data.response, true, false)) + " class=\"control control--text control-addon__base\"/><div class=\"control-addon__affix control-addon__affix--suffix\"><button type=\"button\" class=\"btn btn--large js-response-delete\"><i class=\"icon icon--cross--dark\">Reset</i></button></div></div><div class=\"js-error\"></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const debug = require('../../../../admin/source/js/lib/debug')('question response view')
const BaseFormView = require('../../base/views/form')
const modal = require('modal')

class ResponseFormView extends BaseFormView {
  constructor (...args) {
    super(...args)
    this.$el.on('click', '.js-response-delete', this.handleDelete.bind(this))
    this.on('afterAppend', () => { this.$el.find('input[type=text]:first').focus() })
  }

  handleDelete () {
    modal(
      { content: 'Are you sure you want to delete this response?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
          { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
        ]
      }).on('confirm', this.deleteResponse.bind(this))
  }

  deleteResponse () {
    this.trigger('remove', this.model.cid)
    this.remove()
  }

  render () {
    debug('render', this.model)
    this.$el.empty().append(template({
      data: this.model.toJSON()
    }))

    return this
  }
}

module.exports = ResponseFormView
