import FormView from './views/form'
import Model from './models/model'
import ItemView from './views/item'

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Featured Offer Grid',
    itemView: ItemView,
    description: 'Show a featured offer grid'
  }

  serviceLocator.widgetFactories.get('email').register('featuredOfferGrid', widget)

  done()
}

module.exports = () => ({ emailFeaturedOfferGridWidget: [ 'widget', 'offerAdmin', init ] })
