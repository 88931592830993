const Model = require('merstone')
const createSchema = require('../../../service/administrator/schema')
const schemata = require('@clocklimited/schemata')
const omit = require('lodash.omit')

class AdministratorModel extends Model {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schema = schemata({
      name: 'Administrator',
      properties: omit(
        createSchema(
          null,
          serviceLocator.config).getProperties(),
        'password',
        'passwordSalt',
        'key',
        'keyExpiry'
      )
    })
    this.attributes = this.schema.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schema.cast(attrs))
    })
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }

  reset (attrs) {
    attrs = this.schema.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }
}

module.exports = AdministratorModel
