const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const required = require('validity-required')

module.exports = () => {
  const properties = {
    images: {
      type: Array,
      defaultValue: () => []
    },
    selectedCrop: {
      type: String,
      validators: { all: [ required ] }
    },
    width: {
      type: String,
      validators: { all: [ required ] }
    },
    position: {
      type: String,
      validators: { all: [ required ] }
    }
  }

  const widgetSchemata = schemata({
    name: 'Inline Image Item',
    properties: Object.assign(baseSchema.getProperties(), properties)
  })

  return widgetSchemata
}
