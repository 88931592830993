const CrudService = require('../../../../admin/source/js/lib/crud-service')
const createAuthedRequest = require('../../../../admin/source/js/lib/authed-request')

class CodeWordService extends CrudService {
  constructor (serviceLocator, options) {
    super(serviceLocator, options)
    this.authedRequest = createAuthedRequest(window.config.apiUrl)
  }

  get name () {
    return 'CodeWordService'
  }
  get urlRoot () {
    return '/code-word'
  }

  current (entityId, cb) {
    this.authedRequest('GET', `${this.urlRoot}/${entityId}/current`, null, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      cb(null, body)
    })
  }

  upload (entityId, file, type, cb) {
    var options = { headers: { 'Content-Type': 'text/csv' } }
    this.authedRequest('POST', `${this.urlRoot}/${entityId}/upload/${type}`, file, options, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      try { body = JSON.parse(body) } catch (e) {}
      cb(null, body)
    })
  }

  getError (body) {
    if (body.errors) {
      var error = new Error('Validation Error')
      error.errors = body.errors
      return error
    }
    return new Error(body.error || body.status || body || 'Unknown Error')
  }
}

module.exports = CodeWordService
