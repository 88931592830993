const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const validateLength = require('validity-length')
const imageConfig = require('./image-config.json')
const regexMatch = require('validity-regex-match')
const createUniqueValidator = require('validity-unique-property')
const validateIfSet = require('validity-validate-if-set')
const createContextValidator = require('validity-cf-image-context-selection')
const createCropValidator = require('../../../lib/validators/crop-integrity-validator')
const dateBeforeExpiryValidator = require('validity-date-before-property')
const hat = require('hat')
const hasWidgets = (obj) => obj && obj.widgets && obj.widgets.length > 0
const validateIf =
  (validateFn, comparator) => (key, keyDisplayName, object, cb) => {
    if (comparator(object[key])) {
      return validateFn(key, keyDisplayName, object, cb)
    }

    return cb(null, undefined)
  }

module.exports = (findOne) => {
  const requiredContexts = imageConfig.contexts.map((context) => context.name)
  const requiredCrops = imageConfig.crops.map((crop) => crop.name)
  return schemata({
    name: 'Account Schema',
    properties: {
      _id: {
        type: String
      },
      name: {
        type: String,
        validators: [required]
      },
      themeName: {
        type: String
      },
      logo: {
        type: String
      },
      domain: {
        type: String,
        validators: {
          all:
            // TODO remove duplicate call to validate wrapper,
            // make module support arrays of validators
            [
              validateIfSet(required),
              validateIfSet(
                regexMatch(
                  new RegExp(
                    '^((?=[^.]{1,63}(?:\\.|$))(?![^.]*-(?:\\.|$))' +
                      '[0-9a-z][0-9a-z-]*)(\\.(?=[^.]{1,63}(?:\\.|$))(?![^.]*-(?:\\.|$))' +
                      '[0-9a-z][0-9a-z-]*)+$'
                  ),
                  'Must be a valid domain name'
                )
              ),
              createUniqueValidator(findOne)
            ]
        }
      },
      primaryInstance: {
        type: String
      },
      categories: {
        type: Array,
        validators: [validateLength(1)]
      },
      supportEmailAddress: {
        type: String,
        validators: [required]
      },
      addThisId: {
        type: String
      },
      facebookId: {
        type: String
      },
      twitterId: {
        type: String
      },
      googlePlusId: {
        type: String
      },
      pinterestId: {
        type: String
      },
      instagramId: {
        type: String
      },
      footerHtml: {
        type: String
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },
      allowedEntitlements: {
        type: Array,
        validators: [validateLength(1)],
        defaultValue: () => []
      },
      useAuth0: {
        type: Boolean,
        defaultValue: false
      },
      seasonalCampaignEnabled: {
        type: Boolean,
        defaultValue: false
      },
      seasonalCampaignItems: {
        type: Array,
        defaultValue: () => []
      },
      seasonalCampaignPreviewId: {
        type: String,
        defaultValue: () => hat()
      },
      takeoverInstances: {
        type: Array
      },
      homepageTakeoverUrl: {
        type: String
      },
      offerTakeoverUrl: {
        type: String
      },
      campaignTakeoverImages: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [
            validateIf(createContextValidator(requiredContexts), hasWidgets),
            validateIf(createCropValidator(requiredCrops), hasWidgets)
          ],
          published: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops)
          ],
          archived: [
            createContextValidator(requiredContexts),
            createCropValidator(requiredCrops)
          ]
        }
      },
      homepageTakeoverLiveDate: {
        type: Date,
        validators: [dateBeforeExpiryValidator('homepageTakeoverExpiryDate')]
      },
      homepageTakeoverExpiryDate: {
        type: Date
      },
      offerTakeoverLiveDate: {
        type: Date,
        validators: [dateBeforeExpiryValidator('offerTakeoverExpiryDate')]
      },
      offerTakeoverExpiryDate: {
        type: Date
      },
      googleTagManagerId: {
        type: String,
        validators: { all: [] }
      }
    }
  })
}
