import Model from './model'
import ItemView from '../../widget/views/item/edit-base'
import EditView from '../../widget/views/form/base'

const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('section').register('allFaqs', {
    model: Model,
    name: 'All FAQs',
    itemView: ItemView,
    editView: EditView,
    description: 'Display all FAQs for an account on a page'
  })
  done()
}

module.exports = () => ({ allFaqs: [ 'widget', 'sectionAdmin', init ] })
