const createRowTemplate = require('../../row-template-creator')

const createRedemptionsData = (data) => {
  const rowTemplate = createRowTemplate('monthNumbersNoBuffer')
  const rowDescription = 'Monthly Unique Members Engaged'

  data.forEach(dataPoint => {
    const foundRowSlot = rowTemplate && rowTemplate.find(rowValue => rowValue.month === parseInt(dataPoint.month))
    if (foundRowSlot) {
      foundRowSlot.value = (dataPoint && dataPoint.value) || 0
    }
  })

  rowTemplate.unshift({ value: rowDescription })

  return rowTemplate
}

module.exports = createRedemptionsData
