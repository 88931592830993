const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('article').register('articleLayoutComments',
    {
      model: require('./models/model'),
      name: 'Article Comments',
      description: 'Placeholder for article comments'
    })

  done()
}

module.exports = () => ({ articleLayoutCommentsWidget: [ 'widget', 'sectionAdmin', init ] })
