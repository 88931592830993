const retrieveAccumulatedRow = (row, index, value) => {
  if (!row[index]) return row

  let currentValue = row[index].value
  const updatedIndex = index + 1

  currentValue = currentValue + value

  row[index] = { value: currentValue }
  return retrieveAccumulatedRow(row, updatedIndex, currentValue)
}

module.exports = retrieveAccumulatedRow
