const prePopulateTypes = [ {
  name: 'None',
  value: 'none'
}, {
  name: 'First Name (from identity)',
  value: 'firstName'
}, {
  name: 'Last Name (from identity)',
  value: 'lastName'
}, {
  name: 'Email (from identity)',
  value: 'email'
}, {
  name: 'Address (previously entered)',
  value: 'address'
} ]

module.exports.all = () => prePopulateTypes
