const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model) {
jade_mixins["visibilityMap"] = jade_interp = function(visibility, userVisibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( visibility)
{
if (userVisibility === 'all' || userVisibility === undefined) 
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to all&lt;/strong&gt;&lt;br&gt;This widget is visible to logged in and logged out users.\" class=\"label label--large label--success js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else if (userVisibility === 'loggedInOnly')
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to logged in users&lt;/strong&gt;&lt;br&gt;This widget will not appear for logged out users.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else if (userVisibility === 'loggedOutOnly')
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible to logged out users&lt;/strong&gt;&lt;br&gt;This widget will not appear for logged in users.\" class=\"label label--large label--warning js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
};
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
jade_mixins["visibilityMap"](model.get('visible'), model.get('userVisibility'));
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-crop\">Crop</a></li><li><a class=\"js-edit\">Edit</a></li><li class=\"divider\"></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div></div><div class=\"list-item-content expander-content\"><div class=\"image-select-preview js-image-select-preview\"><a class=\"js-edit\"><img" + (jade.attr("src", model.buildPreviewUrl(150, 150), true, false)) + " class=\"js-preview-image\"/></a></div><div class=\"image-select-controls js-image-select-controls form-row js-contexts\"></div></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return buf.join("");
}
const processingTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"asset-status js-asset-status\"><div class=\"asset-status__inner\"><i class=\"icon-custom icon-custom--spinner\"></i><div class=\"asset-status__title\">Processing Image</div></div></div>");;return buf.join("");
}
const failedTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"asset-status asset-status--error js-asset-status\"><div class=\"asset-status__inner\"><i class=\"icon icon--warning\"></i><div class=\"asset-status__title\">Image Failed</div><div class=\"asset-status__content\">Please remove this image and try again</div></div></div>");;return buf.join("");
}
const warningTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"asset-status asset-status--error js-asset-status\"><div class=\"asset-status__inner\"><i class=\"icon icon--warning\"></i><div class=\"asset-status__title\">Corrupt Crops</div><div class=\"asset-status__content\"><button class=\"btn btn--small js-crop-refresh\">Refresh</button></div></div></div>");;return buf.join("");
}
const BaseItemView = require('../../../widget/views/item/edit-base')
const debug = require('../../../../../admin/source/js/lib/debug')('edit base widget item view')
const CropSelectControls = require('../../templates/widget-templates/components/context-select-controls/view')
const config = window.config
const extend = require('lodash.assign')
const filter = require('lodash.filter')

module.exports = BaseItemView.extend({
  template,
  events: extend({}, BaseItemView.prototype.events
    , { 'click .js-crop': 'handleCrop',
      'click .js-crop-refresh': 'refreshCrops'
    }),

  initialize () {
    this.cropsChecked = false
    if (!this.options.contexts) throw new Error('This widget requires a list of contexts')

    this.listenTo(this.model, 'retrievingCrops', () => {
      this.displayImageProcessing()
    })

    this.listenTo(this.model, 'cropsFailed', () => {
      this.displayImageError()
    })

    this.listenTo(this.options.widgetArea, 'updateContextSelections', () => {
      this.updateContextSelections()
    })

    this.$el.on('click input.js-image-contexts', () => {
      this.options.widgetArea.trigger('updateContextSelections')
    })

    BaseItemView.prototype.initialize.call(this)

    // Prevent edit overlay being fired when adding images.
    this.off('init')
    this.on('init', () => {
      this.updateContextSelections()
    })
  },

  displayImageProcessing () {
    this.removeImageStatus()
    this.$('.js-image-select-preview').prepend(processingTemplate())
  },

  displayImageError () {
    this.removeImageStatus()
    this.$('.js-image-select-preview').prepend(failedTemplate())
  },

  removeImageStatus () {
    this.$('.js-asset-status').remove()
  },

  refreshCrops () {
    this.removeImageStatus()
    var newCrops = filter(this.options.crops, (value, index) => {
      var comparator = this.model.get('crops')[index]
      return typeof this.model.get('crops')[index] === 'undefined' ||
        value.name !== comparator.name
    }, this)
    this.model.setDefaultCrops(newCrops, true)
    this.model.setCrops()
  },

  displayImageWarning () {
    this.$('.js-image-select-preview').prepend(warningTemplate())
    this.$('.js-crop').addClass('disabled').attr('disabled', true)
  },

  render () {
    var result = BaseItemView.prototype.render.call(this)

    this.initCropSelectionControls()

    debug('Adding images', this.options.widgetArea)

    // Check all inputs on the first image added to the widget area.
    if (!this.hasMultipleImages()) {
      if (config.optionalImageContextsDeselectedByDefault) {
        this.$(':input.context-required.js-image-contexts').attr('checked', 'checked')
      } else if (!this.hasContextsSelected()) {
        // Only check inputs if no contexts are selected on the model
        this.$(':input.js-image-contexts').attr('checked', 'checked')
      }
    }

    this.checkImageIntegrety()

    return result
  },

  checkImageIntegrety () {
    var cropsValid = true

    Object.keys(this.options.crops).forEach(key => {
      if (typeof this.model.get('crops') === 'undefined') return false
      var crop = this.model.get('crops')[key]
      if (typeof crop === 'undefined' || typeof crop.src === 'undefined') {
        cropsValid = false
      }
    }, this)

    if (!cropsValid) {
      this.displayImageWarning()
      if (!this.cropsChecked) {
        this.refreshCrops()
        this.cropsChecked = true
      }
    } else {
      this.$('.js-crop').removeClass('disabled').removeAttr('disabled', true)
    }
  },

  updateContextSelections () {
    var selectedContexts = []
    this.$('.js-image-contexts:checked').each((i, context) => {
      selectedContexts.push($(context).attr('name'))
    })

    this.model.set('selectedContexts', selectedContexts)

    this.options.widgetArea.model.trigger('update')
  },

  initCropSelectionControls () {
    var contexts = this.options.contexts

    debug('Adding crop selection controls', contexts)

    contexts.forEach(context => {
      const contextModel = new window.Backbone.Model(context)
      const contextView = new CropSelectControls({
        model: contextModel,
        checked: this.model.get('selectedContexts').indexOf(context.name) !== -1
      })
      this.$('.js-image-select-controls').append(contextView.$el)
    })
  },

  handleCrop () {
    debug('crop', this.model.get('id'))
    const CropView = this.factory.cropView
    const cropView = new CropView(
      { model: this.model,
        crops: this.options.crops
      })

    this.showModal(cropView, 'medium')
  },

  hasContextsSelected () {
    return !!this.model.get('selectedContexts').length
  },

  hasMultipleImages () {
    return this.options.widgetArea.model.widgets.length > 1
  }
})
