const required = require('validity-required')
const schemata = require('@clocklimited/schemata')
const dateBeforePropertyValidator = require('validity-date-before-property')()
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const createUniqueValidator = require('validity-unique-property')
const createDefaultSectionLayout = require('./lib/default-section-layout')
const createDefaultArticleLayout = require('./lib/default-article-layout')

const customValidityMessage = (fn, message) => (key, name, object, cb) => {
  fn(key, name, object, (error, valid) => {
    cb(error, valid ? message : undefined)
  })
}

module.exports = findOne => {
  const fullUrlUniqueValidator = customValidityMessage(createUniqueValidator(findOne, { keys: [ 'instance' ] }),
    'Slug within this instance is already in use')

  return schemata({
    name: 'Section',
    properties: {
      _id: {
        type: String,
        tag: [ 'root' ]
      },
      // Is this section the root section of the site. If so then slug is not editable
      root: {
        type: Boolean,
        defaultValue: false,
        tag: [ 'root' ]
      },
      systemType: {
        type: String,
        defaultValue: null,
        tag: [ 'root' ]
      },
      instance: {
        type: String,
        validators: [ required ],
        tag: [ 'root' ]
      },
      name: {
        type: String,
        validators: { all: [ required ] },
        tag: [ 'root' ]
      },
      slug: {
        type: String,
        validators: {
          all: [ validateIfPropertyEquals('root', false, required), fullUrlUniqueValidator ]
        }
      },
      className: {
        type: String,
        tag: [ 'root' ]
      },
      classNameSectionOnly: {
        type: String,
        tag: [ 'root' ]
      },
      fullUrlPath: {
        type: String
      },
      parent: {
        type: String,
        tag: [ 'root' ]
      },
      order: {
        type: Number,
        tag: [ 'root' ]
      },
      layouts: {
        type: Object,
        tag: [ 'root' ],
        defaultValue: () => ({
          section: createDefaultSectionLayout(),
          offer: createDefaultArticleLayout()
        })
      },
      visible: {
        type: Boolean,
        tag: [ 'root' ],
        defaultValue: false,
        validators: { all: [ required ] }
      },
      liveDate: {
        type: Date,
        tag: [ 'root' ],
        validators: { all: [ dateBeforePropertyValidator ] }
      },
      expiryDate: {
        type: Date,
        tag: [ 'root' ]
      },
      metaTitle: {
        type: String
      },
      metaDescription: {
        type: String
      },
      shareTitle: {
        type: String
      },
      shareDescription: {
        type: String
      },
      onlyAccessibleToLoggedInUsers: {
        type: Boolean,
        defaultValue: false
      },
      previewId: {
        type: String,
        tag: [ 'root' ],
        defaultValue: () => {
          // TODO: Ensure no clash - Serby
          return Math.round(Math.random() * 100000000000).toString(36)
        }
      },
      account: {
        type: String,
        validators: { all: [ required ] },
        tag: [ 'root' ]
      },
      duplicatedFromSection: {
        type: Array
      },
      created: {
        type: Date,
        tag: [ 'root' ],
        defaultValue: () => { return new Date() }
      }
    }
  })
}
