module.exports=[
  {
    "type": "General",
    "label": "General Tags",
    "inputStyle": "textbox"
  },
  {
    "type": "System",
    "label": "System Tags",
    "inputStyle": "checkbox"
  },
  {
    "type": "Person",
    "label": "Person Tags",
    "inputStyle": "textbox"
  }
]
