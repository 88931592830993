const moment = require('moment')

const determineFinancialMonth = require('../../../../../worker/tasks/year-on-year-redemption-report/lib/actual-month-determiner')
const determineFinancialYear = require('../../../../../worker/tasks/year-on-year-redemption-report/lib/financial-year-determiner')

const transformToChart = (data) => {
  const chartData = []

  data.forEach(datapoint => {
    const { month, year, value } = datapoint || {}
    const financialYear = determineFinancialYear(month, year)
    const stringifiedFinancialYear = financialYear.toString()
    const financialMonth = determineFinancialMonth(month)
    const actualMonthName = moment.months(month - 1)

    const bar = {
      x: actualMonthName,
      y: value,
      financialMonth: financialMonth
    }

    const dataGroupedByYear = chartData.find(grouping => grouping.name === stringifiedFinancialYear)

    if (dataGroupedByYear) {
      dataGroupedByYear.points.push(bar)
    }

    if (!dataGroupedByYear) {
      const newGrouping = {}
      newGrouping.name = stringifiedFinancialYear
      newGrouping.year = financialYear
      newGrouping.points = [ bar ]
      chartData.push(newGrouping)
    }
  })

  chartData.sort((a, b) => a.year - parseInt(b.year))
  chartData.forEach(group => group.points.sort((a, b) => a.financialMonth - b.financialMonth))

  return chartData
}

module.exports = transformToChart
