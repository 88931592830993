const View = require('ventnor')
const getReports = require('../../../service/redemption-reporting/report-retriever')

class ReportSelectView extends View {
  constructor (serviceLocator, value) {
    super(serviceLocator, value)
    this.$el = $('<select/>')
    this.$el.attr('name', 'report')
    this.$el.addClass('control control--choice')
    this.value = value
    this.$el.on('change', () => this.emit('change', this.$el.val()))
    this.notSetLabel = '-- Select a report --'
    this.reports = getReports()
  }

  populate () {
    this.$el.append($('<option/>'
      , { text: this.notSetLabel,
        value: '',
        selected: this.value === ''
      }))
    this.reports.forEach(report => {
      this.$el.append($('<option/>'
        , { text: report.displayTitle || report.name,
          value: report.name,
          selected: this.value === report.name
        }))
    })
    return this
  }

  off () {}

  render () {
    return this
  }
}

module.exports = ReportSelectView
