const Model = require('merstone')

class LayoutDescriptorModel extends Model {
  constructor (serviceLocator, attributes, allowDelete) {
    super(serviceLocator, attributes)
    this.allowDelete = allowDelete
  }
}

module.exports = LayoutDescriptorModel
