const CrudService = require('../../../../admin/source/js/lib/crud-service')

class AccountService extends CrudService {
  constructor (serviceLocator) {
    super(serviceLocator)
    this.serviceLocator = serviceLocator
  }

  get name () {
    return 'AccountService'
  }

  get urlRoot () {
    return '/accounts'
  }
}

module.exports = AccountService
