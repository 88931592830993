import FormView from './views/form'
import Model from './models/model'
import ItemView from './views/item'

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    name: 'Related Offers',
    itemView: ItemView,
    description: 'Show related offers'
  }
  serviceLocator.widgetFactories.get('relatedOffer').register('relatedOffers', widget)

  done()
}

module.exports = () => ({ relatedArticles: [ 'widget', 'offerAdmin', init ] })
