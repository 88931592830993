const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div class=\"panel panel-styled\"><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Role Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div>");
if ( data.created)
{
buf.push("<div class=\"form-row form-row-output\"><span class=\"form-label-text\">Created</span><div>" + (jade.escape(null == (jade_interp = format(data.created, 'calendar')) ? "" : jade_interp)) + "</div></div>");
}
buf.push("</div></div><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Permissions</h2></div><div class=\"panel-content\"><div id=\"field--grants\" data-field=\"grants\" class=\"form-row form-row-full-width\"><p class=\"form-label-text\">Select which abilities this role should have<abbr title=\"This field is required\">*</abbr></p><div class=\"js-error\"></div></div><div class=\"js-grants\"><p>Loading Resources…</p></div></div></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const resourceTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (grants, resources, undefined) {
// iterate resources
;(function(){
  var $$obj = resources;
  if ('number' == typeof $$obj.length) {

    for (var resourceName = 0, $$l = $$obj.length; resourceName < $$l; resourceName++) {
      var resource = $$obj[resourceName];

buf.push("<div class=\"form-row form-row-boolean\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = resourceName) ? "" : jade_interp)) + "</span><div class=\"form-field\"><ul>");
// iterate resource.actions
;(function(){
  var $$obj = resource.actions;
  if ('number' == typeof $$obj.length) {

    for (var action = 0, $$l = $$obj.length; action < $$l; action++) {
      var value = $$obj[action];

buf.push("<li><label><input type=\"checkbox\" name=\"fauxGrant\"" + (jade.attr("value", '' + (resourceName) + '.' + (action) + '', true, false)) + (jade.attr("checked", (grants && grants[resourceName] && grants[resourceName].indexOf(action) !== -1), true, false)) + " class=\"control control--boolean\"/>" + (null == (jade_interp = action) ? "" : jade_interp) + "</label></li>");
    }

  } else {
    var $$l = 0;
    for (var action in $$obj) {
      $$l++;      var value = $$obj[action];

buf.push("<li><label><input type=\"checkbox\" name=\"fauxGrant\"" + (jade.attr("value", '' + (resourceName) + '.' + (action) + '', true, false)) + (jade.attr("checked", (grants && grants[resourceName] && grants[resourceName].indexOf(action) !== -1), true, false)) + " class=\"control control--boolean\"/>" + (null == (jade_interp = action) ? "" : jade_interp) + "</label></li>");
    }

  }
}).call(this);

buf.push("</ul></div></div>");
    }

  } else {
    var $$l = 0;
    for (var resourceName in $$obj) {
      $$l++;      var resource = $$obj[resourceName];

buf.push("<div class=\"form-row form-row-boolean\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = resourceName) ? "" : jade_interp)) + "</span><div class=\"form-field\"><ul>");
// iterate resource.actions
;(function(){
  var $$obj = resource.actions;
  if ('number' == typeof $$obj.length) {

    for (var action = 0, $$l = $$obj.length; action < $$l; action++) {
      var value = $$obj[action];

buf.push("<li><label><input type=\"checkbox\" name=\"fauxGrant\"" + (jade.attr("value", '' + (resourceName) + '.' + (action) + '', true, false)) + (jade.attr("checked", (grants && grants[resourceName] && grants[resourceName].indexOf(action) !== -1), true, false)) + " class=\"control control--boolean\"/>" + (null == (jade_interp = action) ? "" : jade_interp) + "</label></li>");
    }

  } else {
    var $$l = 0;
    for (var action in $$obj) {
      $$l++;      var value = $$obj[action];

buf.push("<li><label><input type=\"checkbox\" name=\"fauxGrant\"" + (jade.attr("value", '' + (resourceName) + '.' + (action) + '', true, false)) + (jade.attr("checked", (grants && grants[resourceName] && grants[resourceName].indexOf(action) !== -1), true, false)) + " class=\"control control--boolean\"/>" + (null == (jade_interp = action) ? "" : jade_interp) + "</label></li>");
    }

  }
}).call(this);

buf.push("</ul></div></div>");
    }

  }
}).call(this);
}.call(this,"grants" in locals_for_with?locals_for_with.grants:typeof grants!=="undefined"?grants:undefined,"resources" in locals_for_with?locals_for_with.resources:typeof resources!=="undefined"?resources:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class FormView extends BaseFormView {
  constructor (...args) {
    super(...args)
    this.$el.on('change', '[name=fauxGrant]', this.updateGrants.bind(this))
  }

  render () {
    // Render the template
    this.$el.append(template({
      title: this.isNew ? 'New Role' : 'Edit Role',
      data: this.model.toJSON(),
      format: this.serviceLocator.format
    }))

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.serviceLocator.loadAcl(() => {
      this.$el.find('.js-grants').html(resourceTemplate({
        grants: this.model.toJSON().grants,
        resources: this.serviceLocator.acl.acl
      }))
    })

    return this
  }

  updateGrants () {
    const grants = {}
    this.$el.find('[name=fauxGrant]:checked').each((index, value) => {
      const parts = $(value).val().split('.')
      if (typeof grants[parts[0]] === 'undefined') grants[parts[0]] = []
      grants[parts[0]].push(parts[1])
    })
    this.model.set('grants', grants)
  }
}

module.exports = FormView
