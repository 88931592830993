const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    itemView: require('./views/item'),
    name: 'Quote',
    description: 'Display a quote within a section of the website or an offer'
  }

  serviceLocator.widgetFactories.get('section').register('quote', widget)
  serviceLocator.widgetFactories.get('offerBody').register('quote', widget)

  done()
}

module.exports = () => ({ quoteWidget: [ 'widget', 'sectionAdmin', init ] })
