const View = require('../views/view')

module.exports = serviceLocator => {
  const router = serviceLocator.router
  const displayName = { singular: 'Redemption Data', plural: 'Redemption Data' }

  router.route('redemption-data(/)', 'viewReports', () => {
    if (!serviceLocator.allow('redemption-data', 'read')) {
      return false
    }
    const view = new View({ serviceLocator, displayName })
    router.render(view, displayName.plural)
  })
}
