const schemata = require('../../../../service/widget/model/base-widget')
const createContextValidator = require('validity-cf-image-context-selection')

module.exports = () => {
  const base = schemata()

  Object.assign(base.getProperties(), {
    name: 'Members Hero',
    properties: {
      heroImage: {
        type: Object,
        defaultValue: () => ({}),
        validators: { all: [ createContextValidator([ 'Hero' ]) ] }
      }
    }
  })

  return base
}
