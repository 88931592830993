const async = require('async')

module.exports = (serviceLocator, instanceId, cb) => {
  async.waterfall(
    [ (cb) => {
      if (!instanceId) {
        return serviceLocator.instanceService.find('', {}, [ 'name' ], { pageSize: 1 }, (err, instances) => {
          cb(err, { instance: instances.results[0] })
        })
      }
      serviceLocator.instanceService.read(instanceId, (err, instance) => {
        if (err) return cb(err)
        return cb(null, { instance: instance })
      })
    },
    (obj, cb) => {
      serviceLocator.accountService.read(obj.instance.account, (err, account) => {
        if (err) return cb(err)
        obj.account = account
        return cb(null, obj)
      })
    }
    ], cb)
}
