const Model = require('merstone')
const schema = require('../schema')()

class CompetitionModel extends Model {
  constructor (serviceLocator, attributes) {
    super(serviceLocator, attributes)
    this.schema = schema
    attributes = this.schema.makeDefault(attributes)
    this.set(attributes)
  }

  validate (cb) {
    this.schema.validate(this.attributes, cb)
  }
}

module.exports = CompetitionModel
