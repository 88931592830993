module.exports={
  "crops": [
    { "name": "Landscape", "aspectRatio": "16:9" },
    { "name": "Square", "aspectRatio": "1:1" },
    { "name": "Free", "aspectRatio": "*" }
  ],
  "contexts": [
    { "name": "Hero", "allowMultipleSelection": false },
    { "name": "Header Logo", "allowMultipleSelection": false, "allowNull": true },
    { "name": "Footer Logo", "allowMultipleSelection": false, "allowNull": true }
  ]
}
