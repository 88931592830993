const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const required = require('validity-required')

module.exports = () =>
  schemata({
    name: 'Video',
    properties: {
      videoId: {
        type: String,
        validators: { all: [required] },
      },
      provider: {
        type: String,
        validators: { all: [required] },
      },
      ...baseSchema.getProperties(),
    },
  })
