const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"field--instance\" class=\"form-row\"><label><span class=\"form-label-text\">Instance</span><select name=\"instance\" class=\"control control--choice form-field js-preview-instance\"></select></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The instance you want to preview the calendar on</p></div></div>");;return buf.join("");
}
const instanceOptionTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (name, selected, value) {
buf.push("<option" + (jade.attr("value", value, true, false)) + (jade.attr("selected", selected, true, false)) + ">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</option>");}.call(this,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"selected" in locals_for_with?locals_for_with.selected:typeof selected!=="undefined"?selected:undefined,"value" in locals_for_with?locals_for_with.value:typeof value!=="undefined"?value:undefined));;return buf.join("");
}

class SeasonalCampaignPreviewView extends View {
  constructor (serviceLocator, accountModel) {
    super()
    this.serviceLocator = serviceLocator
    this.$el.on(
      'change',
      '.js-preview-instance',
      this.handleChangeInstance.bind(this)
    )
    this.template = template
    this.accountModel = accountModel
    this.instances = []
  }

  handleChangeInstance () {
    const instanceId = this.$el.find('select[name=instance]').val()
    const instance = this.instances.filter(
      (instance) => instance._id === instanceId
    )[0]
    const url = `${this.serviceLocator.instanceService.createUrl(
      instance,
      this.accountModel.toJSON()
    )}/advent-calendar?previewId=${this.accountModel.get(
      'seasonalCampaignPreviewId'
    )}`
    window.open(url)
  }

  populateInstances () {
    this.serviceLocator.instanceService.find(
      '',
      { account: this.accountModel.get('_id') },
      [ 'name' ],
      {},
      (err, res) => {
        if (err) {
          return this.serviceLocator.logger.error(
            'Could not load instances',
            err
          )
        }
        const instanceSelect = this.$el.find('select[name=instance]')
        instanceSelect.append(
          instanceOptionTemplate({
            value: null,
            name: '-- Choose an instance --'
          })
        )
        this.instances = res.results
        instanceSelect.append(
          res.results.map((instance) => {
            return instanceOptionTemplate({
              value: instance._id,
              name: instance.name
            })
          })
        )
      }
    )
  }

  render () {
    this.$el.append(this.template({}))

    if (this.instances.length === 0) {
      this.populateInstances()
    }

    return this
  }
}

module.exports = SeasonalCampaignPreviewView
