const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('article').register('articleLayoutBody',
    {
      model: require('./models/model'),
      name: 'Article Body',
      description: 'Placeholder for article body widgets'
    })

  done()
}

module.exports = () => ({ articleLayoutBodyWidget: [ 'widget', 'sectionAdmin', init ] })
