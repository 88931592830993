const moment = require('moment')
const momentTimezone = require('moment-timezone')

const determineMonthOnMonthDates = (startDate, endDate, returnAsUTC) => {
  const startMoment = momentTimezone(startDate).tz('Australia/Sydney')
  const endMoment = momentTimezone(endDate).tz('Australia/Sydney')
  const isSameMonth = startMoment.month() === endMoment.month()
  let newStartDate, newEndDate

  const isFromStartOfMonth =
    startMoment.day() === moment(startMoment).startOf('month').day()

  const isToEndOfMonth =
    endMoment.day() === moment(endMoment).endOf('month').day()

  if (isSameMonth && isFromStartOfMonth && isToEndOfMonth) {
    newStartDate = startMoment.subtract(1, 'months').startOf('month')
    newEndDate = endMoment.subtract(1, 'months').endOf('month')
  } else if (!isSameMonth) {
    const numberOfDays = Math.ceil(
      moment
        .duration(endMoment.startOf('day').diff(startMoment.endOf('day')))
        .asDays()
    )
    newEndDate = moment(startMoment).endOf('day').subtract(1, 'days')

    newStartDate = moment(newEndDate)
      .subtract(numberOfDays, 'days')
      .startOf('day')
  } else {
    newStartDate = startMoment.subtract(1, 'months')
    newEndDate = endMoment.subtract(1, 'months')
  }

  return {
    startDate: returnAsUTC ? newStartDate.utc() : newStartDate,
    endDate: returnAsUTC ? newEndDate.utc() : newEndDate
  }
}

module.exports = determineMonthOnMonthDates
