const modal = require('modal')
const debug = require('./debug')('list-delete-delegate')
const async = require('async')

module.exports = function listDeleteDelegate (selectedCollection) {
  var toDelete = selectedCollection.filter(function () { return true })

  if (toDelete.length === 0) return

  function deleteChecked () {
    async.each(toDelete
      , function (model, callback) {
        debug('deleting', model)
        model.destroy({ success: callback })
      }
      , function () {
        this.collection.remove(toDelete)
        this.collection.applyFilter()
      }.bind(this)
    )
  }

  modal({
    buttons: [
      { text: 'Cancel', event: 'cancel', className: 'btn' },
      { text: 'Confirm', event: 'confirm', className: 'btn btn--error' }
    ],
    content: 'Are you sure you want to delete ' +
      toDelete.length + ' ' + (toDelete.length === 1 ? 'item' : ' items') + '?'
  }).on('confirm', deleteChecked.bind(this))
}
