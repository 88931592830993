const extend = require('lodash.assign'),
  formErrorsDelegate =
    require('../../../../admin/source/js/lib/form-errors-delegate')()

function showErrors(formErrors) {
  let errors = {}

  if (formErrors && formErrors.mobileWallet) {
    const mobileWallet = formErrors.mobileWallet

    if (mobileWallet) {
      if (mobileWallet.mobileWalletSupplement) {
        Object.keys(mobileWallet.mobileWalletSupplement).map(function (
          errorIndex
        ) {
          var error = mobileWallet.mobileWalletSupplement[errorIndex]

          if (typeof error === 'object') {
            Object.keys(error).map(function (nestedErrorKey) {
              errors['mobileWalletSupplement_' + nestedErrorKey + errorIndex] =
                error[nestedErrorKey]
            })
          } else {
            errors[errorIndex] = error
          }
        })
      }

      if (mobileWallet.mobileWalletSecondaryFields) {
        if (typeof mobileWallet.mobileWalletSecondaryFields === 'string') {
          errors.mobileWalletSecondaryFields =
            mobileWallet.mobileWalletSecondaryFields
        } else {
          Object.keys(mobileWallet.mobileWalletSecondaryFields).map(function (
            errorIndex
          ) {
            var error = mobileWallet.mobileWalletSecondaryFields[errorIndex]

            if (typeof error === 'object') {
              Object.keys(error).map(function (nestedErrorKey) {
                errors[
                  'mobileWalletSecondaryFields_' + nestedErrorKey + errorIndex
                ] = error[nestedErrorKey]
              })
            }
          })
        }
      }

      if (mobileWallet.mobileWalletTitle) {
        errors.mobileWalletTitle = mobileWallet.mobileWalletTitle
      }

      if (mobileWallet.mobileWalletDescription) {
        errors.mobileWalletDescription = mobileWallet.mobileWalletDescription
      }

      delete formErrors.mobileWallet
    }
  }

  extend(errors, formErrors)
  console.log(errors)

  formErrorsDelegate.showErrors.call(this, errors)
}

module.exports = function () {
  return {
    clearErrors: formErrorsDelegate.clearErrors,
    showErrors: showErrors
  }
}
