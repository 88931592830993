module.exports = createValidator

var equal = require('deep-equal')

function createValidator (requiredCrops) {
  return validate

  function validate (key, msg, object, callback) {
    var cropsValid = true

    if (hasImages(object, key)) {
      object[key].widgets.forEach(function (image) {
        // If there are no crops, this is bad
        if (!Array.isArray(image.crops)) {
          cropsValid = false
          return cropsValid
        }

        var existing = image.crops
          // Get the list of crops that have been downloaded
          .filter(function (crop) { return !!crop.src })
          // Just get their names so that it can be easily to
          // compared to the list of required crops
          .map(function (crop) { return crop.name })

        if (!equal(existing.sort(), requiredCrops.slice(0).sort())) {
          /*
            Validate if all required crops are in the existing crops array - this satisfies instances
            where offers are used in other places, but only require less crops. E.g. newsletter widgets
            take an offer and only require a Square Crop, not the landscape and other crops
          */
          if (requiredCrops.every(elem => existing.indexOf(elem) > -1)) {
            return cropsValid
          }
          cropsValid = false
          return cropsValid
        }
      })
    }
    return callback(null, cropsValid ? undefined : 'Image crops have not been downloaded')
  }
}

function hasImages (object, key) {
  return object[key] && Array.isArray(object[key].widgets) && object[key].widgets.length
}
