const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const length = require('validity-length')

module.exports = () => {
  const contentStackProperties = {
    contentStack: {
      type: Array,
      validators: { all: [ length(1, null) ] },
      defaultValue: () => []
    }
  }
  const widgetSchemata = schemata({
    name: 'Content Stack',
    properties: Object.assign({}, baseSchema.getProperties(), contentStackProperties)
  })

  return widgetSchemata
}
