const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Promo',
    itemView: require('./views/item'),
    description: 'Promo Widget'
  }

  serviceLocator.widgetFactories.get('email').register('emailPromo', widget)

  done()
}

module.exports = () => ({ emailPromoWidget: [ 'widget', 'offerAdmin', init ] })
