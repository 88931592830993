const compileJade = require('browjadify-compile')
const join = require('path').join
const InstanceSelect = require('../../instance/views/instance-select')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, errors) {
buf.push("<form><div class=\"js-errors-summary\"></div><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">New Section Name</span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\">");
if ( errors.name)
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = errors.name) ? "" : jade_interp)) + "</div>");
}
buf.push("</div><div class=\"form-row-description form-copy\"><p>The new name for the section. Leave blank if duplicating to a new instance and the section name should be unchanged.</p></div></div><div id=\"field--instance\" data-field=\"instance\" class=\"form-row\"><label><span class=\"form-label-text\">Instance</span><div class=\"js-instances form-field\"></div></label><div class=\"js-error\">");
if ( errors.instance)
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = errors.instance) ? "" : jade_interp)) + "</div>");
}
buf.push("</div><div class=\"form-row-description form-copy\"><p>This is the instance that this section will be duplicated to. Leave blank to duplicate to the current instance.</p></div></div></form><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Duplicate</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"errors" in locals_for_with?locals_for_with.errors:typeof errors!=="undefined"?errors:undefined));;return buf.join("");
}
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()

module.exports = window.Backbone.View.extend({
  events: {
    'change .js-to-section-dropdown': 'handleAddToSection',
    'click .js-cancel': 'handleCancel',
    'click .js-save': 'handleSave' },
  initialize: function (params) {
    this.data = params.data || {}
    this.errors = params.errors || {}
    this.section = params.section
    this.serviceLocator = params.serviceLocator
    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
    this.render()
  },
  handleCancel: function (e) {
    e.preventDefault()
    this.trigger('cancel')
  },
  handleSave: function (e) {
    e.preventDefault()
    this.trigger('duplicate')
    this.duplicateToInstance()
  },
  render: function () {
    const instanceSelectOptions = {
      placeholder: 'Choose an instance',
      selectOptions: {
        maxItems: 1
      }
    }

    this.$el.empty().append(template({ data: this.data, errors: this.errors }))
    if (Object.keys(this.errors).length) this.showErrors(this.errors)

    this.instanceSelectView = new InstanceSelect(this.serviceLocator, this.data.instance, instanceSelectOptions)
    this.$el.find('.js-instances').append(this.instanceSelectView.render().$el)
    this.instanceSelectView.on('change', instance => {
      this.selectedInstance = instance
      this.includeSubSections = this.$el.find('.js-include-sub-sections').is(':checked')
    })

    return this
  },
  duplicateToInstance: function () {
    const selectedInstance = this.selectedInstance
    const newSectionName = this.$el.find('[name="name"]').val()

    const data = JSON.stringify({ section: this.section, name: newSectionName, instance: selectedInstance })
    window.Backbone.ajax(
      { type: 'POST',
        url: window.config.apiUrl + '/section/duplicate-to-instance',
        data: data,
        success: () => document.location.reload(),
        error: response => this.trigger('error', JSON.parse(data), response.responseJSON.errors),
        dataType: 'json',
        contentType: 'application/json'
      })
  }
})
