const baseSchema = require('../../../../service/widget/model/base-widget')()
const schemata = require('@clocklimited/schemata')
const validateLength = require('validity-length')

module.exports = () => {
  const properties = {
    lists: {
      type: Array,
      validators: [ validateLength(1) ]
    },
    limit: {
      type: Number
    },
    dedupe: {
      type: Boolean,
      defaultValue: true
    }
  }

  const widgetSchemata = schemata({
    name: 'Hero',
    properties: Object.assign(baseSchema.getProperties(), properties)
  })

  return widgetSchemata
}
