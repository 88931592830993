import Model from './model'
import ItemView from '../../widget/views/item/edit-base'
import EditView from '../../widget/views/form/base'

const widget = {
  model: Model,
  name: 'Divider',
  itemView: ItemView,
  editView: EditView,
  description: 'Display a divider on a page'
}

const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('section').register('divider', widget)
  serviceLocator.widgetFactories.get('offer').register('divider', widget)
  done()
}

module.exports = () => ({ divider: [ 'widget', 'sectionAdmin', init ] })
