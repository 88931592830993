const BaseFormView = require('../../../base/views/form')
const formErrorsDelegate = require('../../../../../admin/source/js/lib/form-errors-delegate')()
const modal = require('modal')
const WidgetAreaView = require('../widget-area')
const AssetCollection = require('../../../asset/collections/asset')
const AssetPickerView = require('../../../asset/views/picker')
const getImageFactory = require('../../../asset/lib/image-factory')()
const PartnerSelect = require('../../../partner/views/partner-select')
const RichTextInstanceManager = require('../../../../../admin/source/js/lib/rich-text-editor-instance-manager')
const config = require('../../../../../admin/lib/ckeditor-config')

class FormView extends BaseFormView {
  constructor (serviceLocator, model) {
    super(serviceLocator, model)

    this.$el.on('click', '.js-images-add', e => this.handleAddImages(e))
    this.on('afterAppend', () => this.$el.find('input[type=text]:first').focus())
    // Compatibility with the form errors delegate
    this.$ = selector => this.$el.find(selector)
    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
    this.richTextEditorManager = new RichTextInstanceManager()
    this.on('save', this.richTextEditorManager.destroy.bind(this.richTextEditorManager))
    this.on('cancel', this.richTextEditorManager.destroy.bind(this.richTextEditorManager))
    this.$el.on('submit', 'form', this.submit.bind(this))
  }

  submit (e) {
    e.preventDefault()
    this.save()
  }

  renderPartnerSelect () {
    const partner = new PartnerSelect(this.serviceLocator, this.model.get('partners'))
    this.$el.find('.js-partners').append(partner.render().$el)
    partner.on('change', partners => this.model.set('partners', partners))
    this.attachView(partner)
  }

  handleAddImages (e) {
    e.preventDefault()

    const collection = new AssetCollection()
    const assetListView = new AssetPickerView({
      collection: collection,
      type: 'image',
      serviceLocator: this.serviceLocator
    })

    collection.getByType('image')

    modal({
      title: 'Images',
      className: 'modal-asset-view wide',
      content: assetListView.$el,
      buttons: [
        { text: 'Cancel', event: 'Cancel', keyCodes: [ 27 ], className: 'btn' },
        { text: 'Add selected images', event: 'add', className: 'btn btn--action', keyCodes: [ 13 ] }
      ]
    })
      .on('add', () => this.addImages(assetListView.selectedCollection))
      .on('close', () => assetListView.remove(assetListView))
  }

  addImages (images) {
    const ImageModel = getImageFactory('image').model
    images.map(model => {
      const image = new ImageModel(model.attributes)
      this.model.images.add(image)
      image.setDefaultCrops(this.imageConfig.crops)
      return image
    })
  }

  updateAccountCategoriesAndAttributes () {
    const accountFilter = {}
    if (this.account) accountFilter._id = this.account

    this.serviceLocator.accountService.find('', accountFilter, [], { page: 1, pageSize: 1 }, (error, res) => {
      if (error) return this.serviceLocator.logger.error('Cannot get account', error)
      if (!res.results.length) return
      var $categorySelect = this.$el.find('.js-category')

      $categorySelect.empty()
      $categorySelect.append('<option>-- Select a category --</option>')
      res.results[0].categories.forEach(category => {
        $categorySelect.append('<option value="' + category + '">' + category + '</option>')
      })
      $categorySelect.find('option[value="' + this.model.get('category') + '"]').attr('selected', true)
    })
  }

  save () {
    this.clearErrors()
    this.model.validate((err, errors) => {
      if (err) return alert(err.message)
      if (Object.keys(errors).length) {
        this.showErrors(errors)
        return
      }
      this.emit('save')
    })
  }

  render () {
    // Render the template
    this.$el.append(this.template({
      title: this.isNew ? 'New Item' : 'Edit Item',
      item: this.model.toJSON()
    }))

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
    this.$('.js-text-editor').each((index, value) => this.richTextEditorManager.create(value, { height: 100, startupFocus: false, removeButtons: 'Cut,Copy,Paste,Underline,Subscript' }))
    this.$('.js-text-editor-display').each((index, value) => this.richTextEditorManager.create(value, config.display))

    this.renderImageWidgetArea()
    this.renderPartnerSelect()
    this.updateAccountCategoriesAndAttributes()
    this.$el.find('.js-save-item').on('click', () => this.save())
    this.$el.find('.js-cancel-item').on('click', () => this.emit('cancel'))

    return this
  }

  renderImageWidgetArea () {
    this.imageArea = new WidgetAreaView({
      model: this.model.images,
      receiveDrops: false,
      serviceLocator: this.serviceLocator,
      widgetItemOptions: this.imageConfig
    })
    this.$el.find('.js-image-area').append(this.imageArea.$el)
  }
}

module.exports = FormView
