module.exports={
  "crops": [
    { "name": "Widescreen", "aspectRatio": "9:5" },
    { "name": "Free", "aspectRatio": "*" }
  ],
  "contexts": [
    { "name": "Packshot", "allowMultipleSelection": false },
    { "name": "Logo - Dark Bg", "allowMultipleSelection": false },
    { "name": "Logo - Light Bg", "allowMultipleSelection": false },
    { "name": "Masthead Background", "allowMultipleSelection": false }
  ]
}
