const JSC = require('jscharting')

const generateChart = ({ series, title, xAxis, yAxis }) => {
  JSC.chart('chartDiv', {
    debug: false,
    type: 'line',
    legend: {
      template:
        '%icon %name',
      position: 'top right',
      layout: 'horizontal'
    },
    yAxis: { label_text: yAxis },
    xAxis_label_text: xAxis,
    title_label_text: title,
    series
  })
}

module.exports = generateChart
