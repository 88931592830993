const schemata = require('@clocklimited/schemata')

module.exports = () =>
  schemata({
    name: 'Tag Meta',
    properties: {
      key: {
        type: String
      },
      value: {
        type: String
      }
    }
  })
