const View = require('ventnor')
const modal = require('modal')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Email Addresses</span><div class=\"form-field\"><input type=\"text\" name=\"emailAddresses\" placeholder=\"Emails\" class=\"control control--text form-field js-email-addresses\"/></div></label><div class=\"form-row-description form-copy\"><p>A comma seperated list of emails to send the pdf to.</p></div></div></form>");;return buf.join("");
}

class EmailInputView extends View {
  constructor (...args) {
    super(...args)
    this.emailAddresses = []
    this.$el.on('keyup', '.js-email-addresses', this.handleEmailAddressesChange.bind(this))
  }

  get template () {
    return template
  }

  handleEmailAddressesChange (e) {
    const value = $(e.currentTarget).val()
    this.emailAddresses = value.split(',')
  }

  render () {
    this.$el.empty().append(this.template())
    modal({
      title: 'Email Offer Details',
      content: this.$el,
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn' },
        { text: 'Send', event: 'confirm', className: 'btn btn--success' }
      ]
    }).on('confirm', () => {
      this.emit('confirm', this.emailAddresses)
    })
  }
}

module.exports = EmailInputView
