module.exports = createSchema

const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const extend = require('lodash.assign')

function createSchema () {
  const offerHeaderSchema = {
    alignment: {
      type: String,
      defaultValue: 'Left'
    }
  }

  const widgetSchemata = schemata({
    name: 'Offer Layout Social',
    properties: extend({}, baseSchema.getProperties(), offerHeaderSchema)
  })

  return widgetSchemata
}
