const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (item) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--shortHeadline\" data-field=\"shortHeadline\" class=\"form-row\"><label><span class=\"form-label-text\">Text<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"shortHeadline\"" + (jade.attr("value", item.shortHeadline, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--link\" data-field=\"link\" class=\"form-row\"><label><span class=\"form-label-text\">Link<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"link\"" + (jade.attr("value", item.link, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div></div></div><div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Images</h2></div><div class=\"panel-content\"><div id=\"field--images\" data-field=\"images\" class=\"form-row form-row-full-width\"><div class=\"js-image-area\"></div><div class=\"js-error\"></div></div><div class=\"form-row form-row-actions\"><input value=\"Add Images\" class=\"btn btn--success js-images-add\"/></div></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"item" in locals_for_with?locals_for_with.item:typeof item!=="undefined"?item:undefined));;return buf.join("");
}
const modal = require('modal')
const WidgetAreaView = require('../../widget/views/widget-area')
const AssetCollection = require('../../asset/collections/asset')
const AssetPickerView = require('../../asset/views/picker')
const getImageFactory = require('../../asset/lib/image-factory')()
const imageConfig = require('../../../service/offer/image-config.json')

class FormView extends BaseFormView {
  constructor (...args) {
    super(...args)
    const { account } = args
    this.account = account

    this.$el.on('click', '.js-images-add', e => this.handleAddImages(e))
    this.on('afterAppend', () => this.$el.find('input[type=text]:first').focus())
    // Compatibility with the form errors delegate
    this.$ = selector => this.$el.find(selector)
    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
  }

  handleAddImages (e) {
    e.preventDefault()

    const collection = new AssetCollection()
    const assetListView = new AssetPickerView({
      collection: collection,
      type: 'image',
      serviceLocator: this.serviceLocator
    })

    collection.getByType('image')

    modal({
      title: 'Images',
      className: 'modal-asset-view wide',
      content: assetListView.$el,
      buttons: [
        { text: 'Cancel', event: 'Cancel', keyCodes: [ 27 ], className: 'btn' },
        { text: 'Add selected images', event: 'add', className: 'btn btn--action', keyCodes: [ 13 ] }
      ]
    })
      .on('add', () => this.addImages(assetListView.selectedCollection))
      .on('close', () => assetListView.remove(assetListView))
  }

  addImages (images) {
    const ImageModel = getImageFactory('image').model
    images.map(model => {
      const image = new ImageModel(model.attributes)
      this.model.images.add(image)
      image.setDefaultCrops(imageConfig.crops)
      return image
    })
  }

  save () {
    this.clearErrors()
    this.model.validate((err, errors) => {
      if (err) return alert(err.message)
      if (Object.keys(errors).length) {
        this.showErrors(errors)
        return this.emit('error')
      }
      this.emit('save')
    })
  }

  render () {
    // Render the template
    this.$el.append(template({
      title: this.isNew ? 'New Item' : 'Edit Item',
      item: this.model.toJSON()
    }))

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.renderImageWidgetArea()

    this.$el.find('.js-save-item').on('click', () => this.save())
    this.$el.find('.js-cancel-item').on('click', () => this.emit('cancel'))

    return this
  }

  renderImageWidgetArea () {
    this.imageArea = new WidgetAreaView({
      model: this.model.images,
      receiveDrops: false,
      serviceLocator: this.serviceLocator,
      widgetItemOptions: imageConfig
    })
    this.$el.find('.js-image-area').append(this.imageArea.$el)
  }
}

module.exports = FormView
