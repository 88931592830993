const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format, index, totalItems) {
buf.push("<td>" + (jade.escape((jade_interp = totalItems - index) == null ? '' : jade_interp)) + "</td><td class=\"table-cell--centered\">");
if ( index > 0)
{
buf.push("<input type=\"radio\" name=\"old\"" + (jade.attr("value", data._id, true, false)) + (jade.attr("data-index", index, true, false)) + (jade.attr("checked", (index === 1), true, false)) + " class=\"js-old\"/>");
}
buf.push("</td><td class=\"table-cell--centered\">");
if ( (index + 1) !== totalItems)
{
buf.push("<input type=\"radio\" name=\"new\"" + (jade.attr("value", data._id, true, false)) + (jade.attr("data-index", index, true, false)) + (jade.attr("checked", (index === 0), true, false)) + " class=\"js-new\"/>");
}
buf.push("</td><td>" + (jade.escape((jade_interp = format(data.createdDate, 'calendar')) == null ? '' : jade_interp)) + "</td><td>" + (jade.escape((jade_interp = data.author) == null ? '' : jade_interp)) + "</td><td>" + (jade.escape((jade_interp = data.note) == null ? '' : jade_interp)) + "</td><td>");
if ( index > 0)
{
buf.push("<button type=\"button\"" + (jade.attr("data-revision-id", data._id, true, false)) + (jade.attr("data-index", (totalItems - index), true, false)) + " class=\"btn btn--small js-restore-revision\">Restore This Revision</button>");
}
buf.push("</td>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"index" in locals_for_with?locals_for_with.index:typeof index!=="undefined"?index:undefined,"totalItems" in locals_for_with?locals_for_with.totalItems:typeof totalItems!=="undefined"?totalItems:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  constructor (...args) {
    super(...args)
    this.el = document.createElement('tr')
    this.$el.removeClass('grid__item one-whole')
    this.$el.on('click', '.js-restore-revision', this.restoreRevision.bind(this))
  }

  get template () {
    return template
  }

  restoreRevision (e) {
    const revisionId = $(e.currentTarget).data('revisionId')
    const index = $(e.currentTarget).data('index')

    this.emit('restoreRevision', revisionId, index)
  }
}

module.exports = ListItemView
