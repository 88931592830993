const schemata = require('@clocklimited/schemata')
const dateBeforePropertyValidator = require('validity-date-before-property')

module.exports = () =>
  schemata({
    name: 'Base Widget',
    properties: {
      type: {
        type: String
      },
      className: {
        type: String
      },
      visible: {
        type: Boolean,
        defaultValue: true
      },
      liveDate: {
        type: Date,
        validators: {
          all: [ dateBeforePropertyValidator() ]
        }
      },
      expiryDate: {
        type: Date
      },
      displayOptions: {
        type: Array,
        defaultValue: () => [ 'desktop', 'tablet', 'mobile' ],
        validators: [ (key, keyDisplayName, object, cb) => cb(null, (object[key] && object[key].length) < 1 ? 'At least one display option must be selected' : null) ]
      },
      essential: {
        type: Boolean,
        defaultValue: true
      },
      userVisibility: {
        type: 'String',
        defaultValue: 'all'
      }
    }
  })
