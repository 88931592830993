const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (description, name) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\"></div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2>" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</h2></div>");
if ( description)
{
buf.push("<div class=\"list-item-content expander-content\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div></div>");}.call(this,"description" in locals_for_with?locals_for_with.description:typeof description!=="undefined"?description:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const debug = require('../../../../../admin/source/js/lib/debug')('base widget item view')
const modal = require('modal')
const widgetExpander = require('../../lib/widget-expander-toggle')(debug)

module.exports = window.Backbone.View.extend({
  className: 'js-widget widget-grid__item',
  template,
  events: {
    'click .js-remove': 'handleRemove',
    'click .js-expander-toggle': 'handleExpanderToggle'
  },
  initialize () {
    debug('init')

    this.extraProperties = this.extraProperties || {}

    if (!this.factory) {
      throw new Error('You need to extend this view and provide a factory')
    }
    if (!this.template) {
      throw new Error('You need to extend this view and provide a template')
    }
    this.render()
    this.model.on('change', model => {
      debug('model changed', model)
      this.render()
    })

    this.model.on('save', model => {
      debug('model changed', model)
      this.render()
    })

    this.on('init', this.handleEdit, this)

    this.$el.attr('data-id', this.model.id)
    this.$el.attr('data-cid', this.model.cid)
  },

  handleRemove () {
    debug('remove', this.model.get('id'))

    modal(
      { content: 'Do you want to delete this widget?',
        buttons:
        [ { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [ 27 ] },
          { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
        ]
      })
      .on('confirm', () => {
        debug('remove widget confirmed')
        this.model.trigger('remove', this.model)
      })
  },

  handleExpanderToggle: widgetExpander.toggleExpander,

  render () {
    debug('rendering widget')

    this.$el.empty().append(this.template(
      Object.assign({ name: this.factory.name,
        description: this.factory.description,
        options: this.factory.options,
        model: this.model
      }, this.extraProperties
      )))

    this.$('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
})
