import ItemView from './views/item'
import FormView from './views/form'
import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    model: Model,
    itemView: ItemView,
    editView: FormView,
    name: 'Navigation',
    description: 'Navigation area placeholder'
  }

  serviceLocator.widgetFactories.get('section').register('navigation', widget)
  serviceLocator.widgetFactories.get('offer').register('navigation', widget)

  done()
}

module.exports = () => ({ navigation: [ 'sectionAdmin', init ] })
