const schemata = require('@clocklimited/schemata')
const required = require('validity-required')

module.exports = () =>
  schemata({
    name: 'Offer Redemption Type Voucher',
    properties: {
      redemptionVoucherImage: {
        type: String,
        validators: {
          all: [ required ]
        }
      },
      displayCustomerDetails: {
        type: Boolean,
        defaultValue: true
      }
    }
  })
