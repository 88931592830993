import FormView from './views/form'
import ItemView from './views/item'
import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    itemView: ItemView,
    name: 'Offer Grid',
    description: 'Offer Grid'
  }

  serviceLocator.widgetFactories.get('section').register('offerGrid', widget)
  serviceLocator.widgetFactories.get('relatedOffer').register('offerGrid', widget)

  done()
}

module.exports = () => ({ offerGridWidget: [ 'widget', 'sectionAdmin', init ] })
