const Model = require('./models/purchase')
const FormView = require('./views/form')

const init = (serviceLocator, done) => {
  serviceLocator.redemptionTypeFactory.register('purchase', {
    name: 'Purchase',
    FormView,
    Model,
    supportsUniqueCodes: true,
    supportsStockLimits: true
  })
  done()
}

module.exports = () => ({ purchaseRedemptionType: [ 'offerAdmin', init ] })
