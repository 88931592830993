const ListModel = require('../../list/models/model')
const schemata = require('./schema')()

module.exports = ListModel.extend({
  schemata,
  type: 'hero',
  displayName: 'Hero',
  defaults () {
    return this.schemata.makeDefault({ type: this.type || 'hero' })
  }
})
