const FormView = require('../views/form')
const Model = require('../models/instance')
const notify = require('../../notification/foreground')

module.exports = serviceLocator => {
  // Edit
  serviceLocator.router.route('instances/:id/form', 'editInstance', id => {
    if (!serviceLocator.allow('instance', 'update')) return false

    serviceLocator.instanceService.read(id, (err, instance) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      var form = new FormView(serviceLocator, new Model(serviceLocator, instance), false).render()
      serviceLocator.router.render(form, 'Edit Instance')

      form.on('back', back)

      form.on('save', () => {
        saveExisting(id, form, err => {
          if (err) return
          notify('Saved', 'save')
        })
      })

      form.on('saveAndClose', () => {
        saveExisting(id, form, err => {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate('instances', { trigger: true })
        })
      })
    })
  })

  // Create
  serviceLocator.router.route('instances/form', 'createInstance', () => {
    if (!serviceLocator.allow('instance', 'create')) return false

    const model = new Model(serviceLocator)
    let form

    model.set(model.schema.makeDefault())

    form = new FormView(serviceLocator, model, true).render()

    serviceLocator.router.render(form, 'New Instance')

    form.on('back', back)

    form.on('save', () => {
      saveNew(form, (err, saved) => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('instances/' + saved._id + '/form', { trigger: true })
      })
    })

    form.on('saveAndClose', () => {
      saveNew(form, err => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('instances', { trigger: true })
      })
    })
  })

  const saveExisting = (id, form, cb) => {
    serviceLocator.instanceService.update(id, form.model.toJSON(), (err, instance) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, instance)
    })
  }

  const saveNew = (form, cb) => {
    serviceLocator.instanceService.create(form.model.toJSON(), (err, instance) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      cb(null, instance)
    })
  }

  const back = () => {
    serviceLocator.router.navigate('instances', { trigger: true })
  }
}
