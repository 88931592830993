const schemata = require('@clocklimited/schemata')
const BaseModel = require('cf-base-model')
const required = require('validity-required')
const createContextValidator = require('validity-cf-image-context-selection')
const createImageUrlBuilder = require('cf-image-url-builder')
const ImageAreaModel = require('../../asset/models/image-area')
const config = window.config

const schema = schemata({
  name: 'Seasonal Campaign Item',
  properties: {
    date: {
      type: Date,
      validators: { all: [required] }
    },

    images: {
      type: Object,
      defaultValue: () => ({}),
      validators: { all: [createContextValidator(['Available', 'Redeemed'])] }
    },

    offer: {
      type: String,
      validators: { all: [required] }
    },

    revealText: {
      type: String,
      validators: { all: [required] }
    },

    color: {
      type: String,
      validators: { all: [required] }
    }
  }
})

module.exports = BaseModel.extend({
  schema,
  defaults() {
    return schema.makeDefault()
  },
  validate(cb) {
    this.validateOffer((requestError, offerError) => {
      if (requestError) return cb(requestError)
      this.schema.validate(this.attributes, (ignoreErr, errors) => {
        if (!errors.offer && offerError) errors.offer = offerError
        return cb(Object.keys(errors).length > 0 ? errors : undefined)
      })
    })
  },
  initialize: function (model, serviceLocator) {
    this.images = new ImageAreaModel(this.get('images'))
    this.serviceLocator = serviceLocator
  },

  validateOffer(cb) {
    if (this.get('offer')) {
      return this.serviceLocator.offerService.read(
        this.get('offer'),
        (err, offer) => {
          if (err) return cb(err)

          this.serviceLocator.sectionService.find(
            '',
            { _id: { $in: offer.sections } },
            [],
            { _id: 1, instance: 1 },
            (err, sections) => {
              if (err) return cb(err)
              const instanceIds = sections.results.map((s) => s.instance)

              this.serviceLocator.instanceService.find(
                '',
                { _id: { $nin: instanceIds }, account: offer.account },
                [],
                {},
                (err, instances) => {
                  if (err) return cb(err)

                  if (instances.results.length > 0) {
                    const instanceNamesArr = instances.results.map(
                      (i) => i.name
                    )
                    const instanceNamesStr = instanceNamesArr.reduce(
                      (m, name, i, arr) => {
                        if (i === 0) return name
                        if (i === arr.length - 1) return `${m} and ${name}`
                        return `${m}, ${name}`
                      },
                      ''
                    )
                    const message = `The following instances are not included in this offer: ${instanceNamesStr}`
                    return cb(null, message)
                  }

                  cb()
                }
              )
            }
          )
        }
      )
    }
    cb()
  },

  getPreviewImageUrl: function () {
    const images = this.images.widgets.map(function (widget) {
      return widget.toJSON()
    })
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Available')
    if (!image) return null
    return image.crop('Square').constrain(300).url()
  }
})
