const async = require('async')

module.exports = serviceLocator => (instance, account, cb) => {
  async.parallel({
    instance: cb => {
      serviceLocator.instanceService.read(instance, (error, instance) => {
        if (error) return cb(error)
        return cb(null, instance)
      })
    },
    account: cb => {
      serviceLocator.accountService.read(account, (error, account) => {
        if (error) return cb(error)
        return cb(null, account)
      })
    }
  }, (error, object) => {
    if (error) return cb(error)
    return cb(null, object)
  })
}
