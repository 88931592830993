const ListView = require('../views/list')
const Collection = require('chale')
const TagModel = require('../models/tag')
const Model = require('merstone')
const async = require('async')
const pageSize = 50

module.exports = serviceLocator => {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, { totalItems: 0, showing: 0 })
  let currentPage = 1
  let currentParams = { keywords: '', filter: {}, sort: [ 'dateCreated', 'desc' ] }
  const pagination = { page: currentPage, pageSize: pageSize }

  const getTags = (keywords, filter, sort, pagination) => {
    serviceLocator.tagService.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err) return serviceLocator.logger.error('Could not load tags', err)
      collection.reset(res.results.map(tag => new TagModel(serviceLocator, tag)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendTags = (keywords, filter, sort, pagination) => {
    serviceLocator.tagService.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(tag => {
        collection.add(new TagModel(serviceLocator, tag))
      })
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  // Whenever an tag is updated, reset the model with its new attributes
  serviceLocator.tagService.on('update', (id, attrs) => {
    var model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.tagService.on('create', () => {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getTags(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
  })

  serviceLocator.router.route('tags(/)', 'listTags', () => {
    if (!serviceLocator.allow('tag', 'discover')) return false

    getTags(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)

    var list = new ListView(serviceLocator, collection, paginationModel, serviceLocator.tagTypes).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('tag', 'create')) return false
      serviceLocator.router.navigate('tags/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('tag', 'update')) return false
      serviceLocator.router.navigate('tags/' + id + '/form', { trigger: true })
    })

    list.on('delete', ids => {
      if (!serviceLocator.allow('tag', 'delete')) return false
      const deleteOne = (id, cb) => {
        serviceLocator.tagService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
        })
      }

      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
      })
    })

    list.on('filter', params => {
      currentParams = params
      var pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getTags(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendTags(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
    })

    serviceLocator.router.render(list, 'Tags')
  })
}
