const MenuBarView = require('../views/menu-bar')
const menu =
  [ { title: 'Content',
    items:
    [ { name: 'Sections', route: 'sections', resource: 'section' },
      { name: 'Offers', route: 'offers', resource: 'offer' },
      { name: 'Lists', route: 'lists', resource: 'list' },
      { name: 'FAQs', route: 'faqs', resource: 'faq' },
      { name: 'Assets', route: 'asset-manager', resource: 'asset' },
      { name: 'Partners', route: 'partners', resource: 'partner' },
      { name: 'Form Builder', route: 'form-builders', resource: 'form-builder' },
      { name: 'Tags', route: 'tags', resource: 'tag' },
      { name: 'Redemption Data', route: 'redemption-data', resource: 'redemption-data' },
      { name: 'Reporting Dashboard', route: 'redemption-report', resource: 'redemption-report' },
      { name: 'Redirects', route: 'redirects', resource: 'redirect' },
      { name: 'Email Builder', route: 'emails', resource: 'email' },
      { name: 'Data Capture', route: 'data-capture', resource: 'dataCapture' }
    ]
  },
  { title: 'User Management',
    items:
    [ { name: 'Roles', route: 'roles', resource: 'role' },
      { name: 'Administrators', route: 'administrators', resource: 'administrator' }
    ]
  },
  { title: 'Settings',
    items:
    [ { name: 'Accounts', route: 'accounts', resource: 'account' },
      { name: 'Instances', route: 'instances', resource: 'instance' }
    ]
  },
  { title: 'Analytics',
    items:
    [ { name: 'Offer Analytics', route: 'analytics/offer', resource: '' }
    ]
  }
  ]

const createController = (serviceLocator) => {
  serviceLocator.hub.once('acl:load', () => {
    const menuBarView = new MenuBarView({
      el: $('.js-menu-bar'),
      menu: filterMenu(menu, serviceLocator),
      serviceLocator: serviceLocator
    })

    menuBarView.render()

    menuBarView.on('route', route => {
      serviceLocator.router.navigate(route, { trigger: true })
    })
  })
}

const filterMenu = (menu, serviceLocator) =>
  menu.filter(section => {
    section.items = section.items.filter(menuItem =>
      (menuItem && serviceLocator.allowed(menuItem.resource, 'discover')) || !menuItem.resource
    )
    return section.items.length > 0
  })

module.exports = createController
