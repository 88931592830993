const createController = require('./controllers/controller')
const PartnerService = require('./services/partner')

const init = (serviceLocator, done) => {
  serviceLocator.partnerService = new PartnerService(serviceLocator)
  createController(serviceLocator)
  done()
}

module.exports = () => ({ partnerAdmin: init })
