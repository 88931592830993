const AssetModel = require('../models/asset')
const BaseCollection = require('../../../../admin/source/js/lib/base-collection')
const config = window.config

module.exports = BaseCollection.extend({
  model: AssetModel,

  url: config.apiUrl + '/assets',

  getByType (assetType) {
    var filterType = { type: assetType }
    if (assetType === 'image') {
      filterType = { 'type': { '$in': [ 'image', null ] } }
    }
    this.applyFilter({ filter: filterType })
  }
})
