const compileJade = require('browjadify-compile')
const join = require('path').join
const InstanceSelect = require('../../instance/views/instance-select')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, errors) {
buf.push("<form><div class=\"js-errors-summary\"></div><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">New Email Name</span><input type=\"text\" name=\"name\"" + (jade.attr("value", data.name, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\">");
if ( errors.name)
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = errors.name) ? "" : jade_interp)) + "</div>");
}
buf.push("</div><div class=\"form-row-description form-copy\"><p>The new name for the email. Leave blank if duplicating to a new instance and the email name should be unchanged.</p></div></div><div id=\"field--subject\" data-field=\"subject\" class=\"form-row\"><label><span class=\"form-label-text\">New Email Subject</span><input type=\"text\" name=\"subject\"" + (jade.attr("value", data.subject, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\">");
if ( errors.subject)
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = errors.subject) ? "" : jade_interp)) + "</div>");
}
buf.push("</div><div class=\"form-row-description form-copy\"><p>The new subject for the email. Leave blank if duplicating to a new instance and the email subject should be unchanged.</p></div></div><div id=\"field--previewText\" data-field=\"previewText\" class=\"form-row\"><label><span class=\"form-label-text\">New Email Preview Text</span><input type=\"text\" name=\"previewText\"" + (jade.attr("value", data.previewText, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\">");
if ( errors.previewText)
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = errors.previewText) ? "" : jade_interp)) + "</div>");
}
buf.push("</div><div class=\"form-row-description form-copy\"><p>The new preview text for the email. Leave blank if duplicating to a new instance and the email preview text should be unchanged.</p></div></div><div id=\"field--instance\" data-field=\"instance\" class=\"form-row\"><label><span class=\"form-label-text\">Instances</span><div class=\"js-instances form-field\"></div></label><div class=\"js-error\">");
if ( errors.instance)
{
buf.push("<div class=\"form-row-description form-row-error-text\">" + (jade.escape(null == (jade_interp = errors.instance) ? "" : jade_interp)) + "</div>");
}
buf.push("</div><div class=\"form-row-description form-copy\"><p>This is the instances that this section will be duplicated to. Leave blank to duplicate to the current instance.</p><p>Note: Only offers that are available on the selected instances will be duplicated.</p></div></div></form><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Duplicate</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"errors" in locals_for_with?locals_for_with.errors:typeof errors!=="undefined"?errors:undefined));;return buf.join("");
}
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()

module.exports = window.Backbone.View.extend({
  events: {
    'click .js-cancel': 'handleCancel',
    'click .js-save': 'handleSave' },
  initialize: function (params) {
    this.data = params.data || {}
    this.errors = params.errors || {}
    this.email = params.email
    this.serviceLocator = params.serviceLocator
    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
    this.render()
  },
  handleCancel: function (e) {
    e.preventDefault()
    this.trigger('cancel')
  },
  handleSave: function (e) {
    e.preventDefault()
    this.trigger('duplicate')
    this.duplicateToInstances()
  },
  render: function () {
    this.$el.empty().append(template({ data: this.data, errors: this.errors }))
    if (Object.keys(this.errors).length) this.showErrors(this.errors)

    this.instanceSelectView = new InstanceSelect(this.serviceLocator, this.data.instance)
    this.$el.find('.js-instances').append(this.instanceSelectView.render().$el)
    this.instanceSelectView.on('change', instances => {
      this.selectedInstances = instances
    })

    return this
  },
  duplicateToInstances: function () {
    const selectedInstances = this.selectedInstances
    const newEmailName = this.$el.find('[name="name"]').val()
    const newEmailSubject = this.$el.find('[name="subject"]').val()
    const newEmailPreviewText = this.$el.find('[name="previewText"]').val()

    const data = JSON.stringify({
      email: this.email,
      name: newEmailName,
      subject: newEmailSubject,
      previewText: newEmailPreviewText,
      instances: selectedInstances
    })

    window.Backbone.ajax(
      { type: 'POST',
        url: window.config.apiUrl + '/email/duplicate-to-instance',
        data: data,
        success: () => document.location.reload(),
        error: response => this.trigger('error', JSON.parse(data), response.responseJSON.errors),
        dataType: 'json',
        contentType: 'application/json'
      })
  }
})
