const PartnerModel = require('../models/partner')
const BaseCollection = require('../../../../admin/source/js/lib/base-collection')
const config = window.config

module.exports = BaseCollection.extend({
  model: PartnerModel,
  url: config.apiUrl + '/partners',
  sortProperty: 'name',
  sortDirection: 'asc'
})
