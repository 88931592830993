const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()
const validateLength = require('validity-length')
const required = require('validity-required')

const schema = schemata({
  name: 'List',
  properties: Object.assign({}, baseSchema.getProperties(), {
    title: {
      type: String,
      validators: {
        all: [required],
      },
    },
    subtitle: {
      type: String,
    },
    offers: {
      type: Array,
      validators: {
        all: [validateLength(1)],
      },
      defaultValue: () => [],
    },
  }),
})

module.exports = BaseModel.extend({
  idAttribute: 'id',

  schemata: schema,

  type: 'featuredCompWinners',

  defaults() {
    return schema.makeDefault({ type: this.type || 'featuredCompWinners' })
  },

  validate: validateDelegate,
})
