const CrudService = require('../../../../admin/source/js/lib/crud-service')

class PartnerService extends CrudService {
  get name () {
    return 'PartnerService'
  }

  get urlRoot () {
    return '/partners'
  }
}

module.exports = PartnerService
