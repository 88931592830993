const determinePercentage = require('./percentage-determiner')
const calculateTotal = require('./total-calculator')
const groupColumns = require('./column-grouper')

const createTotalsFooter = (data) => {
  const groupedData = groupColumns(data)

  const { MoM, YoY, 'Campaign Period': campaignPeriod } = groupedData || {}

  const moMTotal = calculateTotal(MoM)
  const yoYTotal = calculateTotal(YoY)
  const campaignPeriodTotal = calculateTotal(campaignPeriod)

  const moMPercentage = determinePercentage(moMTotal, campaignPeriodTotal)
  const yoYPercentage = determinePercentage(yoYTotal, campaignPeriodTotal)

  return [ [
    'Totals',
    moMTotal,
    campaignPeriodTotal,
    yoYTotal,
    moMPercentage ? `${moMPercentage}%` : '',
    yoYPercentage ? `${yoYPercentage}%` : ''
  ] ]
}

module.exports = createTotalsFooter
