const Collection = require('chale')
const Model = require('merstone')
const ListView = require('../views/list')
const createGetRevisions = require('../lib/get-revisions')

module.exports = (serviceLocator, service, type, route, previousView, titleProperty) => {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, { totalItems: 0, showing: 0 })
  const getRevisions = createGetRevisions(serviceLocator, collection, paginationModel)

  serviceLocator.router.route(route, 'listRevisions', id => {
    service.read(id, (err, data) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)
      getRevisions(id, type)
      var list = new ListView(serviceLocator, collection, paginationModel, type, id, data[titleProperty]).render()
      list.on('back', () => {
        serviceLocator.router.navigate(previousView, { trigger: true })
      })
      serviceLocator.router.render(list, 'Revisions')
    })
  })
}
