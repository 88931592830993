const getSortedUnique = require('../sorted-unique-retriever')

const transformToChart = (data, keyField) => {
  const uniqueKeyField = getSortedUnique(data, keyField)
  const uniqueTitles = getSortedUnique(data, 'title')

  const defaultKeyFieldTemplate = uniqueKeyField.map(keyFieldItem => keyFieldItem)

  const chartData = uniqueTitles.map(title => {
    const points = defaultKeyFieldTemplate.map(keyFieldItem => {
      const foundItem = data.find(item => {
        return (item.title === title) && (item[keyField] === keyFieldItem)
      })
      if (!foundItem) return { x: keyFieldItem, y: 0 }
      return ({ x: keyFieldItem, y: foundItem.value })
    })
    return { name: title, points }
  })

  return chartData
}

module.exports = transformToChart
