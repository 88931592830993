const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../service/widget/model/base-widget')()
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()

const schema = schemata({
  name: 'Salutation',
  properties: Object.assign({}, baseSchema.getProperties(), {
    personalise: {
      type: Boolean,
      defaultValue: false,
    },
    subtitle: {
      type: String,
    },
  }),
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'text' })
  },
  validate: validateDelegate,
})
