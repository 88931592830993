const createContoller = require('./controllers/controller')
const LayoutService = require('./services/layout')

const init = (serviceLocator, done) => {
  serviceLocator.layoutService = new LayoutService(serviceLocator)
  createContoller(serviceLocator)
  done()
}

module.exports = () => ({ layout: init })
