const View = require('ventnor')
const formErrorsDelegate =
  require('../../../../admin/source/js/lib/form-errors-delegate')()
const isEqual = require('lodash.isequal')
const ToolbarView = require('../../toolbar/views/form-toolbar')

class FormView extends View {
  constructor(serviceLocator, model, isNew) {
    super(...arguments)
    this.model = model
    this.clearUnsavedChanges()
    this.setupToolbar()
    this.isNew = !!isNew
    this.$el.on('change', this.applyChangeToModel.bind(this))
    this.$el.on('submit', 'form', this.submit.bind(this))

    // Compatibility with backbone admin
    this.trigger = super.emit

    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
  }

  // Compatibility with the form errors delegate
  $(selector) {
    return this.$el.find(selector)
  }

  applyChangeToModel(e) {
    const { name, type, value, checked } = e.target
    const properties = this.model.schema.getProperties()
    if (Object.prototype.hasOwnProperty.call(properties, name)) {
      if (type === 'checkbox' && properties[name].type === Boolean) {
        this.model.set(name, checked)
      } else if (type === 'checkbox' && properties[name].type === Array) {
        const values = this.$el
          .find('[name=' + name + ']')
          .toArray()
          .filter((el) => el.checked)
          .map((el) => el.value)
        this.model.set(name, values)
      } else {
        this.model.set(name, value)
      }
    }
  }

  clearUnsavedChanges() {
    this.initialModel = this.model.toJSON()
  }

  hasUnsavedChanges() {
    return !isEqual(this.initialModel, this.model.toJSON())
  }

  setupToolbar() {
    this.toolbar = new ToolbarView(this.serviceLocator, this.model, this)
    this.toolbar.on('back', this.emit.bind(this, 'back'))
    this.toolbar.on('save', this.emit.bind(this, 'save'))
    this.toolbar.on('saveAndClose', this.emit.bind(this, 'saveAndClose'))
  }

  template() {
    return ''
  }

  render() {}

  submit(e) {
    e.preventDefault()
    this.emit('save')
  }

  close() {
    this.emit('close')
  }
}

module.exports = FormView
