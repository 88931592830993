import FormView from './views/form'
import ItemView from './views/item'
import Model from './models/model'

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    itemView: ItemView,
    name: 'Most Popular',
    description: 'Displays the most popular offers in a grid'
  }

  serviceLocator.widgetFactories.get('section').register('mostPopular', widget)

  done()
}

module.exports = () => ({ mostPopularWidget: [ 'widget', 'sectionAdmin', init ] })
