// Ensures object has the expected functional interface
// var dog = new Dog()
// ['eat', 'sleep', 'shit'].map(interfaceCheck.bind(dog))
module.exports = function interfaceCheck () {
  let fnName = arguments[ 0 ]
  let type = 'function'
  if (typeof arguments[ 1 ] === 'string') {
    fnName = arguments[ 1 ]
    type = arguments[ 0 ]
  }
  const actualType = typeof this[ fnName ]
  if (actualType === 'undefined') {
    throw new Error('this.' + fnName + ' must be implemented')
  } else if (actualType !== type) {
    throw new Error('this.' + fnName + ' must be a ' + type)
  }
}
