const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const createRevisionController = require('../revision/controllers/controller')
const AccountService = require('./services/account')

const initAdmin = () => ({ accountAdmin: init })

const init = (serviceLocator, done) => {
  serviceLocator.accountService = new AccountService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  createRevisionController(serviceLocator,
    serviceLocator.accountService,
    'account',
    'accounts/:id/revisions',
    'accounts',
    'name'
  )
  done()
}

module.exports = initAdmin
