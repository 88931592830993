const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const TagService = require('./services/tag')

const init = (serviceLocator, done) => {
  serviceLocator.tagTypes = [ 'General', 'System', 'Instance', 'Person', 'Sub Category' ]
  serviceLocator.tagService = new TagService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  done()
}

module.exports = () => ({ tagAdmin: init })
