module.exports = initWidget

function initWidget () {
  return { offerLayoutHeaderWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init (serviceLocator, done) {
  serviceLocator.widgetFactories.get('offer').register('offerLayoutHeader',
    { model: require('./models/model'),
      name: 'Offer Header',
      itemView: require('./views/item'),
      editView: require('./views/form'),
      description: 'Placeholder for the offer header'
    })

  done()
}
