const createController = require('./controllers/controller')
const AccountService = require('./services/account')
const RedemptionReportingService = require('./services/redemption-reporting')

const init = (serviceLocator, done) => {
  serviceLocator.redemptionReportingService = new RedemptionReportingService(serviceLocator)
  serviceLocator.accountService = new AccountService(serviceLocator)
  createController(serviceLocator)
  done()
}

module.exports = () => ({ redemptionReportAdmin: [ init ] })
