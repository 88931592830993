const BaseModel = require('cf-base-model')
const validateDelegate =
  require('../../../../../../admin/source/js/lib/validate-delegate')()
const schemata = require('@clocklimited/schemata')
const baseSchema = require('../../../../../service/widget/model/base-widget')()
const validateIfSet = require('validity-validate-if-set')
const isUrl = require('validity-url')
const ImageAreaModel = require('../../../../asset/models/image-area')
const config = window.config
const createImageUrlBuilder = require('cf-image-url-builder')

const schema = schemata({
  name: 'Offer Image',
  properties: Object.assign({}, baseSchema.getProperties(), {
    image: {
      type: Object,
      defaultValue: () => ({}),
      validators: { all: [] }
    },
    callToActionText: {
      type: String,
      validators: []
    },
    callToActionLink: {
      type: String,
      validators: [validateIfSet(isUrl)]
    },
    termsAndConditions: {
      type: String
    },
    segment: {
      type: String,
      defaultValue: () => false
    }
  })
})

module.exports = BaseModel.extend({
  schemata: schema,
  defaults() {
    return schema.makeDefault({ type: this.type || 'offerImage' })
  },
  initialize() {
    this.image = new ImageAreaModel(this.get('image'))
  },
  getPreviewImageUrl() {
    const images = this.image.widgets.map((widget) => widget.toJSON())
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Image')
    if (!image) return null
    return image.crop('Landscape').constrain(300).url()
  },
  validate: validateDelegate
})
