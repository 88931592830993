const CrudService = require('../../../../admin/source/js/lib/crud-service')

class DataCaptureService extends CrudService {
  get name () {
    return 'DataCaptureService'
  }

  get urlRoot () {
    return '/data-capture'
  }
}

module.exports = DataCaptureService
