const init = (serviceLocator, done) => {
  const audioWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Audio',
    itemView: require('./views/item'),
    description: 'Embed Audio Files'
  }

  serviceLocator.widgetFactories.get('offerBody').register('audio', audioWidget)

  done()
}

module.exports = () => ({ audioWidget: [ 'widget', 'sectionAdmin', init ] })
