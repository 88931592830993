const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const createController = require('./controllers/controller')
const OfferService = require('./services/offer')
const createWidgetFactory = require('../widget/lib/base-factory')
const createRegistry = require('regg')

const init = (serviceLocator, cb) => {
  serviceLocator.offerService = new OfferService(serviceLocator)

  serviceLocator.widgetFactories.register('offerBody', createWidgetFactory())
  serviceLocator.widgetFactories.register('relatedOffer', createWidgetFactory())

  serviceLocator.redemptionTypeFactory = createRegistry()

  // Add convenience function to the article type factory to list their display names
  serviceLocator.redemptionTypeFactory.listAll = () =>
    serviceLocator.redemptionTypeFactory.list().map(type => {
      const redemptionType = serviceLocator.redemptionTypeFactory.get(type)
      return {
        name: redemptionType.name,
        allowedAsSecondary: redemptionType.allowedAsSecondary,
        type
      }
    })

  createListController(serviceLocator)
  createFormController(serviceLocator)
  createController(serviceLocator)

  cb(null)
}

module.exports = () => ({ offerAdmin: [ 'widget', 'uniqueCodesAdmin', init ] })
