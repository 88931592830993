const LocationsView = require('../views/locations')
const QuestionService = require('../services/question')

const createController = serviceLocator => {
  serviceLocator.router.route('question-locations/:id/:type', 'viewLocations', (id, type) => {
    const service = new QuestionService(serviceLocator)
    service.findLocations(id, type, (err, data) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const view = new LocationsView(serviceLocator, data).render()
      serviceLocator.router.render(view)

      view.on('back', () => {
        window.history.back()
      })
    })
  })
}

module.exports = createController
