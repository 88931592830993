const View = require('ventnor')
const extend = require('lodash.assign')
const compileJade = require('browjadify-compile')
const join = require('path').join
const optionTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (__instance, fullUrlPath, name) {
buf.push("<div class=\"item\">");
if ( __instance)
{
buf.push("<span class=\"instance-label\"><img" + (jade.attr("src", __instance.logoUrl, true, false)) + (jade.attr("alt", __instance.name, true, false)) + (jade.attr("title", __instance.name, true, false)) + "/></span>");
}
buf.push("" + (jade.escape((jade_interp = name) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = fullUrlPath) == null ? '' : jade_interp)) + " </div>");}.call(this,"__instance" in locals_for_with?locals_for_with.__instance:typeof __instance!=="undefined"?__instance:undefined,"fullUrlPath" in locals_for_with?locals_for_with.fullUrlPath:typeof fullUrlPath!=="undefined"?fullUrlPath:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const createImageUrlBuilder = require('cf-image-url-builder')
class SectionSelect extends View {
  constructor (serviceLocator, selectedSections) {
    super(...arguments)
    this.selectedSections = selectedSections || []
    this.initFilter = { _id: { $in: this.selectedSections } }
    this.loadFilter = { root: false }
    this.selectizeOptions = {}
    this.init()
  }

  get multiple () {
    return false
  }

  init () {
    this.$el = $(this.multiple ? '<select multiple/>' : '<select/>')
    this.$el.addClass('control control--choice')
    this.el = this.$el[0]
    this.$el.attr('placeholder', this.multiple ? 'Choose sections' : 'Choose one section')
  }

  initializeSelectize () {
    this.serviceLocator.sectionService.find('', this.initFilter, [], { pageSize: 5000 }, (err, res) => {
      if (err) return this.serviceLocator.logger.error('Cannot find existing sections', err)
      res.results.forEach(section => {
        section = this.embellishInstanceLogo(section)
        // The item needs to be added to the list
        // of selectize options in order to be selected
        this.el.selectize.addOption(section)
        // Select the added option
        this.el.selectize.addItem(section._id)
      })
      this.el.selectize.on('change', this.updateSelection.bind(this))
    })
  }

  updateSelection () {
    this.selectedSections = this.el.selectize.getValue()
    this.emit('change', this.selectedSections)
  }

  load (query, cb) {
    this.serviceLocator.sectionService.find(query, this.loadFilter, [], { pageSize: 5000 }, (err, data) => {
      if (err) return this.serviceLocator.logger.error('Cannot find sections', err)
      data.results.map(section => this.embellishInstanceLogo(section))
      cb(data.results)
    })
  }

  embellishInstanceLogo (section) {
    if (section.__instance && section.__instance.images) {
      const { url, salt } = this.serviceLocator.config.darkroom
      const imageUrlBuilder = createImageUrlBuilder(url, salt, section.__instance.images.widgets)
      const image = imageUrlBuilder && imageUrlBuilder.getImage('Logo - Light Bg')
      const logoUrl = image && image.crop() && image.crop().url()

      section.__instance.logoUrl = logoUrl
    }
    return section
  }

  render () {
    setTimeout(() => {
      this.$el.selectize(
        extend({ delimiter: ',',
          persist: false,
          create: false,
          onInitialize: this.initializeSelectize.bind(this),
          load: this.load.bind(this),
          preload: true,
          valueField: '_id',
          labelField: 'name',
          searchField: 'name',
          render: { option: optionTemplate, item: optionTemplate }
        }, this.selectizeOptions))
    }, 0)
    return this
  }
}

module.exports = SectionSelect
