const schemata = require('@clocklimited/schemata')
const questionSchema = require('./question-schema')
const validity = require('validity')
const required = require('validity-required')
const isNotEmpty = value => value.length > 0
const nonEmptyValidator =
 validity.createValidatorAllowingFailureMessageOverride(validity.booleanToCallback(isNotEmpty), 'Must have at least one question')

module.exports = () => {
  return schemata({
    name: 'Form Builder',
    properties: {
      _id: {
        type: String
      },
      title: {
        type: String,
        validators: [ required ]
      },
      type: {
        type: String,
        validators: [ required ]
      },
      questions: {
        defaultValue: () => [],
        type: schemata.Array(questionSchema()),
        validators: [ nonEmptyValidator ]
      },
      dateCreated: {
        type: Date,
        defaultValue: () => { return new Date() }
      },
      account: {
        type: String,
        validators: [ required ]
      },
      responseCount: {
        type: Number,
        defaultValue: 0
      }
    }
  })
}
