const createAuthedRequest = require('../../../../admin/source/js/lib/authed-request')

class QuestionService {
  constructor (serviceLocator) {
    this.authedRequest = createAuthedRequest(serviceLocator.config.apiUrl)
  }

  findLocations (id, type, cb) {
    this.authedRequest('GET', '/question/locations/' + id + '/' + type, null, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(body)
      cb(null, body)
    })
  }
}

module.exports = QuestionService
