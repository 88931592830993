const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (locations, title, undefined) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-btn-back\">Back</button></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>Locations: " + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h1></header><div class=\"panel panel-styled\"><div class=\"panel-content\">");
if ( !locations.length)
{
buf.push("<p>This is not currently used anywhere within the CMS</p>");
}
else
{
buf.push("<table><thead><tr><th>Type</th><th>Name</th><th>Actions</th></tr></thead><tbody>");
// iterate locations
;(function(){
  var $$obj = locations;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var location = $$obj[$index];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = location.type) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = location.name) ? "" : jade_interp)) + "</td><td><button" + (jade.attr("data-link", location.link, true, false)) + " class=\"btn spacing-right js-view-location\">Edit</button></td></tr>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var location = $$obj[$index];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = location.type) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = location.name) ? "" : jade_interp)) + "</td><td><button" + (jade.attr("data-link", location.link, true, false)) + " class=\"btn spacing-right js-view-location\">Edit</button></td></tr>");
    }

  }
}).call(this);

buf.push("</tbody></table>");
}
buf.push("</div></div></div></div>");}.call(this,"locations" in locals_for_with?locals_for_with.locations:typeof locations!=="undefined"?locations:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class LocationView extends View {
  constructor (serviceLocator, locations) {
    super(serviceLocator)
    this.data = locations
    this.$el.on('click', '.js-btn-back', this.emit.bind(this, 'back'))
    this.$el.on('click', '.js-view-location', this.handleViewLocation.bind(this))
  }

  // Compatibility with backbone admin
  trigger (e, val) {
    this.emit(e, val)
  }

  handleViewLocation (e) {
    const link = $(e.target).data('link')
    this.serviceLocator.router.navigate(link, { trigger: true })
  }

  render () {
    this.$el.append(template({
      title: this.data.name,
      locations: this.data.locations
    }))

    return this
  }
}

module.exports = LocationView
