const getSortedUnique = require('../sorted-unique-retriever')
const flattenArray = require('../array-flattener')
const createTotalsFooter = require('./totals-footer-creator')
const determinePercentage = require('./percentage-determiner')

const transformToReport = (
  data,
  startDate,
  endDate,
  keyField,
  ranking,
  createHeaders
) => {
  const reportData = {}

  let uniqueKeyField = getSortedUnique(data, keyField)
  const dataGroupedOnKeyField = flattenArray(
    uniqueKeyField.map((name) => {
      return data.filter((item) => item[keyField] === name)
    })
  )

  // Adds values
  const dataTemplate = uniqueKeyField.map((keyFieldItem) => {
    return Object.keys(ranking).map((title) => {
      const foundKeyField = dataGroupedOnKeyField.find((item) => {
        return item[keyField] === keyFieldItem && item.title === title
      })

      if (foundKeyField) {
        return foundKeyField
      }

      return {
        value: 0,
        title,
        [keyField]: keyFieldItem
      }
    })
  })

  // Adds % Comparisons
  dataTemplate.forEach((keyField) => {
    const MoM = keyField.find((item) => item.title === 'MoM')
    const filter = keyField.find((item) => item.title === 'Campaign Period')
    const YoY = keyField.find((item) => item.title === 'YoY')

    keyField.push({ value: '', title: 'MoM %' })
    keyField.push({ value: '', title: 'YoY %' })

    if (MoM.value) {
      const percentageChangeMoM = determinePercentage(MoM.value, filter.value)
      const percentageMoM = keyField.find((item) => item.title === 'MoM %')
      percentageMoM.value = `${percentageChangeMoM}%`
    }

    if (YoY.value) {
      const percentageChangeYoY = determinePercentage(YoY.value, filter.value)
      const percentageYoY = keyField.find((item) => item.title === 'YoY %')
      percentageYoY.value = `${percentageChangeYoY}%`
    }
  })

  // Adds description column
  dataTemplate.forEach((keyFieldItem) => {
    const keyFieldType = keyFieldItem[0][keyField]
    keyFieldItem.unshift({ value: keyFieldType })
  })

  reportData.report = dataTemplate
  reportData.headers = createHeaders(startDate, endDate, keyField)
  reportData.footers = createTotalsFooter(data)

  return reportData
}

module.exports = transformToReport
