const periodicReportFieldRemover = (reportData) => {
  const values = Object.values(reportData)

  const updatedValues = values.map((value) =>
    value.map((row) => {
      const adjustedRow = [ ...row.slice(0, -3) ]
      adjustedRow.splice(1, 1)
      return adjustedRow
    })
  )

  const updatedReport = {
    report: updatedValues[0],
    headers: updatedValues[1],
    footers: updatedValues[2]
  }

  return updatedReport
}

module.exports = periodicReportFieldRemover
