const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterItemView = require('../../../../../widget/views/item/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, id) {
buf.push("<div" + (jade.attr("data-id", id, true, false)) + " class=\"js-item list-item\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-edit-item\">Edit</a><a class=\"js-remove-item\">Remove</a></li></ul></div></div><h2><a class=\"js-edit-item\">" + (jade.escape(null == (jade_interp = data.heading) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><div class=\"grid__item one-third\">");
if ( data.previewImageUrl)
{
buf.push("<img" + (jade.attr("src", data.previewImageUrl, true, false)) + "/>");
}
buf.push("</div><div class=\"grid__item two-thirds\"><dl><dt>Tagline:</dt><dd>" + (jade.escape(null == (jade_interp = data.tagline) ? "" : jade_interp)) + "</dd><dt>Image Position:</dt><dd>" + (jade.escape(null == (jade_interp = data.imagePosition) ? "" : jade_interp)) + "</dd></dl></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined));;return buf.join("");
}

class ItemRepeaterItemView extends BaseItemRepeaterItemView {
  constructor (serviceLocator, model, ...args) {
    super(serviceLocator, model, ...args)
    this.template = template
  }

  render () {
    const data = this.model.toJSON()
    data.previewImageUrl = this.model.getPreviewImageUrl()
    this.$el.append(this.template({ data: data, id: this.model.id }))
    return this
  }
}

module.exports = ItemRepeaterItemView
