const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div></div><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn js-btn-save\">Save</button><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a href=\"#\" class=\"js-btn-save-and-close\">Save and Close</a></li></ul></div></div></div>");;return buf.join("");
}
const modal = require('modal')

class Toolbar extends View {
  constructor (serviceLocator, model, parentView) {
    super(...arguments)
    this.model = model
    this.$el.addClass('toolbar')
    this.parentView = parentView
    this.$el.on('click', '.js-btn-back', this.confirmBack.bind(this))
    this.$el.on('click', '.js-btn-save', this.emit.bind(this, 'save'))
    this.$el.on('click', '.js-btn-save-and-close', this.emit.bind(this, 'saveAndClose'))
  }

  get template () {
    return template
  }

  render () {
    this.$el.empty().append(this.template(
      { data: this.model.toJSON(),
        allowed: this.serviceLocator.allowed
      }))
    return this
  }

  confirmBack () {
    if (!this.parentView.hasUnsavedChanges()) {
      this.emit('back')
    } else {
      modal({
        title: 'You have unsaved changes',
        content:
        'Would you like to continue editing, or discard these changes?',
        buttons:
        [ { text: 'Continue editing', event: 'continue', className: 'btn btn--success', keyCodes: [ 27 ] },
          { text: 'Discard changes', event: 'discard', className: 'btn' }
        ]
      }).on('discard', () => {
        this.parentView.clearUnsavedChanges()
        this.emit('back')
      })
    }
  }
}

module.exports = Toolbar
