const mapFormToObject = require('cf-map-form-to-object')
const TagSelect = require('../../tag/views/tag-select')
const debug = require('../../../../admin/source/js/lib/debug')(
  'asset form view'
)
const bytes = require('bytes')
const formCancelDelegate = require('cf-form-cancel-delegate')
const getImageFactory = require('../lib/image-factory')()
const isEqual = require('lodash.isequal')

module.exports = window.Backbone.View.extend({
  events: {
    submit: (e) => e.preventDefault(),
  },

  initialize() {
    this.initialModel = this.model.toJSON()
    this.render()
  },

  handleSave() {
    const formData = mapFormToObject(this.$el.find('form'), this.model.schemata)
    let type
    formData.tags = this.consolidateTags()
    this.model.set(formData)
    type = this.model.get('type')
    this.model.set('type', type === undefined ? 'image' : type)
    if (
      this.model.isNew() ||
      !isEqual(this.initialModel, this.model.toJSON())
    ) {
      debug('saving model ' + this.model.cid)
      this.model.save()
    }
  },
  handleCancel() {
    formCancelDelegate(debug).apply(this)
  },

  consolidateTags() {
    return [].concat(this.imageTagControl.tags.toJSON())
  },

  render() {
    this.imageTagControl = new TagSelect(
      this.options.serviceLocator,
      this.model.get('tags'),
      'Image'
    )
    this.imageTagControl.loadTags()

    const type = this.model.get('type') || 'image'
    const factory = getImageFactory(type)
    const viewData = this.model.toJSON()

    viewData.size = bytes(viewData.size)
    viewData.imageUrl = this.model.buildPreviewUrl(500)

    this.$el.empty().append(factory.editTemplate({ data: viewData }))
    this.$('.js-tags-input').append(this.imageTagControl.render().$el)
    return this
  },
})
