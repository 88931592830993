const ListView = require('../views/list')
const Collection = require('chale')
const InstanceModel = require('../models/instance')
const Model = require('merstone')
const async = require('async')
const ReorderView = require('../../instance/views/reorder')

const pageSize = 50

module.exports = serviceLocator => {
  const collection = new Collection(serviceLocator, [], [ 'select', 'deSelect' ])
  const paginationModel = new Model(serviceLocator, { totalItems: 0, showing: 0 })
  let currentPage = 1
  let currentParams = { keywords: '', filter: {}, sort: [ 'order', 'asc' ] }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an instance is updated, reset the model with its new attributes
  serviceLocator.instanceService.on('update', (id, attrs) => {
    var model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.instanceService.on('create', () => {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getInstances(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
  })

  serviceLocator.router.route('instances(/)', 'listInstances', () => {
    if (!serviceLocator.allow('instance', 'discover')) return false

    getInstances(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)

    serviceLocator.accountService.cachedFind('', {}, [ 'name' ], { pageSize: 1000 }, (err, res) => {
      if (err) throw err

      var list = new ListView(serviceLocator, collection, paginationModel, res.results).render()

      list.displayFilterParams(currentParams)

      list.on('createNew', () => {
        if (!serviceLocator.allow('instance', 'create')) return false
        serviceLocator.router.navigate('instances/form', { trigger: true })
      })

      list.on('edit', id => {
        if (!serviceLocator.allow('instance', 'update')) return false
        serviceLocator.router.navigate('instances/' + id + '/form', { trigger: true })
      })

      list.on('delete', ids => {
        if (!serviceLocator.allow('instance', 'delete')) return false
        const deleteOne = (id, cb) => {
          serviceLocator.instanceService.delete(id, err => {
            if (err) return cb(err)
            collection.remove(id)
          })
        }

        async.each(ids, deleteOne, err => {
          if (err) return alert(err.message)
        })
      })

      list.on('filter', params => {
        if (!serviceLocator.allow('instance', 'read')) return false
        currentParams = params
        var pagination = { page: currentPage, pageSize: pageSize }
        currentPage = 1
        getInstances(params.keywords, params.filter, params.sort, pagination)
      })

      list.on('loadMore', () => {
        if (!serviceLocator.allow('instance', 'read')) return false
        currentPage += 1
        var pagination = { page: currentPage, pageSize: pageSize }
        appendInstances(currentParams.keywords, currentParams.filter, currentParams.sort, pagination)
      })

      list.on('showRevisions', model => {
        serviceLocator.router.navigate('instances/' + model.id + '/revisions', { trigger: true })
      })

      list.on('reorder', accountId => {
        if (!accountId) return
        serviceLocator.router.navigate('instances/reorder/' + accountId, { trigger: true })
      })

      serviceLocator.router.render(list, 'Instances')
    })
  })

  serviceLocator.router.route('instances/reorder/:accountId(/)', 'reorderInstances', async (accountId) => {
    const account = accountId || serviceLocator.session.account
    const filter = { account }
    await getInstances(currentParams.keywords, filter, currentParams.sort, pagination)

    var reorderView = new ReorderView(serviceLocator, collection).render()
    serviceLocator.router.render(reorderView, 'Reorder Instances')

    reorderView.on('cancel', back)
    reorderView.on('update', (model, cb) => {
      updateModel(model.get('_id'), model, (err, saved) => {
        if (err) return
        cb()
      })
    })

    reorderView.on('save', back)

    reorderView.setupNestable()
  })

  const updateModel = (id, model, cb) => {
    serviceLocator.instanceService.update(id, model.toJSON(), (err, instance) => {
      if (err) {
        return cb(err)
      }

      cb(null, instance)
    })
  }

  const getInstances = async (keywords, filter, sort, pagination) => new Promise((resolve, reject) => {
    serviceLocator.instanceService.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err) {
        resolve()
        return serviceLocator.logger.error('Could not load instances', err)
      }
      collection.reset(res.results.map(instance => new InstanceModel(serviceLocator, instance)))
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
      resolve()
    })
  })

  const appendInstances = (keywords, filter, sort, pagination) => {
    serviceLocator.instanceService.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(instance => {
        collection.add(new InstanceModel(serviceLocator, instance))
      })
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const back = () => {
    serviceLocator.router.navigate('instances', { trigger: true })
  }
}
