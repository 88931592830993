const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, dropdownActions, format, hasSection) {
jade_mixins["stateMap"] = jade_interp = function(schedule, visibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// iterate [schedule, visibility]
;(function(){
  var $$obj = [schedule, visibility];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  }
}).call(this);

};
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
jade_mixins["stateMap"](data.schedule, data.visibility);
if ( allowed('offer', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\">");
if ( (data.hasSection && data.offerType !== 'umbrellaChild') || data.hasParent)
{
buf.push("<button type=\"button\" class=\"btn btn--small js-preview\">Preview</button>");
}
else
{
const label = data.offerType !== 'umbrellaChild' ? 'Can’t be previewed without a section assigned' : `Can't be previewed without a parent`
buf.push("<div data-container=\"body\" data-toggle=\"tooltip\"" + (jade.attr("title", label, true, false)) + " class=\"tooltip__disabled-wrapper js-tooltip-trigger\"><button type=\"button\" class=\"btn btn--small is-disabled\">Preview</button></div>");
}
if ( dropdownActions.length > 0)
{
buf.push("<a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\">");
if ( dropdownActions.indexOf('duplicate') > -1)
{
buf.push("<li><a class=\"js-duplicate\">Duplicate</a></li>");
}
buf.push("</ul>");
}
buf.push("</div></div>");
if ( allowed('offer', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/offers/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.headline) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd>");
if ( data.updatedDate !== undefined)
{
buf.push("<dt>Last updated:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.updatedDate, 'calendar')) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Live:</dt><dd>" + (jade.escape(null == (jade_interp = data.liveDate ? format(data.liveDate, 'calendar') : 'Always') ? "" : jade_interp)) + "</dd><dt>Expires:</dt><dd>" + (jade.escape(null == (jade_interp = data.expiryDate ? format(data.expiryDate, 'calendar') : 'Never') ? "" : jade_interp)) + "</dd></dl>");
if ( hasSection)
{
buf.push("<div><a" + (jade.attr("href", data.publicUrlPath, true, false)) + " target=\"_blank\">" + (jade.escape(null == (jade_interp = data.__fullUrlPath) ? "" : jade_interp)) + "</a></div>");
}
buf.push("</div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"dropdownActions" in locals_for_with?locals_for_with.dropdownActions:typeof dropdownActions!=="undefined"?dropdownActions:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"hasSection" in locals_for_with?locals_for_with.hasSection:typeof hasSection!=="undefined"?hasSection:undefined));;return buf.join("");
}
const stateMap = require('../state-map')
const createImageUrlBuilder = require('cf-image-url-builder')
const EmailInputModal = require('./email-input-modal')
const authedRequest = require('../../../../admin/source/js/lib/authed-request')
const config = window.config

class ListItemView extends BaseListItemView {
  constructor (...args) {
    super(...args)
    this.$el.on('click', '.js-preview', this.emit.bind(this, 'preview'))
    this.$el.on('click', '.js-date-preview', this.emit.bind(this, 'datePreview', this.model))
    this.$el.on('click', '.js-duplicate', this.emit.bind(this, 'duplicate'))
    this.$el.on('click', '.js-email-offer-details', this.handleEmailOfferDetailsClicked.bind(this))
  }

  get template () {
    return template
  }

  handleEmailOfferDetailsClicked (e) {
    const emailInputModal = new EmailInputModal()
    const offerId = $(e.currentTarget).data('id')

    emailInputModal.on('confirm', emailAddresses => {
      this.emailOfferDetails(offerId, emailAddresses)
      emailInputModal.remove()
    })

    emailInputModal.render()
  }

  emailOfferDetails (offerId, emailAddresses) {
    const data = { emailAddresses: emailAddresses }
    const url = '/offer/email/' + offerId
    authedRequest('GET', url, data, err => {
      if (err) return this.serviceLocator.logger.error('could not send email')
    })
  }

  serialize () {
    const images = this.model.get('images').widgets
    const data = this.model.toJSON()
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, this.model.get('images').widgets)

    if (images && images.length &&
      urlBuilder.getImage('Thumbnail') && urlBuilder.getImage('Thumbnail').crop('Square')) {
      data.previewImageUrlSmall = urlBuilder.getImage('Thumbnail').crop('Square').constrain(150).url()
      data.previewImageUrlLarge = urlBuilder.getImage('Thumbnail').crop('Square').constrain(400).url()
    } else {
      data.previewImageUrlSmall = '/assets/img/content/no-photo-medium.png'
      data.previewImageUrlLarge = '/assets/img/content/no-photo-large.png'
    }

    const getVisibilityState = stateMap.visibility.get(this.model.getVisibilityState())
    const getScheduleState = stateMap.schedule.get(this.model.getScheduleState())

    data.schedule = getScheduleState(this.model.get('liveDate'), this.model.get('expiryDate'))
    data.visibility = getVisibilityState()

    data.hasSection = typeof data.__fullUrlPath !== 'undefined'

    // Add include path
    data.publicUrlPath = config.url + data.__fullUrlPath

    return data
  }

  render () {
    const dropdownActions = []
    const data = this.serialize()

    if (this.serviceLocator.allowed('offer', 'duplicate')) {
      dropdownActions.push('duplicate')
    }

    if (data.hasSection) {
      dropdownActions.push('datePreview')
    }

    if (this.serviceLocator.allowed('offer', 'downloadRedemptions')) {
      dropdownActions.push('downloadRedemptions')
    }

    this.$el.empty().append(template({
      data,
      dropdownActions,
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format
    }))

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
