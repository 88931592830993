const View = require('ventnor')
const defaultSelectizeConfig = {
  delimiter: ',',
  create: false,
  preload: true
}

class BaseSelectView extends View {
  constructor (serviceLocator, service, selected, limitToOneValue, singular, plural, textProperty, sortProperty, selectizeConfig = defaultSelectizeConfig) {
    if (!Array.isArray(selected)) selected = [ selected ]
    super(serviceLocator, selected)

    this.service = service
    this.singular = singular
    this.plural = plural
    this.textProperty = textProperty
    this.sortProperty = sortProperty
    this.selectizeConfig = selectizeConfig

    this.$el = $(limitToOneValue ? `<select />` : '<select multiple />')
    this.$el.attr('name', plural)
    this.$el.attr('placeholder', limitToOneValue ? `Choose a ${singular}` : `Choose one or more ${plural}`)
    this.$el.addClass('control control--choice')
    if (!limitToOneValue) this.$el.addClass('control--multiline')

    this.el = this.$el[0]
    this.selected = selected || []
  }

  initializeSelectize () {
    const pagination = { page: 1, pageSize: 500 }

    this.serviceLocator[this.service].find('', { _id: { $in: this.selected } }, [], pagination, (err, res) => {
      if (err) return alert(`Cannot find existing ${this.singular}`)
      const index = res.results.reduce((index, res) => {
        index[res._id] = res
        return index
      }, {})
      // This ensure they stay in the same order
      this.selected.forEach(id => {
        // The item needs to be added to the list
        // of selectize options in order to be selected
        this.el.selectize.addOption({ value: id, text: index[id] ? index[id][this.textProperty] : id })
        // Select the added option
        this.el.selectize.addItem(id)
      })
      this.el.selectize.on('change', this.updateSelection.bind(this))
    })
  }

  updateSelection () {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load (query, cb) {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator[this.service].cachedFind(query, {}, [ this.sortProperty, 'asc' ], pagination, (err, data) => {
      if (err) return cb(err)
      cb(data.results.map(res => {
        return { value: res._id, text: res[this.textProperty] }
      }))
    })
  }

  render () {
    const selectizeSettings = Object.assign({}, defaultSelectizeConfig, this.selectizeConfig, {
      onInitialize: this.initializeSelectize.bind(this),
      load: this.load.bind(this)
    })

    setTimeout(() => {
      this.$el.selectize(selectizeSettings)
    }, 0)
    return this
  }
}

module.exports = BaseSelectView
