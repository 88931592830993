const View = require('ventnor')
const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"notification notification--notice\">Users will see the above <strong>block of text</strong> explaining how to redeem this offer.</div>");;return buf.join("");
}

class FormView extends View {
  constructor (serviceLocator, model, redemptionExperienceIndex) {
    super(serviceLocator)
    this.serviceLocator = serviceLocator
    this.redemptionExperienceIndex = redemptionExperienceIndex
    this.model = model
    this.$el.on('change', this.applyChangeToModel.bind(this))
  }

  applyChangeToModel (e) {
    if (this.model.schema.getProperties().hasOwnProperty(e.target.name)) {
      this.model.set(e.target.name, e.target.value)
    }
  }

  render () {
    this.$el.append(template({
      data: this.model.toJSON(),
      redemptionExperienceIndex: this.redemptionExperienceIndex
    }))
    return this
  }
}

module.exports = FormView
