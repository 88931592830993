const createQuery = (options) => {
  const query = { accountId: options.account, report: options.report, year: { $ne: 1970 } }
  const { financialYearFilter } = options || {}

  if (financialYearFilter && financialYearFilter.length) {
    const filter = Array.isArray(financialYearFilter) ? financialYearFilter : [ financialYearFilter ]
    const financialYears = filter && filter.map(financialYear => ({ financialYear: parseInt(financialYear) }))
    query['$or'] = financialYears
  }

  return query
}

module.exports = createQuery
