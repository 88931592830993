const getSortedUnique = require('../../sorted-unique-retriever')
const createHeaders = require('./header-creator')
const flattenArray = require('../../array-flattener')
const createTotalsFooter = require('./totals-footer-creator')

const transformToReport = (data, startDate, endDate) => {
  const reportData = {}
  let report = []
  const sortedUniqueCategories = getSortedUnique(data, 'category')

  const itemsSplitByCategoriesArray = sortedUniqueCategories.map(category =>
    data.filter(item => item.category === category)
  )

  const largestCategoryLength = Math.max(...itemsSplitByCategoriesArray.map(category => category.length))

  const arrayTemplate = Array(largestCategoryLength).fill(null)

  report = arrayTemplate.map((arr, index) => {
    const mappedArray = []
    itemsSplitByCategoriesArray.forEach(category => {
      const item = category[index]
      const { shortHeadline, offerId, count } = item || {}
      if (item) {
        const description = { value: shortHeadline, id: offerId }
        const value = { value: count }
        return mappedArray.push([ description, value ])
      }
      return mappedArray.push([ { value: '' }, { value: '' } ])
    })
    return flattenArray(mappedArray)
  })

  reportData.report = report
  reportData.headers = createHeaders(sortedUniqueCategories, startDate, endDate)
  reportData.footers = createTotalsFooter(data, sortedUniqueCategories)

  return reportData
}

module.exports = transformToReport
