const mapper = item => {
  const value = item && item.value
  if (typeof value === 'string') { return value.replace(/,/g, '') || '' }
  return value
}

const convertToCsv = (data, view) => {
  return view === 'multiple' ? multiView(data) : singleView(data)
}

const singleView = (data) => {
  const { headers, footers, report } = data || {}
  const mappedReport = report.map(line => {
    return line.map(mapper)
  })

  const csvData = [ ...headers, ...mappedReport, ...footers ]
  return convertToLine(csvData)
}

const multiView = (data) => {
  const csvData = []
  const { headers, footers, report } = data || {}

  headers.forEach((header, i) => {
    csvData.push([ header ])
    report[i].forEach(line => csvData.push(line.map(mapper)))
  })
  csvData.push(footers)
  return convertToLine(csvData)
}

const convertToLine = (csvData) => {
  const lineArray = []
  csvData.forEach((infoArray, index) => {
    const line = infoArray.join(',')
    lineArray.push(index === 0 ? 'data:text/csv;charset=utf-8,' + line : line)
  })
  return lineArray.join('\n')
}

export default convertToCsv
