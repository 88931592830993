const BaseModel = require('cf-base-model')
const schemata = require('@clocklimited/schemata')
const required = require('validity-required')
const createContextValidator = require('validity-cf-image-context-selection')
const createImageUrlBuilder = require('cf-image-url-builder')
const ImageAreaModel = require('../../../../../asset/models/image-area')
const config = window.config

const schema = schemata({
  name: 'Email Bio List Item',
  properties: {
    heading: {
      type: String,
      validators: [ required ]
    },
    displayHeading: {
      type: String,
      validators: [ required ]
    },
    tagline: {
      type: String,
      validators: [ required ]
    },
    displayTagline: {
      type: String,
      validators: [ required ]
    },
    text: {
      type: String,
      validators: [ required ]
    },
    imagePosition: {
      type: String,
      defaultValue: () => 'Left'
    },
    images: {
      type: Object,
      defaultValue: () => ({}),
      validators: { all: [ createContextValidator([ 'Main' ]) ] }
    }
  }
})

module.exports = BaseModel.extend({
  schema,
  defaults () {
    return schema.makeDefault()
  },
  validate (cb) {
    this.schema.validate(this.attributes, (ignoreErr, errors) =>
      cb(Object.keys(errors).length > 0 ? errors : undefined)
    )
  },
  initialize: function () {
    this.images = new ImageAreaModel(this.get('images'))
  },

  getPreviewImageUrl: function () {
    const images = this.images.widgets.map(function (widget) { return widget.toJSON() })
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, images)
    const image = urlBuilder.getImage('Main')
    if (!image) return null
    return image.crop('Square').constrain(300).url()
  }
})
