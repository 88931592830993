const transformToReport = require('./report-transformer')
const transformToChart = require('./chart-transformer')
const queryCreator = require('../yearly-members-engaged-report/query-creator')
const fields = require('./fields')

const yearOnYearRedemptionReport = () => ({
  queryCreator,
  transformToChart,
  transformToReport,
  fields
})

module.exports = yearOnYearRedemptionReport
