const Model = require('./models/competition')
const FormView = require('./views/form')

const init = (serviceLocator, done) => {
  serviceLocator.redemptionTypeFactory.register('competition', {
    name: 'Competition',
    FormView,
    Model,
    supportsRedemptionReset: true
  })
  done()
}

module.exports = () => ({ competitionRedemptionType: [ 'formBuilderAdmin', 'offerAdmin', init ] })
