module.exports = function validateIf (validateFn, comparator) {
  function validate (key, keyDisplayName, object, cb) {
    if (comparator(object[key])) {
      return validateFn(key, keyDisplayName, object, cb)
    }

    return cb(null, undefined)
  }

  return validate
}
