const View = require('ventnor')
const compileJade = require('browjadify-compile')
const InstanceSectionSelect = require('../../../section/views/instance-section-select')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"section-sections-layouts\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Sections & Layouts</h2></div><div class=\"panel-content\"><div class=\"js-bulk-section\"></div><div class=\"js-section-items\"></div><div class=\"form-row form-row-full-width\"><div class=\"form-field\"><button type=\"button\" class=\"btn js-section-add\">Add Section</button></div></div></div></div>");;return buf.join("");
}
const bulkTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (sections, undefined) {
buf.push("<div class=\"control-addon\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h3>Bulk add to sections</h3><p>This allows you to add an offer to all instances within the offers account</p></div><div class=\"panel-content\">");
if ( sections.length > 0)
{
buf.push("<div class=\"form-row form-row-full-width\"><span>Section</span><label><div class=\"form-field\"><select class=\"control control--choice js-section-bulk-select\"><option selected=\"selected\" disabled=\"disabled\">-- Choose a section --</option>");
// iterate sections
;(function(){
  var $$obj = sections;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var section = $$obj[$index];

buf.push("<option" + (jade.attr("value", section.slug, true, false)) + ">" + (jade.escape((jade_interp = section.name) == null ? '' : jade_interp)) + " - /" + (jade.escape((jade_interp = section.slug) == null ? '' : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var section = $$obj[$index];

buf.push("<option" + (jade.attr("value", section.slug, true, false)) + ">" + (jade.escape((jade_interp = section.name) == null ? '' : jade_interp)) + " - /" + (jade.escape((jade_interp = section.slug) == null ? '' : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></div></label><div class=\"form-copy\"><p>Select a section shared by all instances to populate</p></div></div><div class=\"form-row form-row-full-width\"><div class=\"form-field\"><button type=\"button\" class=\"btn js-section-bulk-add\">Add to sections</button></div></div>");
}
else
{
buf.push("<div class=\"notification\">Please choose an account in the labels section below first.</div>");
}
buf.push("</div></div></div>");}.call(this,"sections" in locals_for_with?locals_for_with.sections:typeof sections!=="undefined"?sections:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const itemTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"control-addon\"><div class=\"panel panel-styled\"><div class=\"panel-content\"><div class=\"grid\"><div class=\"grid__item one-half\"><div id=\"field--sections\" data-field=\"sections\" class=\"form-row form-row-full-width\"><span>Section</span><label><div class=\"form-field js-instance-sections\"></div></label><div class=\"form-copy\"><p>Choose a section.</p></div></div></div><div class=\"grid__item one-half\"><div id=\"field--layout\" data-field=\"layout\" class=\"form-row form-row-full-width\"><span>Layout</span><label><div name=\"layout\" class=\"form-field\"><select name=\"layout\" class=\"control control--choice js-layout-select\"></select></div></label><div class=\"js-error\"></div><div class=\"form-copy\"><p>Select which of the section’s available layouts to use.</p></div></div></div></div></div></div><div class=\"control-addon__affix control-addon__affix--suffix\"><button type=\"button\" class=\"btn btn--large js-section-delete\"><i class=\"icon icon--cross--dark\">Reset</i></button></div></div>");;return buf.join("");
}

class SectionsView extends View {
  constructor (serviceLocator, model) {
    super(serviceLocator)
    this.model = model
    this.itemIndex = 0
    this.$el.on('click', '.js-section-add', this.handleAddItem.bind(this))
    this.model.on('change:account', this.setupBulkSection.bind(this))
  }

  handleAddItem () {
    this.addItem()
  }

  setupBulkSection () {
    const area = this.$el.find('.js-bulk-section')
    const account = this.model.get('account') || this.serviceLocator.session.account
    this.serviceLocator.sectionService.getSharedSections(account, (err, sections) => {
      area.empty()
      if (err) {
        return this.serviceLocator.logger.error('Cannot get sections shared between account')
      }

      const $el = $(bulkTemplate({ sections }))
      $el.on('click', '.js-section-bulk-add', () => {
        const selectedSlug = $el.find('.js-section-bulk-select option:selected').val()

        const item = sections.find(section => section.slug === selectedSlug)
        this.model.set('sections', [ ...item.ids ])
        this.model.set('sectionLayouts', item.ids.reduce((obj, id) => {
          obj[id] = null
          return obj
        }, {}))

        this.renderItems()
      })
      area.append($el)
    })
  }

  addItem (section) {
    const $el = $(itemTemplate({}))
    const index = this.itemIndex
    const itemData = { section }

    const updateItem = () => {
      if (!itemData.section) return
      const currentSections = this.model.get('sections') || []
      const currentSectionLayouts = this.model.get('sectionLayouts') || {}
      const currentSectionId = currentSections[index]
      if (currentSectionId) {
        delete currentSectionLayouts[currentSectionId]
      }
      currentSectionLayouts[itemData.section] = itemData.layout || null
      currentSections[index] = itemData.section

      this.model.set('sections', currentSections)
      this.model.set('sectionLayouts', currentSectionLayouts)
    }
    const deleteItem = () => {
      const currentSections = this.model.get('sections') || []
      const currentSectionLayouts = this.model.get('sectionLayouts') || {}
      const currentSectionId = currentSections[index]
      if (currentSectionId) {
        delete currentSectionLayouts[currentSectionId]
      }
      currentSections.splice(index, 1)

      this.model.set('sections', currentSections)
      this.model.set('sectionLayouts', currentSectionLayouts)

      if (index === this.itemIndex - 1) {
        this.itemIndex--
        $el.remove()
      } else {
        this.renderItems()
      }
    }
    $el.on('click', '.js-section-delete', deleteItem.bind(this))
    $el.on('change', '.js-layout-select', () => {
      itemData.layout = $el.find('.js-layout-select').val()
      updateItem()
    })

    this.$el.find('.js-section-items').append($el)

    const instanceSectionSelect = new InstanceSectionSelect(this.serviceLocator, section)
    $el.find('.js-instance-sections').append(instanceSectionSelect.render().$el)
    instanceSectionSelect.on('change', section => {
      itemData.section = section
      itemData.layout = null
      updateItem()
      this.updateLayoutOptions($el, section)
    })
    this.attachView(instanceSectionSelect)
    this.updateLayoutOptions($el, section, (this.model.get('sectionLayouts') || {})[section])

    this.itemIndex++
  }

  updateLayoutOptions ($el, currentSectionId, currentLayoutId) {
    const $layoutSelect = $el.find('.js-layout-select')

    $layoutSelect.empty()
    $('<option/>', { value: '' }).text('Default offer layout').appendTo($layoutSelect)

    if (currentSectionId) {
      this.serviceLocator.sectionService.read(currentSectionId, (err, section) => {
        if (err) return this.serviceLocator.logger.error('Cannot find existing section', err)

        const layouts = section.layouts

        Object.keys(layouts).forEach(key => {
          if (key === 'offer' || key === 'section') return

          const option = $('<option/>')
            .attr('value', layouts[key].key)
            .text(layouts[key].name + (layouts[key].description ? ' - ' + layouts[key].description : ''))
            .appendTo($layoutSelect)

          if (layouts[key].key === currentLayoutId) option.attr('selected', true)
        })
      })
    }
  }

  renderItems () {
    this.$el.find('.js-section-items').empty()
    this.itemIndex = 0

    this.model.get('sections').forEach(section => {
      this.addItem(section)
    })
  }

  render () {
    this.$el.append(template({ offer: this.model.toJSON() }))
    this.renderItems()
    this.setupBulkSection()
    return this
  }
}

module.exports = SectionsView
