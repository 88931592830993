const FormView = require('../../widget/views/form/base')
const Model = require('./models/model')
const ItemView = require('../../widget/views/item/edit-base')

const init = (serviceLocator, done) => {
  const widget = {
    editView: FormView,
    model: Model,
    itemView: ItemView,
    name: 'Search Results',
    description: 'Show search results'
  }

  serviceLocator.widgetFactories.get('section').register('searchResults', widget)

  done()
}

const initWidget = () => ({ searchWidget: [ 'widget', 'sectionAdmin', init ] })

module.exports = initWidget
