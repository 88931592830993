const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, sessionHasSpecificAccount, title, types, undefined) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div class=\"panel panel-styled\"><div class=\"panel-content\"><div id=\"field--tag\" data-field=\"tag\" class=\"form-row\"><label><span class=\"form-label-text\">Tag Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"tag\"" + (jade.attr("value", data.tag, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--type\" data-field=\"type\" class=\"form-row\"><label><span class=\"form-label-text\">Type<abbr title=\"This field is required\">*</abbr></span><select name=\"type\" class=\"control control--choice form-field\"><option value=\"\">-- Choose a type --</option>");
// iterate types
;(function(){
  var $$obj = types;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var tag = $$obj[$index];

buf.push("<option" + (jade.attr("value", tag, true, false)) + (jade.attr("selected", (tag === data.type ? 'selected' : null), true, false)) + ">" + (jade.escape(null == (jade_interp = tag) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var tag = $$obj[$index];

buf.push("<option" + (jade.attr("value", tag, true, false)) + (jade.attr("selected", (tag === data.type ? 'selected' : null), true, false)) + ">" + (jade.escape(null == (jade_interp = tag) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label><div class=\"js-error\"></div></div>");
if ( !sessionHasSpecificAccount)
{
buf.push("<div id=\"field--account\" data-field=\"account\" class=\"form-row\"><label><span class=\"form-label-text\">Account<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-account-select\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"></div></div>");
}
buf.push("<div class=\"form-row\"><span class=\"form-label-text\">Extra detail</span><div class=\"form-field form-field-multi-select\"><ul class=\"js-meta-input\"></ul><button type=\"button\" class=\"btn js-meta-add\">Add Extra Detail</button></div></div></div></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"sessionHasSpecificAccount" in locals_for_with?locals_for_with.sessionHasSpecificAccount:typeof sessionHasSpecificAccount!=="undefined"?sessionHasSpecificAccount:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"types" in locals_for_with?locals_for_with.types:typeof types!=="undefined"?types:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const metaInput = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (item) {
buf.push("<li class=\"js-meta-item\"><div class=\"control-addon\"><div class=\"control-addon__base\"><label><span class=\"form-label-text vhidden\">Tag Meta Key</span><input type=\"text\" name=\"meta-key\"" + (jade.attr("value", item.key, true, false)) + " class=\"control control--text\"/></label><label><span class=\"form-label-text vhidden\">Tag Meta Value</span><textarea type=\"text\" name=\"meta-value\" rows=\"4\" class=\"control control--text control--multiline\">" + (jade.escape(null == (jade_interp = item.value) ? "" : jade_interp)) + "</textarea></label></div><div class=\"control-addon__affix control-addon__affix--suffix\"><button type=\"button\" class=\"btn btn--large js-meta-delete\"><i class=\"icon icon--cross--dark\">Reset</i></button></div></div></li>");}.call(this,"item" in locals_for_with?locals_for_with.item:typeof item!=="undefined"?item:undefined));;return buf.join("");
}
const AccountSelect = require('../../account/views/account-select')

class FormView extends BaseFormView {
  constructor (serviceLocator, model, types, isNew) {
    super(serviceLocator, model, isNew)
    this.types = types
    this.$el.on('click', '.js-meta-add', this.handleMetaAdd.bind(this))
    this.$el.on('click', '.js-meta-delete', this.handleMetaDelete.bind(this))
    this.$el.on('change', '[name^="meta-"]', this.updateTagMeta.bind(this))
  }

  handleMetaAdd () {
    var item = { key: '', value: '' }
    this.$el.find('.js-meta-input').append(metaInput({ item: item }))
  }

  handleMetaDelete (e) {
    this.$el.find(e.target).closest('.js-meta-item').remove()
    this.updateTagMeta()
  }

  updateTagMeta () {
    var meta = []
    this.$el.find('.js-meta-item').each((i, el) => {
      const key = $(el).find('[name=meta-key]').val()
      const value = $(el).find('[name=meta-value]').val()
      if (!key || !value) return
      meta.push({ key: key, value: value })
    })
    this.model.set('meta', meta)
  }

  render () {
    // Render the template
    this.$el.append(template(
      { title: this.isNew ? 'New Tag' : 'Edit Tag',
        data: this.model.toJSON(),
        types: this.types,
        sessionHasSpecificAccount: this.serviceLocator.session && this.serviceLocator.session.account
      }))

    const $meta = this.$el.find('.js-meta-input')
    const meta = this.model.get('meta')

    if (meta) {
      meta.forEach(item =>
        $meta.append(metaInput({ item: item }))
      )
    }

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
    this.renderAccountSelect()
    return this
  }

  renderAccountSelect () {
    const accountView = new AccountSelect(this.serviceLocator, this.model.get('account'))
    accountView.populate()
    this.$el.find('.js-account-select').append(accountView.render().$el)
    accountView.on('change', account => this.model.set('account', account))
  }
}

module.exports = FormView
