module.exports = initWidget

function initWidget () {
  return { offerLayoutBannerWidget: [ 'widget', 'sectionAdmin', init ] }
}

function init (serviceLocator, done) {
  serviceLocator.widgetFactories.get('offer').register('offerLayoutBanner',
    { model: require('./models/model'),
      name: 'Offer Banner',
      itemView: require('./views/item'),
      description: 'Placeholder for the offer banner'
    })

  done()
}
