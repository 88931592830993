const schemata = require('./schema')()
const BaseModel = require('cf-base-model')
const validateDelegate =
  require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata,
  defaults() {
    return schemata.makeDefault({ type: this.type || 'biography' })
  },
  validate: validateDelegate,
})
