const notify = require('../../notification/foreground')

const baseFormController = ({ serviceLocator, service, plural, path, singular, Model, FormView, canDupe, dupeFields = [ '_id' ] }) => {
  // Edit
  serviceLocator.router.route(`${path}/:id/form`, `edit${singular}`, (id) => {
    if (!serviceLocator.allow(singular, 'update')) return false

    service.read(id, (err, entity) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const form = new FormView(serviceLocator, new Model(serviceLocator, entity), false).render()
      serviceLocator.router.render(form, `Edit ${singular}`)

      form.on('back', back)

      form.on('save', () =>
        saveExisting(id, form, err => {
          if (err) return
          notify('Saved', 'save')
        }))

      form.on('saveAndClose', () => saveExisting(id, form, (err) => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate(path, { trigger: true })
      }))
    })
  })

  // Create
  serviceLocator.router.route(`${path}/form`, `edit${singular}`, () => {
    if (!serviceLocator.allow(singular, 'create')) return false

    const model = new Model(serviceLocator)
    model.set(model.schema.makeDefault())

    const form = new FormView(serviceLocator, model, true).render()

    serviceLocator.router.render(form, `New ${singular}`)

    setupSaveNewForm(form)
  })

  if (canDupe) {
    serviceLocator.router.route(`${path}/:id/duplicate`, `edit${singular}`, (id) => {
      if (!serviceLocator.allow(singular, 'duplicate')) return false

      service.read(id, (err, entity) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)

        dupeFields.forEach(field => delete entity[field])

        const form = new FormView(serviceLocator, new Model(serviceLocator, entity), true).render()

        serviceLocator.router.render(form, `New ${singular}`)
        setupSaveNewForm(form)
      })
    })
  }

  const setupSaveNewForm = (form) => {
    form.on('back', back)

    form.on('save', () => saveNew(form, (err, saved) => {
      if (err) return
      notify('Saved', 'save')
      serviceLocator.router.navigate(`${path}/` + saved._id + '/form', { trigger: true })
    }))

    form.on('saveAndClose', () => saveNew(form, err => {
      if (err) return
      notify('Saved', 'save')
      serviceLocator.router.navigate(path, { trigger: true })
    }))
  }

  const saveExisting = (id, form, cb) => {
    service.update(id, form.model.toJSON(), (err, entity) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, entity)
    })
  }

  const saveNew = (form, cb) => {
    service.create(form.model.toJSON(), (err, entity) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.model.reset(entity)
      form.clearUnsavedChanges()
      cb(null, entity)
    })
  }

  const back = () => serviceLocator.router.navigate(path, { trigger: true })
}

module.exports = baseFormController
