import Model from './models/model'
import ItemView from './views/item'
import EditView from './views/form'

const init = (serviceLocator, done) => {
  serviceLocator.widgetFactories.get('section').register('membersHero', {
    model: Model,
    name: 'Members Area Hero',
    itemView: ItemView,
    editView: EditView,
    description: 'Welcome a member to the Members Area'
  })
  done()
}

module.exports = () => ({ membersHero: [ 'widget', 'sectionAdmin', init ] })
