const join = require('path').join
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (message) {
buf.push("<div class=\"page-content\"><div class=\"centering\"><header class=\"page-header\"><h1>Not Found</h1></header>");
if ( message)
{
buf.push("<p>" + (jade.escape(null == (jade_interp = message) ? "" : jade_interp)) + "</p>");
}
else
{
buf.push("<p>The requested resource cannot be found.</p>");
}
buf.push("</div></div>");}.call(this,"message" in locals_for_with?locals_for_with.message:typeof message!=="undefined"?message:undefined));;return buf.join("");
}

module.exports = window.Backbone.View.extend(
  { initialize: function () {
    this.render()
  },
  render: function () {
    this.$el.append(template({ message: this.options.message }))
    return this
  }
  })
