const CrudService = require('../../../../admin/source/js/lib/crud-service')
const createAuthedRequest = require('../../../../admin/source/js/lib/authed-request')

class UniqueCodeService extends CrudService {
  constructor (serviceLocator, options) {
    super(serviceLocator, options)
    this.authedRequest = createAuthedRequest(window.config.apiUrl)
  }

  get name () {
    return 'UniqueCodeService'
  }
  get urlRoot () {
    return '/unique-codes'
  }

  stat (entityId, cb) {
    this.authedRequest('GET', this.urlRoot + '/stat/' + entityId, null, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      cb(null, body)
    })
  }

  export (entityId, type, cb) {
    var options = { headers: { Accept: 'application/octet-stream' } }
    this.authedRequest('GET', this.urlRoot + '/export/' + entityId + '/' + type, null, options, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      cb(null, body, res.headers)
    })
  }

  generate (entityId, n, cb) {
    this.authedRequest('POST', this.urlRoot + '/generate/', { number: n, parentId: entityId }, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      cb(null, body, res.headers)
    })
  }

  upload (entityId, file, name, cb) {
    var options = { headers: { 'Content-Type': 'text/csv' } }
    const encodedName = encodeURIComponent(name)
    this.authedRequest('POST', `${this.urlRoot}/upload/${entityId}/${encodedName}/`, file, options, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      try { body = JSON.parse(body) } catch (e) {}
      cb(null, body)
    })
  }

  deleteAllOfType (entityId, type, cb) {
    this.authedRequest('GET', this.urlRoot + '/' + entityId + '/delete/' + type, null, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      cb(null, body)
    })
  }

  getError (body) {
    if (body.errors) {
      var error = new Error('Validation Error')
      error.errors = body.errors
      return error
    }
    return new Error(body.error || body.status || body || 'Unknown Error')
  }
}

module.exports = UniqueCodeService
