module.exports={
  "crops": [
    { "name": "HPTO Desktop", "aspectRatio": "16:9" },
    { "name": "HPTO Mobile", "aspectRatio": "11:8" },
    { "name": "Offer Desktop", "aspectRatio": "61:8" },
    { "name": "Offer Mobile", "aspectRatio": "55:12" }
  ],
  "contexts": [
    { "name": "HPTO Desktop", "allowMultipleSelection": false, "required": true  },
    { "name": "HPTO Mobile", "allowMultipleSelection": false, "required": true  },
    { "name": "Offer Desktop", "allowMultipleSelection": false, "required": true  },
    { "name": "Offer Mobile", "allowMultipleSelection": false, "required": true }
  ]
}
