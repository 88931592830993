const aggregationQueryCreator = require('./aggregation-query-creator')
const periodicTransformToChart = require('../../periodic-reports/chart-transformer')
const periodicReportTransformer = require('../../periodic-reports/report-transformer')
const periodicCreateHeaders = require('../../periodic-reports/header-creator')
const ranking = require('../../periodic-reports/ranking')

const fields = require('../redemption-count-by-masthead/fields')

const uniqueMembersByMasthead = () => ({
  createAggregationQuery: (options) => aggregationQueryCreator(options, 'instance', 'instance', 'name'),
  transformToChart: (data) => periodicTransformToChart(data, 'instance'),
  transformToReport: (data, startDate, endDate) => periodicReportTransformer(data, startDate, endDate, 'instance', ranking, periodicCreateHeaders),
  fields
})

module.exports = uniqueMembersByMasthead
