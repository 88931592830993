const retrievePeriodicDates = require('../../periodic-date-retriever')

const createAggregationQuery = (
  options,
  keyField,
  targetCollection,
  targetCollectionProperty
) => {
  const { account, startDate, endDate, accountName } = options || {}
  const {
    startDateFilter,
    endDateFilter
  } = retrievePeriodicDates(startDate, endDate)

  return [
    {
      $match: {
        accountId: account,
        type: 'redeem',
        createdDate: {
          $gte: startDateFilter,
          $lte: endDateFilter
        }
      }
    },
    {
      $addFields: {
        [`${targetCollection}ObjectId`]: {
          $toObjectId: `$${targetCollection}Id`
        }
      }
    },
    {
      $lookup: {
        from: `${targetCollection}`,
        localField: `${targetCollection}ObjectId`,
        foreignField: '_id',
        as: `${targetCollection}Data`
      }
    },
    {
      $unwind: {
        path: `$${targetCollection}Data`
      }
    },
    {
      $addFields: {
        [`${keyField}`]: `$${targetCollection}Data.${targetCollectionProperty}`
      }
    },
    {
      $group: {
        _id: {
          userId: '$userId',
          [`${keyField}`]: `$${keyField}`,
          title: 'Campaign Period'
        },
        count: { $sum: 1 }
      }
    },
    {
      $group: {
        _id: {
          instance: '$_id.instance',
          title: '$_id.title'
        },
        totalCount: { $sum: '$count' },
        distinctCount: { $sum: 1 }
      }
    },
    {
      $addFields: {
        [`${keyField}`]: `$_id.${keyField}`,
        title: '$_id.title',
        value: '$distinctCount',
        account: accountName
      }
    },
    { $sort: { [`${keyField}`]: 1, value: 1 } }
  ]
}

module.exports = createAggregationQuery
