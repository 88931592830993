const CrudService = require('../../../../admin/source/js/lib/crud-service')

class SectionService extends CrudService {
  get name () {
    return 'SectionService'
  }

  get urlRoot () {
    return '/sections'
  }

  getSharedSections (entityId, cb) {
    this.authedRequest('GET', '/section/shared?account=' + entityId, null, (err, res, body) => {
      if (err) return cb(err)
      if (res.statusCode >= 300) return cb(this.getError(body))
      cb(null, body)
    })
  }
}

module.exports = SectionService
