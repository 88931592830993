const compileJade = require('browjadify-compile')
const path = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.imageUrl + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('partner', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div><h2><a" + (jade.attr("href", '/partners/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const createImageUrlBuilder = require('cf-image-url-builder')
const config = window.config

module.exports = Backbone.View.extend({
  events: {
    'click .js-select': 'toggleSelect',
    'click .js-edit': 'edit'
  },

  className: '',

  initialize () {
    this.listenTo(this.model, 'select', this.select)
    this.listenTo(this.model, 'deSelect', this.deSelect)
    this.render()
  },

  attributes () {
    return { id: 'partner-id-' + this.model.get('_id') }
  },

  edit (e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if ((e.which === 2) || e.metaKey) return
    e.preventDefault()
    this.trigger('edit')
  },

  toggleSelect (e) {
    var isChecked = $(e.target).is(':checked')
    this.model.trigger(isChecked ? 'select' : 'deSelect', this.model)
  },

  select () {
    this.$('.js-select')[0].checked = true
  },

  deSelect () {
    this.$('.js-select')[0].checked = false
  },

  render () {
    const getImageData = widget => widget.toJSON()

    const images = this.model.get('images').widgets
    const data = this.model.toJSON()
    const drUrl = config.darkroom.url
    const drKey = config.darkroom.salt
    const urlBuilder = createImageUrlBuilder(drUrl, drKey, this.model.get('images').widgets.map(getImageData))

    if (images.length) {
      data.imageUrl = urlBuilder.getImage('Main').crop('Landscape').constrain(150).url()
    } else {
      data.imageUrl = '/assets/img/content/no-photo-medium.png'
    }

    this.$el.empty().append(template({
      data,
      allowed: this.options.serviceLocator.allowed
    }))
    return this
  }
})
