const aggregationQueryCreator = require('./aggregation-query-creator')
const transformToReport = require('./report-transformer')

const fields = require('./fields')

const redemptionCountByCategory = () => ({
  createAggregationQuery: (options) => aggregationQueryCreator(options, 'category', 'offer', 'category'),
  transformToReport: (data, startDate, endDate) => transformToReport(data, startDate, endDate),
  fields
})

module.exports = redemptionCountByCategory
