const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowDelete, layout) {
buf.push("<div class=\"list-item-header\"><div class=\"list-item-actions\"><div class=\"btn-group\">");
if ( allowDelete)
{
buf.push("<button type=\"button\" class=\"btn btn--small js-btn-delete\">Delete</button>");
}
buf.push("</div></div><h2><a class=\"js-btn-edit\">" + (jade.escape(null == (jade_interp = layout.name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\">");
if ( layout.description)
{
buf.push("<p>" + (jade.escape(null == (jade_interp = layout.description) ? "" : jade_interp)) + "</p>");
}
else
{
buf.push("<p><i>No description</i></p>");
}
buf.push("</div>");}.call(this,"allowDelete" in locals_for_with?locals_for_with.allowDelete:typeof allowDelete!=="undefined"?allowDelete:undefined,"layout" in locals_for_with?locals_for_with.layout:typeof layout!=="undefined"?layout:undefined));;return buf.join("");
}
const modal = require('modal')

class LayoutListItemView extends View {
  constructor (serviceLocator, layoutDescriptor) {
    super(...arguments)
    this.layoutDescriptor = layoutDescriptor
    this.$el = $('<li/>')
    this.$el.addClass('list-item list-item--layout-manager')

    // Set up listeners
    this.$el.on('click', '.js-btn-edit', this.emit.bind(this, 'edit', this.layoutDescriptor))
    this.$el.on('click', '.js-btn-delete', this.confirmDelete.bind(this))

    // Update this view's model when a layout is saved
    this.listenTo(serviceLocator.layoutService, 'update', this.updateLayoutDescriptor.bind(this))
    this.listenTo(serviceLocator.sectionService, 'update', this.updateSection.bind(this))
  }

  render () {
    this.$el.empty().append(template({
      layout: this.layoutDescriptor.toJSON(),
      allowDelete: this.layoutDescriptor.allowDelete
    }))
    return this
  }

  updateLayoutDescriptor (event) {
    var isThisLayout =
      event.parentId === this.layoutDescriptor.get('parent')._id &&
      event.key === this.layoutDescriptor.get('key') &&
      event.isBuiltIn === this.layoutDescriptor.get('isBuiltIn')

    if (!isThisLayout) return
    this.layoutDescriptor.set('layout', event.layout)
    if (event.name) this.layoutDescriptor.set('name', event.name)
    if (event.description) this.layoutDescriptor.set('description', event.description)

    this.render()
  }

  updateSection (section) {
    if (this.layoutDescriptor.get('parent')._id === section._id) {
      this.layoutDescriptor.set('parent', section)
    }
  }

  delete () {
    this.serviceLocator.layoutService.delete(this.layoutDescriptor.toJSON(), err => {
      if (err) return alert(err)
      this.remove()
    })
  }

  confirmDelete () {
    modal(
      { title: 'Confirm Delete',
        content: 'Are you sure you want to delete this layout?',
        buttons:
        [ { text: 'Cancel', className: 'btn', event: 'cancel' },
          { text: 'Delete Layout', className: 'btn btn--error', event: 'confirm' }
        ]
      }).on('confirm', this.delete.bind(this))
  }
}

module.exports = LayoutListItemView
